import findIndex from "lodash/findIndex";
import Logger from "@/utils/Logger";
import NotifyCancelError from "./NotifyCancelError";

const Debug = false;
const Debug_Id = null;

const topics: { [key: string]: { id: string, handler: (topic: string, id: string, value: any) => boolean | void | Promise<boolean | void> }[] } = {};


/**
 * Stellt einen Nachrichtenübermittler zur Verfügung.
 */
export default {
    /**
     * Registriert einen Empfänger für ein Thema.
     *
     * @param topic - Das Thema.
     * @param id - Die Id des Empfängers.
     * @param fnc - Die auszuführende Funktion.
     */
    attach<T extends TNotifyTopic>(topic: T, id: string, fnc: (topic: string, id: string, value: TNotifyTopicPayload<T>) => boolean | void | Promise<boolean | void>) {
        if (Debug && !Debug_Id)
            Logger.warn(`Id [${id}] - starts listening to: [${topic}]`);
        else if (Debug && id === Debug_Id)
            Logger.warn(`Id [${id}] - starts listening to: [${topic}]`);

        if (topics[topic]?.some(t => t.id === id))
            this.detach(topic, id);

        if (!topics[topic])
            topics[topic] = [];

        topics[topic].push({ id, handler: fnc });
    },

    /**
     * Entfernt einen Empfänger aus einem Thema.
     *
     * @param topic - Das Thema.
     * @param id - Die Id des Empfängers.
     */
    detach(topic: string, id: string) {
        if (Debug && !Debug_Id)
            Logger.warn(`Id [${id}] - stops listening to: [${topic}]`);
        else if (Debug && id === Debug_Id)
            Logger.warn(`Id [${id}] - stops listening to: [${topic}]`);

        const receivers = topics[topic];

        if (!receivers)
            return;

        const index = findIndex(receivers, ["id", id]);

        if (index === -1)
            return;

        topics[topic].splice(index, 1);

        if (topics[topic].length === 0)
            delete topics[topic];
    },

    /**
     * Sendet ein Themenereignis an alle registrierten Empfänger.
     *
     * @param topic - Das Thema.
     * @param value - Ein Wert.
     */
    async notify(topic: string, value?: any): Promise<boolean> {
        if (Debug)
            Logger.warn(`Sending Topic [${topic}] to:`);

        let result = true;

        for (const receiver of (topics[topic] || [])) {
            if (Debug && !Debug_Id)
                Logger.warn(`- ${receiver.id}`);
            else if (Debug && receiver.id === Debug_Id)
                Logger.warn(`- ${receiver.id}`);

            try {
                const receiverResult = await receiver.handler(topic, receiver.id, value) !== false;

                if (result && !receiverResult)
                    result = false;
            } catch (e) {
                Logger.error("Fehler in der registrierten Methode:", e);
            }
        }

        return result
    },

    /**
     * Sendet ein Themenereignis an einen registrierten Empfänger.
     *
     * @param topic Das Thema.
     * @param id Die Id des Empfängers.
     * @param value Ein Wert.
     * @param waitForReceiver Wartet auf die Registrierung des Empfängers.
     *
     * @returns Ein abbrechbares Promise-Objekt.
     */
    notifyId<T extends TNotifyTopic>(topic: T, id: string, value?: TNotifyTopicPayload<T>, waitForReceiver:boolean = false): CancelablePromise | Promise<any> {
        let cancel = false;

        const delayedNotification = async (topic: string, id: string, value?: any, count = 0):Promise<any> => {
            if (Debug)
                Logger.warn(`(${count + 1}) Sending Topic [${topic}] to:`);

            if (count === 3000)
                return false;

            let result = true;

            const receiver = topics[topic]?.find(t => t.id === id);

            if (!receiver)
                return new Promise((resolve, reject) => {
                    if (cancel)
                        return reject(new NotifyCancelError("Promise abgebrochen"));

                    setTimeout(async () => {
                        if (cancel)
                            return reject(new NotifyCancelError("Promise abgebrochen"));

                        const result = await delayedNotification(topic, id, value, ++count);

                        resolve(result);
                    }, 100);
                });

            if (Debug && !Debug_Id)
                Logger.warn(`- ${receiver.id}`);
            else if (Debug && receiver.id === Debug_Id)
                Logger.warn(`- ${receiver.id}`);

            try {
                const receiverResult = await receiver.handler(topic, receiver.id, value) !== false;

                if (result && !receiverResult)
                    result = false;
            } catch (e) {
                Logger.error("Fehler in der registrierten Methode:", e);
            }

            return result
        };

        const immediateNotification = async ():Promise<any> => {
            const receiver = topics[topic]?.find(t => t.id === id);

            if (!receiver)
                return true;

            if (Debug)
                Logger.warn(`Sending Topic [${topic}] to:`);

            if (Debug && !Debug_Id)
                Logger.warn(`- ${receiver.id}`);
            else if (Debug && receiver.id === Debug_Id)
                Logger.warn(`- ${receiver.id}`);

            try {
                const receiverResult = await receiver.handler(topic, receiver.id, value) !== false;

                return receiverResult;
            } catch (e) {
                Logger.error("Fehler in der registrierten Methode:", e);
            }
        };

        if (waitForReceiver)
            return {
                promise: delayedNotification(topic, id, value),
                cancel: () => cancel = true,
            };

        return new Promise(async (resolve) => {
            const result = await immediateNotification();

            resolve(result);
        });
    }
};
