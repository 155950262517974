import overflows from "../private.overflows";
import Scroller from "../../index";

/**
 * Ruft die gespeicherte Callback-{@linkcode Function} auf, wenn sich
 * der Zustand der Skrollbar geändert hat.
 *
 * @this Scroller
 */
const notifyState = function() {
    (this.callbacks.state || (() => {}))({
        scrollable: {
            horizontal: this.scrollerState.horizontal.scrollable,
            vertical: this.scrollerState.vertical.scrollable
        },

        overflows: {
            top: overflows.call(this, "top"),
            bottom: overflows.call(this, "bottom")
        }
    });
};

export default notifyState;
