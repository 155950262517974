/**
 * Die aktuell berücksichtigten Auswertearten
 */
type ReportName = "balance" | "cash" | "company" | "default" | "estate" | "insurance" | "liquidity" | "pension" | "property" | "security" | "taxes";

type BaseColors = {
    /**
     * Die erste Basisfarbe als HEX-Code
     */
    base1: string,

    /**
     * Die zweite Basisfarbe als HEX-Code
     */
    base2: string
};

const ColorMappings = {
    reportTypes: {
        balance: {
            base1: "#204871",
            base2: "#DCDCDC"
        },

        cash: {
            base1: "#A57314",
            base2: "#DCDCDC"
        },

        company: {
            base1: "#5A3967",
            base2: "#DCDCDC"
        },

        insurance: {
            base1: "#9D4C2C",
            base2: "#DCDCDC"
        },

        liquidity: {
            base1: "#006400",
            base2: "#DCDCDC"
        },

        pension: {
            base1: "#6D241B",
            base2: "#DCDCDC"
        },

        property: {
            base1: "#C19F00",
            base2: "#DCDCDC"
        },

        security: {
            base1: "#C76E0F",
            base2: "#fc8b00",
        },

        taxes: {
            base1: "#85161E",
            base2: "#DCDCDC"
        },

        estate: {
            base1: "#006796",
            base2: "#DCDCDC"
        },

        default: {
            base1: "#204871",
            base2: "#DCDCDC"
        }
    },

    /**
     * Liefert die Basisfarben einer Auswertung
     *
     * @param {ReportName} reportType
     *
     * @returns {BaseColors}
     */
    getColorsByReportType(reportType:ReportName):BaseColors {
        if (!this.reportTypes[reportType])
            return this.reportTypes.default;

        return this.reportTypes[reportType];
    },

    /**
     * Liefert die Standardfarben für eine unbekannte Auswertung
     *
     * @returns {BaseColors}
     */
    getDefaultColors():BaseColors {
        return this.reportTypes.default;
    },

    /**
     * Liefert die möglichen Auswertungstypen
     *
     * @returns {ReportName[]}
     */
    getReportTypes():ReportName[] {
        return Object.keys(this.reportTypes).filter(k => k !== "default") as ReportName[];
    }
};

export type { ReportName };

export { ColorMappings };
