import axios from "axios";
import Constant from "@/utils/Constant";
import Logger from "@/utils/Logger";

/**
 * Prüft, ob es sich bei dem Fehlerobjekt um mehrere Fehlermeldungen handelt
 *
 * @param error - Der Fehler
 *
 * @returns Der Wert, ob es sich um mehrere Fehlermeldungen handelt
 */
const hasMultipleErrors = (error: XhrErrorResponse<"multiple">): boolean => {
    if (!error.response?.data?.errors)
        return false;

    return Object.keys(error.response.data.errors).length > 0;
};

/**
 * Verarbeitet ein {@link ReadableStream} und liefert ein JSON-Objekt zurück
 *
 * @param stream Der Stream
 *
 * @returns Das JSON-Objekt
 */
const processStream = async (stream: ReadableStream) => {
    const reader = stream.getReader();
    const decoder = new TextDecoder();

    const readStream = async (jsonString = ""): Promise<any> => {
        const { done, value } = await reader.read();

        if (done)
            return JSON.parse(jsonString);

        // Fortfahren, bis der Stream vollständig gelesen ist
        return readStream(`${jsonString}${decoder.decode(value, { stream: true })}`);
    }

    // Starte das Lesen des Streams
    return readStream() as Promise<{
        display: {
            detail: string
            title: string
        }
    }>;
};

/**
 * Stellt eine Klasse zur einfacheren Handhabung eines XmlHttpRequest-Fehlers zur Verfügung.
 */
class XhrError {
    /**
     * Der XML-HttpRequest-Fehler.
     */
    private xhrError: unknown;

    /**
     * Das Modal, das die Fehlermeldung anzeigt.
     */
    private $modal?: IModalApi;

    /**
     * Reject Methode.
     */
    private reject?: () => Promise<any> = (): Promise<any> => Promise.reject();

    /**
     * C'tor
     *
     * @param options - Die Konfiguration
     * @param options.e - Der Xhr-HttpRequest-Fehler
     * @param options.$modal - Das Modal, das die Fehlermeldung anzeigt
     * @param options.reject - Eine Reject Methode, die aufgerufen wird, wenn ein Fehler auftritt
     */
    constructor({ e, $modal, reject }: { e: unknown, $modal?: IModalApi, reject?: (value?:any) => Promise<any>|any|void }) {
        this.xhrError = e;
        this.$modal = $modal;
        this.reject = reject;
    }

    /**
     * Liefert einen Wert, der angibt, ob der Client keine aktive Internetverbindung hat.
     *
     * @returns Der Zustand, ob der Client keine aktive Internetverbindung hat
     */
    static isOffline(): boolean {
        return !window.navigator.onLine;
    }

    /**
     * Liefert die Problembeschreibung der Serverantwort.
     *
     * @param options - Die Konfiguration
     * @param options.defaultTitle - Der Standardtitel
     * @param options.defaultDetail - Die Standardfehlermeldung
     *
     * @returns Die Problembeschreibung
     */
    async getProblem({ defaultTitle = "Fehler", defaultDetail = "Ein unbekannter Fehler ist aufgetreten.<br>Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut." }: { defaultTitle?: string, defaultDetail?: string } = {}): Promise<ProblemDetails> {
        // Response Blob versuchen:
        try {
            const blobError = this.xhrError as XhrErrorResponse<"blob">;
            const data = await blobError.response.data.text();
            const parsed = JSON.parse(data) as { status: number, title: string, detail: string };

            return {
                code: parsed.status,
                title: parsed.title,
                detail: parsed.detail
            };
        } catch (noBlobError) {
            // Response Arraybuffer versuchen:
            const bufferError = this.xhrError as XhrErrorResponse<"arraybuffer">;

            try {
                const uArray = new Uint8Array(bufferError.response.data);
                const parsed = JSON.parse(new TextDecoder("utf-8").decode(uArray)) as { status: number, title: string, detail: string };

                return {
                    code: parsed.status,
                    title: parsed.title,
                    detail: parsed.detail
                };
            } catch (noArrayBufferError) {
                try {
                    const body = await processStream((this.xhrError as XhrErrorResponse<"readableStream">).response.data);

                    return {
                        code: (this.xhrError as XhrErrorResponse<"readableStream">).response.status ?? 404,
                        title: body.display.title ?? defaultTitle,
                        detail: body.display.detail ?? defaultDetail
                    };
                } catch (e) {
                    // Response Json als ProblemDetails versuchen:
                    const multiError = this.xhrError as XhrErrorResponse<"multiple">;

                    if (hasMultipleErrors(multiError)) {
                        let message = "";

                        const errors = Object
                            .values(multiError.response.data.errors ?? { "Unbekannter Fehler": defaultDetail })
                            .flat();

                        [...new Set<string>(errors)]
                            .forEach(msg => message += `${msg}<br>`);

                        return {
                            code: multiError.response.status,
                            title: defaultTitle,
                            detail: message
                        };
                    }

                    const singleError = this.xhrError as XhrErrorResponse<"single">;

                    if (singleError.response?.data?.display)
                        return {
                            code: singleError.response.status || singleError.status || 503,
                            title: singleError.response.data.display.title ?? defaultTitle,
                            detail: singleError.response.data.display.detail ?? defaultDetail
                        };

                    const singleDisplayError = this.xhrError as XhrErrorResponse<"singleDisplay">;

                    // Response Json als ProblemDetails versuchen:
                    return {
                        code: singleDisplayError.response?.status ?? 503,
                        title: singleDisplayError.response?.data?.title ?? defaultTitle,
                        detail: singleDisplayError.response?.data?.detail ?? defaultDetail
                    };
                }
            }
        }
    }

    /**
     * Gibt an, ob der Server einen Fehler gesendet hat.
     *
     * @returns Der Zustand, ob der Server einen Fehler gesendet hat
     */
    isServerError(): boolean {
        return !XhrError.isOffline() && !this.isForbidden() && !this.isUnauthorized() && !(this.xhrError as XhrErrorResponse<"single">).status;
    }

    /**
     * Gibt an, ob der Client im Rahmen eines Serveranfrage einen Fehler verursacht hat.
     *
     * @returns Der Zustand, ob der Client im Rahmen einer Serveranfrage einen Fehler verursacht hat
     */
    isClientError(): boolean {
        return !XhrError.isOffline() && !this.isServerError() && !this.isForbidden() && !this.isUnauthorized();
    }

    /**
     * Gibt an, ob der Client nicht berechtigt ist, auf die Serveranfrage eine Serverantwort zu erhalten.
     *
     * @returns Der Zustand, ob der Client nicht berechtigt ist, auf die Serveranfrage eine Serverantwort zu erhalten
     */
    isForbidden():boolean {
        return !XhrError.isOffline() && (this.xhrError as XhrErrorResponse<XhrErrorType>).response?.status === 403;
    }

    /**
     * Gibt an, ob der Client nicht angemeldet ist.
     *
     * @returns Der Zustand, ob der Client nicht angemeldet ist
     */
    isUnauthorized():boolean {
        return !XhrError.isOffline() && (this.xhrError as XhrErrorResponse<XhrErrorType>).response?.status === 401;
    }

    /**
     * Gibt an, ob die Resource nicht gefunden wurde.
     *
     * @returns Der Zustand, ob die Resource nicht gefunden wurde.
     */
    isNotFound():boolean {
        return !XhrError.isOffline() && (this.xhrError as XhrErrorResponse<XhrErrorType>).response?.status === 404;
    }

    /**
     * Ruft eine Callback-Methode auf, wenn die Serveranfrage durch den Benutzer abgebrochen wurde.
     *
     * @param callback - Die Callback-Methode
     *
     * @returns Die Instanz
     */
    onCancel(callback: () => void): this {
        if (!axios.isCancel(this.xhrError))
            return this;

        try {
            callback();
        } catch (e) {
            Logger.error("Fehler in der Abbruch-Funktion", e);
        }

        return this;
    }

    /**
     * Ruft eine Callback-Methode auf, wenn der Client einen Fehler im Rahmen eines Serveranfrage verursacht hat.
     *
     * @param callback - Die Callback-Methode
     *
     * @returns Die Instanz
     */
    onClientError(callback?: () => void): this {
        if ((axios.isCancel(this.xhrError)))
            return this;

        if (!this.isClientError())
            return this;

        try {
            if (callback)
                callback();
            else if (this.$modal)
                this.$modal.show("dialog", {
                    title: "Fehler",
                    message: "Ein unbekannter Fehler ist aufgetreten. Bitte wiederholen Sie den Vorgang zu einem späteren Zeitpunkt erneut.",

                    buttons: {
                        cancel: {
                            caption: Constant.Button.OK.Caption,
                            accesskey: Constant.Button.OK.Accesskey,

                            callback: this.reject
                        }
                    }
                });
        } catch (e) {
            Logger.error("Client Fehler", e);
        }

        return this;
    }

    /**
     * Ruft eine Callback-Methode auf, wenn der Client keine Berechtigung zur Serveranfrage hat.
     *
     * @param callback - Die Callback-Methode
     *
     * @returns Die Instanz
     */
    onForbidden(callback?: () => void): this {
        if ((axios.isCancel(this.xhrError)))
            return this;

        if (!callback)
            return this;

        if (!this.isForbidden())
            return this;

        try {
            callback();
        } catch (e) {
            Logger.error("Authorisierungsproblem", e);
        }

        return this;
    }

    /**
     * Ruft eine Callback-Methode auf, wenn der Client nicht angemeldet ist.
     *
     * @param callback - Die Callback-Methode
     *
     * @returns Die Instanz
     */
    onUnauthorized(callback?: () => void): this {
        if ((axios.isCancel(this.xhrError)))
            return this;

        if (!this.isUnauthorized())
            return this;

        try {
            if (callback)
                callback();
            else if (this.$modal)
                this.$modal.show("dialog", {
                    title: "Autorisierung",
                    message: "Sie sind nicht angemeldet. Bitte melden Sie sich an, um diese Funktion nutzen zu können.",

                    buttons: {
                        cancel: {
                            caption: Constant.Button.OK.Caption,
                            accesskey: Constant.Button.OK.Accesskey,

                            callback: this.reject
                        }
                    }
                });
        } catch (e) {
            Logger.error("Authorisierungsproblem", e);
        }

        return this;
    }

    /**
     * Ruft eine Callback-Methode auf, wenn der Client keine
     * Internetverbindung hat.
     *
     * @param callback - Die Callback-Methode
     *
     * @returns Die Instanz
     */
    onOffline(callback?: () => void): this {
        if ((axios.isCancel(this.xhrError)))
            return this;

        if (!XhrError.isOffline())
            return this;

        try {
            if (callback)
                callback();
            else if (this.$modal)
                this.$modal.show("dialog", {
                    title: "Verbindungsprobleme",
                    message: "Bitte überprüfen Sie Ihre Internetverbindung.",

                    buttons: {
                        cancel: {
                            caption: Constant.Button.OK.Caption,
                            accesskey: Constant.Button.OK.Accesskey,

                            callback: this.reject
                        }
                    }
                });
        } catch (e) {
            Logger.error("Client ist offline", e);
        }

        return this;
    }

    /**
     * Ruft eine Callback-Methode auf, wenn der Server einen
     * Fehler gesendet hat.
     *
     * @param callback - Die Callback-Methode
     *
     * @returns Die Instanz
     */
    onServerError(callback?: () => void): this {
        if ((axios.isCancel(this.xhrError)))
            return this;

        if (!this.isServerError())
            return this;

        try {
            if (callback)
                callback();
            else if (this.$modal)
                this.$modal.show("dialog", {
                    title: "Fehler",
                    message: "Ein unbekannter Fehler ist aufgetreten. Bitte wiederholen Sie den Vorgang zu einem späteren Zeitpunkt erneut.",

                    buttons: {
                        cancel: {
                            caption: Constant.Button.OK.Caption,
                            accesskey: Constant.Button.OK.Accesskey,

                            callback: this.reject
                        }
                    }
                });
        } catch (e) {
            Logger.error("Serverfehler während der Abfrage", e);
        }

        return this;
    }
}

export default XhrError;
