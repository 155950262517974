import Scroller from "../../index";

const onMouseUp = {
    /**
     * Registriert das {@linkcode MouseEvent} bei lossgelassener Maustaste.
     *
     * @this Scroller
     */
    attach: function() {
        if (this.isIFrame)
            return;

        document.addEventListener("mouseup", this.registeredHandler.dragState);
    },

    /**
     * Entfernt das {@linkcode MouseEvent} bei lossgelassener Maustaste.
     *
     * @this Scroller
     */
    detach: function() {
        if (this.isIFrame)
            return;

        document.removeEventListener("mouseup", this.registeredHandler.dragState);
    }
};

export default onMouseUp;
