import cloneDeep from "lodash/cloneDeep";
import flatten from "lodash/flatten";
import { mapFailed, mapUploaded } from "./mapper";
import axios from "axios";
import XhrError from "@/utils/XhrError/XhrError";
import prepareFormData from "./prepareFormData";
// eslint-disable-next-line
import UploadFile from "./models/UploadFile";

/**
 * Versendet die Transferpakete der Warteschlange.
 *
 * @param {{url: String, query: String, queue: Array<UploadFile[]>, axiosConfig: Object, maxTransferSize: Number, next: Function, cancelled: Function}} options
 */
const sendQueue = async ({url, queue, axiosConfig = {}, maxTransferSize = 0, query, next = () => { return true; }, cancelled = () => {}}) => {
    let qsa = query ? `?${query}` : "";

    let amount = {
        total: flatten(queue).length,
        sent: 0,
        failed: 0
    };

    let bytes = {
        total: 0,
        loaded: 0
    };

    flatten(queue)
        .forEach(uploadFile => bytes.total += uploadFile.file.size);

    for (let i = 0; i < queue.length; i++) {
        const formData = prepareFormData({uploadFiles: queue[i], maxTransferSize, url});
        const abortController = new AbortController();
        const cumulatedSizes = [];

        let currentTotal = 0;
        let currentFiles = 0;

        queue[i].forEach((uploadFile, i) => {
            const cumulatedSize = i > 0 ? cumulatedSizes[i - 1].cumulatedSize + uploadFile.file.size : uploadFile.file.size;

            cumulatedSizes.push({
                cumulatedSize,
                uploadFile
            });
        });

        const maxSize = cumulatedSizes[cumulatedSizes.length - 1].cumulatedSize;

        axiosConfig.signal = abortController.signal;

        axiosConfig.onUploadProgress = progressEvent => {
            if (!currentTotal)
                currentTotal = progressEvent.total;

            const fileIndex = cumulatedSizes
                .findIndex(fileInfo => fileInfo.cumulatedSize >= Math.min(maxSize, progressEvent.loaded)) + 1;

            if (fileIndex > -1)
                currentFiles = fileIndex;

            const files = cloneDeep(cumulatedSizes)
                .splice(0, fileIndex)
                .map(fileInfo => fileInfo.uploadFile);

            axiosConfig.onProgress({
                amount: {
                    total: amount.total,
                    sent: amount.sent + currentFiles,
                    failed: amount.failed
                },

                bytes: {
                    loaded: progressEvent.lengthComputable
                        ? progressEvent.loaded + bytes.loaded
                        : 0,

                    total: bytes.total,
                },

                files
            });
        };

        try {
            if (!next({abortController}))
                return;

            const overwrite = queue[i][0].overwrite;
            let qsaOverwrite = "";

            if (overwrite)
                qsaOverwrite = qsa.length || url.split("/").at(-1).includes("?") ? "&overwrite=true" : "?overwrite=true";

            const { data, status } = await axios.put(`${url}${qsa}${qsaOverwrite}`, formData, axiosConfig);

            let failed = status === 202
                ? []
                : mapFailed({
                    detail: data.detail,
                    conflictPaths: data.conflicts,
                    errorPaths: data.errors,
                    queueSent: queue[i]
                });

            const uploaded = mapUploaded({
                uploaded: data.uploaded || [],
                queueSent: queue[i]
            });

            bytes.loaded += currentTotal;
            amount.failed += failed.length;
            amount.sent = queue
                .filter((arr, idx) => idx < i + 1)
                .map(arr => arr.length)
                .reduce((value, length) => value + length, 0);

            axiosConfig.onProgress({
                bytes,
                amount
            });

            if (!next({abortController, response: { failed, uploaded, loaded: bytes.loaded, total: bytes.total}}))
                return;
        } catch (e) {
            if (axios.isCancel(e)) {
                cancelled();

                return;
            }

            const isOffline = XhrError.isOffline();

            if (!e.response && !isOffline) {
                console.error(e);

                return;
            }

            let failed = null;
            const { data } = !isOffline
                ? e.response
                : { data: { detail: "Keine Internetverbindung" } };

            const hasProblemDetails = data && (data.conflicts || data.errors);

            failed = mapFailed({
                detail: data ? data.detail : null,

                conflictPaths: hasProblemDetails
                    ? data.conflicts
                    : [],

                errorPaths: hasProblemDetails
                    ? data.errors
                    : !hasProblemDetails ? queue[i].map(uploadFile => uploadFile.path) : [],

                queueSent: queue[i]
            });

            const uploaded = mapUploaded({
                uploaded: data && data.uploaded || [],
                queueSent: queue[i]
            });

            bytes.loaded += currentTotal;
            amount.failed += failed.length;
            amount.sent = amount.sent = queue
                .filter((arr, idx) => idx < i + 1)
                .map(arr => arr.length)
                .reduce((value, length) => value + length, 0);

            axiosConfig.onProgress({
                bytes,
                amount
            });

            if (!next({abortController, response: { failed, uploaded, loaded: bytes.loaded, total: bytes.total }}))
                return;
        }
    }
};

export default sendQueue;
