export default function (e) {
    /**
     * Status shiftKey zurücksetzen?
     */
    if (e.keyCode === 16) {
        this.state.shift.active = false;

        if (this.state.selected.length === 0)
            this.state.shift.position = -1;
    }

    /**
     * Status ctrlKey zurücksetzen?
     */
    if (e.keyCode === 17)
        this.state.ctrl.active = false;
}