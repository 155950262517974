import getScrollbarRatio from "../private.getScrollbarRatio";
import Scroller from "../../index";

/**
 * Aktualisiert die Thumbgröße der übergebenen Achse
 *
 * @param {"horizontal"|"vertical"} axis
 */
function updateThumbSize(this: Scroller, axis: "horizontal" | "vertical") {
    const bar = axis == "horizontal" ? this.elements.barHoz : this.elements.barVert;
    const thumb = bar.querySelector(".scrollthumb") as HTMLElement | null;

    if (!thumb)
        return;

    const ratio = getScrollbarRatio.call(this, axis);
    const thumbOffset = axis === "horizontal" ? this.scrollerState.horizontal.left : this.scrollerState.vertical.top;
    const trackProp = axis === "horizontal" ? "width" : "height";
    const thumbSize = this.scroll === "normal"
        ? Math.max((bar.getBoundingClientRect()[trackProp] - thumbOffset) * ratio, this.bars.minSize)
        : ((bar.getBoundingClientRect()[trackProp] - thumbOffset) / this.scrollerState.pages) - this.scrollerState.gap;
    const styleProp = axis === "horizontal" ? "width" : "height";

    if (axis === "horizontal")
        this.scrollerState.horizontal.thumbWidth = thumbSize;
    else
        this.scrollerState.vertical.thumbHeight = thumbSize;

    thumb.style[styleProp] = `${thumbSize}px`;
};

export default updateThumbSize;
