import { isEmptyWs } from "../string";
import defaultOptions from "./defaultOptions";
import { marked } from "marked";
import moment from "moment";
import "moment/dist/locale/de";

/**
 * @typedef {("asCurrency"|"asCurrencyNoSymbol"|"asDouble"|"asPercent"|"asSquareMeter"|"asMarkdown")} Format
 */

/**
 * Bereitet das Options Objekt für den Formatierer vor.
 *
 * @param {{minDecimals: number, maxDecimals: number }} options
 *
 * @returns {{minimumFractionDigits: number, maximumFractionDigits: number}}
 */
const prepareDigits = ({minDecimals = 0, maxDecimals = 2} = {}) => {
    return {
        minimumFractionDigits: minDecimals,
        maximumFractionDigits: maxDecimals
    };
};

/**
 * Gibt die Lokalisierung aus dem Optionsparameter zurück
 *
 * @param {{locale: string}} options
 *
 * @returns {string}
 */
const getLocale = ({locale = "de-DE"} = {}) => locale;

/**
 * Liefert einen per {@linkcode Intl.NumberFormat} konvertierten Wert
 *
 * @param {Format} formatType
 * @param {string|number} value
 * @param {{locale: string, minDecimals: number, maxDecimals: number}} options
 *
 * @returns {string|number} Der formatierte Wert.
 */
function runFormatter(formatType, value, {currency = "EUR", locale, minDecimals, maxDecimals} = {}) {
    const formatOptions = {...defaultOptions[formatType], currency, ...prepareDigits({minDecimals, maxDecimals})};

    return new Intl.NumberFormat(getLocale({locale}), formatOptions).format(value);
}

/**
 * Stellt Konvertierfunktionen zur Verfügung
 *
 * @param {(boolean|number|string|object)} value
 */
// eslint-disable-next-line
const Formatter = value => {
    return {
        /**
         * Konvertiert einen {@linkcode string}- oder {@linkcode number}-Wert
         * zu einer {@linkcode string}-Währung mit Währungssymbol
         *
         * @param {{currency?: string, locale?: string, minDecimals: number, maxDecimals: number}} options
         *
         * @returns {string}
         */
        asCurrency: ({currency, locale, minDecimals, maxDecimals}) =>
            runFormatter("asCurrency", value, {
                currency,
                locale,
                minDecimals,
                maxDecimals
            }),

        /**
         * Konvertiert einen {@link string}- oder {@linkcode number}-Wert
         * zu einer {@linkcode string}-Währung ohne Währungssymbol
         *
         * @param {{locale?: string, minDecimals: number, maxDecimals: number}} options
         *
         * @returns {string}
         */
        asCurrencyNoSymbol: ({locale, minDecimals, maxDecimals}) =>
            runFormatter("asCurrencyNoSymbol", value, {
                locale,
                minDecimals,
                maxDecimals
            }),

        /**
         * Konvertiert einen {@linkcode string}-Wert oder {@linkcode number}-Zeitstempel
         * in einen {@linkcode string}-Datumswert nach vorgegebenem Datumsformat (vgl. Datumsparameter {@link https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Date})
         *
         * @param {{format: string}} options
         *
         * @returns {string}
         */
        asDate({format = "DD.MM.YYYY"} = {}) {
            /** Keine Instanz von Date und Wert leer? */
            if (!(value instanceof Date) && (!value || isEmptyWs(value)))
                return "";

            return moment(new Date(value)).format(format);
        },

        /**
         * Konvertiert einen {@linkcode string}- oder {@linkcode number}-Wert in
         * einen {@linkcode string}-Wert mit Dezimalstellen und Tausendertrenner
         * nach Lokalisierungsvorgabe
         *
         * @param {{locale: string, minDecimals: number, maxDecimals: number}} options

         * @returns {string}
         */
        asDouble: ({locale, minDecimals, maxDecimals}) =>
            runFormatter("asDouble", value, {
                locale,
                minDecimals,
                maxDecimals
            }),

        /**
         * Placebokonvertierer aufgrund der ServiceUI-Api
         *
         * @returns {string}
         */
        asIcon: () => value,

        /**
         * Konvertiert einen {@link string} in einen {@linkcode number}-Wert
         *
         * @returns {number}
         */
        asInteger: () => parseInt(value, 10),

        /**
         * Konvertiert eine Markdown-Zeichenkette in eine HTML-Zeichenkette
         *
         * @returns {string}
         */
        asMarkdown: () => marked.parse(value),

        /**
         * Placebokonvertierer aufgrund der ServiceUI-Api
         *
         * @returns {any}
         */
        asNull: () => value,

        /**
         * Konvertiert einen {@linkcode string} in einen {@linkcode number}-Wert
         * unter Beibehaltung von Dezimalstellen als Punktnotation
         *
         * @returns {number}
         */
        asNumber: () => typeof value === "string"
            ? Number(value.replace(/\./g, "").replace(/,/, "."))
            : 0,

        /**
         * Konvertiert einen {@linkcode string}- oder {@linkcode number}-Wert
         * zu einem {@linkcode string}-Prozentwert mit den vorgegebenen Nachkommastellen
         *
         * @param {{locale?: string, minDecimals: number, maxDecimals: number}} options
         *
         * @returns {string}
         */
        asPercent: ({locale, minDecimals, maxDecimals}) =>
            runFormatter("asPercent", value, {
                locale,
                minDecimals,
                maxDecimals
        }),

        /**
         * Konvertiert einen {@linkcode string}- oder {@linkcode number}-Wert
         * in einen {@linkcode string}-Wert unter Berücksichtigung der
         * vorgegebenen Nachkommastellen und häng den {@linkcode string}-Wert "qm" an das Ende ein
         *
         * @param {{locale: string, minDecimals: number, maxDecimals: number}} options
         *
         * @returns {string}
         */
        asSquaremeter: ({locale, minDecimals, maxDecimals}) =>
            `${runFormatter("asSquareMeter", value, {locale, minDecimals, maxDecimals})} m²`,

        /**
         * Konvertiert einen {@linkcode string}- oder {@linkcode number}-Wert
         * in einen {@linkcode string}-Wert unter Berücksichtigung der
         * vorgegebenen Nachkommastellen und häng den {@linkcode string}-Wert "GByte"
         * an das Ende ein
         *
         * @param {{locale: string, minDecimals: number, maxDecimals: number}} options
         *
         * @returns {string}
         */
        asStorage: ({locale, minDecimals, maxDecimals}) =>
            `${runFormatter("asDouble", value, {locale, minDecimals, maxDecimals})} GByte`,

        /**
         * Konvertiert ein {@linkcode boolean}, {@linkcode number} oder {@linkcode object} zu einen
         * {@linkcode string}
         *
         * @returns {string}
         */
        asString: () => {
            if (typeof value === "object")
                return JSON.stringify(value);

            try {
                return value.toString();
            } catch (e) {
                return value;
            }
        }
    };
};

export default Formatter;
