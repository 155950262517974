import { escapePath } from "../pathConverter";
// eslint-disable-next-line
import UploadFile from "../Uploader/models/UploadFile";

/**
 * Bereitet Formulardaten für einen Dateitransfer vor und gibt diese zurück.
 *
 * @param {{uploadFiles: UploadFile[]}} options
 *
 * @returns {formData: FormData}
 */
const prepareFormData = ({uploadFiles}) => {
    const formData = new FormData();

    uploadFiles.forEach(uploadFile =>
        formData.append("File", uploadFile.file, escapePath(uploadFile.dispositionPath))
    );

    return formData;
};

export default prepareFormData;
