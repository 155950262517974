import axios from "axios";
// @ts-ignore
import combineURLs from "axios/lib/helpers/combineURLs";
import CrmContact from "@/apis/crm/models/CrmContact";
import DriveAuth from "./DriveAuth";

/**
 * Stellt eine Benutzer-Klasse zur Verfügung.
 */
class DriveUsers {
    /**
     * Privates Feld: Authorisierungsmethoden
     */
    _auth:DriveAuth;

    /**
      * Privates Feld: Basisadresse.
      */
    _baseUrl:string|null = null;

    /**
     * C'tor
     *
     * Setzt die Basisadresse für den Api-Aufruf.
     *
     * @param baseUrl - Die Basisadresse.
     * @param auth - Die Authorisierungsmethoden.
     */
    constructor(baseUrl:string|null, auth:DriveAuth) {
        this._auth = auth;
        this._baseUrl = baseUrl;
    }

    /**
     * Liefert alle Kontakte mit DMS-Zugriff des Mandanten.
     *
     * @returns Die Liste der Kontakte.
     */
    async getContact(guid:string) {
        const { data = [] } = await axios.get(combineURLs(this._baseUrl, `contacts/${guid}`), {
            headers: {
                Authorization: `Bearer ${this._auth?.AccessToken}`
            }
        });

        return new CrmContact(data);
    }
}

export default DriveUsers;
