<script lang="ts" setup>
import type { PropType } from "vue";
import { computed, ref, watch } from "vue";
import { injectUtility } from "@/utils/utility.helper";
import { isEmptyWs } from "@/utils/string";
import cloneDeep from "lodash/cloneDeep";
import kebabCase from "lodash/kebabCase";
import Button from "./Button/Button.vue";
import Checkbox from "@/components/UI/Checkbox.vue";
import Clipboard from "@/utils/Clipboard";
import FileUpload from "./FileUpload.vue";
import FilterButton from "@/components/UI/FilterButton.vue";
import FilterUtils from "@/utils/Filter/index";
import murmurhash from "murmurhash";
import Search from "./Search.vue";
import Select from "@/components/UI/Select/Select.vue";
import Urls from "@/utils/Urls";
import useCacheUtils from "@/mixins/composition.cache";
import useComponentUtils from "@/mixins/composition.component";
import FilterModel from "@/utils/Filter/FilterModel";

const $cache = injectUtility("Cache");
const $reactiveCache = injectUtility("ReactiveCache");
const $config = injectUtility("Config");
const $page = injectUtility("Page");
const $request = injectUtility("Request");

/**
 * Das {@linkcode HTMLElement} Wurzelelement der Toolbar
 */
const root = ref<HTMLElement>();

/**
 * Die {@linkcode HTMLElement} Steuerelemente
 */
const items = ref<(InstanceType<typeof Button> | InstanceType<typeof Checkbox> | InstanceType<typeof FileUpload> | InstanceType<typeof FilterButton> )[]>([]);

const { componentId } = useComponentUtils({
    node: root,
    plugins: {
        page: $page,
    }
});

const cache = useCacheUtils({
    componentId,
    cache: $cache,
    reactiveCache: $reactiveCache
});

type ICachedItem = {
    member: string
    page: number
    value: string
    columns: string[]
    ordering: string[]
    queries: { [key: number]: any }
}

/**
 * Der Cache aller Toolbar-Dropdown-Queries zur Reduzierung der Serveranfragen.
 */
const queryCache: ICachedItem[] = cache.id("ToolbarSelect").get("queries") || [];

const emit = defineEmits(["btnClick", "clearFilter", "setFilter", "setSearchValue", "setUploadFiles"]);

const props = defineProps({
    /**
     * Der initiale aktive Control-Element Index (wird durch die Elternkomponente vorgegeben).
     */
    activeIndex: {
        type: Number,
        default: 0
    },

    /**
     * Gibt an, ob die Werte (z.b. letzte Auswahl eines Dropdowns) für den nächsten Aufruf gecacht werden dürfen.
     */
    cachable: {
        type: Boolean,
        default: true
    },

    /**
     * Die von der Elternkomponente übergebenen Toolbar-Steuerelemente.
     *
     * @type {IToolbarControl[]}
     */
    controls: {
        type: Array as PropType<IToolbarControl[]>,
        default: () => []
    },

    /**
     * Ein Css-Klassen-String, welche auf sämtliche Toolbar-Root-Elemente angewandt wird.
     */
    cssGroup: {
        type: String,
        default: ""
    },

    /**
     * Gibt an, ob die Toolbarelemente deaktiviert sind (es werden keine Ereignisse bei einer Benutzerinteraktion ausgelöst).
     */
    disabled: {
        type: Boolean,
        default: false
    },

    /**
     * Die Anzeigeart der Toolbar-Elemente.
     */
    displayAs: {
        type: String as PropType<"default" | "editor">,
        default: "default"
    },

    /**
     * Die Id der Elternkomponente.
     */
    parentId: {
        type: String,
        default: null
    },

    /**
     * Gibt an, ob die Ereignisse der sichbaren Elemente {@linkcode visibleControls} sowie {{@linkcode visibleRightControls}} an die Pendanten der Vue-Komponenten (Button, Select, etc) weitergegeben werden sollen
     *
     * HINWEIS: Wird derzeitig nur von der {@linkcode Button}-Komponente unterstützt.
     */
    passEvents: {
        type: Boolean,
        default: false
    },

    /**
     * Das Thema des Clipboard zum möglichen Einfügen von Daten aus dem Clipboard.
     */
    pasteTopic: {
        type: String,
        default: null
    },

    /**
     * Die von der Elternkomponente übergebenen Toolbar-Steuerelemente (rechte Seite).
     */
    rightControls: {
        type: Array as PropType<IToolbarControl[]>,
        default: () => []
    },

    /**
     * Der Suchbegriff für die Toolbar-Suche, falls vorhanden.
     */
    searchValue: {
        type: String,
        default: ""
    },

    /**
     * Ein Wert, der angibt, ob keine, eine oder mehrere Zeilen ausgewählt wurden.
     */
    selected: {
        type: String as PropType<"none" | "single" | "multiple">,
        default: "none"
    }
});

/**
 * Gibt den Index des aktiven Control-Elements zurück.
 */
const mutableActiveIndex = ref(props.activeIndex);

/**
 * Die darzustellenden Toolbar-Steuerelemente.
 */
const visibleControls = ref<IToolbarControl[]>([]);

/**
 * Die darzustellenden Toolbar-Steuerelemente (rechte Seite).
 */
const visibleRightControls = ref<IToolbarControl[]>([]);

/**
 * Die Anzahl der in den Dom fertig eingehängten Toolbar-Elemente.
 */
const readyControls = ref(0);

/**
 * Die Anzeigeart der Toolbar-Elemente.
 */
const buttonShape = computed(() => props.displayAs === "editor" ? "rect" : "round");

/**
 * Gibt einen Css-Klassen-String zurück, welcher auf alle Toolbar-Root-Elemente
 * angewandt wird.
 */
const cssClasses = computed(() => {
    return `toolbar__select${props.cssGroup.length > 0 ? ` toolbar__select--${props.cssGroup}` : ""}`
});

/**
 * Die Anzahl der Controls, auf die noch gewartet wird.
 */
const pendingControls = computed(() => {
    let pendingCount = visibleControls.value.filter(({ $type }) => $type === "select").length;

    pendingCount += visibleRightControls.value.filter(({ $type }) => $type === "select").length;

    return pendingCount;
});

/**
 * Liefert den Status, ob alle verfügbaren Toolbar-Elemente fertig in den
 * Dom eingehangen wurde.
 */
const isVisible = computed(() => readyControls.value >= pendingControls.value);

/**
 * Holt alle Optionen eines Elements vom Endpunkt.
 */
const queryOptions = async ({ control = {}, matches = [], member, options = [], selected, value }: { control: Partial<IToolbarControl>, matches?: ISelectOption[], member: string, options: ISelectOption[], selected?: string | number | null, value: string | null }): Promise<(ISelectOption & { selectable?: boolean, align?: "left" | "center" | "right" })[]> => {
    const isCachable = control.isCachable !== false;
    const idxCacheItem = queryCache.findIndex(chacheItem => chacheItem.member === member);
    const cacheItem = idxCacheItem > -1 ? queryCache[idxCacheItem] : { page: 0, value, columns: [], ordering: [], queries: {}, member } as ICachedItem;

    if (idxCacheItem > -1 && matches.length === 0)
        if (cacheItem.value != value) {
            cacheItem.page = 0;
            cacheItem.value = value ?? "";
        } else if (options.length === 0)
            cacheItem.page = 0;
        else
            cacheItem.page++;

    const services = control.services;

    if (cacheItem.columns.length === 0) {
        const configUrl = services
            ? `${Urls(services, $config).View.config()}?type=select`
            : "";

        const { columns = [], ordering = {} }: { columns: IViewColumn[], ordering: any } = (await $request.get({
            url: configUrl
        })).data;

        cacheItem.columns = columns.map(column => column.member);
        cacheItem.ordering = ordering.map(({ member = null, order = "ascending" }) => {
            return {
                property: member,
                order
            };
        });
    }

    const skip = member ? cacheItem.page * 10 : 0;

    const content = {
        filter: "",
        search: member && !isEmptyWs(cacheItem.value ?? "") ? cacheItem.value : "",
        ordering: cacheItem.ordering,
        select: cacheItem.columns,
        skip,
        take: 10
    };

    const dataUrl = services
        ? Urls(services, $config).Data.getItems()
        : "";

    const queryId = murmurhash.v3(`${JSON.stringify(content)}.${dataUrl}`);

    let dataMatches: { [key: string]: any }[] = [];

    if (!isCachable || !cacheItem.queries[queryId]) {
        const { matches = [] } = (await $request.post({
            url: dataUrl,
            content
        })).data;

        dataMatches = matches;

        cacheItem.queries[queryId] = cloneDeep(dataMatches);
    } else
        dataMatches = cloneDeep(cacheItem.queries[queryId]);

    const newMatches = dataMatches
        .map(match => {
            return {
                text: control.displayMember ? match[control.displayMember] : "",
                value: control.targetMember ? match[control.targetMember] : "",
                icon: control.iconMember ? match[control.iconMember.toLowerCase()] : ""
            };
        });

    matches = [...matches, ...newMatches];

    const saveCacheItem = () => {
        if (idxCacheItem > -1)
            queryCache[idxCacheItem] = cacheItem;
        else
            queryCache.push(cacheItem);

        cache.id("ToolbarSelect").set("queries", queryCache);

        return true;
    };

    if (cacheItem.page === 0 && matches.length === 0 && skip === 0) {
        if (saveCacheItem())
            return [{
                text: "0 Ergebnisse",
                value: "nullvalue",
                selectable: false,
                align: "center"
            }];
    }

    /**
     * Prüfen, ob weitere Optionen vom Server geladen werden müssen.
     */
    // eslint-disable-next-line
    if (!value && selected && dataMatches.length && !matches.some(match => match.value == selected)) {
        cacheItem.page++;

        if (saveCacheItem())
            return queryOptions({
                control,
                member,
                selected,
                value,
                options,
                matches
            });

        return [];
    }

    if (matches.length === 0)
        cacheItem.page--;

    if (saveCacheItem())
        return matches;

    return [];
};

/**
 * Inkrementiert die Anzahl geladenen Steuerelemente (derzeitig nur Drowpdowns)
 */
const addReady = () => readyControls.value++;

/**
 * Liefert die Bezeichnung des Steuerelements für das Logging.
 *
 * @param hint Der Hinweis des Steuerelements
 * @param $type Der Typ des Steuerelements
 *
 * @returns Die Bezeichnung des Steuerelements für das Logging
 */
const getApmName = ({ hint, $type }: { hint: string, $type?: TFormControlType }) => `toolbar-${kebabCase(hint || $type)}`;

/**
 * Liefert den Iconbezeichner einer Checkbox
 *
 * @param iconChecked Der Bezeichner des Icons, wenn die Checkbox markiert ist
 * @param iconUnchecked Der Bezeichner des Icons, wenn die Checkbox nicht markiert ist
 * @param value Der Wert der Checkbox
 *
 * @returns Der Bezeichner des Icons
 */
// const getCheckboxIcon = ({ iconChecked = "", iconUnchecked = "", value }: { iconChecked: string, iconUnchecked: string, value: string }) => {
const getCheckboxIcon = ({ iconChecked = "", iconUnchecked = "", value }: IToolbarControl) => {
    /** @todo mit MG Lösung besprechen, da die Anzeige als markiert nicht von der Eigenschaft {@link value} abhängen darf */
    return value
        ? iconChecked
        : iconUnchecked;
};

/**
 * Liefert den aktuell gewählten bzw. gecachten Wert eines Select-Controls.
 *
 * @param member Der Member des Controls
 * @param selected Der aktuell gewählte Wert
 * @param items Die Optionen des Controls
 *
 * @returns Der aktuell gewählte bzw. gecachte Wert
 */
const getSelectedValue = ({ member, selected, items = [] }: IToolbarControl) => {
    if (!member)
        return selected;

    const parentFilters: IFilter[] = props.cachable && cache.id(props.parentId).get("filters") || [];
    const menuFilters: IFilter[] = props.cachable && cache.id($page.left.id()).get("filters") || [];
    const pageFilters: IFilter[] = props.cachable && cache.id($page.top.id()).get("filters") || [];

    const selectedFilter = parentFilters.find(({ name }) => name === member) ||
        menuFilters.find(({ name }) => name === member) ||
        pageFilters.find(({ name }) => name === member);

    if (!selectedFilter)
        return selected;

    if (items.length && !items.map(({ value }) => value).includes(selectedFilter.value))
        return selected;

    return selectedFilter.value;
};

/**
 * Generiert ein Objekt mit allen notwendigen Css-Klassen.
 *
 * @param css Die Css-Klasse, die auf das Control-Element angewandt werden soll
 * @param index Der Index des Control-Elements
 *
 * @returns Ein Objekt mit allen notwendigen Css-Klassen
 */
const getClassObject = ({ css }: { css?: string }, index: number) => {
    const cssClasses: { [key: string]: boolean } = {
        active: mutableActiveIndex.value === index
    };

    if (css)
        cssClasses[css] = true;

    return cssClasses;
};

/**
 * Liefert, falls vorhanden den Text für einen Tooltip.
 *
 * @param value Ein Wert für den Tooltip
 *
 * @returns Der Text für einen Tooltip
 */
const getTooltip = (value?: string) =>
    value && value.length > 0 && `Suchen nach ${value}` || undefined;

/**
 * Ermittelt den Typ des Toolbar-Elements.
 *
 * @param $type Der Typ des Toolbar-Elements
 * @param compareType Der zu vergleichende Typ
 *
 * @returns Der Typ
 */
const isType = ({ $type }: { $type?: TFormControlType }, compareType: TFormControlType) =>
    $type?.toLowerCase() === compareType;

/**
 * Gibt an, ob es sich um einen Primary-Button (eingefärbter Hintergrund/Weisse Icons)
 * oder Sekundär-Button (transparenter Hintergrund, unveränderte Iconfarben) handelt.
 *
 * @param mainButton Der Wert der Eigenschaft "mainButton"
 *
 * @returns Der Wert der Eigenschaft "mainButton"
 */
const isPrimaryButton = ({ mainButton = false }: { mainButton?: boolean }) => mainButton;

/**
 * Emittiert die Sucheingabe an die Elternkomponente.
 *
 * @param searchValue Der Suchbegriff
 * @param startPending Die Funktion zum Starten der Ladeanimation
 * @param stopPending Die Funktion zum Stoppen der Ladeanimation
 */
const setSearchValue = (searchValue: string, startPending: () => void, stopPending: () => void) =>
    emit("setSearchValue", searchValue, startPending, stopPending);

/**
 * Emittiert ein Toolbar-Element-Klick an die Elternkomponente.
 *
 * @param item Das Steuerelement
 * @param showAnimation Die Funktion zum Starten der Ladeanimation
 * @param hideAnimation Die Funktion zum Stoppen der Ladeanimation
 * @param payload Ein beliebiger Payload, der an die Elternkomponente übergeben wird.
 */
const tbButtonClick = (item: IToolbarControl, showAnimation: () => void, hideAnimation: () => void, payload?: any) =>
    emit("btnClick", item, showAnimation, hideAnimation, payload);

/**
 * Emittiert ein Toolbar-FileUpload-Klick an die Elternkomponente.
 *
 * @param item Das Steuerelement
 * @param startPending Die Funktion zum Starten der Ladeanimation
 * @param stopPending Die Funktion zum Stoppen der Ladeanimation
 * @param files Die hochzuladenden Dateien
 */
const tbFileUploadClick = (item: IToolbarControl, startPending: () => void, stopPending: () => void, files: File[]) => {
    const preparedItem = {
        ...item,
        files
    };

    emit("btnClick", preparedItem, startPending, stopPending, files);
};

/**
 * Liefert den Text eines Steuerelements.
 *
 * @param text Der Text des Steuerelements
 *
 * @returns Der Text des Steuerelements
 */
const getText = ({ text = "" }: { text?: string }) => text;

/**
 * Setzt den Aktiv-Status eines Control-Elements (CSS-Class).
 *
 * @param controlIndex Der Index des Control-Elements
 * @param control Das Control-Element
 */
const setActive = (controlIndex: number, control: Partial<IToolbarControl> & { setActive?: boolean } = {}) => {
    if (control.setActive === false)
        return;

    mutableActiveIndex.value = controlIndex;
};

/**
 * Holt die Optionen vom Service ab.
 *
 * @param control Das Control-Element
 * @param value Der Wert des Control-Elements
 * @param options Die Optionen des Control-Elements
 * @param selected Der aktuell gewählte Wert
 */
const getOptions = async (control: IToolbarControl, value: string | null, options: ISelectOption[], selected?: string | number | null) => {
    const { member, id = "" } = control;

    const result = await queryOptions({
        control,
        member: `${murmurhash.v3(member || id)}`,
        selected,
        value,
        options
    });

    return result;
};

/**
 * Emittiert den zu setzenden Filter an die Elternkomponente.
 *
 * @param control Das Control-Element
 * @param value Der Wert des Control-Elements
 */
const setFilter = (control: IToolbarControl, value: FilterModel | null) => {
    if (control.$type && isType(control, "checkbox"))
        control.value = value;

    const filter = FilterUtils
        .mapFilters(control.filters ?? [])
        .find(filter => filter.name === control.member);

    if (value === null && filter?.name)
        return emit("clearFilter", filter.name, control);

    if (value === null)
        return;

    if (filter)
        filter.value = value;

    emit("setFilter", filter, control);
};

/**
 * (De-)aktiviert in Abhängikeit der Button-Auswahl-Modus ("selectionMode") den jew. betroffenen
 * Button.
 */
const setButtonState = () => {
    visibleControls.value.forEach(control => {
        const selectionMode = (control.selectionMode || "notRelevant").toLowerCase();
        const hasPasteAction = (control.events || [])
            .flat()
            .map(({ actions }) => actions)
            .flat()
            .some(({ type }) => type === "PasteAction");

        if (hasPasteAction)
            return control.disabled = (props.pasteTopic && Clipboard.data() && Clipboard.meta().topic === props.pasteTopic) !== true;

        if (selectionMode === "single" && props.selected !== "single")
            return control.disabled = true;

        if (selectionMode === "single" && props.selected === "single")
            return control.disabled = false;

        if (selectionMode === "singleormultiple" && !["single", "multiple"].includes(props.selected))
            return control.disabled = true;

        control.disabled = false;
    });

    visibleRightControls.value.forEach((control, inx) => {
        const selectionMode = (control.selectionMode || "notRelevant").toLowerCase();
        const hasPasteAction = (control.events || [])
            .flat()
            .map(({ actions }) => actions)
            .flat()
            .some(({ type }) => type === "PasteAction");

        if (hasPasteAction)
            return control.disabled = (props.pasteTopic && Clipboard.data() && Clipboard.meta().topic === props.pasteTopic) !== true;

        if (selectionMode === "single" && props.selected !== "single")
            return control.disabled = true;

        if (selectionMode === "single" && props.selected === "single")
            return visibleRightControls.value[inx].disabled = false;

        if (selectionMode === "singleormultiple" && !["single", "multiple"].includes(props.selected))
            return control.disabled = true;

        control.disabled = false;
    });
};

/**
 * Überwacht die Änderung der durch die Elternkomonente vorgegebenen Steuerelemente
 * und bereitet alle sichtbaren zur Anzeige vor
 */
watch(
    () => props.controls,
    () => {
        visibleControls.value = props.controls.filter(({ hidden, position }) => !hidden && position !== "right");

        setButtonState();
    },
    { immediate: true, flush: "post" }
);

/**
 * Überwacht die Änderung der durch die Elternkomonente vorgegebenen Steuerelemente der rechten Seite
 * und bereitet alle sichtbaren zur Anzeige vor
 */
watch(
    () => props.rightControls,
    () => {
        visibleRightControls.value = [...props.controls.filter(({ hidden, position }) => !hidden && position === "right"), ...props.rightControls.filter(({ hidden, position }) => !hidden)];
    },
    { immediate: true, flush: "post" }
);

/**
 * Überwacht, ob Setuerelemente in der Elternkomponente ausgewählt wurden
 * und setzt dann den Button-Status
 */
watch(
    () => props.selected,
    () => setButtonState(),
    { immediate: true, flush: "post" }
);

/**
 * Überwacht die Eigenschaft activeIndex (wird von der Elternkomponente übergeben)
 * und weist ihren Wert der Eigenschaft "mutableActiveIndex" zu, damit das
 * entsprechende Control-Element auf aktive (für die CSS-Klasse) gesetzt werden kann.
 */
watch(
    () => props.activeIndex,
    () => mutableActiveIndex.value = props.activeIndex,
    { flush: "post" }
);

defineExpose({
    items
});
</script>

<template lang="pug">
//- Eine Dynamische Toolbar-Komponente.
.toolbar.toolbar__wrapper(
    :class="{'toolbar__wrapper--show': isVisible, 'toolbar--editor': displayAs === 'editor'}"
)
    ul.toolbar__left
        li.toolbar__listitem(
            v-for="(control, index) in visibleControls"

            :data-index="index"
            :key="componentId + '.' + index"
        )
            FileUpload.toolbar__fileupload(
                v-if="isType(control, 'file')"

                ref="items"

                :css="getClassObject(control, index)"
                :disabled="disabled || control.disabled === true"
                :hint="control.hint || ''"
                :icon="control.icon || ''"
                :key="componentId + '.' + index"
                :label="control.label"
                :shape="buttonShape"
                :text="getText(control)"
                :fileTypes="control.fileTypes || []"
                :type="isPrimaryButton(control) ? 'primary' : 'secondary'"

                @files="(files, startPending, stopPending) => tbFileUploadClick(control, startPending, stopPending, files)"
            )

            Button.toolbar__filedownload(
                v-if="isType(control, 'filesave')"

                ref="items"

                :blink="control.blink"
                :caption="getText(control)"
                :class="getClassObject(control, index)"
                :disabled="disabled || control.disabled === true"
                :hint="control.hint || ''"
                :name="getApmName(control)"
                :icon="control.icon || ''"
                :key="componentId + '.' + index"
                :receiver="control.receiver"
                :shape="buttonShape"
                :type="isPrimaryButton(control) ? 'primary' : 'secondary'"

                @click="(showLoader, hideLoader) => tbButtonClick(control, showLoader, hideLoader)"
            )

            Button.toolbar__button(
                v-if="isType(control, 'button')"

                ref="items"

                :id="getApmName(control)"
                :blink="control.blink"
                :events="passEvents ? control.events : []"
                :signalType="control.signalType"
                :caption="getText(control)"
                :class="getClassObject(control, index)"
                :disabled="disabled || control.disabled === true"
                :hint="control.hint || ''"
                :name="getApmName(control)"
                :icon="control.icon || ''"
                :key="componentId + '.' + index"
                :receiver="control.receiver"
                :popovertarget="control.popoverTarget"
                :shape="buttonShape"
                :type="isPrimaryButton(control) ? 'primary' : 'secondary'"

                @click="(showLoader, hideLoader) => { tbButtonClick(control, showLoader, hideLoader); setActive(index, control); }"
            )

            Search.toolbar__search(
                v-if="isType(control, 'search')"

                ref="items"

                :preset="searchValue"
                :placeholder="control.placeholder"
                :tooltip="getTooltip(control.placeholder)"

                @input="setSearchValue"
            )

            FilterButton.toolbar__filter(
                v-if="isType(control, 'viewselect')"

                tabindex="0"

                ref="items"

                :displayMember="control.displayMember"
                :icon="control.icon || 'Filter'"
                :iconMember="control.displayIcon || 'icon'"
                :member="control.member"
                :name="control.hint || 'Filter'"
                :services="control.services || {}"
                :targetMember="control.targetMember"

                @selected="value => setFilter(control, value)"
            )

            Select(
                v-if="isType(control, 'select') && control.items"

                ref="items"

                :align="control.itemAlignment || 'left'"
                :clearable="control.isClearable === false ? false : true"
                :collapsible="true"
                :cssClasses="cssClasses"
                :empty="control.empty"
                :hint="control.hint || ''"
                :items="control.items"
                :maxWidth="'200px'"
                :minWidth="'32px'"
                :searchable="control.isSearchable === false ? false : true"
                :selected="getSelectedValue(control)"

                @change="value => setFilter(control, value)"
                @ready="addReady"
            )

            Select(
                v-else-if="isType(control, 'select') && !control.items"

                ref="items"

                :align="control.itemAlignment || 'left'"
                :cssClasses="cssClasses"
                :clearable="control.isClearable === false ? false : true"
                :collapsible="true"
                :empty="control.empty"
                :hint="control.hint || ''"
                :items="(value, options, selected) => getOptions(control, value, options, selected)"
                :key="componentId + '.' + control.member"
                :maxWidth="'200px'"
                :minWidth="'32px'"
                :searchable="control.isSearchable === false ? false : true"
                :selected="getSelectedValue(control)"

                @change="value => setFilter(control, value)"
                @ready="addReady"
            )

            Checkbox.toolbar__checkbox(
                v-if="isType(control, 'checkbox')"

                :checked="control.value"
                :icon="getCheckboxIcon(control)"

                @update:checked="value => setFilter(control, value)"
            )

    ul.toolbar__right
        li.toolbar__listitem(
            v-for="(control, index) in visibleRightControls"

            :key="componentId + '.' + (index + (visibleControls.length || 0))"
            :data-index="(index + (visibleControls.length || 0))"
        )
            FileUpload.toolbar__fileupload(
                v-if="isType(control, 'file')"

                ref="items"

                :css="getClassObject(control, (index + (visibleControls.length || 0)))"
                :disabled="disabled || control.disabled === true"
                :hint="control.hint || ''"
                :icon="control.icon"
                :key="componentId + '.' + (index + (visibleControls.length || 0))"
                :label="control.label"
                :shape="buttonShape"
                :text="getText(control)"
                :fileTypes="control.fileTypes || []"
                :type="isPrimaryButton(control) ? 'primary' : 'secondary'"

                @files="(files, startPending, stopPending) => tbButtonClick(control, startPending, stopPending, files)"
            )

            Button.toolbar__filedownload(
                v-if="isType(control, 'filesave')"

                ref="items"

                :blink="control.blink"
                :caption="getText(control)"
                :class="getClassObject(control, (index + (visibleControls.length || 0)))"
                :disabled="disabled || control.disabled === true"
                :hint="control.hint || ''"
                :name="getApmName(control)"
                :icon="control.icon"
                :key="componentId + '.' + (index + (visibleControls.length || 0))"
                :receiver="control.receiver"
                :shape="buttonShape"
                :type="isPrimaryButton(control) ? 'primary' : 'secondary'"

                @click="(showLoader, hideLoader) => tbButtonClick(control, showLoader, hideLoader)"
            )

            Button.toolbar__button(
                v-if="isType(control, 'button')"

                ref="items"

                :blink="control.blink"
                :id="getApmName(control)"
                :actions="control.actions || []"
                :caption="getText(control)"
                :class="getClassObject(control, (index + (visibleControls.length || 0)))"
                :disabled="disabled || control.disabled === true"
                :events="passEvents ? control.events : []"
                :hint="control.hint || ''"
                :icon="control.icon"
                :key="componentId + '.' + (index + (visibleControls.length || 0))"
                :name="getApmName(control)"
                :receiver="control.receiver"
                :signalType="control.signalType"
                :popovertarget="control.popoverTarget"
                :shape="buttonShape"
                :type="isPrimaryButton(control) ? 'primary' : 'secondary'"

                @click="(showLoader, hideLoader) => { tbButtonClick(control, showLoader, hideLoader); setActive((index + (visibleControls.length || 0)), control)}"
            )

            Search.toolbar__search(
                v-if="isType(control, 'search')"

                ref="items"

                :preset="searchValue"
                :placeholder="control.placeholder"
                :tooltip="getTooltip(control.placeholder)"

                @input="setSearchValue"
            )

            FilterButton.toolbar__filter(
                v-if="isType(control, 'viewselect')"

                tabindex="0"

                ref="items"

                :displayMember="control.displayMember"
                :icon="control.icon || 'Filter'"
                :iconMember="control.displayIcon || 'icon'"
                :member="control.member"
                :name="control.hint || 'Filter'"
                :services="control.services || {}"
                :targetMember="control.targetMember"

                @selected="value => setFilter(control, value)"
            )

            Select(
                v-if="isType(control, 'select') && control.items"

                ref="items"

                :align="control.itemAlignment || 'left'"
                :clearable="control.isClearable === false ? false : true"
                :collapsible="true"
                :cssClasses="cssClasses"
                :empty="control.empty"
                :hint="control.hint || ''"
                :items="control.items"
                :key="componentId + '.' + control.member"
                :maxWidth="'200px'"
                :minWidth="'32px'"
                :searchable="control.isSearchable === false ? false : true"
                :selected="getSelectedValue(control)"

                @change="value => setFilter(control, value)"
                @ready="addReady"
            )

            Select(
                v-else-if="isType(control, 'select') && !control.items"

                ref="items"

                :align="control.itemAlignment || 'left'"
                :cssClasses="cssClasses"
                :clearable="control.isClearable === false ? false : true"
                :collapsible="true"
                :empty="control.empty"
                :hint="control.hint || ''"
                :items="(value, options, selected) => getOptions(control, value, options, selected)"
                :maxWidth="'200px'"
                :minWidth="'32px'"
                :searchable="control.isSearchable === false ? false : true"
                :selected="getSelectedValue(control)"

                @change="value => setFilter(control, value)"
                @ready="addReady"
            )

            Checkbox(
                v-if="isType(control, 'checkbox')"

                :checked="control.value"
                :icon="getCheckboxIcon(control)"

                @update:checked="value => setFilter(control, value)"
            )
</template>

<style lang="scss">
@import "@/theme/elements/toolbar/style/select/index.scss";
@import "@/theme/elements/toolbar/style/checkbox.scss";
@import "@/theme/elements/toolbar/toolbar.scss";
</style>
