import filter from "lodash/filter";
import get from "lodash/get";
import map from "lodash/map";
import tinycolor from "tinycolor2";
import BaseChart from "./BaseChart";
import ColorGenerator from "../ColorGenerator";

/**
 * Eine Klase zur Generierung der Highcharts-Optionen einer Line-Chart.
 */
export default class LineChart extends BaseChart {
    /**
     * C'Tor
     *
     * @param {Function} caller Die aufrufende Funktion.
     */
    constructor(caller) {
        super(caller);
    }

    /**
     * Bereitet die darzustellenden Daten der Chart vor.
     *
     * @param {Array} data Die Rohdaten
     *
     * @returns {Object} Die Klasseninstanz.
     */
    prepareSeries(data) {
        let rows = (filter(data, item => item.level === this.level) || []);
        let colorsToFill = ColorGenerator(this.baseColors[0], this.baseColors[1], rows.length);

        rows.forEach((row, rowIndex) => {
            let prepareData = () => {
                let name = row.name || "";
                let cells = get(row, "cells", []);

                const color = rows[rowIndex].backgroundColor ?? colorsToFill[rowIndex];

                const serie = {
                    name: name,
                    data: map(cells, cell => {
                        let template = get(cell, "template", "Currency");
                        let isSubtract = get(row, "operator", "subtract", "") === "subtract";
                        let cellValue = this.caller.formatCell({value: cell.value, template: template});
                        let castedValue = template === "Currency" ? parseInt(cellValue.replace(/\./g, ""), 10) : parseFloat(cellValue.replace(/,/g, "."));

                        if (isSubtract && castedValue > 0) castedValue *= -1;

                        return {
                            name: name,
                            y: castedValue,
                            drilldown: true,
                            stack: name
                        };
                    }),
                    marker: {
                        enabled: row.showMarker ?? true
                    },
                    stack: "left",
                    color: `#${tinycolor(color).toHex().toUpperCase()}`,
                    animation: this.animationDuration
                };

                if (row.dashStyle)
                    serie.dashStyle = row.dashStyle;


                return serie;
            };


            this.series.push(prepareData());
        });

        return this;
    }

    /**
     * Liefert die Highcharts-Optionswerte zur Darstellung des Diagramms.
     *
     * @returns {Highchart.options} Die Highchart-Optionswerte.
     */
    getPrepared() {
        return {
            chart: {
                type: "spline",
                inverted: false
            },

            plotOptions: {
                series: {
                    lineWidth: 3,
                    states: {
                        inactive: {
                            opacity: 1
                        }
                    }
                }
            },

            sourceType: this.sourceType,
            tooltip: this.tooltip,
            series: this.series,

            legend: {
                itemStyle: {
                    fontWeight: "normal"
                }
            }
        };
    }
}
