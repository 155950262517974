<script setup lang="ts">
import type { PropType } from "vue";
import Button from "@/components/UI/Button/Button.vue";
import Error from "@/components/UI/Pending/Components/Error.vue";
import Info from "@/components/UI/Pending/Components/Info.vue";
import Logger from "@/utils/Logger";
import Spinner from "@/components/UI/Pending/Components/Spinner.vue";

/**
 * Verzögerung in Millisekunden, bis die Ladeanimation angezeigt werden soll.
 */
let delay = 1000;

const props = defineProps({
    /**
    * Ein Button-Objekt, welches optional unter der Nachricht angezeigt und für Ereignisse genutzt werden kann.
    */
    button: {
        type: Object as PropType<{ event: (() => void | Promise<void>) | Function; icon?: string; title: string; } | undefined>,
        default: undefined
    },

    /**
    * Wert, der angibt, ob die Animation aufgrund eines Fehlers
    * (bspw. AJAX Response-Error) gestoppt werden soll.
    */
    error: {
        type: Boolean,
        default: false
    },

    /**
    * Übergebene Nachricht.
    */
    message: {
        type: String,
        default: ""
    },

    /**
    * Wert, der angibt, ob die Pending-Komponente angezeigt werden soll.
    */
    pending: {
        type: Boolean,
        default: false
    }
});

/**
 * Löst das von der Elternkomponente übergebene Button-Ereignis aus, falls gesetzt.
 */
const btnClick = () => {
    let btnFnc = props.button?.event || (() => { });

    try {
        btnFnc();
    } catch (e) {
        Logger.error("Das injizierte Button-Event enthält Fehler und konnte nicht ausgeführt werden:", e);

        throw e;
    }
};

</script>

<template lang="pug">
div.loading-animation(
    :class="{ 'state--show': pending || error }"
)
    .pending
        Spinner(
            v-if="!error"

            :delay="delay"
        )

        Info(
            v-if="!error"

            :delay="delay"
            :pending="props.pending"
            :message="props.message"
        )

        Error(
            v-else

            :message="props.message"
        )
            Button(
                v-if="props.button"

                :class="{'vis--visible': button !== null}"
                :icon="props.button?.icon || ''"
                :caption ="props.button?.title || ''"

                @click="btnClick()"
            )

        slot
</template>

<style lang="scss" scoped>
.loading-animation {
    --duration: 4000ms;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 12px;
    width: 100%;
    height: 100%;

    opacity: 0;
    transition: opacity 250ms ease-out;

    container: loading-anim / size;

    .pending {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.75em;

        @container loading-anim (height < 54px) {
            .spinner {
                display: none;
            }
        }

        @container loading-anim (height < 120px) {
            .messages {
                display: none;
            }
        }
    }

    &.state--show {
        opacity: 1;
        transition: opacity 250ms ease-out;
    }
}
</style>
