import debounce from "lodash/debounce";
import { clearPreSelection, clearSelection, addSelected} from "./lib/selection";
import $ from "@/utils/dom";
import selectRow from "./lib/selectRow";
import clickOutsideHandler from "./handler/clickOutside";
import keyUpHandler from "./handler/keyUp";
import keyDownHandler from "./handler/keyDown";
import mouseMoveHandler from "./handler/mouseMove";
import selectHandler from "./handler/select";
import Logger from "@/utils/Logger";

class Navigation {
    /**
     * BEREICH: Getter/Setter
     */

    /**
     * Setzt die DataView-Instanz.
     *
     * @param {VueInstance<DataView>} ctx Die DataView-Instanz.
     */
    set ctx(ctx) { this._ctx = ctx; }

    /**
     * Liefert die DataView-Instanz.
     *
     * @returns {VueInstance<DataView>} ctx Die DataView-Instanz.
     */
    get ctx() { return this._ctx; }

    /**
     * Speichert die Tabelleninstanz.
     *
     * @param {HTMLTable} table Die Tabelleninstanz.
     */
    set table(table) { this._table = table; }

    /**
     * Liefert die Tabelleninstanz.
     *
     * @returns {HTMLTable} Die Tabelleninstanz.
     */
    get table() { return this._table; }

    /**
     * Speichert die Tabellenhead-Instanz.
     *
     * @param {HTMLTableHead} tableHead Die Tabellenheader-Instanz.
     */
    set tableHead(tableHead) { this._tableHead = tableHead; }

    /**
     * Liefert die Tabellenhead-Instanz.
     *
     * @returns {HTMLTableHead} Die Tabellenhead-Instanz.
     */
    get tableHead() { return this._tableHead; }

    /**
     * Speichert die Tabellenbodyr-Instanz.
     *
     * @param {HTMLTableBody} tableBody Die Tabellenbody-Instanz.
     */
    set tableBody(tableBody) { this._tableBody = tableBody; }

    /**
     * Liefert die Tabellenbodyr-Instanz.
     *
     * @returns {HTMLTableBody} Die Tabellenbody-Instanz.
     */
    get tableBody() { return this._tableBody; }

    /**
     * Weist eine Callback-Methode nach (De-)Selektion einer Tabellenzeile
     * zu.
     *
     * @param {Function} onSelect Die Callback-Methode.
     */
    set onSelect (onSelect) { this._onSelect = onSelect; }

    /**
     * Liefert eine Callback-Methode nach (De-)Selektion einer Tabellenzeile
     * zu.
     *
     * @returns {Function} Die Callback-Methode.
     */
    get onSelect () { return this._onSelect; }

    /**
     * Weist eine Callback-Methode (debounced) nach (De-)Selektion einer Tabellenzeile
     * zu.
     *
     * @param {Function} onSelectDebounced Die Callback-Methode (debounced).
     */
    set onSelectDebounced (onSelectDebounced) { this._onSelectDebounced = onSelectDebounced; }

    /**
     * Liefert eine Callback-Methode (debounced) nach (De-)Selektion einer Tabellenzeile
     * zu.
     *
     * @returns {Function} Die Callback-Methode.
     */
    get onSelectDebounced () { return this._onSelectDebounced; }

    /**
     * Setzt den Wert, ob mehrere Zeilen gleichzeitig auszuwählen.
     *
     * @param {Boolean} [multiselect=true] Der Wert, ob mehrere Zeilen gleichzeitig ausgewählt werden können.
     */
    set multiselect(multiselect) { this._multiselect = multiselect; }

    /**
     * Liefert den Wert, ob mehrere Zeilen gleichzeitig auszuwählen.
     *
     * @returns {Boolean} Der Wert, ob mehrere Zeilen gleichzeitig ausgewählt werden können.
     */
    get multiselect() { return this._multiselect; }

    /**
     * Bereich Navigations-Status.
     *
     * @param {Object} state
     */
    state = {
        ctrl: {
            active: false,
            position: -1,
            marker: -1
        },

        shift: {
            active: false,
            position: -1
        },

        select: {
            position: -1
        },

        table: {
            focus: false,
        },

        mouse: {
            x: -1,
            y: -1
        },

        selected: []
    };

    /**
     * Erzeugt eine Instanz der DataView-Navigation.
     *
     * @param {VueComponent} ctx Die DataView-Komponenteninstanz.
     * @param {Object} options Ein Parameter-Obbjekt.
     * @param {Function} options.onSelect Eine Callback-Methode nach (De-)Selektion einer Tabellenzeile.
     */
    constructor(ctx, options) {
        this.ctx = ctx;
        this.multiselect = options.multiselect !== false;
        this.onSelect = options ? (options.onSelect || function() { return null; }) : function() { return null; };
        this.onSelectDebounced = options ? (debounce(options.onSelect, 450) || function() { return null; }) : function() { return null; };
        this.table = this.ctx.$refs.table;
        this.tableHead = this.ctx.$refs.tableHead;
        this.tableBody = this.ctx.$refs.tableBody;

        this.bindEvents();
    }

    /**
     * Erzeugt alle notwendigen EventListener zur Navigation innerhalb der Tabelle.
     */
    bindEvents() {
        this.clickOutsideHandler = e => clickOutsideHandler.call(this, e);
        this.focusInHandler = () => this.state.table.focus = true;
        this.focusOutHandler = () => this.state.table.focus = false;
        this.keyUpHandler = e => keyUpHandler.call(this, e);
        this.keyDownHandler = e => keyDownHandler.call(this, e);
        this.mouseMoveHandler = e => mouseMoveHandler.call(this, e);
        this.selectHandler = e => selectHandler.call(this, e);
        this.resetKeyHandler = () => {
            keyUpHandler.call(this, {keyCode: 16});
            keyUpHandler.call(this, {keyCode: 17});
        };

        /**
         * Event-Handler binden:
         */
        window.addEventListener("focus", this.resetKeyHandler);
        window.addEventListener("keydown", this.keyDownHandler);
        window.addEventListener("keyup", this.keyUpHandler);
        window.addEventListener("click", this.clickOutsideHandler);
        window.addEventListener("touchstart", this.clickOutsideHandler);

        if (this.table) {
            this.table.addEventListener("mousemove", this.mouseMoveHandler);
            this.table.addEventListener("touchmove", this.mouseMoveHandler);
        }

        if (this.tableBody) {
            this.tableBody.addEventListener("blur", this.focusOutHandler);
            this.tableBody.addEventListener("focus", this.focusInHandler);
            this.tableBody.addEventListener("click", this.focusInHandler);
            this.tableBody.addEventListener("click", this.selectHandler);
            this.tableBody.addEventListener("touchstart", this.focusInHandler);
            this.tableBody.addEventListener("touchstart", this.selectHandler);
        }
    }

    /**
     * Setzt den aktuellen Zeilenindex.
     *
     * @param {Number} index Der zu setzende Zeilenindex.
     * @param {Boolean} [silent=false] Ein Wert, der angibt, ob die Auswahl ohne Visualisierung stattfinden soll.
     * @param {Boolean} [clear=true] Ein Wert, der angibt, ob die aktuelle Auswahl aufgehoben werden soll.
     * @param {Boolean} [callback=true] Ein Wert, der angibt, ob die Callback-Methode aufgerufen werden soll.
     */
    select(index, silent, clear, callback) {
        if (silent !== true) {
            let row = $(this.tableBody).find(`tr:nth-child(${index + 1})`).get(0);

            clearPreSelection.call(this);

            if (clear !== false)
                clearSelection.call(this);

            selectRow.call(this, {target: row});
        }

        addSelected.call(this, index);

        if (callback !== false) this.callback();
    }

    /**
     * Liefert die ausgewählten Zeilenindizes.
     *
     * @returns {Array} Die ausgewählten Zeilenindizes.
     */
    getSelected() {
        return this.state.selected;
    }

    /**
     * Hebt die aktuelle Auswahl inkl. Vorselektion auf.
     *
     * @param {Boolean} [silent=false] Ein Wert, der angibt, ob die Callback-Methode aufgerufen werden soll.
     */
    deselectAll(silent) {
        clearPreSelection.call(this);
        clearSelection.call(this);

        this.state.select.position = -1;

        if (silent !== true)
            this.callback();
    }

    /**
     * Ruft die Callback-Methode (gesetzt im Konstruktor) auf.
     *
     * @param {Boolean} debounced Ein Wert, der angibt, ob die Funktion debounced augerufen weden soll.
     */
    callback(debounced) {
        if (debounced !== true)
            try {
                this.onSelect(Object.values(this.state.selected));
            } catch (e) {
                Logger.error("Fehler in der Callback-Methode:", e);
            }
        else
            try {
                this.onSelectDebounced(Object.values(this.state.selected));
            } catch (e) {
                Logger.error("Fehler in der Callback-Methode:", e);
            }
    }

    /**
     * Alle registrierten Ereignisse entfernen:
     */
    destroy() {
        window.removeEventListener("focus", this.resetKeyHandler);
        window.removeEventListener("keyup", this.keyUpHandler);
        window.removeEventListener("keydown", this.keyDownHandler);
        window.removeEventListener("click", this.clickOutsideHandler);
        window.removeEventListener("touchstart", this.clickOutsideHandler);

        if (this.table) {
            this.table.removeEventListener("mousemove", this.mouseMoveHandler);
            this.table.removeEventListener("touchmove", this.mouseMoveHandler);
        }

        if (this.tableBody) {
            this.tableBody.removeEventListener("blur", this.focusOutHandler);
            this.tableBody.removeEventListener("focus", this.focusInHandler);
            this.tableBody.removeEventListener("click", this.focusInHandler);
            this.tableBody.removeEventListener("touchstart", this.focusInHandler);
            this.tableBody.removeEventListener("mouseleave", this.mouseLeaveHandler);
            this.tableBody.removeEventListener("touchend", this.mouseLeaveHandler);
        }
    }
}

export default Navigation;
