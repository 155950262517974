import { ColorMappings } from "@/utils/ColorMappings";
import _ from "lodash";
import $ from "@/utils/dom";
import AreaChart from "./charts/AreaChart";
import BarChart from "./charts/BarChart";
import LineChart from "./charts/LineChart";
import MapChart from "./charts/MapChart";
import PieChart from "./charts/PieChart";
import StackChart from "./charts/StackChart";
import TrendChart from "@/components/UI/TrendChart/utils/TrendChart";

/**
 * Eine Methode zum erzeugen einer Chart-Objekt-Einstellung.
 *
 * @param {Object} chart Die Chart
 *
 * @returns {Promise<Object>} Chain-Methoden.
 */
export async function createChart(chart) {
    let _this = this;
    let template = (_this.rows || [])
        .filter(row => row.level === 1)
        .map(row => row.cells)
        .flat()
        .map(cell => cell.template)
        .shift() || "Currency";

    const symbol = {
        Currency: "€",
        Percent: "%",
        Storage: "GByte"
    }[template] || "€";

    /**
     * Eine Variable, die den Tooltip einer Chart wiederspiegelt.
     * Diese wird verwendet für: LineChart, BarChar, StackCart.
     *
     * @var {Object} sharedTooltip
     */
    let sharedTooltip = {
        useHTML: true,
        headerFormat: "{series.name} {point.key}<br>",
        formatter: function() {
            if (this.series.type === "flags") {
                const tooltipTitle = this.series.userOptions.data.find(flag => flag.x == this.point.x)?.text || "";

                if (!tooltipTitle)
                    return false;

                return `<span>${tooltipTitle}</span>`;
            }

            let value = parseFloat(_.get(this, "y", 0));

            if (template === "Percent" && value !== 0)
                value /= 100;

            let formatted = _this.formatCell({
                value: value,
                template: template
            });

            return `<span>${this.key} <br> ${this.x}:  <b>${formatted}</b></span>`;
        }
    };

    /**
     * 1. Basisfarbe aus hardcodierten Farbvorgaben.
     */
    let baseColor1 = ColorMappings.getColorsByReportType(_this.colorScheme).base1;

    /**
     * 2. Basisfarbe aus hardcodierten Farbvorgaben.
     */
    let baseColor2 = ColorMappings.getColorsByReportType(_this.colorScheme).base2;

    /**
     * Chart konfigurieren:
     */
    let prepared = chart
        .setBaseColors(baseColor1, baseColor2)
        .setAnimationDuration(650)
        .setSource(_.get(_(_.get(this, "plan.diagrams", [])).filter(diagram => _.get(diagram, "$type", "unknown") === "pieChart").value(), "0.sourceType", "rows"))
        .prepareSeries(JSON.parse(JSON.stringify(this.rows)))
        .prepareFlags(JSON.parse(JSON.stringify(this.plan?.flags || [])), JSON.parse(JSON.stringify(this.plan?.axis && this.plan?.axis.find(axis => axis.direction === "x") || [])), baseColor1)
        .setTooltip(sharedTooltip)
        .getPrepared();

    /**
     * Chartkonfiguration erweitern:
     */
    if (!prepared.legend && ["mapchart"].indexOf(this.visualizationMode) === -1)
        prepared.legend = {};

    /**
     * Farben der horizontalen Labels bei Drilldown-Funktionalität anpassen.
     */
    prepared.drilldown = {
        activeAxisLabelStyle: {
            color: $().cssVar("dove-gray"),
            textDecoration: "none"
        },
    };

    if (["barchart", "stackchart", "linechart", "piechart"].includes(this.visualizationMode)) {
        const plotBands = _.get(this.plan, "axis.0.plotBands") ?? true;

        prepared.xAxis = [{
            tickInterval: _.get(this.plan, "axis.0.tickInterval") ?? 1,
            plotBands: (() => {
                return _.times(this.columns.length / 2, num => {
                    let from = 2 * num - 0.5;

                    return {
                        color: plotBands === true ? "#FAFAFA" : "#FFFFFF",
                        strokeWidth: 2,
                        from: from,
                        to: from + 1
                    };
                });
            })(),

            categories: _.map(this.columns, "name"),
            labels: {
                enabled: _.get(this.plan, "axis.0.enableLabels") ?? true,
                style: {
                    color: $().cssVar("dove-gray"),
                    "text-decoration": "none"
                }
            },

            gridLineColor: $().cssVar("silver"),
            gridLineWidth: 0.5
        }];
    }

    if (["barchart", "stackchart", "linechart"].includes(this.visualizationMode)) {
        let additionalXAxis = _.get(this.plan, "axis.1.items") || [];

        if (additionalXAxis.length)
            prepared.xAxis.push({
                linkedTo: 0,
                categories: _.map(additionalXAxis, "name"),
                opposite: true,
                title: {
                    text: _.get(this.plan, "axis.1.title") || "",
                    align: "middle",
                    margin: 0,
                    offset: 40,
                    style: {
                        fontWeight: "bold",
                        transform: "translateX(-20px)",
                        "-webkit-transform": "translateX(-20px)",
                        "-ms-tranfrom": "translateX(-20px)",
                        "-moz-transform": "translateX(-20px)",
                        "-o-transform": "translateX(-20px)"
                    }
                },
                tickInterval: _.get(this.plan, "axis.1.tickInterval") ?? 1,
                labels: {
                    enabled: _.get(this.plan, "axis.1.enableLabels") ?? true,
                    style: {
                        color: $().cssVar("dove-gray"),
                        "text-decoration": "none"
                    }
                }
            });
    }

    if (["barchart", "stackchart", "linechart", "piechart"].includes(this.visualizationMode)) {
        const hasFlags = this.plan?.flags && this.plan?.flags.length > 0;

        prepared.yAxis = [{
            lineWidth: 1,
            title: "",
            opposite: _.get(this.plan, "axis.1.opposite"),
            labels: {
                formatter: function() {
                    let value = this.value;

                    if (template === "Percent" && value !== 0)
                        value = value / 100;

                    return value !== 0 ? _this.formatCell({value: value, template: template}) : `0 ${symbol}`;
                },

                style: {
                    color: $().cssVar("boulder")
                }
            },

            plotLines: [{
                color: "#000000", // Color value
                dashStyle: "solid", // Style of the plot line. Default to solid
                value: 0, // Value of where the line will appear
                width: 0.5, // Width of the line
                zIndex: 5
            }],
            top: !hasFlags ? "0%" : "5%",
            height: !hasFlags ? "100%" : "95%",

            gridLineColor: $().cssVar("silver"),
            gridLineWidth: 0.5
        }, {
            height: !hasFlags ? "0%" : "4.9%",
            title: "",
            labels: {
                enabled: false
            }
        }];
    }

    if (this.visualizationMode === "stackchart")
        prepared.yAxis[0].reversedStacks = false;

    return prepared;
}

/**
 * Eine Methode, zur Neukalkulation der Highcharts-Optionswerte.
 */
const updateChart = async function () {
    let mappings = {
        areachart: {
            property: "areaChartData",
            chartClass: AreaChart
        },

        barchart: {
            property: "barChartData",
            chartClass: BarChart
        },

        stackchart: {
            property: "stackChartData",
            chartClass: StackChart
        },

        piechart: {
            property: "pieChartData",
            chartClass: PieChart
        },

        linechart: {
            property: "lineChartData",
            chartClass: LineChart
        },

        mapchart: {
            property: "mapChartData",
            chartClass: MapChart
        },

        securitychart: {
            property: "trendChartData",
        },

        candlechart: {
            property: "trendChartData",
        }
    };

    if (!this.visualizationMode)
        return;

    const visualizationMode = this.visualizationMode.toLowerCase();

    if (!mappings[visualizationMode])
        return;

    if (["securitychart", "candlechart"].includes(visualizationMode))
        return this[mappings[visualizationMode].property] = new TrendChart(this.plan.points).toSecurityInfo();

    this[mappings[visualizationMode].property] = await createChart.call(this, new mappings[visualizationMode].chartClass(this));
};

export default updateChart;
