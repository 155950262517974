import FailedFile from "./models/FailedFile";
// eslint-disable-next-line
import UploadFile from "./models/UploadFile";

/**
 * Konvertiert fehlgeschlagene Dateien nach einem Dateitransfer
 * in einen Container mit FailedFile-Models.
 *
 * @param {{detail: String, conflictPaths: String[], errorPaths: String[], queueSent: UploadFile[]}} options
 *
 * @returns {FailedFile[]}
 */
const mapFailed = ({detail = null, conflictPaths = [], errorPaths = [], queueSent = []}) => {
    /**
     * Die Konvertierten Fehler.
     *
     * @var {Array}
     */
    let converted = [];

    /**
     * Fügt ein Fehlermodel zum Array {@link converted} hinzu.
     *
     * @param {{detail: String, path: String, problem: "Conflict"|"Error"}} options
     */
    const addToConverted = ({detail, path, problem} = {}) => {
        const filtered = queueSent.filter(uploadFile => uploadFile.path === path);

        if (filtered.length) {
            let uploadFile = filtered[0];

            uploadFile.overwrite = problem === "Conflict";

            converted.push(new FailedFile({
                detail,
                uploadFile,
                problem
            }));
        }
    };

    conflictPaths.forEach(path => {
        addToConverted({
            detail,
            path,
            problem: "Conflict"
        });
    });

    errorPaths.forEach(path => {
        addToConverted({
            detail,
            path,
            problem: "Error"
        });
    });

    return converted;
};

/**
 * Liefert die erfolgreich hochgeladenen Dateien.
 *
 * @param {{uploaded: {}, queueSent: UploadFile[]}} options
 *
 * @returns {UploadFile[]}
 */
const mapUploaded = ({uploaded = [], queueSent = []} = {}) =>
    queueSent.reduce((files, file) => {
        const uploadedFile = uploaded.find(up => up.path === file.path);

        if (!uploadedFile)
            return files;

        file.path = uploadedFile.path;
        file.access = uploadedFile.access;
        file.shareType = uploadedFile.shareType;
        file.shareable = uploadedFile.shareable === true;

        files.push(file);

        return files;
    }, []);

export { mapFailed, mapUploaded };
