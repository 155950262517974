/**
 * Stellt Konstanten zur Verfügung im Inkonsistenzen zu vermeiden.
 */
export default {
    System: {
        NOTIFICATION: {
            /**
             * Eine Schlüssel zur Benachrichtigung, dass ein Menü geschlossen wird
             */
            BEFORE_CLOSE: "beforeClose",

            /**
             * Eine Benachrichtigung bei Menüwechsel.
             *
             * Hinweis: Ein Menüwechsel findet immer dann statt, wenn:
             *
             *  a) Ein Menü in der oberen Navigation gewechselt wird,
             *  b) Ein Menü in der linken Navigation gewechselt wird,
             */
            MENU_SWITCH: "MENU_SWITCH",

            /**
             * Eine Benachrichtigung bei Tabwechsel.
             */
            TAB_SWITCH: "TAB_SWITCH"
        }
    },

    Modal: {
        LOGOUT: {
            Title: "Fehler während der Abmeldung",
            Description: "Die Abmeldung ist fehlgeschlagen. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut."
        }
    },

    Button: {
        APPLY: {
            Accesskey: "h",
            Caption: "Überne<u>h</u>men",
            Hint: "Übernehmen"
        },

        CANCEL: {
            Accesskey: "b",
            Caption: "A<u>b</u>brechen",
            Hint: "Abbrechen"
        },

        CLOSE: {
            Accesskey: "c",
            Caption: "S<u>c</u>hließen",
            Hint: "Schließen"
        },

        CONSENT: {
            Accesskey: "s",
            Caption: "Zu<u>s</u>timmen",
            Hint: "Zustimmen"
        },

        DISCARD: {
            Accesskey: "v",
            Caption: "<u>V</u>erwerfen",
            Hint: "Verwerfen"
        },

        DOWNLOAD: {
            Accesskey: "d",
            Caption: "<u>D</u>ownload",
            Hint: "Download"
        },

        EXPORT: {
            Accesskey: "x",
            Caption: "E<u>x</u>portieren",
            Hint: "Exportieren"
        },

        NO: {
            Accesskey: "n",
            Caption: "<u>N</u>ein",
            Hint: "Nein"
        },

        OK: {
            Accesskey: "o",
            Caption: "<u>O</u>K",
            Hint: "Ok"
        },

        OVERWRITE: {
            Accesskey: "r",
            Caption: "Übe<u>r</u>schreiben",
            Hint: "Überschreiben"
        },

        RETRY: {
            Accesskey: "v",
            Caption: "Erneut <u>v</u>ersuchen",
            Hint: "Erneut versuchen"
        },

        SAVE: {
            Accesskey: "s",
            Caption: "<u>S</u>peichern",
            Hint: "Speichern"
        },

        SELECT: {
            Accesskey: "u",
            Caption: "<u>A</u>uswählen",
            Hint: "Auswählen"
        },

        SEND: {
            Accesskey: "e",
            Caption: "S<u>e</u>nden",
            Hint: "Senden"
        },

        YES: {
            Accesskey: "j",
            Caption: "<u>J</u>a",
            Htin: "Ja"
        }
    }
};
