import type Select from "./index";
import { isEmptyWs } from "../../string";
import { store } from "./store";
import $ from "@/utils/dom";
import debounce from "lodash/debounce";
import Logger from "@/utils/Logger";
import placeholder from "./placeholder";
import Toolkit from "@/utils/Toolkit";

/**
 * Positioniert die Liste horizontal.
 */
const alignList = function (this: Select) {
    const list = document.getElementById(`list-${this.id}`);
    const select = typeof this.target === "string" ? document.getElementById(this.target) : this.target;
    const wrapper = select?.querySelector(".select__wrapper");

    if (!list || !wrapper)
        return;

    list.style.left = `${wrapper.getBoundingClientRect().left}px`;
};

/**
 * Positioniert die Liste (ul) vertikal und stellt die vertikale Richtung
 * ein, in der die Liste geöffnet werden soll (nach oben/unten).
 */
const adjustList = function (this: Select) {
    const bodyRect = document.body.getBoundingClientRect();
    const bodyHeight = bodyRect.height;

    const select = typeof this.target === "string" ? document.getElementById(this.target) : this.target;
    const wrapper = select?.querySelector(".select__wrapper");
    const list = document.getElementById(`list-${this.id}`);

    if (!list || !wrapper)
        return;

    const rect = wrapper.getBoundingClientRect();

    const height = rect.height;
    const listHeight = list.offsetHeight;
    const shouldOpenUp = rect.top + height + listHeight > bodyHeight;

    this.setState("openup", shouldOpenUp);

    list.style.top = !shouldOpenUp
        ? `${rect.top + height}px`
        : `${rect.top - listHeight}px`;

    list.style.position = "fixed";
    list.style.float = "";
    list.style.zIndex = "999999";

    $(list).addClass("select--adjusted");
};

/**
 * Erzeugt einen Dom-Container und hängt das Icon ein.
 *
 * @param iconLoader - Die Funktion zum Laden des icons.
 * @param icon - Der Name des Icons.
 */
const fetchIcon = async function (iconLoader: (icon: string) => Promise<string | null>, icon: string) {
    let iconContainer = null;

    if (typeof iconLoader === "function") {
        try {
            const svgIcon = await iconLoader(icon);

            if (!svgIcon)
                return null;

            iconContainer = document.createElement("div");
            iconContainer.className = "icon__wrapper";

            const iTag = document.createElement("i");
            iTag.className = "icon__html";

            iTag.innerHTML = svgIcon;
            iconContainer.appendChild(iTag);
        } catch (e) { /** Nichts zu tun! */ }
    }

    return iconContainer;
};

/**
 * Setzt die Breite des Selects-Wrappers zurück auf die initiale Breite.
 */
const resetWidth = function (this: Select) {
    if (!this.collapsible)
        return;

    if (this.width === "calc")
        return this.adjust();

    const select = typeof this.target === "string" ? document.getElementById(this.target) : this.target;
    const wrapper = select?.querySelector(".select__wrapper");

    if (!wrapper)
        return;

    (wrapper as HTMLElement).style.width = this.width;
};

/**
 * Schließt die Liste.
 */
const closeList = async function (this: Select) {
    if (this.getState("open") && !this.cancelling)
        setTimeout(() => {
            this.onClose();
            this.cancelling = false;
        }, 75);

    /**
     * Sonst den Geöffnet Status zurücksetzen.
     */
    this.setState("open", false);
    this.setState("openup", false);

    /**
     * Wenn es sich um ein durchsuchbares Select-Control handelt,
     * dann bei keiner Eingabe, den ursrpünglich ausgewählten Wert
     * wieder in die Selection eintragen.
     */
    const select = typeof this.target === "string" ? document.getElementById(this.target) : this.target;
    const input = select?.querySelector(".select__input") as HTMLInputElement | null;

    /**
     * Den Hover-Status zurücksetzen:
     */
    this.setState("hovering", false);

    if (
        input
        // Durchsuchbar?
        && this.getState("searchable") === true
        // Verfügt über aktuelle Auswahl?
        && this.selectedText.length > 0
        // Keine Eingabe?
        && input.value.length === 0
    ) {
        this.setState("input", this.selectedText);
        input.value = this.selectedText;
    }

    /**
     * Den Platzhalter ausblenden, falls
     *
     * a) Keine aktuelle Auswahl besteht,
     * b) oder der eingebene Text im Eingabefeld der aktuellen Auswahl entspricht.
     */
    if (!this.selected || this.selectedText === this.input)
        placeholder(this).hide();

    /**
     * Eingabe zurücksetzen + Breiten anpassen.
     */
    if (this.selected && input?.value.toLowerCase() !== `${this.selectedText}`.toLowerCase()) {
        await resetSelection.call(this);
        adjustSelection.call(this);
    } else if (this.selected)
        adjustSelection.call(this);
    else if (!this.selected)
        resetWidth.call(this);
};

/**
 * Passt die Breite des Selects an die mit dem breitesten Text im jew. Listenelement an.
 */
export const adjustWidth = function (this: Select) {
    const select = typeof this.target === "string" ? document.getElementById(this.target) : this.target;
    const wrapper = select?.querySelector(".select__wrapper") as HTMLElement | null;
    const list = document.getElementById(`list-${this.id}`);

    if (!list || !wrapper)
        return;

    if (!this.autoAdjust)
        return list.style.width = `${wrapper.getBoundingClientRect().width}px`;

    const measurement = document.getElementById(`list__measurement-${this.id}`);

    if (!measurement)
        return;

    const items = [...measurement.querySelectorAll("li")];
    const wrapperWidth = wrapper.getBoundingClientRect().width;

    let refWidth = this.calcedWidth;

    if (items.length > 0)
        items.forEach(item => {
            const computed = window.getComputedStyle(item, null);

            if (!computed)
                return;

            const padding = parseFloat(computed.getPropertyValue("padding-left") ?? "0") + parseFloat(computed.getPropertyValue("padding-right") ?? "0");
            const width = padding + item.getBoundingClientRect().width;

            if (width > refWidth)
                refWidth = width + 4;
        });

    refWidth = Math.round(refWidth);

    measurement.innerHTML = "";

    if (refWidth < wrapperWidth)
        this.calcedWidth = wrapperWidth;
    else
        this.calcedWidth = refWidth;

    list.style.width = `${this.calcedWidth}px`;
    wrapper.style.width = `${this.calcedWidth}px`;
};

/**
 * Passt die Breite der aktuellen Auswahl an den Inhalt an, sofern das Select verkleinerbar (collapsible) ist.
 *
 * @param animate Ein Wert, der angibt, ob die Napssung animiert werden soll.
 */
export const adjustSelection = function (this: Select, animate: boolean = true) {
    const $selection = typeof this.target === "string" ? document.getElementById(this.target) : this.target;
    const $measurement = $selection?.querySelector(".select__text-measurement") as HTMLElement | null;

    this.setState("animate", this.animate && animate);

    if (!this.getState("collapsible") || !$selection || !$measurement)
        return;

    $measurement.innerHTML = this.selectedText;

    let width: number = parseInt(getComputedStyle($measurement).left, 10) + $measurement.offsetWidth;

    if (this.getState("clearable")) {
        const $clear = $selection.querySelector(".select__icon-clear") as HTMLElement | null;

        width += $clear
            ? $clear.offsetWidth + parseInt(getComputedStyle($clear).right, 10) + 11
            : 11;
    } else {
        const $arrow = $selection.querySelector(".select__icon-arrow") as HTMLElement | null;

        width += $arrow
            ? $arrow.offsetWidth + parseInt(getComputedStyle($arrow).right, 10)
            : 0
    }

    const $wrapper = $selection.querySelector(".select__wrapper") as HTMLElement | null;

    if ($wrapper)
        $wrapper.style.width = `${width}px`;

    this.setState("delay", width < this.calcedWidth && width < parseInt(this.maxWidth, 10));
};

const createCssClasses = function (this: Select) {
    let cssClasses = typeof this.cssClasses === "string" && this.cssClasses.length > 0
        ? this.cssClasses.split(" ")
        : [];

    if (this.collapsible)
        cssClasses.push("select--collapsible");

    if (this.clearable)
        cssClasses.push("select--clearable");

    if (this.iconized)
        cssClasses.push("select--iconized");

    if (this.searchable)
        cssClasses.push("select--searchable");

    cssClasses.push(`select--align-${this.itemAlignment}`);

    return cssClasses;
};

/**
 * Erzeugt eine Dom-Liste (ul).
 */
export const createList = function (this: Select) {
    const id = this.id;
    const dest = this.dest;
    const fragment = document.createDocumentFragment();
    const select = document.getElementById(id) as HTMLElement | null;
    const cssClasses = createCssClasses.call(this);

    if (select && cssClasses.length > 0)
        cssClasses.forEach(cssClass => $(select).addClass(cssClass));

    if (!this.selectable)
        return;

    const ul = document.createElement("ul");
    const ulMeasurement = document.createElement("ul");
    const listClasses = [
        "select__list",
        ...cssClasses
    ].join(" ");

    ul.id = `list-${id}`;
    ul.className = listClasses;
    ul.style.minWidth = this.minWidth;
    ul.style.maxWidth = this.maxWidth;

    ulMeasurement.id = `list__measurement-${id}`;
    ulMeasurement.className = listClasses;

    fragment.appendChild(ul);
    fragment.appendChild(ulMeasurement);

    if (dest && !isEmptyWs(dest))
        document.querySelector(dest)?.appendChild(fragment);
    else
        document.body.appendChild(fragment);
};

/**
 * Fokussiert das aktiv ausgewählte Listenelement.
 */
const focusSelected = function (this: Select) {
    const list = document.getElementById(`list-${this.id}`) as HTMLElement | null;

    if (!list)
        return;

    const selectedText = this.selectedText;
    const focusIndex = this.options?.findIndex(option => option.text === selectedText);

    if (focusIndex === -1)
        return;

    const options = [...list.querySelectorAll(".select__content")];

    removeFocus.call(this);
    setFocus(options[focusIndex] as HTMLElement);

    let listBoundings = list.getBoundingClientRect();
    let optionBoundings = options[focusIndex].getBoundingClientRect();
    let isInViewPort = optionBoundings.top >= listBoundings.top && optionBoundings.top + optionBoundings.height <= listBoundings.top + listBoundings.height;

    if (isInViewPort)
        return;

    // Option ausserhalb des sichtbaren Bereichs, also reinscrollen
    let diff = optionBoundings.top < listBoundings.top
        // Option oberhalb des sichbaren Bereichs
        ? listBoundings.top - optionBoundings.top
        // Option unterhalb des sichbaren Bereichs
        : (optionBoundings.top + optionBoundings.height - listBoundings.top - listBoundings.height) * -1;

    this.simplebar.getScrollElement().scrollTop -= diff;
};

/**
 * Fokkusiert die nächste bzw. vorherige Option in der Liste.
 *
 * @param previous - Ein Wert, der angibt, ob nach oben ausgewählt werden soll.
 */
const focusOption = async function (this: Select, previous: boolean = false) {
    const list = document.getElementById(`list-${this.id}`) as HTMLElement | null;
    const focused = list?.querySelector(".select__content--focused");

    if (!list)
        return;

    const options = [...list.querySelectorAll(".select__content")];
    const idxFocused = focused ? options.indexOf(focused) : -1;
    const focusIndex = Math.min(Math.max(0, idxFocused + (previous ? - 1 : 1)), options.length - 1);

    removeFocus.call(this);
    setFocus(options[focusIndex] as HTMLElement);

    let listBoundings = list.getBoundingClientRect();
    let optionBoundings = options[focusIndex].getBoundingClientRect();
    let isInViewPort = optionBoundings.top >= listBoundings.top && optionBoundings.top + optionBoundings.height <= listBoundings.top + listBoundings.height;

    // Option ausserhalb des sichtbaren Bereichs?
    if (!isInViewPort) {
        let diff = optionBoundings.top < listBoundings.top
            // Option oberhalb des sichbaren Bereichs
            ? listBoundings.top - optionBoundings.top
            // Option unterhalb des sichbaren Bereichs
            : (optionBoundings.top + optionBoundings.height - listBoundings.top - listBoundings.height) * -1;

        this.simplebar.getScrollElement().scrollTop -= diff;
    }

    if (focusIndex === options.length - 1 && typeof this.onBottom === "function") {
        try {
            await this.onBottom(!isEmptyWs(this.input) ? this.input : null, this.options ?? []);
        } catch (e) {
            Logger.error("Fehler in der onBottom-Methode: ", e);
        }
    }
};

/**
 * Setzt die Css-Klasse zur Fokussierung einer Auswahloption.
 *
 * @param option - Die zu fokussierende Auswahloption.
 */
const setFocus = (option: HTMLElement) =>
    option.classList.add("select__content--focused");

/**
 * Entfernt den Fokus der fokussierten Option.
 */
const removeFocus = function (this: Select) {
    const list = document.getElementById(`list-${this.id}`);
    const focused = list?.querySelector(".select__content--focused") as HTMLElement | null;

    focused?.classList.remove("select__content--focused");
};

/**
 * Liefert die aktuell fokussierte Auswahloption.
 *
 * @returns Die aktuell fokussierte Auswahloption.
 */
const getFocused = function (this: Select): HTMLElement | null {
    const list = document.getElementById(`list-${this.id}`);

    return list ? list.querySelector(".select__content--focused") : null;
};

/**
 * Liefert die die Option des fokussierten Listenelements
 *
 * @returns Die Option des fokussierten Listenelements
 */
const getFocusedOption = function (this: Select): ISelectOption | undefined {
    const list = document.getElementById(`list-${this.id}`) as HTMLElement | null;
    const focused = list?.querySelector(".select__content--focused");
    const selectedValue = focused?.getAttribute("data-value");

    return this.options?.find(option => option.value == selectedValue);
};

/**
 * Setzt die aktuelle Selektion auf einen Wert.
 *
 * @param selectedValue - Der ausgewählte Wert.
 */
const setSelection = function (this: Select, selectedValue: string | number) {
    const select = typeof this.target === "string" ? document.getElementById(this.target) : this.target;
    const wrapper = select?.querySelector(".select__wrapper");
    const result = this.options?.find(option => option.value == selectedValue);

    if (!result)
        return;

    /** Die onSelect-Funktion aufrufen */
    if (typeof this.onSelect === "function")
        this.onSelect(result);

    this.setState("selected", result.value);
    this.selectedText = result.text;

    /** Setze die Eingabe auf den ausgewählten Wert der Option. */
    this.setState("input", "");

    const input = wrapper?.querySelector(".select__input") as HTMLInputElement | null;

    if (input)
        input.value = result.text;

    placeholder(this).syncText();
};

/**
 * Entfernt die CSS-Klassen der Signalisierung, ob die Maus über das erste oder letzte Element
 * liegt.
 */
const removeSelectionClasses = function (this: Select) {
    const elements = [document.getElementById(`list-${this.id}`), typeof this.target === "string" ? document.getElementById(this.target) : this.target];
    const cssClasses = ["select--first-selected", "select--last-selected"];

    elements.forEach(element => cssClasses.forEach(cssClass => element?.classList.remove(cssClass)));
};

/**
 * Setzt die Auswahl auf die zuletzt aktive.
 */
const resetSelection = async function (this: Select) {
    const select = typeof this.target === "string" ? document.getElementById(this.target) : this.target;
    const wrapper = select?.querySelector(".select__wrapper");
    const input = (this.searchable ? wrapper?.querySelector(".select__input") : null) as HTMLInputElement | null;

    if (!input)
        return;

    input.value = this.selectedText;

    this.setState("input", null);

    placeholder(this).hide();

    if (typeof this.onValue === "function")
        try {
            this.onValue(null, false);
        } catch (e) {
            Logger.error("Fehler in der onValue-Funktion:", e);
        }
};

/**
 * Erzeugt für ein Listenelement die folgenden Ereignisse:
 *
 * - Klick-Ereignis auf Listenelement.
 * - Mouseover-Ereignis auf Liste.
 * - Mousout-Ereignis auf Liste.
 * - Scroll-Ereignis auf Liste.
 * - Keyboard-Navigation.
 */
export const bindEvents = function (this: Select) {
    const list = document.getElementById(`list-${this.id}`);
    const select = typeof this.target === "string" ? document.getElementById(this.target) : this.target;
    const wrapper = select?.querySelector(".select__wrapper");
    const input = (this.searchable ? wrapper?.querySelector(".select__input") : null) as HTMLInputElement | null;

    const eventHandler = {
        input: {
            /**
             * Ereignis: Eingabefeld wird verlassen.
             */
            blur: async () => {
                if (this.disabled)
                    return;

                if (!this.getState("hovering")) {
                    this.setState("focused", false);

                    /** Die Liste schließen */
                    await closeList.call(this);
                } else {
                    Toolkit.tool("repeat", {
                        callback: () => {
                            if (!this.getState("open"))
                                return false;

                            // Die Liste ist geöffnet. Liste neu Ausrichten.
                            adjustWidth.call(this);
                            adjustList.call(this);
                            alignList.call(this);

                            return true;
                        },

                        interval: 6,
                        duration: 1250
                    }).repeat();
                }
            },

            /**
             * Die Eingabe an die aufrufende Komponente weiterdelegieren
             *
             * @param {Event} e Das Eingabeereignis.
             */
            debouncedInput: debounce(async (e: Event) => {
                if (this.disabled)
                    return;

                const input = e.target as HTMLInputElement | null;

                if (!input)
                    return;

                /**
                 * Auswahl zurücksetzen, falls Eingabe der aktuellen Auswahl entspricht.
                 */
                if (this.selected && input.value.toLowerCase() === this.selectedText.toLowerCase())
                    await resetSelection.call(this);
                /**
                 * Sonst Wert weiterdelegieren.
                 */
                else if (typeof this.onValue === "function")
                    try {
                        this.onValue(!isEmptyWs(input.value) ? input.value : null, false);
                    } catch (e) {
                        Logger.error("Fehler in der onValue-Funktion:", e);
                    }
            }, 250),

            /**
             * Ereignis: Eingabefeld wird fokussiert.
             */
            focus: () => {
                if (this.disabled)
                    return;

                this.onInputFocus?.(input!);

                this.setState("animate", true);
                this.setState("focused", true);

                if (this.getState("open"))
                    return;

                // Den Geöffnet-Status wechseln:
                this.setState("open", true);

                this.onOpen();

                Toolkit.tool("repeat", {
                    callback: () => {
                        if (!this.getState("open"))
                            return false;

                        // Die Liste ist geöffnet. Liste neu Ausrichten.
                        adjustWidth.call(this);
                        adjustList.call(this);
                        alignList.call(this);

                        return true;
                    },

                    interval: 6,
                    duration: 1250
                }).repeat();
            },

            /**
             * Den Eingabestatus bei Eingabe setzen
             *
             * @param {Event} e Das Eingabeereignis
             */
            input: (e: Event): void => {
                if (this.disabled)
                    return;

                const input = e.target as HTMLInputElement | null;
                const isEmpty = isEmptyWs(input?.value ?? "");

                removeFocus.call(this);
                this.setState("input", !isEmpty ? input?.value : "");

                placeholder(this)[(this.selected && isEmpty) || (this.selected && input?.value !== this.selectedText) ? "show" : "hide"]();
            },

            /**
             * Die Eingabe bei Tastendruck setzen
             *
             * @param e
             */
            keydown: async (e: KeyboardEvent) => {
                if (e.key !== "Escape" && e.key !== "Enter")
                    return;

                if (e.key === "Escape") {
                    try {
                        this.cancelling = true;
                        this.onCancel?.();
                    } catch (e) {
                        Logger.error("Fehler in der onCancel-Funktion:", e);
                    }

                    return;
                }

                try {
                    this.onValue?.(!isEmptyWs(this.input) ? this.input : null, true);
                } catch (e) {
                    Logger.error("Fehler in der onValue-Funktion:", e);
                }

                /**
                 * Liste schließen.
                 */
                await closeList.call(this);

                /**
                 * Eingabe zurücksetzen + Breiten anpassen.
                 */
                if (this.selected && input && input.value.toLowerCase() !== this.selectedText.toLowerCase()) {
                    await resetSelection.call(this);
                    adjustSelection.call(this);
                } else if (!this.selected)
                    resetWidth.call(this);
            }
        },

        list: {
            /**
             * Ereignis: Option auswählen
             *
             * @param {Event} e Das Klickereignis
             */
            click: async (e: Event) => {
                if (this.disabled)
                    return;

                const target = e.target as HTMLElement;
                const li = target.tagName.toLowerCase() === "li"
                    ? target
                    : target.closest("li");

                /** Wenn kein Listenelement oder Kind angeklickt, dann abbrechen */
                if (!li)
                    return;

                if (!await this.onConfirmSelection(li.getAttribute("data-value") ?? ""))
                    return await closeList.call(this);

                this.onBeforeSelect && await this.onBeforeSelect();
                placeholder(this).hide();
                removeSelectionClasses.call(this);

                setSelection.call(this, li.getAttribute("data-value") ?? "");

                /** Die Liste schließen */
                await closeList.call(this);
            },

            /**
             * Ereignis: Maus über der Liste.
             */
            mouseenter: () => {
                if (this.disabled)
                    return;

                this.setState("hovering", true);
            },

            /**
             * Ereignis: Maus verlässt Liste.
             */
            mouseleave: () => {
                if (this.disabled)
                    return;

                this.setState("hovering", false);
            },

            /**
             * Ereignis: Option highlighten.
             *
             * @param {Event} e Das Mousemove-Ereignis
             */
            mousemove: (e: Event) => {
                if (this.disabled)
                    return;

                if (!e.target)
                    return;

                const target = e.target as HTMLElement;
                const li: HTMLElement | null = target.classList.contains("select__content")
                    ? target
                    : target.closest(".select__content");

                if (!li)
                    return;

                if (!li.classList.contains("select__content--focused"))
                    try {
                        this.onPreview?.(this.options?.find(option => option.value == li.getAttribute("data-value")) ?? null);
                    } catch (e) {
                        Logger.error("Fehler in der onPreview-Funktion:", e);
                    }

                removeFocus.call(this);
                setFocus(li);

                const lis = Array.from(list!.querySelectorAll("li")) || [];
                const idxLi = Array.from(lis).indexOf(li as HTMLLIElement);

                if (idxLi === 0) {
                    list?.classList.remove("select--last-selected");
                    select?.classList.remove("select--last-selected");

                    li?.classList.add("select--first-selected");
                    select?.classList.add("select--first-selected");
                } else if (idxLi === (target.closest(".select__list")?.querySelectorAll("li") ?? "").length - 1) {
                    list?.classList.remove("select--first-selected");
                    select?.classList.remove("select--first-selected");

                    list?.classList.add("select--last-selected");
                    select?.classList.add("select--last-selected");
                } else {
                    list?.classList.remove("select--first-selected");
                    select?.classList.remove("select--last-selected");
                }
            },

            /**
             * Ereignis: Css-Klassen der ersten und letzten Option entfernen.
             */
            mouseout: () => {
                if (this.disabled)
                    return;

                removeSelectionClasses.call(this);
            }
        },

        select: {
            /**
             * Ereignis: Maus über der Liste.
             */
            mouseenter: () => {
                if (this.disabled)
                    return;

                this.setState("hovering", true);
            },

            /**
             * Ereignis: Maus verlässt Liste.
             */
            mouseleave: () => {
                if (this.disabled)
                    return;

                this.setState("hovering", false);
            },
        },

        simplebar: {
            /**
             * Ereignis: Scrollen
             */
            scroll: () => {
                if (this.disabled)
                    return;

                const container = this.simplebar.getScrollElement();
                const lis: HTMLLIElement[] = [...container.querySelectorAll("li")];

                if (lis.length === 0)
                    return;

                const listBoundings = container.getBoundingClientRect();
                const listY = listBoundings.top;
                const listHeight = listBoundings.height;

                for (const li of lis) {
                    const liBoundings = li.getBoundingClientRect();
                    const liY = liBoundings.top + liBoundings.height;
                    const cssMethod = liY < listY - 36 || liY > listY + listHeight + 36
                        ? "add"
                        : "remove";

                    li.classList[cssMethod]("select__content--hidden");
                }

                const isBottom = container.scrollHeight - container.scrollTop - container.getBoundingClientRect().height <= 0;

                if (!isBottom)
                    return;

                try {
                    this.onBottom(!isEmptyWs(this.input) && this.input.toLowerCase() !== this.selectedText.toLowerCase() ? this.input : null, this.options ?? []);
                } catch (e) {
                    Logger.error("Fehler in der onBottom-Methode: ", e);
                }
            }
        },

        wrapper: {
            /**
             * Ereignisbehandlung für:
             *
             * - Auswahl aufheben (Klick auf X-Icon).
             * - Liste anzeigen/verstecken (Klick auf Auswahl-Element).
             *
             * @param {Event} e
             */
            click: (e: Event) => {
                if (this.disabled)
                    return;

                const target = (e as MouseEvent).target as HTMLElement;
                const isInput = target.classList.contains("select__input");

                let newOpenSate = !this.getState("open");

                this.setState("animate", true);

                // Wenn das Eingabefeld fokussiert wurde, dann mache nichts.
                if (isInput && this.getState("searchable") !== true)
                    return;

                if (isInput)
                    newOpenSate = true;

                const isClear = target.classList.contains("select__icon-clear") || target.closest(".select__icon-clear");

                closeLists(e);

                // Wenn der Button "X" geklickt wurde:
                if (isClear)
                    return clearSelection.call(this);

                // Den Geöffnet-Status wechseln:
                if (newOpenSate)
                    this.onOpen();
                else
                    closeList.call(this);

                this.setState("open", newOpenSate);

                if (this.getState("searchable"))
                    input?.focus();

                Toolkit.tool("repeat", {
                    callback: () => {
                        if (!this.getState("open"))
                            return false;

                        // Die Liste ist geöffnet. Liste neu Ausrichten.
                        adjustWidth.call(this);
                        adjustList.call(this);
                        alignList.call(this);

                        return true;
                    },

                    interval: 6,
                    duration: 1250
                }).repeat();

                if (!this.getState("open"))
                    return;

                focusSelected.call(this);
            },

            /**
             * Ereignisbehandlung für:
             *
             * - Navigation per Tasten: HOCH/RUNTER
             * - Auswahl per Taste: ENTER
             * - Eingabe aufheben per Taste: ESC
             *
             * @param {Event} e Das Tastaturereignis
             */
            keydown: async (e: Event) => {
                const keyEvent = e as KeyboardEvent;

                /**
                 * Taste: HOCH oder RUNTER
                 */
                if (!this.disabled && ["ArrowDown", "ArrowUp"].includes(keyEvent.key)) {
                    e.preventDefault();
                    e.stopPropagation();

                    // Ist Liste geschlossen?
                    if (!this.getState("open")) {
                        this.setState("open", true);

                        this.onOpen();

                        Toolkit.tool("repeat", {
                            callback: () => {
                                if (!this.getState("open"))
                                    return false;

                                // Die Liste ist geöffnet. Liste neu Ausrichten.
                                adjustWidth.call(this);
                                adjustList.call(this);
                                alignList.call(this);

                                return true;
                            },

                            interval: 6,
                            duration: 1250
                        }).repeat();
                    }
                    // Taste hoch/runter:
                    else if (["ArrowDown", "ArrowUp"].includes(keyEvent.key)) {
                        await focusOption.call(this, keyEvent.key === "ArrowUp");

                        if (!input)
                            return;

                        const focusedOption = getFocusedOption.call(this);

                        if (!focusedOption)
                            return;

                        try {
                            this.onPreview?.(focusedOption);
                        } catch (e) {
                            Logger.error("Fehler in der onPreview-Funktion:", e);
                        }

                        input.value = focusedOption.text;
                    }
                }
                /**
                 * Taste: ENTER
                 */
                else if (!this.disabled && keyEvent.key === "Enter") {
                    keyEvent.preventDefault();
                    keyEvent.stopPropagation();

                    let focusedOption = getFocused.call(this);

                    /**
                     * Ist eine Auswahloption in der Liste fokussiert?
                     */
                    if (focusedOption) {
                        /**
                         * Platzhalter ausblenden.
                         */
                        placeholder(this).hide();

                        /**
                         * Css-Klassen des ersten und letzten Listenelements entfernen.
                         */
                        removeSelectionClasses.call(this);

                        /**
                         * Setze die aktuelle Auswahl auf die fokussierte
                         * Auswahloption.
                         */
                        setSelection.call(this, focusedOption.getAttribute("data-value")!);

                        /**
                         * Die Liste schließen
                         */
                        await closeList.call(this);
                    }
                }
                /**
                 * Taste: ESC
                 */
                else if (!this.disabled && keyEvent.key === "Escape") {
                    /**
                     * Fokus vom Eingabefeld entfernen.
                     */
                    if (input && document.activeElement === input)
                        input.blur();

                    if (!this.cancelling)
                        try {
                            this.onCancel?.();
                        } catch (e) {
                            Logger.error("Fehler in der onCancel-Funktion:", e);
                        }

                    this.cancelling = false;
                    /**
                     * Liste schließen.
                     */
                    await closeList.call(this);

                    /**
                     * Eingabe zurücksetzen + Breiten anpassen.
                     */
                    if (this.selected && input && input.value.toLowerCase() !== this.selectedText.toLowerCase()) {
                        await resetSelection.call(this);
                        adjustSelection.call(this);
                    } else if (!this.selected)
                        resetWidth.call(this);
                }
            }
        }
    };

    input?.addEventListener("input", eventHandler.input.input);
    input?.addEventListener("keydown", eventHandler.input.keydown);
    input?.addEventListener("input", eventHandler.input.debouncedInput);
    input?.addEventListener("mousedown", eventHandler.input.focus);
    input?.addEventListener("blur", eventHandler.input.blur);

    wrapper?.addEventListener("keydown", eventHandler.wrapper.keydown);
    wrapper?.addEventListener("click", eventHandler.wrapper.click);

    list?.addEventListener("click", eventHandler.list.click);
    list?.addEventListener("mousemove", eventHandler.list.mousemove);
    list?.addEventListener("mouseenter", eventHandler.list.mouseenter);
    list?.addEventListener("mouseleave", eventHandler.list.mouseleave);
    list?.addEventListener("mouseout", eventHandler.list.mouseout);

    select?.addEventListener("mouseenter", eventHandler.select.mouseenter);
    select?.addEventListener("mouseleave", eventHandler.select.mouseleave);

    if (typeof this.onBottom === "function")
        this.simplebar.getScrollElement().addEventListener("scroll", eventHandler.simplebar.scroll);

    /**
     * Liefert eine Funktion zum entfernen allee registrierten Ereignisse.
     */
    return (): void => {
        input?.removeEventListener("input", eventHandler.input.input);
        input?.removeEventListener("keydown", eventHandler.input.keydown);
        input?.removeEventListener("input", eventHandler.input.debouncedInput);
        input?.removeEventListener("mousedown", eventHandler.input.focus);
        input?.removeEventListener("blur", eventHandler.input.blur);

        wrapper?.removeEventListener("keydown", eventHandler.wrapper.keydown);
        wrapper?.removeEventListener("click", eventHandler.wrapper.click);

        list?.removeEventListener("click", eventHandler.list.click);
        list?.removeEventListener("mousemove", eventHandler.list.mousemove);
        list?.removeEventListener("mouseenter", eventHandler.list.mouseenter);
        list?.removeEventListener("mouseleave", eventHandler.list.mouseleave);
        list?.removeEventListener("mouseout", eventHandler.list.mouseout);

        select?.removeEventListener("mouseenter", eventHandler.select.mouseenter);
        select?.removeEventListener("mouseleave", eventHandler.select.mouseleave);

        if (typeof this.onBottom === "function")
            this.simplebar.getScrollElement().removeEventListener("scroll", eventHandler.simplebar.scroll);
    }
};

/**
 * Hebt die aktuelle Auswahl auf.
 */
export const clearSelection = function (this: Select, closeAfter: boolean = true) {
    // Wenn eine Auswahl besteht:
    if (this.getState("selected")) {
        // Dann hebe die Auswahl auf.
        this.setState("selected", false);

        if (closeAfter)
            closeList.call(this);

        this.selectedText = "";

        resetWidth.call(this);

        this.onSelect(null);
    }

    /**
     * Die Eingabe löschen:
     */
    this.setState("input", "");

    const select = typeof this.target === "string" ? document.getElementById(this.target) : this.target;;
    const wrapper = select?.querySelector(".select__wrapper");
    const input = wrapper?.querySelector(".select__input") as HTMLInputElement | null;

    if (input)
        input.value = "";

    placeholder(this).clearText();
    placeholder(this).hide();
};

/**
 * Aktualisiert die Optionseigenschaften wie bspw. Visibility.
 */
export const updateOptions = function (this: Select) {
    const lis: HTMLLIElement[] = Array.from(document.querySelectorAll(`#list-${this.id} li`));

    /**
     * Alle Listenelemente durchlaufen.
     */
    for (const li of lis) {
        const option = this.options?.filter(opt => opt.value === li.dataset.value)?.at(0);

        if (!option)
            continue;

        /**
         * Prüfen ob das Listenelement versteckt oder angezeigt werden soll.
         */
        const cssMethod = (option.visibility ?? "visible").toLowerCase() === "collapsed" ? "add" : "remove";

        /**
         * Verstecken oder anzeigen:
         */
        li.classList[cssMethod]("-m-no-display");

        /**
         * Falls das Listenelement versteckt wurde und es aktuell ausgewählt war,
         * dann die Standardoption auswählen:
         */
        if (cssMethod === "add" && this.selected == option.value && typeof this.setDefault === "function")
            try {
                this.setDefault();
            } catch (e) {
                Logger.error("Fehler in der setDefault-Methode:", e);
            }
    }
};

/**
 * Erzeugt die Dom-Listenelemente (li) für eine Liste (ul).
 *
 * @param options - Die Parameter
 * @param options.id - Die ID des Selects
 * @param options.iconLoader - Die Funktion zum Laden der Icons
 */
export const createOptions = async ({ id, iconLoader, options }: { id: string, iconLoader: (icon: string) => Promise<string | null>, options: ISelectOption[] }) => {
    const listId = `list-${id}`;
    const list = document.getElementById(listId);
    const scrollArea = list?.querySelector(".simplebar-content");
    const container = scrollArea || list;
    const measurement = document.getElementById(`list__measurement-${id}`);

    if (!container)
        return;

    // Listeneinträge einhängen:
    for (const option of options) {
        const selectable = option.selectable !== false;
        const li = document.createElement("li") as HTMLLIElement;

        li.className = `select__content${!selectable ? " select__content--disabled" : ""}`;
        li.dataset.value = `${option.value}`;
        li.dataset.isnull = `${option.value === null}`;

        const animContainer = document.createElement("div");
        animContainer.className = "select__animate";

        const span = document.createElement("span");
        span.className = "select__text";
        span.innerHTML = option.html ?? option.text ?? "";

        if (option.icon) {
            const icon = await fetchIcon(iconLoader, option.icon);

            icon?.classList.add("select__icon-custom");

            if (icon)
                animContainer.appendChild(icon);
        }

        animContainer.appendChild(span);
        li.appendChild(animContainer);

        let clone = li.cloneNode(true);

        container.appendChild(li);
        measurement?.appendChild(clone);
    }
};

/**
 * Schließt alle Listen.
 *
 * @param event - Das Event-Objekt.
 */
export const closeLists = async (event: Event) => {
    const target = (event.target && event.target !== window ? event.target : document) as HTMLElement;

    for (const cachedList of store.lists) {
        const select = cachedList.select;
        const id = cachedList.id;

        /**
         * Wenn sich das angeklickte Element NICHT innerhalb dieser Liste befindet,
         * und sich das angeklickte Element NICHT innerhalb der Auswahlansicht befindet,
         * dann soll diese Liste in der Iteration geschlossen werden.
         */
        const $list = document.getElementById(`list-${id}`) as HTMLElement;

        if (
            select.getState("open")
            && $list
            && !$list.contains(target)
            && !document.querySelector(`[data-id="${id}"]`)?.querySelector(".select__wrapper")?.contains(target)
            && !document.getElementById(id)?.querySelector(".select__wrapper")?.contains(target)

        ) {
            await closeList.call(select);

            select.onCancel?.();
        }
    }
};

/**
 * (De-)highlighted nicht übereinstimmende Texte in Listenelementen.
 */
export const highlightOptions = function (this: Select) {
    const list = document.getElementById(`list-${this.id}`);
    const spans = Array.from(list?.querySelectorAll(".select__content:not(.select__content--disabled) .select__text") ?? []);

    if (!spans.length || isEmptyWs(this.input))
        return;

    const value = this.input.toLowerCase();
    const rx = new RegExp(`(${value})`, "gi");

    /** Highlighting rausnehmen */
    (spans || []).forEach(span => {
        const highlighters: HTMLElement[] = Array.from(span.querySelectorAll(".select__text--highlight") ?? []);

        highlighters.forEach(highlighter => {
            if (highlighter.firstChild)
                highlighter.parentNode?.insertBefore(highlighter.firstChild, highlighter);

            highlighter.parentNode?.removeChild(highlighter);
        });
    });

    const matches = spans.filter(span => span.innerHTML.toLowerCase().includes(value));

    /** Betroffene Texte highlighten */
    (matches || []).forEach(span => span.innerHTML = span.innerHTML.replace(rx, "<span class=\"select__text--highlight\">$&</span>"));
};
