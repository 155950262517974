import axios from "axios";
import { escapePath } from "../utils/pathConverter";
// @ts-ignore
import combineURLs from "axios/lib/helpers/combineURLs";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import Drive from "./index";
// eslint-disable-next-line
import DriveAuth from "./DriveAuth";
import DriveFile from "../models/DriveFile";
import DriveFileRelease from "../models/DriveFileRelease";
import DriveDirectory from "../models/DriveDirectory";
import DriveTag from "../models/DriveTag";
import DriveUser from "../models/DriveUser";
import Logger from "@/utils/Logger";
import DriveFileSystemObject from "../models/DriveFileSystemObject";

interface IAbortControllers {
    [key: string]: AbortController | undefined;
};

/**
 * Stellt eine Verzeichnis-Klasse zur Verfügung.
 */
class DrivePath {
    /**
     * Singleton-Instanz.
     */
    static Instance: DrivePath | null = null;

    /**
     * Privates Feld: Authorisierungsmethoden
     */
    _auth: DriveAuth | null = null;

    /**
     * Privates Feld: Basisadresse.
     */
    _baseUrl: string | null = null;

    /**
     * Gibt an, ob sich der Darstellungsmodus vom Drive in Anhangsmodus befindet.
     */
    isAttached = false;

    /**
     * Gibt an, ob der aktuelle Pfad der Papierkorb ist.
     */
    isRecycleBin = false;

    /**
     * Gibt an, ob der aktuelle Pfad der Kundenuploadpfad ist.
     */
    isUserActivities = false;

    /**
     * Gibt an, ob der aktuelle Pfad der Kontakte-Pfad ist.
     */
    isContacts = false;

    /**
     * Gibt an, ob der aktuelle Pfad der 'Öffentliche Dokumente'-Pfad ist.
     */
    isGeneralDocuments = false;

    /**
     * Gibt an, ob der aktuelle Pfad der 'Finanzdaten'-Pfad ist.
     */
    isFinanceData = false;

    /**
     * Gibt an, ob der aktuelle Pfad der 'Berichte'-Pfad ist.
     */
    isReports = false;

    /**
     * Stellt die möglichen Dateiarten bereit.
     */
    FILE_TYPE = {
        DIRECTORY: "directory",
        FILE: "file"
    };

    /**
     * Stellt den Pfad zum Parpierkorb bereit.
     */
    RECYCLE_PATH = "/.recycle-bin";

    /**
     * Stellt den Klarnamen des Pfads zum Parpierkorb bereit.
     */
    RECYCLE_PATH_NAME = "Papierkorb";

    /**
     * Stellt den Pfad zum Ordner "Kundenuploads" bereit.
     */
    USER_ACTIVITIES_PATH = "/.user-activities";

    /**
     * Stellt den Klarnamen zum Ordner "Kundenuploads" bereit.
     */
    USER_ACTIVITIES_PATH_NAME = "Kundenuploads";

    /**
     * Stellt den Pfad zum Kundenordner bereit.
     */
    USER_UPLOAD_PATH = "/.uploads";

    /**
     * Stellt den Pfad zu den Kontakten bereit.
     */
    CONTACTS_PATH = "/.contacts";

    /**
     * Stellt den Klarnamen des Pfads zu den Kontakten bereit.
     */
    CONTACTS_PATH_NAME = "Kontakte";

    /**
     * Stellt den Pfad zum Ordner "Öffentliche Dokumente" bereit.
     */
    GENERAL_DOCUMENTS_PATH = "/.public-documents";

    /**
     * Stellt den Klarnamen des Pfads zu den öffentlichen Dokumenten bereit.
     */
    GENERAL_DOCUMENTS_PATH_NAME = "Öffentliche Dokumente";

    /**
     * Stellt den Pfad zum Ordner "Finanzdaten" bereit.
     */
    FINANCE_DATA_PATH = "/.finance-data";

    /**
     * Stellt den Klarnamen des Pfads der Finanzdaten bereit.
     */
    FINANCE_DATA_PATH_NAME = "Finanzdaten";

    /**
     * Stellt den Pfad zum Ordner "Berichte" bereit.
     */
    REPORTS_PATH = "/.reports";

    /**
     * Stellt den Klarnamen des Pfads der Berichte bereit.
     */
    REPORTS_PATH_NAME = "Berichte";

    /**
     * Axios Abbruch-Token.
     */
    AbortControllers: IAbortControllers = {
        List: undefined,
        Release: undefined,
        Search: undefined
    };

    /**
     * C'tor
     *
     * Setzt die Basisadresse für den Api-Aufruf.
     *
     * @param baseUrl - Basisadresse
     * @param auth - Authorisierungsmethoden
     */
    constructor(baseUrl: string, auth: DriveAuth) {
        if (DrivePath.Instance)
            return DrivePath.Instance;

        DrivePath.Instance = this;
        this._auth = auth;
        this._baseUrl = baseUrl;
    }

    /**
     * Holt den Wurzelpfad des Drives für den initialen Aufruf des Dateilistings.
     *
     * @param endpoint - Der Endpunkt.
     *
     * @returns Der Wurzelpfad.
     */
    async fetchRootPath(endpoint: string) {
        this.AbortControllers.Root = new AbortController();

        const result = await axios.get(combineURLs(this._baseUrl, endpoint), {
            signal: this.AbortControllers.Root.signal,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${this._auth?.AccessToken}`
            }
        });

        return result.data;
    }

    /**
     * Liefert die Dateien und Ordner sowie die Freigabe eines Pfads.
     *
     * @param options - Die Parameter.
     * @param options.path - Der Pfad.
     * @param options.qsa - Die Query-String-Parameter.
     * @param options.endpoint - Der Endpunkt.
     *
     * @returns Eine Liste aller Dateissystemobjekte inkl. Freigabeberechtigungen
     */
    async list({ path, qsa, endpoint = "ls" }: { path: string, qsa: string, endpoint: "ls" | string }): Promise<{ fsos: DriveFileSystemObject[], release: DriveFileRelease }> {
        if (this.AbortControllers.List)
            this.AbortControllers.List.abort();

        if (this.AbortControllers.Release)
            this.AbortControllers.Release.abort();

        if (this.AbortControllers.Root)
            this.AbortControllers.Root.abort();

        if (this.AbortControllers.Search)
            this.AbortControllers.Search.abort();

        this.AbortControllers.Search = undefined;
        this.AbortControllers.List = new AbortController();
        this.AbortControllers.Release = new AbortController();

        this.isRecycleBin = path.startsWith(this.RECYCLE_PATH);
        this.isUserActivities = path.startsWith(this.USER_ACTIVITIES_PATH);
        this.isContacts = path.startsWith(this.CONTACTS_PATH);
        this.isGeneralDocuments = path.startsWith(this.GENERAL_DOCUMENTS_PATH);
        this.isFinanceData = path.endsWith(this.FINANCE_DATA_PATH);
        this.isReports = path.endsWith(this.REPORTS_PATH);

        /**
         * Versprechen, alle Dateien und Ordner vom Pfad abzuholen.
         */
        const listPromise = new Promise(async (resolve, reject) => {
            try {
                const { data = [] }: { data: (DriveFile | DriveDirectory)[] } = await axios.get(combineURLs(this._baseUrl, `${escapePath(`${endpoint}/${path}`)}${qsa || ''}`), {
                    signal: this.AbortControllers.List?.signal,
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${this._auth?.AccessToken}`
                    }
                });

                let items: DriveFileSystemObject[] = [];

                data.forEach(fso => {
                    if (fso.type === "file") {
                        (<DriveFile>fso).uploader = new DriveUser((<DriveFile>fso).uploader);

                        ((<DriveFile>fso).tags || []).forEach((tag, idx) => (<DriveFile>fso).tags[idx] = new DriveTag(tag));
                    }

                    items.push(fso.type.toLowerCase() === "file" ? new DriveFile(fso as IDriveFileConstructorParameters, this._baseUrl) : new DriveDirectory(fso as IDriveDirectoryConstructorParameters, this._baseUrl));
                });

                resolve(items);
            } catch (e) {
                reject(e);
            }
        });

        /**
         * Versprechen, die Dateiberechtigung des Pfads abzuholen.
         */
        const releasePromise = new Promise(async (resolve, reject) => {
            const defaultRelease = new DriveFileRelease({
                contactId: null,
                inheritedBy: null,
                access: null
            });

            if (path === "/")
                resolve(defaultRelease);
            else {
                try {
                    const releases = await Drive.Releases?.getReleases({
                        path,
                        abortController: this.AbortControllers.Release
                    });

                    resolve((releases || [defaultRelease])[0]);
                } catch (e) {
                    if (axios.isCancel(e))
                        reject(e);
                    else
                        resolve(defaultRelease);
                }
            }
        });

        return new Promise((resolve, reject) => {
            Promise
                .all([listPromise, releasePromise])
                .then(
                    /**
                     * Methodenaufruf bei Erfolg.
                     *
                     * @param values - Die Dateien und Ordner sowie die Freigabe.
                     */
                    values => resolve({ fsos: values[0] as DriveFileSystemObject[], release: values[1] as DriveFileRelease }),

                    /**
                     * Methodenaufruf bei Fehler.
                     *
                     * @param error - Der Fehler.
                     */
                    error => reject(error)
                );
        });
    }

    /**
     * Holt eine Liste aller Dateisystemobjekte, welche im Titel oder im Inhalt
     * den/die Suchbegriffe aufweisen.
     *
     * @param options - Die Parameter.
     * @param options.query - Die Suchbegriffe.
     * @param options.take - Die Anzahl der zu holenden Dateisystemobjekte.
     * @param options.skip - Die Anzahl der zu überspringenden Dateisystemobjekte.
     * @param options.path - Der Pfad.
     *
     * @returns Die Liste aller gefundenen Dateisystemobjekte.
     */
    async find({ query, take = 30, skip = 0, path = "/" }: { query: string, take: number, skip: number, path: string }): Promise<IDriveMatch[]> {
        if (this.AbortControllers.Search)
            this.AbortControllers.Search.abort();

        if (this.AbortControllers.List)
            this.AbortControllers.List.abort();

        this.AbortControllers.List = undefined;
        this.AbortControllers.Search = new AbortController();

        let qsa = `search?query=${escapePath(query)}&path=${escapePath(path)}&take=${take}${skip > 0 ? `&skip=${skip}` : ""}`;

        const result = get(await axios.get(combineURLs(this._baseUrl, qsa), {
            signal: this.AbortControllers.Search.signal,
            headers: {
                Authorization: `Bearer ${this._auth?.AccessToken}`
            }
        }), "data", []);

        this.isRecycleBin = false;

        return result;
    }


    /**
     * Fragt das genutzte und maximale Kontingent ab.
     */
    quota() {
        Logger.log("DriveDirectory::quota");
    }

    /**
     * Sortiert die angezeigten Dateisystemobjekte. Es werden Zuerst die virtuellen Ordner, dann die echten Ordner und zuletzt die Dateien sortiert.
     *
     * @param fileSystemObjects - Die angezeigten Dateisystemobjekte.
     * @param sort - Das Attribut des Dateisystemobjekts, wonach sortiert werden soll.
     * @param sortAsc - Aufsteigend sortieren (sonst absteigend).
     *
     * @returns Die sortierten Dateisystemobjekte.
     */
    sort(fileSystemObjects: DriveFileSystemObject[], sort = "name", sortAsc = true) {
        fileSystemObjects = cloneDeep(fileSystemObjects);

        const vMappings: { [key: string]: number } = {
            ".recycle-bin": 1,
            ".public-documents": 2,
            ".contacts": 3,
            ".user-activities": 4
        };

        const virtCustomSorted = fileSystemObjects
            .filter(({ type, name, virtual }) => type === "directory" && virtual && vMappings[name ?? ""])
            .sort((a, b) => vMappings[a.name ?? ""] > vMappings[b.name ?? ""] ? 1 : -1);

        const virtualDirectories = fileSystemObjects
            .filter(({ type, name, virtual }) => type === "directory" && virtual && !vMappings[name ?? ""])
            .sort((a: { [key: string]: any }, b: { [key: string]: any }) => a[sort] > b[sort] ? -1 : 1);

        const directories = fileSystemObjects
            .filter(fso => fso.type === "directory" && !fso.virtual)
            .sort((a: { [key: string]: any }, b: { [key: string]: any }) => a[sort].toLowerCase() > b[sort].toLowerCase() ? 1 : -1);

        const files = fileSystemObjects
            .filter(fso => fso.type === "file")
            .sort((a: { [key: string]: any }, b: { [key: string]: any }) => a[sort].toLowerCase() > b[sort].toLowerCase() ? 1 : -1);

        if (sortAsc)
            return [...virtCustomSorted, ...virtualDirectories, ...directories, ...files];

        return [...virtCustomSorted, ...virtualDirectories, ...directories, ...files].reverse();
    }

    /**
     * Liefert den Klarnamen eines Pfads.
     *
     * @param fso - Das Dateisystemobjekt.
     *
     * @returns Der Klarnamen.
     */
    pathName(fso: DriveFileSystemObject) {
        if (fso.path === "/")
            return "Dokumente";

        const comparables = [
            this.RECYCLE_PATH.replace(/\//g, ""),
            this.USER_ACTIVITIES_PATH.replace(/\//g, ""),
            this.CONTACTS_PATH.replace(/\//g, ""),
            this.GENERAL_DOCUMENTS_PATH.replace(/\//g, "")
        ];

        if (!comparables.includes(fso.name ?? ""))
            return fso.name;

        const pathMappings: { [key: string]: any } = {};

        pathMappings[this.RECYCLE_PATH] = this.RECYCLE_PATH_NAME;
        pathMappings[this.USER_ACTIVITIES_PATH] = this.USER_ACTIVITIES_PATH_NAME;
        pathMappings[this.CONTACTS_PATH] = this.CONTACTS_PATH_NAME;
        pathMappings[this.GENERAL_DOCUMENTS_PATH] = this.GENERAL_DOCUMENTS_PATH_NAME;

        if (pathMappings[fso.path ?? ""])
            return pathMappings[fso.path ?? ""];

        if (fso.path?.endsWith(this.FINANCE_DATA_PATH))
            return this.FINANCE_DATA_PATH_NAME;
        else if (fso.path?.endsWith(this.REPORTS_PATH))
            return this.REPORTS_PATH_NAME;

        return fso.name;
    }
}

export default DrivePath;
