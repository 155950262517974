/**
 * Prüft, ob ein Objekt ein Abbruchfehler ist
 *
 * @param obj Das zu prüfende Objekt
 *
 * @returns Ob das Objekt ein Abbruchfehler ist
 */
const hasAborted = (obj: unknown): obj is DOMException => obj instanceof DOMException && obj.name === "AbortError";

/**
 * Prüft, ob ein Objekt über die Eigenschaft {@linkcode inputType} verfügt
 *
 * @param obj Das zu prüfende Objekt
 *
 * @returns Ob das Objekt über die Eigenschaft {@linkcode inputType} verfügt
 */
const hasInputType = (obj: unknown): obj is { inputType: string } => isObject(obj) && Object.prototype.hasOwnProperty.call(obj, "inputType");

/**
 * Prüft, ob ein Objekt über die Eigenschaft {@linkcode maximum} verfügt
 *
 * @param obj Das zu prüfende Objekt
 *
 * @returns Ob das Objekt über die Eigenschaft {@linkcode maximum} verfügt
 */
const hasMaxProperty = (obj: unknown): obj is { maximum: number} =>
    isObject(obj) && Object.prototype.hasOwnProperty.call(obj, "maximum") && (obj as { maximum: number | null }).maximum !== null;

/**
 * Prüft, ob ein Objekt über die Eigenschaft {@linkcode minimum} verfügt
 *
 * @param obj Das zu prüfende Objekt
 *
 * @returns Ob das Objekt über die Eigenschaft {@linkcode minimum} verfügt
 */
const hasMinProperty = (obj: unknown): obj is { minimum: number} =>
    isObject(obj) && Object.prototype.hasOwnProperty.call(obj, "minimum") && (obj as { minimum: number | null }).minimum !== null;

/**
 * Prüft, ob ein Objekt über die Eigenschaften {@linkcode minimum} und {@linkcode maximum} verfügt
 *
 * @param obj Das zu prüfende Objekt
 *
 * @returns Ob das Objekt über die Eigenschaften {@linkcode minimum} und {@linkcode maximum} verfügt
 */
const hasMinMaxProperty = (obj: unknown): obj is { minimum: number, maximum: number} =>
    isObject(obj) && Object.prototype.hasOwnProperty.call(obj, "minimum") && Object.prototype.hasOwnProperty.call(obj, "maximum")
    && (obj as { minimum: number | null }).minimum !== null && (obj as { maximum: number | null }).maximum !== null;

/**
 * Prüft, ob ein Objekt über die Eigenschaft {@linkcode panel} verfügt
 *
 * @param obj Das zu prüfende Objekt
 *
 * @returns Ob das Objekt über die Eigenschaft {@linkcode panel} verfügt
 */
const hasPanel = (obj: unknown): obj is { panel: TPanel } => isObject(obj) && Object.prototype.hasOwnProperty.call(obj, "panel");

/**
 * Prüft, ob ein Objekt über die Eigenschaft {@linkcode stack} verfügt
 *
 * @param obj Das zu prüfende Objekt
 *
 * @returns Ob das Objekt über die Eigenschaft {@linkcode stack} verfügt
 */
const hasStack = (obj: unknown): obj is { stack: TStack } => isObject(obj) && Object.prototype.hasOwnProperty.call(obj, "stack");

/**
 * Prüft, ob ein Objekt über die Eigenschaft {@linkcode tabs} verfügt
 *
 * @param obj Das zu prüfende Objekt
 *
 * @returns Ob das Objekt über die Eigenschaft {@linkcode tabs} verfügt
 */
const hasTabs = (obj: unknown): obj is { tabs: TTab[] } => isObject(obj) && Object.prototype.hasOwnProperty.call(obj, "tabs");

/**
 * Prüft, ob ein Wert vom Typ {@linkcode bigint} ist
 *
 * @param value Der zu prüfende Wert
 *
 * @returns Ob der Wert vom Typ {@linkcode bigint} ist
 */
const isBigInt = (value: unknown): value is bigint => typeof value === "bigint";

/**
 * Prüft, ob ein Steuerelement vom Typ {@linkcode TControl} ein {@linkcode IButtonControl} ist
 *
 * @param obj Das zu prüfende Steuerelement
 *
 * @returns Ob das Steuerelement vom Typ {@linkcode IButtonControl} ist
 */
const isButtonControl = (obj: unknown): obj is IButtonControl => isControl(obj) && obj.$type === "button";

/**
 * Prüft, ob ein Objekt vom Typ {@linkcode TControl} ist
 *
 * @param obj Das zu prüfende Objekt
 *
 * @returns Ob das Objekt vom Typ {@linkcode TControl} ist
 */
const isControl = (obj: unknown): obj is TControl => !isNullOrUndefined(obj) && typeof obj === "object" && !Array.isArray(obj) && obj!.hasOwnProperty("$type");

/**
 * Prüft, ob ein Wert vom Typ {@linkcode Date} ist
 *
 * @param value Der zu prüfende Wert
 *
 * @returns Ob der Wert vom Typ {@linkcode Date} ist
 */
const isDate = (value: unknown): value is Date => value instanceof Date;

/**
 * Prüft, ob ein Steuerelement vom Typ {@linkcode TControl} ein Date-Steuerelement ist
 *
 * @param control Das zu prüfende Steuerelement
 *
 * @returns Ob das Steuerelement ein Date-Steuerelement ist
 */
const isDateControl = (control: unknown): control is { $type: "date" } => isControl(control) && control.$type === "date";

/**
 * Prüft, ob es sich bei einer Aktion um eine Löschaktion handelt
 *
 * @param action Die zu prüfende Aktion
 *
 * @returns Ob es sich bei der Aktion um eine Löschaktion handelt
 */
const isDeleteAction = (action: TAction<TActionName>): action is TAction<"delete"> => action.$type === "delete";

/**
 * Prüft, ob ein Fehler ein Dialog-Abbruchfehler ist
 *
 * @param error Der zu prüfende Fehler
 *
 * @returns Der Wert, ob der Fehler ein Dialog-Abbruchfehler ist
 */
const isDialogCancelError = (error: unknown): error is { isCanceled: boolean } =>  error instanceof Error && error.name === "DialogCancelError";

/**
 * Prüft. ob ein Knoten ein {@linkcode Element} ist
 *
 * @param node Der zu prüfende Knoten
 *
 * @returns Ob der Knoten ein {@linkcode Element} ist
 */
const isElement = (node: unknown): node is Element => node instanceof Element;

/**
 * Prüft, ob ein Wert vom Typ {@linkcode Error} ist
 *
 * @param obj Der zu prüfende Wert
 *
 * @returns Ob der Wert vom Typ {@linkcode Error} ist
 */
const isError = (obj: unknown): obj is Error => obj instanceof Error;

/**
 * Prüft, ob ein Steuerelement vom Typ {@linkcode TControl} ein {@linkcode TFileControl} ist
 *
 * @param obj Das zu prüfende Steuerelement
 *
 * @returns Ob das Steuerelement vom Typ {@linkcode TFileControl} ist
 */
const isFileControl = (obj: unknown): obj is IFileControl => isControl(obj) && obj.$type === "file";

/**
 * Prüft, ob ein Wert vom Typ {@linkcode PromiseSettledResult} ein erfülltes Promise ist
 *
 * @param value Der zu prüfende Wert
 *
 * @returns Ob der Wert ein erfülltes Promise ist
 */
const isFullfilled = (value: PromiseSettledResult<any>): value is PromiseFulfilledResult<any> => value.status === "fulfilled";

/**
 * Prüft, ob ein Wert vom Typ {@linkcode Function} ist
 *
 * @param obj Der zu prüfende Wert
 *
 * @returns Ob der Wert vom Typ {@linkcode Function} ist
 */
const isFunction = (obj: any): obj is Function => obj instanceof Function;

/**
 * Prüft, ob ein Wert ein {@linkcode HTMLElement} ist
 *
 * @param element Das zu prüfende Element
 *
 * @returns Ob das Element ein {@linkcode HTMLElement} ist
 */
const isHtmlElement = (element: unknown): element is HTMLElement => element instanceof HTMLElement;

/**
 * Prüft, ob ein Element ein interaktives Formularelement ist
 *
 * @param element Das zu prüfende Element
 *
 * @returns Ob das Element ein interaktives Formularelement ist
 */
const isInteractiveFormElement = (element: HTMLElement): element is InteractiveFormElement => (
    element instanceof HTMLSelectElement ||
    element instanceof HTMLInputElement ||
    element instanceof HTMLTextAreaElement ||
    element instanceof HTMLOptionElement ||
    element instanceof HTMLAnchorElement ||
    element instanceof HTMLButtonElement
);

/**
 * Prüft, ob das nächste verfügbare übergeordnete Element ein interaktives Formularelement ist
 *
 * @param target Das zu prüfende Element
 *
 * @returns Ob das nächste verfügbare übergeordnete Element ein interaktives Formularelement ist
 */
const isInteractiveFormElementClosest = (target: EventTarget | null): target is InteractiveFormElement => {
    if (!target)
      return false;

    const closestElement = (target as HTMLElement).closest("input, select, textarea, option, a, button");

    return closestElement !== null && isInteractiveFormElement(closestElement as HTMLElement);
};

/**
 * Prüft, ob ein Wert ein Schlüssel-Wert-Paar ist
 *
 * @param value Der zu prüfende Wert
 *
 * @returns Ob der Wert ein Schlüssel-Wert-Paar ist
 */
const isKeyValuePair = <T>(value: unknown): value is KeyValuePair<T> =>
    typeof value === "object" && !isNullOrUndefined(value) && !Array.isArray(value);

/**
 * Prüft, ob ein Objekt vom Typ {@linkcode TModule} ist
 *
 * @param obj Das zu prüfende Objekt
 *
 * @returns Ob das Objekt vom Typ {@linkcode TModule} ist
 */
const isModule = (obj: unknown): obj is TModule => isObject(obj) && Object.prototype.hasOwnProperty.call(obj, "sections");

/**
 * Prüft, ob ein Knoten ein {@linkcode Node} ist
 *
 * @param node Der zu prüfende Knoten
 *
 * @returns Ob der Knoten ein {@linkcode Node} ist
 */
const isNode = (node: unknown): node is Node => node instanceof Node;

/**
 * Prüft, ob ein Wert {@linkcode null} oder {@linkcode undefined} ist
 *
 * @param value Der zu prüfende Wert
 *
 * @returns Ob der Wert {@linkcode null} oder {@linkcode undefined} ist
 */
const isNullOrUndefined = (value: unknown): value is null | undefined => value === null || value === undefined;

/**
 * Prüft, ob ein Objekt vom Typ {@linkcode TSection} ist
 *
 * @param obj Das zu prüfende Objekt
 *
 * @returns Ob das Objekt vom Typ {@linkcode TSection} ist
 */
const isSection = (obj: unknown): obj is TSection => isObject(obj) && Object.prototype.hasOwnProperty.call(obj, "subSections");

/**
 * Prüft, ob ein Steuerelement vom Typ {@linkcode TControl} ein {@linkcode TSelectControl} ist
 *
 * @param control Das zu prüfende Steuerelement
 *
 * @returns Ob das Steuerelement vom Typ {@linkcode TSelectControl} ist
 */
const isSelectControl = (obj: unknown): obj is TSelectControl => isControl(obj) && obj.$type === "select";

/**
 * Prüft, ob es sich bei einer Aktion um eine SetIcon-Aktion handelt
 *
 * @param action Die zu prüfende Aktion
 *
 * @returns Ob es sich bei der Aktion um eine SetIcon-Aktion handelt
 */
const isSetIconAction = (action: TAction<TActionName>): action is TAction<"setIcon"> => action.$type === "setIcon";

/**
 * Prüft, ob ein Wert vom Typ {@linkcode string} ist
 *
 * @param value Der zu prüfende Wert
 *
 * @returns Ob der Wert vom Typ {@linkcode string} ist
 */
const isString = (value: unknown): value is string => typeof value === "string";

/**
 * Prüft, ob ein Wert vom Typ {@linkcode object} ist
 *
 * @param value Der zu prüfende Wert
 *
 * @returns Ob der Wert vom Typ {@linkcode object} ist
 */
const isObject = (value: unknown): value is object => typeof value === "object" && !isNullOrUndefined(value) && !Array.isArray(value);

/**
 * Prüft, ob ein Knoten ein {@linkcode Text} ist
 *
 * @param node Der zu prüfende Knoten
 *
 * @returns Ob der Knoten ein {@linkcode Text} ist
 */
const isText = (node: Node): node is Text => node instanceof Text;

/**
 * Prüft, ob ein Wert vom Typ {@link type} ist
 *
 * @param value Der zu prüfende Wert
 * @param type Der Typ, der geprüft werden soll
 *
 * @returns Ob der Wert vom Typ {@link type} ist
 */
const isTypeOf = <T>(value: unknown, type: new () => T): value is T =>
    value instanceof type;

/**
 * Prüft, ob ein {@linkcode methodName} in einem {@linkcode obj} ein Promise-Objekt ist
 *
 * @param obj Das zu prüfende Objekt
 * @param methodName Der Name der Methode, die geprüft werden soll
 *
 * @returns Ob die Methode ein Promise-Objekt ist
 */
const isPromiseMethod = (obj: any, methodName: string): obj is { [key: string]: AsyncFunction } => {
    const method = obj[methodName];

    return (
        method &&
        typeof method === "function" &&
        method.constructor &&
        method.constructor.name === "AsyncFunction"
    );
};

/**
 * Prüft, ob ein {@linkcode error} ein Response-Error ist
 *
 * @param error Der zu prüfende Fehler
 *
 * @returns Ob der Fehler ein Response-Fehler ist
 */
const isResponseError = (error: any): error is { response: Response } => {
    if (!(error instanceof Error))
        return false;

    return (error as any).response?.status !== undefined;
};

export {
    hasAborted,
    hasInputType,
    hasMaxProperty,
    hasMinProperty,
    hasMinMaxProperty,
    hasPanel,
    hasStack,
    hasTabs,
    isBigInt,
    isButtonControl,
    isControl,
    isDate,
    isDateControl,
    isDeleteAction,
    isDialogCancelError,
    isElement,
    isError,
    isFileControl,
    isFullfilled,
    isFunction,
    isHtmlElement,
    isInteractiveFormElement,
    isInteractiveFormElementClosest,
    isKeyValuePair,
    isModule,
    isNode,
    isNullOrUndefined,
    isObject,
    isPromiseMethod,
    isResponseError,
    isSection,
    isSelectControl,
    isSetIconAction,
    isString,
    isText,
    isTypeOf
};
