import "unorm";

/**
 * Prüft, ob eine Zeichenfolge leer ist.
 *
 * @param {String} str Die zu prüfende Zeichenfolge.
 *
 * @return {Boolean} Der Wert, ob die Zeichenfolge leer ist.
 */
export function isEmpty(str) {
    if (str === null || str === undefined)
        return true;

    if (typeof str !== "string")
        str = String(str);

    return str === "";
}

/**
 * Prüft, ob eine Zeichenfolge leer ist oder aus Steuerzeichen besteht.
 *
 * @param {String} str Die zu prüfende Zeichenfolge.
 *
 * @return {Boolean} Der Wert, ob die Zeichenfolge leer ist oder aus Steuerzeichen besteht.
 */
export function isEmptyWs(str) {
    if (str === null || str === undefined)
        return true;

    if (typeof str !== "string")
        str = String(str);

    return str.trim() === "";
}

/**
 * Prüft, ob eine Zeichenfolge aus Kleinbuchstaben besteht.
 *
 * @param {String} str Die zu prüfende Zeichenfolge.
 *
 * @return {Boolean} Der Wert, ob die Zeichenfolge aus Kleinbuchstaben besteht.
 */
export function isLowerCase(str) {
    return str.toLowerCase() === str;
}

/**
 * Prüft, ob eine Zeichenfolge aus Großbuchstaben besteht.
 *
 * @param {String} str Die zu prüfende Zeichenfolge.
 *
 * @return {Boolean} Der Wert, ob die Zeichenfolge aus Großbuchstaben besteht.
 */
export function isUpperCase(str) {
    return str.toUpperCase() === str;
}

/**
 * Prüft, ob eine Zeichenfolge aus Steuerzeichen besteht.
 *
 * @param {String} str Die zu prüfende Zeichenfolge.
 *
 * @return {Boolean} Der Wert, ob die Zeichenfolge aus Steuerzeichen besteht.
 */
export function isWhitespace(str) {
    if (!str || typeof str !== "string")
        return false;

    return str.trim() === "";
}

/**
 * Prüft, ob ein Zeichen ein lateinischer Buchstabe ist.
 *
 * @param {String} c Das zu prüfende Zeichen.
 *
 * @return {Boolean} Der Wert, ob das Zeichen ein lateinischer Buchstabe ist.
 */
export function isAlpha(c) {
    return c >= "a" && c <= "z" || c >= "A" && c <= "Z";
}

/**
 * Prüft, ob ein Zeichen eine Zahl ist.
 *
 * @param {String} c Das zu prüfende Zeichen.
 * @return {Boolean} Der Wert, ob das Zeichen eine Zahl ist.
 */
export function isNumeric(c) {
    if (typeof c !== "string" || c.length !== 1)
        return false;

    return c >= "0" && c <= "9";
}

/**
 * Prüft, ob ein Zeichen eine fürs Web sichere Zeichensetzung ist.
 *
 * @param {String} c Das zu prüfende Zeichen.
 *
 * @return {Boolean} Der Wert, ob das Zeichen eine fürs Web sichere Zeichensetzung ist.
 */
export function isWebSafePunctuation(c) {
    return c === "-" || c === "." || c === "_" || c === "~";
}

/**
 * Wandelt eine Zeichenfolge in die Slug-Darstellung um:
 * "HalloWelt!!" => "hallo-welt"
 *
 * @param {String} str Die umzuwandelnde Zeichenfolge.
 * @param {Object} options Die optionalen Konfigurationsparameter.
 * @param {Boolean} [options.toLower=true] Gibt an, ob die Zeichenkette kleingesschrieben zurückgegeben werden soll.
 *
 * @return {String} Die umgewandelte Zeichenfolge.
 */
export function slugify(str, options = null) {
    if (typeof (str) !== "string" || str === "")
        return str;

    let toLower = (options && options.toLower !== false) !== false;

    let result = "";
    let ws = false;
    let lower = false;
    let append = c => { result += (toLower ? c.toLowerCase() : c); };

    let normalized = str.normalize("NFD").split("");

    normalized.forEach(c => {
        if (isAlpha(c)) {
            if (ws || isUpperCase(c) && lower)
                append("-");
            append(c);
            lower = isLowerCase(c);
            ws = false;
        } else if (c.charCodeAt(0) === 776)
            append("e");
        else if (c === "ß")
            append("ss");
        else if (isNumeric(c)) {
            if (ws)
                append("-");

            append(c);
            lower = true;
            ws = false;
        } else if (isWebSafePunctuation(c)) {
            append(c);
            lower = ws = false;
        } else if (isWhitespace(c))
            ws = true;
    });

    return result;
}

/**
 * Bereitet einen String für einen Vergleich eines regulären Ausdrucks vor.
 *
 * @param {String} value
 *
 * @returns {String}
 */
export const rxEscape = value => value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

/**
 * Konvertiert einen numerischen Wert der Größe einer Datei in einen menschenlesbaren Format.
 *
 * @param {Number} value
 *
 * @returns {String}
 */
export const toFilesize = value => {
    if (value < 1024) return `${value} Bytes`;
    if (value < Math.pow(1024, 2)) return `${Math.round(value / 1024)} KBytes`;
    if (value < Math.pow(1024, 3)) return `${Math.round(value / Math.pow(1024, 2))} MBytes`;
    if (value < Math.pow(1024, 4)) return `${Math.round(value / Math.pow(1024, 3))} GBytes`;

    return `${Math.round(value / Math.pow(1024, 4))} TBytes`;
};
