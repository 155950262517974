import ColorManip from "color";

/**
 * Eine Klasse zur Anwendung von Farboperationen.
 *
 * @param {String|{r: number, g: number, b: number, a: Number?}} color
 *
 */
class Color extends ColorManip {
    /**
     * C'Tor
     *
     * @param {String|ColorManip} color
     *
     * @constructor
     */
    constructor(color) {
        super(color);
    }

    /**
     * ***Ermittelt*** die ***Helligkeit*** der ***Farbe*** und gibt einen Wert
     * zwischen ***0*** und ***1*** zurück. Je ***kleiner*** der Wert, ***desto dunkler*** ist die Farbe.
     *
     * @returns {Number} Der Helligkeitswert.
     */
    brightness() {
        const rgb = this.rgb();

        /**
         * Rot hat einen prozentualen Anteil von ca. 24,1% an der Helligkeit der Farbe.
         */
        const r = Math.pow(rgb.color[0], 2) * .241;

        /**
         * Grün hat einen prozentualen Anteil von ca. 69,1% an der Helligkeit der Farbe.
         */
        const g = Math.pow(rgb.color[1], 2) * .691;

        /**
         * Blau hat einen prozentualen Anteil von ca. 6,8% an der Helligkeit der Farbe.
         */
        const b = Math.pow(rgb.color[2], 2) * .068;

        /**
         * Der prozentuale Gesamthelligkeitswert.
         */
        return Math.sqrt(r + g + b) / 255;
    }

    /**
     * Liefert die Farbinstanz unter Anwendung eines Alphawerts.
     *
     * @param {Number} alpha
     *
     * @returns {Color}
     */
    alpha(alpha) {
        return new Color(super.alpha(alpha));
    }

    /**
     * Liefert den Wert, ob die Farbe Dunkel in Abhängigkeit eines Schwellenwerts ist.
     *
     * @param {Number} treshold
     *
     * @returns {Boolean}
     */
    isDark(treshold = .7) {
        return this.brightness() < treshold;
    }

    /**
     * Liefert einen RGB-Wert der Farbe oder wandelt den RGBa-Wert in einen RGB-Wert der Farbe
     * unter Berücksichtigung der Transparenz auf einem übergebenen Hintergrund um.
     *
     * @param {String?} color
     *
     * @returns {Color}
     */
    rgb(color = null) {
        if (!color)
            return new Color(super.rgb());

        const foreground = this.rgb();
        const background = new Color(color).rgb();
        const alpha = foreground.valpha;

        const r = parseInt((1 - alpha) * background.color[0] + alpha * foreground.color[0]);
        const g = parseInt((1 - alpha) * background.color[1] + alpha * foreground.color[1]);
        const b = parseInt((1 - alpha) * background.color[2] + alpha * foreground.color[2]);

        return new Color(`rgb(${r}, ${g}, ${b})`).rgb();
    }

    /**
     * Liefert die geeignete Hoverfarbe.
     *
     * @returns {Color}
     */
    getHoverColor() {
        let foreground = this.hsl();

        if (this.isDark(0.1))
            foreground.color[2] += 30;
        else if (this.isDark(0.5))
            foreground.color[2] += 10;
        else
            foreground.color[2] -= 10;

        return foreground;
    }

    /**
     * Liefert die Bestätigung, dass die Farbe auf dem übergebenen Hintergrund für das menschliche Auge
     * unterscheidbar ist (Kontrast; ttps://www.w3.org/WAI/WCAG21/Understanding/non-text-contrast.html#:~:text=enhancing%20assistive%20technology.-,Active%20User%20Interface%20Components,ratio%20with%20the%20adjacent%20colors).
     *
     * @param {String|Color} color
     * @param {Number} sensitivity
     *
     * @returns {Boolean}
     */
    perceivable(color, sensitivity = 3) {
        const foreground = this.rgb(color);
        const background = new Color(color).rgb(foreground);

        return foreground.contrast(background) > sensitivity;
    }

    /**
     * Verringert bzw. erhöht die Helligkeit der Farbe in Abhängigkeit des Kontrasts einer Hintergrundfarbe.
     *
     * @param {String|Color} color
     * @param {Number} sensitivity
     *
     * @returns {Color}
     */
    adjustLightness(color, sensitivity = 3) {
        let hsl = this.rgb(color).hsl();

        if (this.perceivable(color, sensitivity))
            return hsl;

        const bgColor = new Color(color);
        const isDark = bgColor.isDark();
        const step = isDark ? 1 : -1;
        const lightness = Math.floor(hsl.color[2] * 10) / 10;
        hsl.color[2] = lightness;

        let isPerceivable = false;

        while (!isPerceivable) {
            hsl.color[2] += step;
            hsl.color[2] = Math.max(0, Math.min(hsl.color[2], 100));

            isPerceivable = new Color(hsl).perceivable(color, sensitivity) || [0, 100].includes(hsl.color[2]);
        }

        return new Color(hsl);
    }
}

export default Color;
