/**
 * Stellt ein Benutzerobjekt zur Verfügung.
 */
class DriveUser {
    /**
     * Der eindeutige Bezeichner des Gastkontos.
     *
     * @property {String} unique_name
     */
    unique_name = "";

    /**
     * Die E-Mail Adresse des Gastkontos.
     *
     * @property {String} email
     */
    email = "";

    /**
     * Der Mandant des Gastkontos.
     *
     * @property {String} tenant
     */
    tenant = "";

    /**
     * Der Vorname des Gastkonto-Benutzers.
     *
     * @property {String} given_name
     */
    given_name = "";

    /**
     * Der Nachname des Gastkonto-Benutzers.
     *
     * @property {String} family_name
     */
    family_name = "";

    /**
     * Die URL des Benutzerbilds.
     *
     * @property {String} image
     */
    image = "";

    /**
     * Der Anzeigename oder die Id des Benutzers.
     *
     * @property {String} displayName
     */
    get displayName() {
        const displayName = [this.given_name, this.family_name].join(" ").trim();

        return displayName.legth
            ? displayName
            : this.email;
    }

    /**
     * C'Tor.
     *
     * @param {{unique_name: String, email: String, tenant: String, given_name: String, family_name: String, image: String}} options
     */
    constructor({unique_name = "", email = null, tenant = "", given_name = "", family_name = "", image = ""} = {}) {
        this.unique_name = unique_name;
        this.email = email;
        this.tenant = tenant;
        this.given_name = given_name;
        this.family_name = family_name;
        this.image = image;
    }
}

export default DriveUser;
