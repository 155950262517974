import camelCase from "lodash/camelCase";
import ToolkitTool from "../ToolkitTool";

class DecodeQueryTool extends ToolkitTool<{query: string}> {
    /**
     * Dekodiert einen Query-String
     *
     * @param camelCaseKeys Gibt an, ob die Schlüssel in CamelCase konvertiert werden sollen
     */
    public decode(camelCaseKeys = false) {
        return this.params.query
            .split("?")
            .filter(s => RegExp("(\\S+?=\\S+?)").test(s))
            .map(s => s.split("&"))
            .flat()
            .reduce((obj, pair) => {
                let [key, value] = pair.split("=");

                key = key.split("/").at(-1)!;

                obj[!camelCaseKeys ? key : camelCase(key)] = decodeURIComponent(value);

                return obj;
            }, {} as Record<string, string>);
    }
}

export default DecodeQueryTool;
