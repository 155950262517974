import $ from "@/utils/dom";

/**
 * Hebt die aktuell selektierte Zeile grafisch hervor.
 *
 * @param {Event} e Das Ereignisobjekt.
 */
export default function (e) {
    let target = e.target;
    let isHead = $(e.target).parent("thead").get(0) !== undefined;

    if (target && !isHead) {
        let row = target.tagName && target.tagName.toLowerCase() === "tr"
            ? target
            : $(target).parent("tr").get(0);

        requestAnimationFrame(() => $(row).addClass("selected"));
    }
}
