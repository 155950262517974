import FilterModel from "@/utils/Filter/FilterModel";
import FilterUtils from "@/utils/Filter/index";
import cloneDeep from "lodash/cloneDeep";
import each from "lodash/each";
import set from "lodash/set";

/**
 * Ein Mixin zur Bereitstellung von Parameter nach folgender Priorität.
 *
 * 1) Variablen der Seite (Modul).
 * 2) Variablen des Menüs (Sektion).
 * 3) Variablen der Komponente.
 */
export default {
    methods: {
        /**
         * Liefert Hilfsfunktionen für den Zugriff der Parameter der einbindenden Komponente.
         *
         * @returns {Object} Die Hilfsfunktionen.
         */
        parameters() {
            let parameters = this.$model.get("Parameters");
            let topMenuId = this.$page.top.id();
            let leftMenuId = this.$page.left.id();

            return {
                /**
                 * Löscht alle Parameter einer Seite aus den Cache.
                 *
                 * @param {String} pageId Die Seite.
                 */
                remove(pageId) {
                    parameters.remove(`menu.${pageId}`);
                    parameters.remove(`page.${pageId}`);
                    parameters.remove(`component.${pageId}`);

                    parameters.commit();
                },

                /**
                 * Ersetzt Parameterwerte in einem String oder JSON-Objekt.
                 *
                 * @param {String|Object} subject Der zu ersetzende String bzw. das Objekt.
                 * @param {FilterModel[]} custom Zusätzlich zu berücksichtigende Filter
                 * @param {Boolean} flatten Objekt Punktnotation ({a: {b: 2}} => a.b: 2) konvertieren.
                 *
                 * @returns {(String|{})} Der ersetzte String bzw. das Objekt.
                 */
                resolve(subject, custom = [], flatten) {
                    if (typeof subject === "number") return subject;

                    const regexParam = /\{(\b[^}]*)\}/ig;

                    if (typeof subject === "string") {
                        const combined = FilterUtils.assign(FilterUtils.mapJson(this.combined()), ...custom);

                        combined
                            .filter(({value}) => typeof value === "string")
                            .forEach(filter => {
                                const matches = (filter.value || "").match(regexParam);

                                if (!matches) return;

                                matches.forEach(match => {
                                    const parameter = match.replace("{", "").replace("}", "");
                                    const desiredFilter = combined.find(({name}) => name === parameter);
                                    const value = desiredFilter && desiredFilter.value !== undefined
                                        ? desiredFilter.value
                                        : null;

                                    filter.value = filter.value.replace(match, value);
                                });
                            });

                        (subject.match(regexParam) || [])
                            .forEach(match => {
                                const parameter = match.replace("{", "").replace("}", "");
                                const filter = combined.find(({name}) => name === parameter);
                                const value = filter && filter.value !== undefined
                                    ? filter.value
                                    : null;

                                subject = subject.replace(match, value);
                            });

                        return subject;
                    }

                    if (Array.isArray(subject) && !subject.map(filter => filter instanceof FilterModel).includes(false)) {
                        const combined = [...FilterUtils.mapJson(this.combined()), ...custom];

                        combined
                            .filter(({value}) => typeof value === "string")
                            .forEach(filter => {
                                const matches = (filter.value || "").match(regexParam);

                                if (!matches) return;

                                matches.forEach(match => {
                                    const parameter = match.replace("{", "").replace("}", "");
                                    const desiredFilter = combined.find(({name}) => name === parameter);

                                    if (!desiredFilter) return;

                                    filter.value = filter.value.replace(match, desiredFilter.value);
                                });
                            });

                        subject
                            .filter(({value}) => typeof value === "string")
                            .forEach(filter => {
                                (filter.value.match(regexParam) || [])
                                    .forEach(match => {
                                        const parameter = match.replace("{", "").replace("}", "");
                                        const replaceFilter = combined.find(({name}) => name === parameter);
                                        const value = replaceFilter && replaceFilter.value !== undefined
                                            ? replaceFilter.value
                                            : null;

                                        filter.value = filter.value.replace(match, value);
                                    });
                            });

                        return subject;
                    }

                    const prepared = {};

                    each(subject, (value, key) => {
                        if (!flatten) set(prepared, key, this.resolve(value, custom));
                        else prepared[key] = this.resolve(value, custom);
                    });

                    return prepared;
                },

                /**
                 * Erzeugt aus einem Objekt ein Query-String und ersetzt alle Platzhalter
                 * mit den Seitenparameterwerten.
                 *
                 * @param {Object} subject Das Objekt.
                 *
                 * @returns {String} Die Query.
                 */
                toQuery(subject) {
                    const mappings = {"number" : "intParser", "boolean": "boolParser", "object": "objectParser"};
                    const conditionMappings = {
                        "contains": " ~= ",
                        "equals": " == ",
                        "greaterEquals": " >= ",
                        "greaterThan": " > ",
                        "lessEquals": " <= ",
                        "lessThan": " < ",
                        "notEquals": " != "
                    };

                    const parser = {
                        defaultParser: (key, value) => key.indexOf("~=") === -1 ? ` == "${value}"` : `"${value}"`,
                        intParser: (key, value) => key.indexOf("~=") === -1 ? ` == ${parseInt(value, 10)}` : `${parseInt(value, 10)}`,
                        boolParser: (key, value) => key.indexOf("~=") === -1 ? ` == ${value}` : `${value}`,
                        objectParser: (key, objValue) => {
                            return key.indexOf("~=") === -1
                                ? `${conditionMappings[objValue.operator || "equals"]}"${objValue.value}"`
                                : `"${objValue.value}"`;
                        }
                    };

                    let query = "";

                    each(subject, (value, key) => {
                        const parserMethod = mappings[typeof value] ? parser[mappings[typeof value]] : parser.defaultParser;

                        query += value === null ? `${key} && ` : `${key}${parserMethod(key, value)} && `;
                    });

                    if (query.length > 0) query = query.slice(0, -4);

                    return this.resolve(query);
                },

                page: {
                    /**
                     * Liefert die Parameter der Seite.
                     *
                     * @param {String} [key=null] [optional] Der ParameterSchlüssel.
                     *
                     * @returns {Object} Die Parameter.
                     */
                    get: (key, _default = {}) => {
                        let value = parameters.get(`page.${topMenuId}${key ? `.${key}` : ""}`);

                        return value !== undefined ? value : _default;
                    },

                    /**
                     * Setzt einen Parameterwert der aktiven Seite.
                     *
                     * @param {String} key Der Parameter-Schlüssel.
                     * @param {Any} value Der Parameter-Wert.
                     */
                    set: (key, value) => {
                        parameters.set(`page.${topMenuId}.${key}`, value);
                        parameters.commit();
                    }
                },

                menu: {
                    /**
                     * Liefert Parameter des aktiven Menüs.
                     *
                     * @param {String} [key=null] [optional] Der ParameterSchlüssel.
                     *
                     * @returns {Object} Die Parameter.
                     */
                    get: (key, _default = {}) => {
                        let value = parameters.get(`menu.${topMenuId}.${leftMenuId}${key ? `.${key}` : ""}`);

                        return value !== undefined ? value : _default;
                    },

                    /**
                     * Setzt einen Parameterwert des Menüs.
                     *
                     * @param {String} key Der Parameter-Schlüssel.
                     * @param {Any} value Der Parameter-Wert.
                     */
                    set: (key, value) => {
                        if (value !== null && value !== undefined)
                            parameters.set(`menu.${topMenuId}.${leftMenuId}.${key}`, value);
                        else
                            parameters.remove(`menu.${topMenuId}.${leftMenuId}.${key}`);

                        parameters.commit();
                    }
                },

                component: {
                    /**
                     * Liefert die Parameter der Komponente.
                     *
                     * @param {String} [key=null] [optional] Der ParameterSchlüssel.
                     *
                     * @returns {Object} Die Parameter.
                     */
                    get: (key, _default = {}) => {
                        let value = parameters.get(`component.${this.componentId}${key ? `.${key}` : ""}`);

                        return value !== undefined ? value : _default;
                    },

                    /**
                     * Setzt einen Parameterwert der Komponente.
                     *
                     * @param {String} key Der Parameter-Schlüssel.
                     * @param {Any} value Der Parameter-Wert.
                     */
                    set: (key, value) => {
                        parameters.set(`component.${this.componentId}.${key}`, value);
                        parameters.commit();
                    }
                },

                /**
                 * Verschmelzt die Parameter und gibt diese zurück.
                 *
                 * @param {String} [key=null] [optional] Der ParameterSchlüssel.
                 *
                 * @returns {Object} Die verschmelzten Parameter.
                 */
                combined: key => {
                    const pageParams = cloneDeep(this.parameters().page.get());
                    const menuParams = cloneDeep(this.parameters().menu.get());
                    const componentParams = cloneDeep(this.parameters().component.get());
                    const combined = {...pageParams, ...menuParams, ...componentParams};

                    return key ? combined[key] : combined;
                }
            };
        }
    }
};
