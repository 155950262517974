import Scroller from "../index";

/**
 * Liefert das Verhältnis zwischen dem äußeren Scroll-Wrapper und dem scrollbaren Bereich
 * auf horizontaler oder vertikaler Achse
 *
 * @this Scroller
 *
 * @param {"horizontal"|"vertical"} axis
 *
 * @returns {Number}
 */
const getScrollbarRatio = function(axis = "vertical") {
    if (!this.isIFrame)
        return axis === "vertical"
            ? this.elements.barVert.getBoundingClientRect().height / this.element.scrollHeight
            : this.elements.barHoz.getBoundingClientRect().width / this.element.scrollWidth;

    return axis === "vertical"
        ? this.elements.barVert.getBoundingClientRect().height / this.element.contentDocument.body.scrollHeight
        : this.elements.barHoz.getBoundingClientRect().width / this.element.contentDocument.body.scrollWidth;
};

export default getScrollbarRatio;

