import Scroller from "../index";
import onBottom from "./events/onBottom";
import onMouseDown from "./events/onMouseDown";
import onMouseLeave from "./events/onMouseLeave";
import onMouseMove from "./events/onMouseMove";
import onMouseUp from "./events/onMouseUp";
import onScroll from "./events/onScroll";

/**
 * Deregistriert die Benutzererignisse der proprietären Skrollbar
 *
 * @this Scroller
 */
const detachEvents = function() {
    this.registeredHandler.resize.destroyed = true;
    clearTimeout(this.registeredHandler.resize.timer);

    onBottom.detach.call(this);
    onMouseDown.detach.call(this);
    onMouseLeave.detach.call(this);
    onMouseMove.detach.call(this);
    onMouseUp.detach.call(this);
    onScroll.detach.call(this);
};

export default detachEvents;
