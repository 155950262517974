import tinycolor from "tinycolor2";
import cloneDeep from "lodash/cloneDeep";

/**
 * Liefert die Helligkeit eines Farbwerts.
 *
 * @param {Color.Rgb} color Die RGB-Frabe.
 *
 * @returns {Number} Die Helligkeit des Farbwerts.
 */
const ColorBrightness = color => {
    return Math.sqrt(0.299 * Math.pow(color.r, 2) + 0.587 * Math.pow(color.g, 2) + 0.114 * Math.pow(color.b, 2));
};

/**
 * Liefert die Schrittweite für eine spezifische Kontraststärke.
 *
 * @param {Number} contrast Der Kontrastwert.
 *
 * @returns {Number} Die vorgeschlagene Schrittweite.
 */
const GetContrastStepSize = contrast => {
    // sinnvoller Helligkeitsbereich wird angenommen von 50 bis 250
    switch (contrast) {
    case 1:
    case 2: return 66;
    case 3: return 58;
    case 4: return 50; // hoher kontrast (1)
    case 5: return 37.5; // mittel kontrast (2)
    case 6: return 25; // niedgrig kontrast (3)
    }

    return 0;
};

/**
 * Erzeugt eine prozentual veränderte Farbe.
 *
 * @param {Color.Rgb} color Die RGB Farbe
 * @param {Number} changeValue Der prozentuale Veränderungswert
 *
 * @returns {tinycolor} Eine Tinycolor-Farbinstanz.
 */
const CreateTInt = (color, changeValue) => {
    if (changeValue === 0)
        return color;

    if (changeValue < 0) {
        return tinycolor({
            r: Math.round(Math.min(255, Math.max(0, color.r + (changeValue * 255)))),
            g: Math.round(Math.min(255, Math.max(0, color.g + (changeValue * 255)))),
            b: Math.round(Math.min(255, Math.max(0, color.b + (changeValue * 255))))
        }).toRgb();
    }

    return tinycolor({
        r: color.r + (changeValue * (255 - color.r)),
        g: color.g + (changeValue * (255 - color.g)),
        b: color.b + (changeValue * (255 - color.b))
    });
};

/**
 * Füllt ein Array mit Helligkeitsabstufungen einer Basisfarbe.
 *
 * @param {Color.Rgb} color Ein Basisfarbwert.
 * @param {Number} stepSize Die Schrittweite der Helligkeitsstufen.
 * @param {Array} colors Ein Array zum befüllen der Farben.
 *
 * @returns {Array} Die Helligkeitsabstufungen einer Basisfarbe.
 */
const FillTintArray = (color, stepSize, colors) => {
    if (!stepSize)
        return colors;

    let brightStart = ColorBrightness(color);
    let bright = brightStart;
    let min = 50;
    let max = 225;

    while (bright > min + stepSize) {
        bright -= stepSize;
    }

    while (bright < max) {
        colors.push(CreateTInt(color, (bright - brightStart) / 255));

        bright += stepSize;
    }

    return colors;
};

/**
 * Prüft, ob eine Farbe im Grautonbereich ist.
 *
 * @param {Color.Rgb} color Die zu prüfende Farbe.
 *
 * @returns {Boolean} Der Wert, ob die Farbe im Grautonbereich ist.
 */
const isGrey = color => {
    return color.r === color.g && color.r === color.b;
};

/**
 * Wandelt einen RGB-Wert in einen HSV-Wert um.
 *
 * @param {Color.Rgb} color Der RGB-Farbwert
 *
 * @returns {Color.Hsv} Der HSV-Wert der Farbe.
 */
const RgbToHsv = color => {
    let r = color.r / 255;
    let g = color.g / 255;
    let b = color.b / 255;

    let hsv = {};
    let min, max, delta;

    min = Math.min(Math.min(r, g),b);
    max = Math.max(Math.max(r,g),b);

    hsv.v = max;
    delta = max - min;

    if (delta < 0.00001) {
        hsv.s = 0;
        hsv.h = 0; // grauton

        return hsv;
    }

    if (max > 0.0) {
        hsv.s = (delta / max);
    } else {
        hsv.s = 0.0;
        hsv.h = 0.0;

        return hsv;// grauton
    }

    if (r >= max)
        hsv.h = (g - b) / delta;
    else {
        if (g >= max)
            hsv.h = 2.0 + (b - r) / delta;
        else
            hsv.h = 4.0 + (r - g) / delta;
    }

    hsv.h *= 60.0;

    if (hsv.h < 0.0)
        hsv.h += 360.0;

    return hsv;
};

/**
 * Wandelt einen HSV-Wert in einen RGB-Wert um
 *
 * @param {Color.Hsv} hsv Der HSV-Wert
 *
 * @returns {Color.Rgb} Der RGB-Wert
 */
const HsvToRgb = hsv => {
    let r, g, b;
    let hh, p, q, t, ff;
    let i;

    if (hsv.s <= 0.0) {
        r = hsv.v;
        g = hsv.v;
        b = hsv.v;
        return {r: r, g: g, b: b};
    }

    hh = hsv.h;

    if (hh >= 360.0) hh = 0.0;

    hh /= 60.0;
    i = parseInt(hh);
    ff = hh - i;
    p = hsv.v * (1.0 - hsv.s);
    q = hsv.v * (1.0 - (hsv.s * ff));
    t = hsv.v * (1.0 - (hsv.s * (1.0 - ff)));

    switch (i) {
    case 0:
        r = hsv.v;
        g = t;
        b = p;
        break;
    case 1:
        r = q;
        g = hsv.v;
        b = p;
        break;
    case 2:
        r = p;
        g = hsv.v;
        b = t;
        break;
    case 3:
        r = p;
        g = q;
        b = hsv.v;
        break;
    case 4:
        r = t;
        g = p;
        b = hsv.v;
        break;
    case 5:
    default:
        r = hsv.v;
        g = p;
        b = q;
        break;
    }

    return {r: parseInt(r*255), g: parseInt(g*255), b: parseInt(b*255)};
};

/**
 * Ermittelt anhand einer Basisfarbe kontrastreiche HSV-Farbabstuffungen.
 *
 * @param {Color.Rgb} color Die Basisfarbe
 * @param {Number} contrast Der Kontrast
 * @param {Array} colors Der Farbcontainer
 *
 * @returns {Array} Die kontrastreichen Farbabstufungen einer Basisfarbe.
 */
const AddColorsHSV = (color, contrast, colors) => {
    if (!contrast)
        return colors;

    if (isGrey(color)) {
        colors = FillTintArray(color, GetContrastStepSize(contrast), colors);

        return colors;
    }

    let step = 0.1;

    switch (contrast) {
    case 1:
    case 2:
        step = .5;
        break;
    case 3:
        step = .29;
        break;
    case 4:
        step = .2;
        break;
    case 5:
        step = .15;
        break;
    case 6:
    case 7:
        step = .1;
        break;
    }

    let hsvBase = RgbToHsv(color);
    let hsvTmp = cloneDeep(hsvBase);

    colors.push(HsvToRgb(hsvTmp));

    let farbe = null;

    if ((hsvTmp.s <= .75 && hsvTmp.v >= .25) || hsvTmp.v <= .75 && hsvTmp.s >= .25) {
        // erst s hoch
        while (hsvTmp.s + step <= 1 && hsvTmp.v - step >= 0) {
            hsvTmp.s += step;
            hsvTmp.v -= step;

            farbe = HsvToRgb(hsvTmp);

            if (ColorBrightness(farbe) > 40)
                colors.push(farbe);
        }

        // dann runter
        hsvTmp = cloneDeep(hsvBase);

        while (hsvTmp.s - step >= 0 && hsvTmp.v + step <= 1) {
            hsvTmp.s -= step;
            hsvTmp.v += step;

            farbe = HsvToRgb(hsvTmp);

            if (ColorBrightness(farbe) > 40)
                colors.push(farbe);
        }
    } else {
        // einzen bewegen
        step *= 2;

        while (hsvTmp.s - step >= .25) {
            hsvTmp.s -= step;
            farbe = HsvToRgb(hsvTmp);

            if (ColorBrightness(farbe) > 40)
                colors.push(farbe);
        }

        hsvTmp = cloneDeep(hsvBase);

        while (hsvTmp.v - step >= .15) {
            hsvTmp.v -= step;
            farbe = HsvToRgb(hsvTmp);

            if (ColorBrightness(farbe) > 40)
                colors.push(farbe);
        }
    }

    return colors;
};

/**
 * Sortiert die Liste nach Helligkeit
 *
 * @param {Array} colors Die Farben
 * @param {Boolean} desc Die Sortierreihenfolge
 *
 * @returns {Array} Die sortierte Liste.
 */
const SortByBrightness = (colors, desc) => {
    if (colors.length <= 1)
        return colors;

    let k, l;

    for (k = 1; k <= colors.length - 1; ++k) {
        let iteration = k - 1;

        for (l = k + 1; l <= colors.length; ++l) {
            let vergleich = l - 1;

            if (desc) {
                if (ColorBrightness(colors[iteration]) > ColorBrightness(colors[vergleich])) {
                    let t = colors[iteration];

                    colors[iteration] = colors[vergleich];
                    colors[vergleich] = t;
                }
            }
            else {
                if (ColorBrightness(colors[iteration]) < ColorBrightness(colors[vergleich])) {
                    let t = colors[vergleich];

                    colors[vergleich] = colors[iteration];
                    colors[iteration] = t;
                }
            }
        }
    }

    return colors;
};

/**
 * Füllt das Array solange bis genug Farben vorhanden sind durch Grauinterpolation
 *
 * @param {Array} colors Das Farbarray
 * @param {Number} amount Die Anzahl der zu generierenden Farben.
 *
 * @returns {Array} Die Farbwerte
 */
const GetMissingColors = (colors, amount) => {
    if (colors.length > amount)
        return colors;

    if (colors.length === 0)
        colors.push({r: 0, g: 0, b: 0});

    let missing = amount - colors.length;

    if (missing < 1)
        return colors;

    let step = parseInt(255 / (missing + 1));

    for (var i = 1; i <= missing; i++) {
        let g = 255 - (i * step); // helle Farben zuerst

        colors.push({r: g, g: g, b: g});
    }

    return colors;
};

/**
 * Berechnet Farbwerte basierend auf 2 Basisfarben und der Anzahl der zu berechnenden
 * Farbwerte.
 *
 * @param {String} baseColor1 Die 1. Basisfarbe
 * @param {String} baseColor2 Die 2. Basisfarbe
 * @param {Number} amount Die Anzahl der zu berechnenden Farbwerte
 * @param {Number} contrast Der Kontrast
 *
 * @returns {Array<String>} Ein Array mit berechneten Farbwerten
 */
const createColors = (baseColor1, baseColor2, amount, contrast) => {
    let tmp = null;

    let color1 = tinycolor(baseColor1).toRgb();
    let color2 = tinycolor(baseColor2).toRgb();

    if (contrast <= 1) contrast = 2;
    if (contrast >= 7) contrast = 6;

    let colors = [];
    let desc = true;

    if (isGrey(color1)) {
        tmp = color1;
        color1 = color2;
        color2 = tmp;
    }

    /**
     * Farben aus 1. Basisfarbe
     */

    let generated = [];

    for (var i = 2; i <= contrast; i++) {
        generated = AddColorsHSV(color1, i, generated);

        if (generated.length > 1 && generated.length >= amount / 2)
            break;

        if (i !== contrast)
            generated = [];
    }

    generated = SortByBrightness(generated, desc);

    desc = !desc;

    // ergebniss der ersten farbe übernehmen
    if (generated.length > 0)
        colors.push(...generated);

    generated = [];

    // zweite farbe
    for (var k = 2; k <= contrast; ++k) {
        generated = AddColorsHSV(color2, k, generated);

        if (generated.length >= amount / 2)
            break;

        if (k !== contrast)
            generated = [];
    }

    if (!isGrey(color2) || generated.length + colors.length >= amount) {
        generated = SortByBrightness(generated, desc);

        desc = !desc;

        // ergebniss der ersten farbe übernehmen
        colors.push(...generated);

        generated = [];
    }

    return colors;
};

/**
 * Liefert Farben aus einer Liste von Farbwerten.
 *
 * @param {Array} colors Die Farbwerte
 * @param {Number} amount Die Anzahl der zu nutzenden Farbwerte.
 * @param {Number} picktyp Die Art der Auswahl.
 *
 * @returns {Array} Die ausgewählten Farben.
 */
const SelectColors = (colors, amount, picktyp) => {
    let result = [];
    let index = 1;
    let stepSize = 1; // beschluss vom 6.6.16 aufsteigend die farben verwenden // (double)(possibleColors.Count()) / needed;

    if (picktyp === 2) {
        index = amount;
        stepSize = -1;
    } else if (picktyp === 3) {
        stepSize = parseInt(colors.length / amount, 10);
    }

    while (index <= colors.length && result.length < amount) {
        result.push(colors[index - 1]);

        index += stepSize;
    }

    return result;
};

/**
 * Stellt eine Funktion zu Ermittlung kontrastreicher Farbwerte zweier Basisfarben zur Verfügung.
 *
 * @param {String} baseColor1 Die 1. Basisfarbe
 * @param {String} baseColor2 Die 2. Basisfarbe
 * @param {Number} amount Die Anzahl der zu ermittelnden Farbwerte.
 *
 * @returns {Array} Eine Liste kontrastreicher Farbabstufungen aufgrund der Basisfarben.
 */
export default (baseColor1, baseColor2, amount) => {
    let tmp = [];
    let contrast = 7;
    let colors = [];

    if (tmp.length === 0)
        tmp = createColors(baseColor1, baseColor2, amount, contrast);

    tmp = GetMissingColors(tmp, amount);
    colors = SelectColors(tmp, amount, 1);

    return colors;
};
