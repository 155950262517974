/**
 * Eine Fehlerklasse, die geworfen wird, wenn ein Empfänger ein Ereignis abbricht.
 */
class NotifyCancelError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "NotifyCancelError";
    }
}

export default NotifyCancelError;
