<script lang="ts" setup>
import type { PropType, StyleValue } from "vue";
import { computed, onBeforeUnmount, nextTick, ref, watch } from "vue";
import { injectUtility } from "@/utils/utility.helper";
import { isEmptyWs } from "@/utils/string";
import difference from "lodash/difference";
import murmurhash from "murmurhash";
import Select from "@/utils/Select/js";
import SelectSelection from "./SelectSelection.vue";
import useComponentUtils from "@/mixins/composition.component";

const store: { [key: number]: string } = {};

const $config = injectUtility("Config");
const $page = injectUtility("Page");

const emit = defineEmits(["change", "changeIcon", "opened", "ready"]);

/**
 * Das {@linkcode HTMLElement} Wurzelelement der Toolbar
 */
const wrapper = ref<HTMLElement>();

const { componentId, nodeId } = useComponentUtils({
    node: wrapper,
    plugins: {
        page: $page,
    },

    hooks: {
        async init() {
            select.value?.remove();

            select.value = new Select({
                target: nodeId.value,

                selected: props.selected,
                animate: props.animate,
                autoAdjust: props.autoAdjust,
                iconized: iconized.value,
                itemAlignment: props.align,
                searchable: props.searchable,
                selectable: !props.disabled,
                clearable: props.clearable,
                collapsible: props.collapsible,
                showLastSelection: props.showLastSelection,

                minWidth: props.minWidth,
                maxWidth: props.maxWidth,
                width: props.empty?.text ? "calc" : props.width,

                cssClasses: props.cssClasses,
                iconLoader: fetchIcon,

                setDefault: () => {
                    /**
                     * Alle Optionen filtern, dessen Wert dem Standardoptionswert entspricht.
                     */
                    let defaultOptions = (options.value ?? [])
                        .filter(o => o.value == props.default)

                    /**
                     * Den ersten gefundenen Eintrag zuordnen:
                     */
                    let defaultOption = defaultOptions.length > 0 ? defaultOptions.at(0) : null;

                    /**
                     * Falls keine Eintrag vorhanden:
                     */
                    if (!defaultOption) {
                        /**
                         * Alle Optionen filtern, dessen Wert ungleich dem Standardoptionswert und dem aktuell ausgewälten ist.
                         */
                        defaultOptions = (options.value ?? [])
                            .filter(o => o.value != props.selected && o.value != props.default);

                        /**
                         * Den ersten gefundenen Eintrag zurodnen:
                         */
                        defaultOption = defaultOptions.length > 0 ? defaultOptions.at(0) : null;
                    }

                    /**
                     * Wenn eine Option als Standardwert gefunden wurde:
                     */
                    if (defaultOption) {
                        /**
                         * Den Standardwert setzen:
                         */
                        selectedOption.value = defaultOption;
                        emitSelectedOption(defaultOption.value);

                        if (defaultOption && "icon" in defaultOption)
                            emitSelectedIcon(defaultOption.icon);

                    }
                },

                onConfirmSelection: async () =>
                    await props.onConfirmSelection(),

                onBeforeSelect: async () =>
                    await props.onBeforeChange(),

                onSelect: async option => {
                    selectedOption.value = option;
                    emitSelectedOption(option ? option.value : null);

                    if (option && "icon" in option)
                        emitSelectedIcon(option.icon);

                    select.value?.removeOptions();

                    options.value = await fetchOptions(null, [], selectedOption.value?.value ?? null);

                    await select.value?.appendOptions(options.value);
                },

                onValue: async (value, byEnterKey) => {
                    if (byEnterKey)
                        return;

                    select.value?.removeOptions();
                    options.value = await fetchOptions(value, [], null);

                    await select.value?.appendOptions(options.value);
                },

                onBottom: async (value, _options) => {
                    if (typeof props.items !== "function")
                        return;

                    options.value = await fetchOptions(value, _options, selectedOption.value?.value ?? null);

                    await select.value?.appendOptions(options.value);
                },

                onOpen: () => emit("opened", true),
                onClose: () => emit("opened", false)
            });

            if (select.value) {
                try {
                    options.value = await fetchOptions(null, [], props.selected);
                    await select.value.appendOptions(options.value);

                    const filteredOption = (options.value ?? [])
                        .filter(option => option.value == props.selected)

                    if (filteredOption.length > 0) {
                        selectedOption.value = filteredOption[0];

                        select.value?.setSelected(filteredOption[0], false);
                    } else if (props.selected)
                        select.value?.clear();
                } catch (e) {
                    /** Nichts zu tun, da Komponente unmounted */
                }
            }

            if (!props.selected && props.empty?.text)
                select.value?.adjust();

            emit("ready", true);
        }
    }
});

const select = ref<Select>();

const props = defineProps({
    /**
     * Gibt an, ob die Breite des Selects animiert werden soll.
     */
    animate: {
        type: Boolean,
        default: true
    },

    /**
     * Ein Tooltip während des hoverns.
     */
    hint: {
        type: String,
        default: ""
    },

    /**
     * Der vorausgewählte Optionswert (option-value).
     */
    selected: {
        type: [String, Number],
        default: null
    },

    /**
     * Der ausgewählte Standardoptionswert (option-value)
     */
    default: {
        type: [String, Number],
        default: null
    },

    /**
     * Gibt das darzustellende Icon bei nicht ausgewähltem Eintrag zurück.
     */
    empty: {
        type: Object as PropType<ISelectOption>,
        default: null
    },

    /**
     * Ein Array aller Optionen oder eine Methode, welche die Optionen liefert.
     *
     * @type {Array<Option>|Function}
     */
    items: {
        type: [Array, Function] as PropType<ISelectOption[] | ((value: string | null, existiingOptions: ISelectOption[], selected?: string | number | null) => Promise<ISelectOption[]>)>,
        default: () => []
    },

    /**
     * Stellt die horizontale Ausrichtung des Inhalts bereit.
     */
    align: {
        type: String as PropType<"left" | "right">,
        default: "left"
    },

    /**
     * Die initiale Breite des Selects.
     */
    width: {
        type: String,
        default: null
    },

    /**
     * Die minimale Breite des Selects.
     */
    minWidth: {
        type: String,
        default: "0px"
    },

    /**
     * Die maximale Breite des Selects.
     */
    maxWidth: {
        type: String,
        default: null
    },

    /**
     * Dynamische Breitenberechnung des Selects.
     *
     * Wenn diese Einstellung aktiviert ist, dann wird die Breite des Selects anhand der
     * breitesten Option bestimmt und gesetzt.
     */
    autoAdjust: {
        type: Boolean,
        default: true
    },

    /**
     * Gibt an, ob im Select über ein Eingabefeld nach Optionen gesucht werden darf.
     */
    searchable: {
        type: Boolean,
        default: false
    },

    /**
     * Gibt an, ob die aktuelle Auswahl aufhebbar ist.
     */
    clearable: {
        type: Boolean,
        default: false
    },

    /**
     * Gibt an, ob das Select bei leerer Auswahl verkleinerbar ist.
     */
    collapsible: {
        type: Boolean,
        default: false
    },

    /**
     * Liefert dem Select-Control hinuzufügende Css-Klassen.
     */
    cssClasses: {
        type: String,
        default: ""
    },

    /**
     * Ein Wert, der angibt, ob das Select nur lesbar ist.
     */
    disabled: {
        type: Boolean,
        value: false
    },

    /**
     * Liefert optional zusätzliche Css-Klassen des Wrappers -> .select__outer-wrapper.
     */
    wrapperCssClasses: {
        type: String,
        default: ""
    },

    /**
     * Eine Labelbezeichnung für das Select.
     */
    label: {
        type: [String, Boolean],
        default: null
    },

    /**
     * Gibt die Labelbreite an.
     */
    labelWidth: {
        type: [String, Boolean],
        default: "auto"
    },

    /**
     * Ein Wert, der angibt, ob die letzte Auswahl während einer Eingabe weiterhin als kleiner Infotext angezeigt werden soll.
     */
    showLastSelection: {
        type: Boolean,
        default: false
    },

    /**
     * Gibt die an das Select anzuhängende Einheit an (z.B. "cm", "qm", "€", etc).
     */
    unit: {
        type: String,
        default: null
    },

    /**
     * Eine Callback-Funktion vor Wechsel eines Listeneintrags
     */
    onBeforeChange: {
        type: Function,
        default: () => true
    },

    /**
     * Eine Callback-Funktion zur Bestätigung der Auswahl.
     */
    onConfirmSelection: {
        type: Function,
        default: () => true
    }
});

/**
 * Stellt die ausgewählte Option der Liste zur Verfügung.
 */
const selectedOption = ref<ISelectOption | null>(null);

/**
 * Die verfügbaren Optionen der Liste.
 */
const options = ref<ISelectOption[]>([]);

/**
 * Liefert einen Wert, der angibt, ob das SelectControl Icons darstellen wird.
 *
 * @returns Der Wert, ob das SelectControl Icons darstellen wird.
 */
const iconized = computed(() => {
    const hasEmptyIcon = props.empty?.icon !== null;
    const hasIcon = typeof props.items !== "function"
        ? props.items?.filter(item => item.icon).length > 0
        : false

    return hasEmptyIcon || hasIcon;
});

/**
 * Liefert individuelle Stylevorgaben der Dropdown-Anzeige.
 *
 * @returns Die individuellen Stylevorgaben der Dropdown-Anzeige.
 */
const styleDeclaration = computed(() => {
    const styleDeclaration: Partial<CSSStyleDeclaration> = {
        minWidth: props.minWidth,
        maxWidth: props.maxWidth
    };

    if (props.width)
        styleDeclaration.width = props.width;

    return styleDeclaration as StyleValue;
});

/**
 * Liefert die Optionen.
 *
 * @param value Der zu filternde Wert.
 * @param existingOptions Die derzeitigen vorhandenen Optionen.
 * @param selected Der Wert der ausgewälten Option.
 *
 * @returns Die Optionen.
 */
const fetchOptions = async (value: string | null, existingOptions: ISelectOption[], selected: string | number | null): Promise<ISelectOption[]> => {
    let options = [];

    if (typeof props.items === "function") {
        const fetched = await props.items(value, existingOptions, selected !== undefined ? selected : null);

        options = JSON.parse(JSON.stringify(fetched)) as ISelectOption[];
    } else
        options = JSON.parse(JSON.stringify(props.items)) as ISelectOption[];

    (options || []).forEach(option => {
        if (typeof option.value === "undefined")
            option.value = null;
    });

    return options;
};

/**
 * Emittiert den aktuell ausgewählten Eintrag (Wert) an die Elternkomponente.
 *
 * @param value Der zu emittierende Wert.
 */
const emitSelectedOption = (value: string | number | null) =>
    emit("change", value === null ? null : value ?? props.selected);


/**
 * Emittiert die Iconbezeichnung des aktuell ausgewählten Eintrags.
 *
 * @param iconName Die Iconbezeichnung,
 */
const emitSelectedIcon = (iconName?: string) =>
    emit("changeIcon", iconName);

/**
 * Setzt den Hover Status.
 *
 * @param {Boolean} hovering Der Hover-Status.
 */
const setHoverState = (hovering: boolean) =>
    select.value?.setState("hovering", hovering);

/**
 * Holt ein Icon vom CDN.
 *
 * @param icon Der Name des Icons.
 *
 * @returns Das SVG-Icon oder null.
 */
const fetchIcon = async (icon: string) => {
    const iconUrl = !isEmptyWs(icon) ? `${$config.get("cdn.icons")}${icon.toLowerCase()}.svg` : false;

    if (!iconUrl)
        return null;

    const iconHash = murmurhash.v3(iconUrl);

    async function iconResolve(iconUrl: string): Promise<string> {
        return new Promise((resolve, reject) => {
            fetch(iconUrl)
                .then(response => resolve(response.text()))
                .catch(error => reject(error));
        });
    }

    if (store[iconHash])
        return store[iconHash];

    try {
        store[iconHash] = await iconResolve(iconUrl);

        return store[iconHash];
    } catch (error) { /** KEINE AUSGABE NÖTIG */ }

    return null;
}

/**
 * Setzt die Css-Klassen des Selects.
 *
 * @param cssOld Die alten Css-Klassen.
 * @param cssNew Die neuen Css-Klassen.
 */
const setCssClasses = (cssOld: string, cssNew: string) => {
    if (!select.value)
        return;

    const arrNew = cssNew?.split(" ") ?? [];
    const arrOld = cssOld?.split(" ") ?? [];

    const removeCss = difference(arrOld, arrNew);
    const addCss = difference(arrNew, arrOld);

    select.value.removeCssClasses(removeCss);
    select.value.addCssClasses(addCss);
};

/**
 * Überwacht die Änderung der Css-Klassen und
 * ändert diese, wenn notwendig.
*/
watch(
    () => props.cssClasses,
    (cssNew: string, cssOld: string) => setCssClasses(cssOld, cssNew),
    { flush: "post" }
);

/**
 * Überwacht die Änderung der aktuellen Auswahl von aussen und stellt den Wert ein.
 */
watch(
    () => props.selected,
    () => {
        const filteredOption = (options.value || [])
            .filter(option => option.value == props.selected)

        if (filteredOption.length === 0)
            return;

        selectedOption.value = filteredOption[0];

        select.value?.setSelected(filteredOption[0], false);
    },
    { flush: "post" }
);

/**
 * Überwacht den Deaktiviert-Status des Selects und wendet diesen auf die Select-Blibliothek an.
 */
watch(
    () => props.disabled,
    () => {
        nextTick(() => {
            const selectMethod = props.disabled ? "disable" : "enable";
            const cssMethod = props.disabled ? "add" : "remove";

            select.value && select.value[selectMethod]();
            wrapper.value && wrapper.value.classList[cssMethod]("select--disabled");
        });
    },
    {
        immediate: true,
        flush: "post"
    }
);

watch(
    () => props.items,
    async () => {
        if (typeof props.items === "function")
            return;

        select.value?.removeOptions();

        options.value = await fetchOptions(null, [], props.selected);

        await select.value?.appendOptions(options.value);

        const filteredOption = (options.value || [])
            .filter(option => option.value == props.selected);


        if (filteredOption.length === 0)
            return;

        selectedOption.value = filteredOption[0];

        select.value?.setSelected(filteredOption[0], false);
    }, {
        immediate: false,
        flush: "post"
    }
);

onBeforeUnmount(() => select.value?.remove());
</script>

<template lang="pug">
//- Eine individuelle Select-Box mit erweitertem Styling.
.control.select__selection(
    ref="wrapper"

    :id="nodeId"
)
    label.select__label(
        v-if="label"

        :style="{width: `${labelWidth}`}"
        :for="componentId"

        @mouseenter="setHoverState(true)"
        @mouseleave="setHoverState(false)"
    ) {{ label }}

    label(
        v-if="label == false && labelWidth != false"

        :style="{width: `${labelWidth}`}"
    )

    section.select__outer-wrapper(
        :class="wrapperCssClasses"
    )
        .select__wrapper(
            :style="styleDeclaration"
            :tabindex="searchable ? -1 : 0"
            :title="hint && hint.length > 0 ? hint : ''"
        )
            .select__border
            .select__border-custom

            SelectSelection(
                ref="selectSelection"

                :selected="selectedOption"
                :empty="empty"
                :iconized="iconized"
                :disabled="disabled"
                :searchable="searchable"
                :showLastSelection="showLastSelection"
            )

        span.unit(v-if="unit !== null") {{ unit }}

        slot
</template>
<style lang="scss">
form main .scrollboxsensor > section fieldset .control .wrapper:not(.\--fieldset).select__outer-wrapper {
    .fileupload {
        position: absolute;
        display: block;
        width: 80px;
    }
}

@import "@/utils/Select/scss/selection.scss";
@import "@/utils/Select/scss/list.scss";
</style>
