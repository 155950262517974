import cloneDeep from "lodash/cloneDeep";
// eslint-disable-next-line
import FailedFile from "./models/FailedFile";
// eslint-disable-next-line
import UploadFile from "./models/UploadFile";

/**
 * Erzeugt Pakete in Abhängikeit der maximal zugelassenen Paket- und Transfergröße für einen anstehenden Datei(en)transfer
 * und gibt diese zurück.
 *
 * @param {{failedFiles: FailedFile[], maxTransferSize: Number}} options
 *
 * @returns {UploadFile[]}
 */
const createQueueByError = ({failedFiles, maxTransferSize}) => {
    let newPackages = [];
    let newPackage = [];

    /**
     * @type {UploadFile[][]}
     */
    let uploadFilesByProblem = failedFiles
        .reduce((reduced, failedFile) => {
            if (!reduced.length)
                reduced.push({});

            if (!reduced[0][failedFile.problem]) {
                reduced[0][failedFile.problem] = Object.keys(reduced[0]).length + 1;

                reduced.push([]);
            }

            reduced[reduced[0][failedFile.problem]].push(failedFile.uploadFile);

            return reduced;
        }, [])
        .slice(1);

    /**
     * Kalkuliert die aktuelle Paketgröße bei neu hinzuzufügender Datei.
     *
     * @param {UploadFile} newFile
     *
     * @returns {Number}
     */
    const calcPackageSize = newFile => {
        return newPackage.reduce((result, current) => {
            return result + current.file.size;
        }, 0) + newFile.file.size;
    };

    /**
     * Fügt eine hochladbare Datei in den Paketpool ein, sofern das aktuelle Paket
     * die maximale Paketgröße sowie die maximale Transfergröße nicht erreicht hat und liefert
     * Status, ob das Paket in den Paketpool eingehangen wurde.
     *
     * @param {UploadFile} uploadFile
     * @param {UploadFile[]} uploadFiles
     * @param {Number} idx
     */
    const tryPushToPackages = (uploadFile, uploadFiles, idx) => {
        // Prüfen, ob neu hinzuzufügende Datei die maximale Paketgröße überschreiten würde.
        // Falls ja, dann das Paket ohne die hinzuzufügende Datei schließen.
        if (calcPackageSize(uploadFile) > maxTransferSize && newPackage.length) {
            newPackages.push(cloneDeep(newPackage));

            newPackage.length = 0;
        }

        // Datei in das aktuelle Paket einfügen.
        newPackage.push(uploadFile);

        // Prüfen, ob letzte hinzuzufügende Datei und falls ja, dann in Paketpool hinzufügen.
        if (idx === uploadFiles.length -1)
            newPackages.push(cloneDeep(newPackage));
    };

    uploadFilesByProblem.forEach(uploadFiles => {
        uploadFiles
            // Nach Dateigröße aufsteigend sortieren
            .sort((uploadFileA, uploadFileB) => uploadFileA.file.size - uploadFileB.file.size)
            // Dateipakete in Abhängigkeit der maximalen Paket- und Transfergröße erzeugen.
            /** @todo: Fehlermeldung, Datei zu groß, wenn tryPushToPackages === false */
            .forEach((uploadFile, idx) => tryPushToPackages(uploadFile, uploadFiles, idx));
    });

    return newPackages;
};

export default createQueueByError;
