<script lang="ts" setup>
import { ref } from "vue";
import { injectUtility } from "@/utils/utility.helper";
import Icon from "@/components/UI/Icon.vue";
import useCacheUtils from "@/mixins/composition.cache";
import useComponentUtils from "@/mixins/composition.component";

/**
 * Das Wurzel-{@linkcode HTMLElement} der Komponente
 */
const root = ref<HTMLElement>();

const emit = defineEmits(["selected"]);

const $cache = injectUtility("Cache");
const $reactiveCache = injectUtility("ReactiveCache");
const $modal = injectUtility("Modal");
const $page = injectUtility("Page");

const { componentId } = useComponentUtils({
    node: root,
    hooks: {
        init: () => refreshFilter()
    },

    plugins: {
        page: $page
    }
});

const cache = useCacheUtils({
    componentId,
    cache: $cache,
    reactiveCache: $reactiveCache
});

const props = defineProps({
    /**
     * Der Name des Filters (wird für den Tooltip als auch für die Titelzeile der DataView verwendet).
     */
    name: {
        type: String,
        default: "Filter"
    },

    /**
     * Die Eigenschaft, die dem Filter hinzugefügt wird.
     */
    member: {
        type: String,
        default: null
    },

    /**
     * Das darzustellende Filter-Icon.
     */
    icon: {
        type: String,
        default: "filter"
    },

    /**
     * Der Wert der Eigenschaft, die bei der Auswahl eines Eintrags aus der ModalRemoteSelectionComponent für den Filter angewendet werden soll.
     */
    targetMember: {
        type: String,
        default: null
    },

    /**
     * Die Art der Darstellung der ModalSelectionComponent.
     */
    viewType: {
        type: String,
        default: "select"
    },

    /**
     * Gibt die Services zurück, welche für die Abfrage des User Interfaces sowie der Daten
     * Serverabfrage herangezogen wird.
     */
    services: {
        type: Object,
        default: () => { return {}; }
    },

    /**
     * Die Eigenschaft, dessen Wert nach erfolgter Auswahl eines Eintrags in der
     * ModalSelectionComponent als Text angezeigt wird.
     */
    displayMember: {
        type: String,
        default: null
    },

    /**
     * Die Eigenschaft, dessen Wert nach erfolgter Auswahl eines Eintrags in der
     * ModalSelectionComponent als Icon angezeigt wird.
     */
    iconMember: {
        type: String,
        default: "icon"
    }
});

/**
 * Der angezeigte Wert im Button.
 */
const displayValue = ref("");

/**
 * Das angezeigte Icon im Button.
 */
const displayIcon = ref("");

/**
 * Ein Wert der angibt, ob der Filterbutton eine Selektion hat
 * HINWEIS: dient der Anzeige des Clear-Buttons.
 */
const hasSelection = ref(false);

/**
 * Öffnet die ModelSelectionComponent und emittiert nach Auswahl eines
 * Eintrags die Daten an die Elternkomponente.
 */
const openModalSelect = () => {
    root.value?.blur();

    $modal.show("selection", {
        title: `${props.name}`,
        layout: {
            member: props.member,
            viewType: props.viewType,
            services: props.services
        },

        apply: data => {
            const value = data && data[props.displayMember] || props.name;
            const icon = data && data[props.iconMember] || props.icon;

            hasSelection.value = true;
            displayValue.value = value;
            displayIcon.value = icon;

            cache.set("displayValue", value);
            cache.set("displayIcon", icon);
            cache.set("hasSelection", true);

            emit("selected", data && data[props.targetMember]);
        }
    });
};

/**
 * Löscht den gesetzten Filter und benachrichtigt die Elternkomponte darüber.
 */
const clearFilter = () => {
    root.value?.blur();

    displayValue.value = "";
    displayIcon.value = props.icon;
    hasSelection.value = false;

    cache.set("hasSelection", false);
    cache.set("displayValue", "");
    cache.set("displayIcon", props.icon);

    emit("selected", null);
};

/**
 * Aktualisiert den Inhalt der Komponente
 */
const refreshFilter = () => {
    displayValue.value = cache.get("displayValue") ?? "";
    hasSelection.value = cache.get("hasSelection") ?? false;
    displayIcon.value = props.icon;
};
</script>

<template lang="pug">
//- Eine Komponente zum Abruf von Daten in Form einer Liste, welcher bei Auswahl eines Eintrags einen Filter auf ein Ziel durchführt.
div.btn.btn--filter.btn--secondary.btn--iconized(
    ref="root"
    type="button"

    :class="{'btn--selected': hasSelection}"
    :title="name"

    @click="openModalSelect"
)
    Icon.icon--custom(
        :icon="displayIcon"
    )

    span.btn__caption {{ displayValue  }}

    Icon(
        icon="close"

        @click.stop="clearFilter"
    )
</template>

<style lang="scss">
/** BEM - Modifizierer: Filter-Button */
.btn.btn--filter {
    &.btn--iconized.btn--secondary {
        position: relative;
        float: left;

        border-radius: 32px;
        width: auto;
        height: 32px;
        min-width: 32px;

        transition: width 125ms ease-out, border-color 125ms ease-in;

        border: 2px var(--color-button-secondary-border) solid;

        /** BEM - Elemente */
        .btn {
            /** BEM - Element: Button Text */
            &__caption {
                padding: 0px;
                margin: 0;
                color: var(--color-accent-1);
                font-size: 0.833333em;
                min-width: 0px;
                z-index: 1;

                transition: padding 125ms ease-out;
            }
        }

        /** BEM - Block: Icon */
        .icon {
            /** BEM - Element: Der Icon-Wrapper */
            &__wrapper {
                position: absolute;
            }

            /** BEM - Element: Der Html Container für das darzustellende Icon */
            &__html {
                width: 16px;
                height: 16px;
            }

            /** BEM - Modifizier: Auswahl aufheben Icon */
            &--close {
                /** BEM - Element: Der Icon-Wrapper */
                &.icon__wrapper {
                    width: 26px;
                    height: 28px;
                }

                /** BEM - Element: Der Html Container für das darzustellende Icon */
                .icon__html {
                    /** HTML - Element: SVG Element (Der Icon-Inhalt) */
                    > svg {
                        display: block;
                        position: relative;
                        float: left;
                        width: 16px;
                        height: 16px;

                        path {
                            fill: var(--color-disabled);
                            transition: fill 125ms ease-in;
                        }
                    }
                }
            }

            /** BEM - Modifizier: Indiviuelles Icon */
            &--custom {
                top: 6px;
                left: 0;
                right: auto;
                display: block;
                width: 16px;
                height: 16px;
                margin-left: 6px;
            }

            /** BEM - Modifizier: Auswahl aufheben Icon */
            &--close {
                right: 2px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                top: 0px;
                width: 26px;
                height: 100%;
                pointer-events: none;
                touch-action: none;
                opacity: 0;

                z-index: 2;

                transition: opacity 250ms cubic-bezier(0.23, 1, 0.32, 1);
            }
        }

        /** BEM - Modifizierer */
        &.btn {
            &--selected {
                border: 2px var(--color-button-primary) solid;
                transition: width 125ms ease-out, border-color 125ms ease-in;

                /** BEM - Modifizier: Auswahl aufheben Icon */
                .icon--close {
                    opacity: 1;

                    pointer-events: auto;
                    touch-action: auto;

                    transition: opacity 250ms cubic-bezier(0.23, 1, 0.32, 1);

                    .icon__html > svg path {
                        fill: var(--color-button-primary);
                        transition: fill 125ms ease-in;
                    }
                }

                /** BEM - BLock: Button */
                .btn {
                    /** BEM - Element: Button Text */
                    &__caption {
                        padding: 0px 32px 0px 32px;

                        transition: padding 125ms ease-out;
                    }

                    /** BEM - Modifizierer: Button mit individuellem Icon */
                    &--iconized {
                        margin-left: 4px;

                        transition: margin 125ms ease-out;
                    }
                }
            }
        }

        &:focus:not(.btn--pending),
        &:hover:not(.btn--pending),
        &.btn--hover:not(.btn--pending) {
            border: 2px var(--color-button-primary) solid;

            .icon--close .icon__html > svg path {
                fill: var(--color-button-primary);
                transition: fill 125ms ease-in;
            }
        }
    }
}
</style>
