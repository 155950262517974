<script lang="ts" setup>
import { computed, nextTick, onMounted, ref, watch } from "vue";
import Debounce from "./Debounce.vue";
import Icon from "@/components/UI/Icon.vue";

const emit = defineEmits(["input"]);

const props = defineProps({
    /**
     * Gibt an, ob die Suche deaktiviert ist.
     */
    disabled: {
        type: Boolean,
        default: false
    },

    /**
     * Der voreingestellte Suchwert.
     */
    preset: {
        type: String,
        default: ""
    },

    /**
     * Der Tooltip-Text.
     */
    tooltip: {
        type: String,
        default: "Eintrag suchen"
    },

    /**
     * Der Platzhalter für das Suchfeld
     */
    placeholder: {
        type: String,
        default: ""
    },

    /**
     * Gibt an, ob das Suchfeld immer geöffnet sein soll.
     */
    stayOpen: {
        type: Boolean,
        default: false
    }
});

/**
 * Das Wurzelelement der Komponente
 */
const root = ref<HTMLElement>();

/**
 * Der Suchbegriff.
 */
const value = ref("");

/**
 * Der Anzeigezustand (geöffnet/geschlossen).
 */
const isOpen = ref(false);

/**
 * Der Fokus-Zustand.
 */
const isFocused = ref(false);

/**
 * Gibt an, ob eine Ladeanimation angezeigt werden soll (kann von externen Komponenten überschrieben werden).
 */
const pending = ref(false);

/**
 * Gibt an, ob der Suchbegriff aufgehoben werden soll und ein damit einhergehender ServiceApi-Aufruf
 * ausstehend ist
 */
const clearing = ref(false);

/**
 * Der Checkbox-Status.
 */
const checked = ref(false);

/**
 * Gibt an, ob die Suche durch den Benutzer aufgehoben wurde.
 */
const closedInternal = ref(false);

/**
 * Gibt zurück, ob die Suche einen Wert enthält.
 */
const hasSearchValue = computed(() => value.value.trim().length > 0);

/**
 * Liefert die Css-Klassen in Abhängikeit des Eingabefeld-Zustands.
 */
const cssClasses = computed(() => {
    return {
        /**
         * Status: geöffnet.
         */
        "search--opened": isOpen.value || props.stayOpen,

        /**
         * Status: verfügt über eine Eingabe.
         */
        "search--clearable": hasSearchValue.value,

        /**
         * Status: Eingabefeld fokussiert.
         */
        "search--focused": isFocused.value,

        /**
         * Status: Eingabefeld wartend auf Ergebnis (XHR).
         */
        "search--pending": pending.value,

        /**
         * Status: Checkbox aktiv.
         */
        "search--checked": checked.value,

        /**
         * Status: Suchbegriff löschend
         */
        "search--clearing": clearing.value
    };
});

/**
 * Öffnet die Suche und setzt automatisch den Fokus auf das Eingabefeld, nachdem der Benutzer
 * mit der Maus über die Suche fährt.
 */
const open = () => {
    if (props.disabled)
        return;

    isFocused.value = true;
    isOpen.value = true;

    const $input = root.value?.querySelector("input");

    if (!$input)
        return;

    $input.focus();
};

/**
 * Schliesst die Suche, sofern diese keinen Suchbegriff enthält.
 */
const close = () => {
    if (props.disabled)
        return;

    isFocused.value = false;

    if (hasSearchValue.value)
        return;

    isOpen.value = false;
};

/**
 * Setzt die Sucheingabe zurück.
 *
 * @param stayOpened Der Wert, ob die Suche offen stehen soll
 */
const resetSearch = (stayOpened = false) => {
    value.value = "";
    isOpen.value = false;
    isFocused.value = false;

    if (stayOpened)
        return;

    close();
};

/**
 * Löscht die Sucheingabe.
 *
 * @param stayOpened Der Wert, ob die Suche offen stehen soll
 */
const clear = (stayOpened = false) => {
    if (props.disabled)
        return;

    if (!value.value.trim().length)
        return;

    clearing.value = true;

    searchForValue("");

    value.value = "";
    isOpen.value = stayOpened;
    isFocused.value = stayOpened;
    closedInternal.value = stayOpened;
};

/**
 * Speichert den eingegebenen Suchbegriff in die VUE-Property "searchValue"
 * und schliesst die Suche, sofern der Wert leer ist.
 *
 * @param newValue Der zu speichernde Wert
 * @param forceSearch Suche erzwingen, auch bei gleichem Suchwert
 */
const searchForValue = (newValue: string, forceSearch = false) => {
    if (!forceSearch && value.value.trim() === newValue.trim())
        return;

    value.value = newValue;
    isOpen.value = newValue.length > 0;

    emit(
        "input",

        newValue,

        /**
         * Ladeanimation anzeigen.
         */
        // eslint-disable-line
        () => nextTick(() => pending.value = true),

        /**
         * Ladeanimation verbergen.
         */
        // eslint-disable-line
        () => nextTick(() => pending.value = false)
    );
};

const blur = (v:string) => {
    if (!v.length)
        close();
};

watch(
    () => props.preset,
    () => {
        value.value = props.preset;
        isOpen.value = value.value.length > 0;
    },
    { flush: "post" }
);

watch(
    pending,
    () => {
        if (!pending.value)
            clearing.value = false;
    }
);

onMounted(() => {
    value.value = props.preset;

    if (value.value.length === 0)
        return;

    isOpen.value = true;
    isFocused.value = true;
});

defineExpose({
    resetSearch,
    searchForValue
});
</script>

<template lang="pug">
//- Eine dynamische Sucheingabe.
.search__container(
    ref="root"

    :title="tooltip"
    :class="cssClasses"

    @click="open"
)
    Icon.search__icon-search.-m-no-events(
        v-if="!clearing"
        icon="search"
    )

    Debounce.search__input(
        ref="inputDebounce"
        type="text"
        tag="input"

        :disabled="disabled"
        :value="value"
        :placeholder="placeholder"

        @change="v => searchForValue(v)"
        @focus="open"
        @blur="v => blur(v)"
        @clear="clear(true)"
    )

    Icon.search__icon-close(
        v-if="!pending"
        icon="close"

        :title="'Suche aufheben'"

        @click.stop="clear(false)"
    )
</template>

<style lang="scss">
/** Search Hover + Focused Mixin */
@mixin search-hover {
    border: 2px var(--color-button-primary) solid;

    transition: border-color 125ms ease-in;

    .search__icon-search,
    .search__icon-close {
        .icon__html {
            >svg {

                use,
                path {
                    transition: fill 125ms ease-in;
                    fill: var(--color-button-primary);
                }
            }
        }
    }
}

/** BEM - Elemete der Sucheingabe */
.search {

    /** BEM - Element: Der Container der Sucheingabe */
    &__container {
        position: relative;
        float: left;
        width: auto;
        min-width: 32px;

        border-top-left-radius: 32px;
        border-bottom-left-radius: 32px;

        border-top-right-radius: 32px;
        border-bottom-right-radius: 32px;

        padding-bottom: 5px;
        padding-top: 5px;
        height: 32px;

        background-color: transparent;

        border: 2px solid var(--color-button-secondary-border);

        transition: border-color 125ms ease-in;

        cursor: pointer;

        .switch {
            opacity: 0;
            transition: opacity 50ms;
            pointer-events: none;
            touch-action: none;
        }

        /** Suchfeld gehovert */
        &:hover {
            @include search-hover();
        }

        &:before,
        &:after {
            content: "";
            display: none;
        }
    }

    /** BEM - Icon Elemente */
    &__icon {

        /**
         * BEM - Elemente:
         *
         * - Icon Suchen
         * - Icon Auswahl aufheben
         */
        &-search,
        &-close {
            position: absolute;

            top: 0px;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;

            z-index: 2;
        }

        /** BEM - Element: Icon Suchen */
        &-search {
            width: 20px;
            left: 4px;

            .icon__html {
                >svg {
                    pointer-events: none;
                    touch-action: none;

                    width: 14px;
                    height: 14px;

                    use,
                    path {
                        fill: var(--color-disabled);
                        transition: fill 125ms ease-in;
                    }
                }
            }
        }

        /** BEM - Element: Icon Suche aufheben */
        &-close {
            right: 7px;
            width: 16px;

            pointer-events: none;
            touch-action: none;

            opacity: 0;
            transition: opacity 250ms cubic-bezier(0.23, 1, 0.32, 1);

            /** BEM - Element: Der Html Container für das darzustellende Icon */
            .icon__html {
                width: 16px;
                height: 16px;

                >svg {
                    display: block;
                    position: relative;
                    float: left;
                    width: 100%;
                    height: 100%;

                    path {
                        fill: var(--color-background);
                        transition: fill 125ms ease-in;
                    }
                }
            }
        }
    }

    /**
     * BEM - Element: Eingabefeld
     */
    &__input {
        position: relative;
        height: 100%;

        margin-left: 0px;
        margin-right: 0px;

        width: 5px;

        background-color: transparent;
        border: none;
        display: block;

        color: var(--color-tab-foreground);

        opacity: 0;

        z-index: 1;

        transition: margin-left 50ms cubic-bezier(0.23, 1, 0.32, 1) 125ms,
            margin-right 50ms cubic-bezier(0.23, 1, 0.32, 1) 125ms,
            width 1050ms cubic-bezier(0.23, 1, 0.32, 1) 125ms,
            opacity 250ms cubic-bezier(0.23, 1, 0.32, 1);

        cursor: pointer;

        &:focus,
        &:active {
            outline: none;
        }
    }

    /** BEM - Element: Slider */
    .switch-slider {
        z-index: 1;
    }

    /** BEM - Modifizierer: Geöffneter Zustand */
    &--opened {
        cursor: default;

        .search__icon-search {
            cursor: text;
        }

        >input {
            margin-left: 32px;
            margin-right: 32px;

            cursor: text;

            width: 70px;
            opacity: 1;

            transition: margin-left 50ms ease-out,
                margin-right 250ms ease-out,
                width 250ms ease-out,
                opacity 350ms ease-out 250ms;
        }
    }

    /** BEM - Modifizierer: Eingabefeld fokussiert */
    &--focused {
        @include search-hover();
    }

    /** BEM - Modifizierer: Sucheingabe wartend (XHR) */
    &--pending {

        &:before,
        &:after {
            content: "";

            display: block;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            position: absolute;
            top: 2px;
            left: auto;
            right: 3px;
            background-color: var(--color-button-primary);
            transform: scale(0);
        }

        &:before {
            animation: cssload-search-loader 2000ms infinite ease-in-out;
        }

        &:after {
            animation: cssload-search-loader 2000ms infinite 500ms ease-in-out;
        }

        &.search--clearing:after {
            left: 2px;
        }
    }

    /** BEM - Modifizierer: Eingabe aufhebbar */
    &--clearable {

        /**
        * BEM - Element: Eingabefeld
        */
        .search__input {
            width: 102px;
            margin-right: 32px;
        }

        /** BEM - Element: Icon Suche aufheben */
        .search__icon-close {
            cursor: pointer;
            pointer-events: auto;
            touch-action: auto;
            opacity: 1;
            transition: opacity 250ms cubic-bezier(0.23, 1, 0.32, 1);
        }

        /** BEM - Element: Toogle-Switch */
        .switch {
            opacity: 1;
            pointer-events: all;
            touch-action: auto;

            transition: opacity 175ms ease-out;
        }
    }
}</style>
