
import Clipboard from "@/utils/Clipboard";

/**
 * Ein Mixin zur Bereitstellung der Clipboardfunktionalität in einer Komponente.
 *
 * Wissenswert: Die Id wird über die Modul-Id, Sektions-Id, horizontale sowie vertikale Position der Komponente
 * im Dom ermittelt.
 */
export default {
    computed: {
        /**
         * Stellt Clipboard-Funktionen für die Komponente bereit.
         *
         * @returns {Object<Function>} Die Clipboard-Funktionen.
         */
        clipboard() {
            let _this = this;

            return {
                /**
                 * Liefert die Zeilen.Ids des aktuellen Kopier- bzw. Ausschneidevorgangs der Tabelle.
                 *
                 * @returns {Array} Die Zeilen-Ids.
                 */
                ids() {
                    return _this.cache.get("clipboard.ids") || [];
                },

                /**
                 * Fügt Daten für einen Kopiervorgang in die Clipboard-Instanz ein.
                 *
                 * @param {String} clipboardTopic Das Thema
                 * @param {Object} clipboardData Die Clipboard-Daten.
                 * @param {Array} ids Die für den Kopiervorgang ausgewählten Zeilen-Ids der Tabelle.
                 */
                async copy(clipboardTopic, clipboardData, ids) {
                    _this.cache.set("clipboard.ids", ids);

                    await Clipboard.copy(_this.componentId, clipboardTopic, clipboardData);
                },

                /**
                 * Fügt Daten für einen Ausschneidevorgang in die Clipboard-Instanz ein.
                 *
                 * @param {String} clipboardTopic Das Thema.
                 * @param {Object} clipboardData Die Clipboard-Daten.
                 * @param {Object} callbackParameters Zustäzliche Callback-Parameter.
                 * @param {Array} ids Die für den Ausschneidevorgang ausgewählten Zeilen-Ids der Tabelle.
                 */
                async cut(clipboardTopic, clipboardData, callbackParameters, ids) {
                    _this.cache.set("clipboard.ids", ids);

                    await Clipboard.cut(_this.componentId,clipboardTopic, clipboardData, callbackParameters);
                },

                /**
                 * Liefert den aktuellen Clipboard-Vorgangstyp.
                 *
                 * @returns {"copy"|"cut"} Der Clipboard-Vorgangstyp.
                 */
                type() {
                    return Clipboard.type();
                },

                /**
                 * Löscht den Inhalt des Clipboards.
                 *
                 * @param {Boolean} [silent=false] Gibt an, ob nur die Zeilenindices aus dem Cache gelöscht werden sollen.
                 */
                clear(silent) {
                    _this.cache.set("clipboard.ids", []);

                    if (silent !== true) Clipboard.clear();
                },

                /**
                 * Liefert die Parameter des letzten Kopier- bzw. Ausschneidevorgangs.
                 *
                 * @returns {Any} Die Parameter.
                 */
                meta() {
                    return Clipboard.meta();
                },

                /**
                 * Liefert die Clipboarddaten des letzten Kopier- bzw. Ausschneidevorgangs.
                 *
                 * @returns {Any} Die Clipboarddaten.
                 */
                data() {
                    return Clipboard.data();
                }
            };
        }
    }
};
