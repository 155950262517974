<script lang="ts" setup>

const props = defineProps({
    /**
    * Die von der Elternkomponente übergebenen Nachrichten.
    */
    message: {
        type: String,
        default: ""
    }
});

</script>

<template lang="pug">
.error  
    span.message( 
        v-html="props.message"
    )             

    slot
</template>
    
<style lang="scss" scoped>
.error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;

    width: 100%;

    >.message,
    > :nth-child(2) {
        opacity: 0;
        transform: translate3d(0, 24px, 0);
    }

    >.message {
        padding: 0px;
        color: var(--color-foreground);
        font-size: 0.8em;
        font-weight: 500;

        animation: fade-in-form-bottom 1s forwards linear;
        animation-timing-function: ease-out;
    }

    > :nth-child(2) {
        pointer-events: auto;
        touch-action: auto;
        backface-visibility: hidden;

        animation: fade-in-form-bottom 1s forwards 150ms linear;
        animation-timing-function: ease-out;
    }
}

@keyframes fade-in-form-bottom {
    0% {
        opacity: 0;
        transform: translate3d(0, 28px, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
</style>