import cloneDeep from "lodash/cloneDeep";
// eslint-disable-next-line
import UploadFile from "./models/UploadFile";

/**
 * Erzeugt Pakete in Abhängigkeit der maximal zugelassenen Paket- und Transfergröße für einen anstehenden Datei(en)transfer
 * und gibt diese zurück.
 *
 * @param {{files: UploadFiles[], maxTransferSize: Number}} options
 *
 * @returns {UploadFile[][]}
 */
const createQueue = ({files, maxTransferSize}) => {
    let newPackages = [];
    let newPackage = [];

    /**
     * Kalkuliert die aktuelle Paketgröße bei neu hinzuzufügender Datei.
     *
     * @param {UploadFile} newFile
     *
     * @returns {Number}
     */
    const calcPackageSize = newFile => {
        return newPackage.reduce((result, current) => {
            return result + current.file.size;
        }, 0) + newFile.file.size;
    };

    /**
     * Fügt einen Deitsystemeintrag in den Paketpool ein, sofern das aktuelle Paket
     * die maximale Paketgröße sowie die maximale Transfergröße erreicht hat und liefert
     * den Status, ob das Paket in den Paketpool eingehangen wurde.
     *
     * @param {UploadFile} file
     * @param {Number} idx
     */
    const tryPushToPackages = (file, idx) => {
        // Prüfen, ob neu hinzuzufügende Datei die maximale Paketgröße überschreiten würde.
        // Falls ja, dann das Paket ohne die hinzuzufügende Datei schließen.
        if (calcPackageSize(file) > maxTransferSize && newPackage.length) {
            newPackages.push(cloneDeep(newPackage));

            newPackage.length = 0;
        }

        // Datei in das aktuelle Paket einfügen.
        newPackage.push(file);

        // Prüfen, ob letzte hinzuzufügende Datei und falls ja, dann in Paketpool hinzufügen.
        if (idx === files.length -1)
            newPackages.push(cloneDeep(newPackage));
    };

    files
        // Nach Dateigröße aufsteigend sortieren
        .sort((fileA, fileB) => fileA.file.size - fileB.file.size)
        // Dateipakete in Abhängigkeit der maximalen Paket- und Transfergröße erzeugen.
        .forEach((file, idx) => {
            if (maxTransferSize !== "off")
                tryPushToPackages(file, idx)
            else
                newPackages.push([file]);
        });

    return newPackages;
};

export default createQueue;
