<script lang="ts" setup>
import { onMounted, ref } from "vue";

/**
 * Die Dom-Referenz auf das Ball-Elemente
 */
const balls = ref<HTMLElement>();

const props = defineProps({
    /**
     * Zeit[ms] mit der die Animation verzögert gestartet werden soll.
     */
    delay: {
        type: Number,
        default: 0
    }
});

onMounted(() => {
    if (!balls.value)
        return;

    balls.value.style.setProperty('--anim-delay', `${props.delay}ms`);
});
</script>

<template lang="pug">
.spinner(
    ref="balls"
) 
    div.wBall.wBall_1
    div.wBall.wBall_2
    div.wBall.wBall_3
    div.wBall.wBall_4

</template>

<style lang="scss" scoped>
.spinner {
    --anim-delay: 1000ms;

    display: flex;
    gap: 6px;
    height: 32px;
    text-align: center;

    opacity: 0;
    animation: fadeIn 500ms var(--anim-delay) forwards;

    .wBall {
        animation: jump 2000ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite;

        width: 10px;
        height: 10px;

        background-color: var(--color-button-primary);

        border-radius: 5px;

        left: 12px;
        top: 12px;

        &.wBall_1 {
            transition: opacity 1450ms ease-out;
            animation-delay: 0s;
        }

        &.wBall_2 {
            left: 26px;
            animation-delay: 0.12s;
        }

        &.wBall_3 {
            left: 40px;
            animation-delay: 0.24s;

            background-color: var(--color-error);
        }

        &.wBall_4 {
            left: 54px;
            animation-delay: 0.36s;
        }
    }
}

</style>
