/**
 * Eine allgemeine Basisklasse für die einzelnen Highchart-Optionswerte aller Diagramme.
 */
export default class BaseChart {
    protected caller: TChartConstructorParameters;
    protected animationDuration: number = 0;
    protected level: number;
    protected series: any[];
    protected sourceType?: "rows" | "columns";
    protected baseColors?: [string | null, string | null];

    public tooltip?: any;

    /**
     * C'Tor
     *
     * @param caller - Die Instanz des Aufrufers.
     */
    constructor(caller: TChartConstructorParameters) {
        this.caller = caller;
        this.animationDuration = 0;

        const diagram = (caller.plan.diagrams || []).find(diagram => (diagram.$type || diagram.type).toLowerCase() === caller.visualizationMode);

        this.level = (diagram || { level: 1}).level;

        this.series = [];
    }

    /**
     * Setzt die Highcharts-Option zur Darstellung des Tooltips eines Werts im Diagramm.
     *
     * @param tooltip - Die Tooltip-Optionen
     */
    public setTooltip(tooltip: any):this {
        this.tooltip = tooltip;

        return this;
    }

    /**
     * Setzt die Basisfarben der Diagramme.
     *
     * @param baseColor1 - Die 1. Basisfarbe
     * @param baseColor2 - Die 2. Basisfarbe
     */
    public setBaseColors(baseColor1?: string, baseColor2?: string):this {
        this.baseColors = [baseColor1 || null, baseColor2 || null];

        return this;
    }

    /**
     * Setzt die Animationsdauer des Diagramms.
     *
     * @param milliseconds - Die Animationsdauer in Millisekunden.
     */
    public setAnimationDuration(milliseconds: number):this {
        this.animationDuration = milliseconds;

        return this;
    }

    /**
     * Setzt die Quelle der darzustellenden Daten.
     *
     * @param source - Die Quelle.
     */
    public setSource(source: "rows" | "columns"):this {
        this.sourceType = source;

        return this;
    }

    /**
     * Liefert die Flags zur Darstellung im Diagramm.
     *
     * @param flags - Die Flags.
     * @param xAxis - Die X-Achse des Diagramms.
     * @param color - Die Farbe der Flags.
     */
    public prepareFlags(flags: {text: string, title: string, x: number, position: "onAxis"|"onSeries"|"inPane", series: string}[], xAxis: {items: {name: string|number}[]}, color: string):this {
        return this;
    }
}
