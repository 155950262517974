import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";
import set from "lodash/set";
import ToolkitTool from "../ToolkitTool";
import union from "lodash/union";

/**
 * Ein Typ zur Beschreibung der Differenz zweier Werte
 */
type Difference = {
    value1: any;
    value2: any;
}

/**
 * Ein Typ zur Beschreibung der Unterschiede zweier Objekte
 */
type Differences = {
    [key: string | number | symbol]: Difference | Differences;
}

/**
 * Ein Tool zur Ermittlung der Unterschiede zweier Objekte
 */
class DifferenceToolTool<T> extends ToolkitTool<{ objA: T, objB: T }> {
    /**
     * Liefert die Differenz der beiden Objekte zurück
     */
    difference() {
        const differences: Differences = {};

        /**
         * Vergleicht zwei Objekte rekursiv
         *
         * @param value1 Das erste Objekt
         * @param value2 Das zweite Objekt
         * @param path Der aktuelle Pfad im Objekt
         *
         * @returns Die Differenz der beiden Objekte
         */
        function compareObjects(value1: any, value2: any, path: string[] = []): void {
            if (isEqual(value1, value2))
                return;

            if (isObject(value1) && isObject(value2)) {
                const allKeys = union(Object.keys(value1), Object.keys(value2));

                for (const key of allKeys) {
                    compareObjects((value1 as Record<string, any>)[key], (value2 as Record<string, any>)[key], [...path, key]);
                }
            } else {
                set(differences, path, { value1, value2 });
            }
        }

        compareObjects(this.params.objA, this.params.objB);

        return differences;
    }
}

export default DifferenceToolTool;
