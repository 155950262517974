<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";
import { injectUtility } from "@/utils/utility.helper";
import fetchIcon from "@/store/icon";

const emit = defineEmits(["click"]);

const $config= injectUtility("Config");

const iconContainer  = ref<HTMLElement | null>(null);

const props = defineProps({
    /**
     * Die Füllfarbe des Icons.
     */
    fill: {
        type: String,
        default: null
    },

    /**
     * Das darzustellende Icon.
     */
    icon: {
        type: String,
        default: ""
    },

    /**
     * Die Deckkraft des Icons.
     */
    opacity: {
        type: Number,
        default: 1
    },

    /**
     * Standardereignisbehandlung unterbinden.
     */
    prevent: {
        type: Boolean,
        default: false
    },

    /**
     * Emittierung des Klickereignis an die Eltern-Dom-Elemente unterbinden.
     */
    stop: {
        type: Boolean,
        default: false
    }
});

/**
 * Liefert ein CSS-Klassen-Objekt der Icon-Komponente.
 *
 * @returns Die CSS-Klassen.
 */
const classObject = computed(() => {
    const cssClasses: { [key: string]: boolean } = {};

    if (!props.icon.trim().length)
        return cssClasses;

    cssClasses[`icon--${props.icon.toLowerCase()}`] = true;

    return cssClasses;
});

/**
 * Emittiert den Klick auf das Icon an die Elternkomponente.
 *
 * @param e - Das Klickereignis.
 */
const click = (e: MouseEvent) => {
    if (props.stop)
        e.stopPropagation();

    if (props.prevent)
        e.preventDefault();

    emit("click", e);
};

/**
 * Löst das Icon auf und hängt bei Wunsch dieses in den Dom.
 */
const resolveIcon = async function () {
    if (!props.icon.trim().length)
        return;

    const iconContent = await fetchIcon({
        $config,
        icon: props.icon,
    });

    if (!iconContent || !iconContainer.value)
        return;

    iconContainer.value.innerHTML = iconContent;

    const svg:SVGSVGElement | null = iconContainer.value.querySelector("svg");

    if (!svg)
        return;

    if (props.fill) {
        svg.setAttribute("fill", props.fill);

        svg.querySelectorAll("path").forEach(path => path.setAttribute("fill", props.fill));
    }

    (svg as any).style.opacity = props.opacity;
};

/**
 * Überwacht die Eigenschaft "fill" und passt die Füllfarbe des Icons an.
 */
watch(
    () => props.fill,

    async () => {
        if (!iconContainer.value)
            return;

        let svg: SVGSVGElement | null = iconContainer.value.querySelector("svg");

        if (!svg)
            await resolveIcon();

        svg = iconContainer.value.querySelector("svg")!;

        (svg as Element).setAttribute("fill", props.fill);

        svg.querySelectorAll("path")
            .forEach(path => path.setAttribute("fill", props.fill));
    },
    { flush: "post" }
);

watch(
    () => props.icon,
    async () => {
        await resolveIcon.call(this);
    },
    { flush: "post" }
)

onMounted(async () => {
    await resolveIcon();
});

</script>

<template lang="pug">
//- Eine SVG-Icon-Komponente.
.icon__wrapper(
    :class="classObject"

    v-on:click="click"
)
    i.icon__html(
        ref="iconContainer"
    )

    slot
</template>

<style lang="scss">
/** BEM - Block: Icon */
.icon {

    /** BEM - Element: Der Icon-Wrapper */
    &__wrapper {
        position: relative;
        float: left;
        width: auto;
        height: auto;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    /** BEM - Element: Der Html Container für das darzustellende Icon */
    &__html {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        /** HTML - Element: SVG Element (Der Icon-Inhalt) */
        >svg {
            display: inline-block;
            width: 100%;
            height: 100%;
            vertical-align: middle;
        }
    }
}
</style>
