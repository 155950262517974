import type { Ref } from "vue";
import { onBeforeUnmount } from "@vue/runtime-core";
import { computed, watch } from "vue";
import { PageApi } from "@/caching/page";
import Logger from "@/utils/Logger";
import MessageProvider from "@/utils/MessageProvider";
import Constant from "@/utils/Constant";

/**
 * Wandert den Dom-Baum bis zur Wurzel zurück und
 * liefert aufgrund der vorhandenen Nachbar und Elternknoten eine
 * eindeutige Positions-Id des Elements.
 *
 * @param node - Das mit einer Id zu versehende Html-Element im Baum
 * @param id - Ein Id-Postfix
 *
 * @returns Due eindeutige Id
 */
const traverseTree = (node: HTMLElement, id?: string): string => {
    if (!node)
        return "undefined";

    if (!node.parentNode)
        return id || "undefined";

    if (!node.tagName)
        return "undefined";

    if (node.tagName.toLowerCase() === "body")
        return (id ?? "")
            .split(".")
            .filter(Boolean)
            .reverse()
            .join(".");

    if (!id)
        id = "";

    id += `${Array.from(node.parentNode.childNodes).indexOf(node)}.`;

    return traverseTree(node.parentNode as HTMLElement, id);
};

type TComponentUtilsParameters = {
    node: Ref<HTMLElement | undefined>

    plugins: {
        page: PageApi
    }

    hooks?: {
        beforeUnmount?: () => Promise<void> | void
        init?: () => Promise<void> | void
    }
};

type TComponentUtilsResult = {
    componentId: Ref<string>
    menuId: Ref<string>
    pageId: Ref<string>
    nodeId: Ref<string>
};

/**
 * Generiert Ids in Abhängigkeit des aktuellen
 * Menüs und der Position im Html-Baum des übergebenen Html-Elements.
 *
 * @param options - Die Konfiguration
 *
 * @returns Die generierten Ids
 */
const useComponentUtils = ({ node, plugins, hooks }: TComponentUtilsParameters): TComponentUtilsResult => {
    /**
     * Die Id des aktiven linken Menüs
     */
    const menuId = computed(() => plugins.page.left.id());

    /**
     * Die Id des aktiven oberen Menüs
     */
    const pageId = computed(() => plugins.page.top.id());

    /**
     * Die Id der Komponente
     */
    const componentId = computed(() => {
        if (!node || !node.value)
            return "undefined";

        return `${menuId.value}.${traverseTree(node.value)}`;
    });

    /**
     * Die Komponenten-Id für die Verwendung im Html
     */
    const nodeId = computed(() => {
        if (!componentId.value)
            return "";

        return componentId.value.replace(/\./g, "_");
    });

    watch(
        componentId,
        async () => {
            if (!componentId.value)
                return;

            try {
                hooks?.beforeUnmount && MessageProvider.attach(Constant.System.NOTIFICATION.MENU_SWITCH, componentId.value, async (topic, receiverId) => {
                    MessageProvider.detach(Constant.System.NOTIFICATION.MENU_SWITCH, componentId.value);
                    MessageProvider.detach(Constant.System.NOTIFICATION.TAB_SWITCH, componentId.value);

                    if (receiverId !== componentId.value)
                        return;

                    try {
                        await hooks.beforeUnmount!();
                    } catch (e) {
                        Logger.error("Fehler in der Methode: beforeUnmount", e);
                    }
                });

                hooks?.beforeUnmount && MessageProvider.attach(Constant.System.NOTIFICATION.TAB_SWITCH, componentId.value, async (topic, receiverId) => {
                    MessageProvider.detach(Constant.System.NOTIFICATION.MENU_SWITCH, componentId.value);
                    MessageProvider.detach(Constant.System.NOTIFICATION.TAB_SWITCH, componentId.value);

                    if (receiverId !== componentId.value)
                        return;

                    try {
                        await hooks.beforeUnmount!();
                    } catch (e) {
                        Logger.error("Fehler in der Methode: beforeUnmount", e);
                    }
                });

                await hooks?.init?.();
            } catch (e) {
                Logger.error("Fehler in der Methode: init", e);
            }
        },
        {
            flush: "post",
            immediate: false
        }
    );

    onBeforeUnmount(async () => {
        if (!hooks?.beforeUnmount)
            return;

        try {
            await hooks.beforeUnmount();
        } catch (e) {
            Logger.error("Fehler in der Methode: beforeUnmount", e);
        }
    });

    return {
        componentId,
        menuId,
        pageId,
        nodeId
    };
};


export type { TComponentUtilsParameters, TComponentUtilsResult };
export default useComponentUtils;
