import combineURLs from "axios/lib/helpers/combineURLs";
import createFolders from "./createFolders";
import createQueue from "./createQueue";
import createQueueByError from "./createQueueByError";
import Drive from "../../api";
import { escapePath } from "@/apis/drive/utils/pathConverter";
// eslint-disable-next-line
import FailedFile from "./models/FailedFile";
import sendQueue from "./sendQueue";
// eslint-disable-next-line
import UploadFile from "./models/UploadFile";

/**
 * Eine Klasse für einen Datei(en)transfer.
 */
class Uploader {
    /**
     * Querystring Appendix.
     *
     * @type {String}
     */
    _query = "";

    /**
     * Zielpfad Endpunkt.
     *
     * @type {String}
     */
    _path = "";

    /**
     * Aussenstehende Dateien des Dateitransfers.
     *
     * @type {UploadFile[]}
     */
    _pendings = [];

    /**
     * Die aufzurufende Methode bei Abbruch eines Datei(en)transfers.
     */
    onCancelCallback = () => { }

    /**
     * Die aufzurufende Methode nach Erzeugung eines Verzeichnisses ohne Dateiinhalt.
     */
    onDirectoryCreatedCallback = () => { }

    /**
     * Die aufzurufende Methode bei Beendigung eines Datei(en)transferpakets der Warteschlange.
     */
    onFilesFinishedCallback = () => { }

    /**
     * Die aufzurufende Methode bei Beendigung eines Datei(en)transferpakets der Warteschlange
     * im Rahmen eines zuvor fehlgeschlagenen Datei(en)transfers mit anschließendem erneuten Versuch.
     */
    onFilesRetriedCallback = () => { }

    /**
     * Die aufzurufende Methode bei Beendigung eines Datei(en)transfers.
     */
    onFinishedCallback = () => { }

    /**
     * Die aufzurufende Methode bei Fortschritt eines Datei(en)transfers.
     */
    onProgressCallback = () => { }

    /**
     * Die aufzurufende Methode bei Beendigung eines Datei(en)transfers
     * im Rahmen eines zuvor fehlgeschlagenen Datei(en)transfers mit anschließendem erneuten Versuch
     */
    onRetryFinishedCallback = () => { }

    /**
     * Die aufzurufende Methode bei Start eines Datei(en)transfers
     * im Rahmen eines zuvor fehlgeschlagenen Datei(en)transfers mit anschließendem erneuten Versuch
     */
    onRetryStartCallback = () => { }

    /**
     * Die aufzurufende Methode bei Start eines Datei(en)transfers.
     */
    onStartCallback = () => { }

    set auth(auth) { this._auth = auth; }
    get auth() { return this._auth; }

    set filesAbortController(filesAbortController) { this._filesAbortController = filesAbortController; }
    get filesAbortController() { return this._filesAbortController; }

    set folderAbortController(folderAbortController) { this._folderAbortController = folderAbortController; }
    get folderAbortController() { return this._folderAbortController; }

    set hasCancelled(hasCancelled) { this._hasCancelled = hasCancelled; }
    get hasCancelled() { return this._hasCancelled; }

    set fileCountSent(amount) { this._fileCountSent = amount; }
    get fileCountSent() { return this._fileCountSent; }

    get axiosConfig() {
        return {
            maxRedirects: 0,

            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${this._auth.AccessToken}`
            },

            onProgress: progressEvent => {
                const {
                    amount = { total: 0, sent: 0, failed: 0 },
                    bytes = { total: 0, loaded: 0 },
                    files = []
                } = progressEvent;

                this.onProgressCallback({
                    amount,
                    bytes,
                    files
                });
            }
        };
    }

    /**
     * @type {{upload: string|null, folder: string|null}}}
     *
     */
    endpoints = {
        upload: null,
        folder: null
    };

    set baseUrl(url) { this._baseUrl = url; }
    get baseUrl() { return this._baseUrl; }

    set maxTransferSize(maxTransferSize) { this._maxTransferSize = maxTransferSize; }
    get maxTransferSize() { return this._maxTransferSize; }

    constructor({ auth, baseUrl, maxTransferSize = 1024 * 10 }) {
        this.auth = auth;
        this.baseUrl = baseUrl || Drive.baseUrl;
        this.maxTransferSize = maxTransferSize;
    }

    /**
     * Sendet Dateien an eine Zieladresse (Dateiupload)
     *
     * @param {{files: UploadFile[], folders: String[], query: String, path: String}} options
     *
     * @returns {Uploader}
     */
    async send({ files, folders = [], query = null, path = ""}) {
        this._query = query;
        this._path = path;

        this.hasCancelled = false;
        this.fileCountSent = 0;

        const queue = createQueue({
            files,
            maxTransferSize: this.maxTransferSize,
        });

        this._pendings = queue.flat();

        /**
         * Überflüssige Verzeichnispfade aus dem Array entfernen.
         * Bspw. wenn ["/a/b", "/a/b/c", "/a/b/c/d"], dann wird "/a/b" und "/a/b/c" entfernt.
         */
        const reduced = folders.reduce((result, path) => {
            result = result.filter(s => !path.startsWith(s));

            if (!result.filter(s => s.startsWith(path)).length)
                result.push(path);

            return result;
        }, []);

        const amount = {
            total: files.length
        };

        this.onStartCallback(amount);

        const fileUrl = combineURLs(this.baseUrl, `${this.endpoints.upload}/${escapePath(path)}`.replace(/\/{2,}/g, "/")).replace(/\/$/g, "");

        await sendQueue({
            url: fileUrl,
            maxTransferSize: this.maxTransferSize,
            queue,
            query: query,
            axiosConfig: this.axiosConfig,

            next: ({ abortController, response }) => {
                this.filesAbortController = abortController;

                if (response) {
                    this.updatePendings(response.uploaded);

                    this.onFilesFinishedCallback(response);
                }


                return !this.hasCancelled;
            },

            cancelled: () => this.onCancelCallback()
        });

        const folderUrl = combineURLs(this.baseUrl, escapePath(`${this.endpoints.folder}`.replace(/\/{2,}/g, "/")));

        await createFolders({
            url: folderUrl,
            folders: reduced,
            axiosConfig: {
                headers: {
                    Authorization: `Bearer ${this._auth.AccessToken}`
                }
            },

            next: ({ abortController, directory }) => {
                this.folderAbortController = abortController;

                if (directory)
                    this.onDirectoryCreatedCallback(directory);

                return !this.hasCancelled;
            },

            cancelled: () => this.onCancelCallback()
        });

        this.onFinishedCallback();

        return this;
    }

    /**
     * Liefert aussenstehende Dateiten des Dateiransfers.
     *
     * @returns {UploadFile[]}
     */
    getPendings() {
        return this._pendings;
    }

    /**
     * Aktualisiert den Container der noch ausstehenden Dateien des Dateitransfers.
     *
     * @param {UploadFile[]} uploaded
     */
    updatePendings(uploaded) {
        this._pendings = this._pendings
            .filter(uploadFile => !uploaded.some(fso => fso.path === uploadFile.path));
    }


    /**
     * Wiederholt einen fehlgeschlagenen Dateitransfer.
     *
     * @param {FailedFile[]} failedFiles
     *
     * @returns {Uploader}
     */
    async retry(failedFiles) {
        failedFiles.forEach(failedFile => failedFile.retrying = true);

        const queue = createQueueByError({
            failedFiles,
            maxTransferSize: this.maxTransferSize,
        });

        const amount = {
            total: failedFiles.length,
            sent: 0,
            failed: 0
        };

        this.onRetryStartCallback(amount);

        const fileUrl = combineURLs(this.baseUrl, `${this.endpoints.upload}/${escapePath(this._path)}`.replace(/\/{2,}/g, "/")).replace(/\/$/g, "");

        await sendQueue({
            url: fileUrl,
            queue,
            query: this._query,
            maxTransferSize: this.maxTransferSize,
            axiosConfig: this.axiosConfig,

            next: ({ abortController, response }) => {
                if (response)
                    this.onFilesRetriedCallback(response);

                this.filesAbortController = abortController;

                return !this.hasCancelled;
            },

            cancelled: () => this.onCancelCallback()
        });

        this.onRetryFinishedCallback();

        return this;
    }

    /**
     * Bricht den aktuellen Datei(en)transfer ab.
     *
     * @returns {Uploader}
     */
    cancel() {
        this.hasCancelled = true;

        if (this.filesAbortController)
            this.filesAbortController.abort();

        if (this.folderAbortController)
            this.folderAbortController.abort();

        return this;
    }

    /**
     * Merkt sich die aufzurufende Callback-Funktion beim Start eines Datei(en)transfers.
     *
     * @param {Function} callback
     *
     * @returns {Uploader}
     */
    onStart(callback) {
        this.onStartCallback = callback;

        return this;
    }

    /**
     * Merkt sich die aufzurufende Callback-Funktion während des Fortschritts eines Datei(en)transfers.
     *
     * @param {Function} callback
     *
     * @returns {Uploader}
     */
    onProgress(callback) {
        this.onProgressCallback = callback;

        return this;
    }

    /**
     * Merkt sich die aufzurufende Callback-Funktion nach Erzeugung eines Verzeichnisses ohne Dateiinhalt.
     *
     * @param {Function} callback
     *
     * @returns {Uploader}
     */
    onDirectoryCreated(callback) {
        this.onDirectoryCreatedCallback = callback;

        return this;
    }

    /**
     * Merkt sich die aufzurufende Callback-Funktion nach Ende eines transferierten Dateipakets der Warteschlange.
     *
     * @param {Function} callback
     *
     * @returns {Uploader}
     */
    onFilesFinished(callback) {
        this.onFilesFinishedCallback = callback;

        return this;
    }

    /**
     * Merkt sich die aufzurufende Callback-Funktion nach Ende eines transferierten Dateipakets der Warteschlange
     * nach erneutem Versuch eines zuvor fehlgeschlagenen Datei(en)transfers.
     *
     * @param {Function} callback
     *
     * @returns {Uploader}
     */
    onFilesRetried(callback) {
        this.onFilesRetriedCallback = callback;

        return this;
    }

    /**
     * Merkt sich die aufzurufende Callback-Funktion beim Ende eines Datei(en)transfers.
     *
     * @param {Function} callback
     *
     * @returns {Uploader}
     */
    onFinished(callback) {
        this.onFinishedCallback = callback;

        return this;
    }

    /**
     * Merkt sich die aufzurufende Callback-Funktion nach Ende eines Datei(en)transfers
     * nach erneutem Versuch eines zuvor fehlgeschlagenen Datei(en)transfers
     *
     * @param {Function} callback
     *
     * @returns {Uploader}
     */
    onRetryFinished(callback) {
        this.onRetryFinishedCallback = callback;

        return this;
    }

    /**
     *
     * Merkt sich die aufzurufende Callback-Funktion vor Beginn eines Datei(en)transfers
     * bei erneutem Versuch eines zuvor fehlgeschlagenen Datei(en)transfers.
     *
     * @param {Function} callback
     *
     * @returns {Uploader}]
     */
    onRetryStart(callback) {
        this.onRetryStartCallback = callback;

        return this;
    }

    /**
     * Merkt sich die aufzurufende Callback-Funktion beim Abbruch eines Datei(en)transfers.
     *
     * @param {Function} callback
     *
     * @returns {Uploader}
     */
    onCancel(callback) {
        this.onCancelCallback = callback;

        return this;
    }
}

export default Uploader;
