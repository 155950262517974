import Scroller from "../../index";

const onMouseMove = {
    /**
     * Registriert das {@linkcode MouseEvent} bei Bewegung des Mauszeigers.
     *
     * @this Scroller
     */
    attach: function() {
        if (this.isIFrame)
            return;

        document.addEventListener("mousemove", this.registeredHandler.thumbMove);
    },

    /**
     * Entfernt das {@linkcode MouseEvent} bei Bewegung des Mauszeigers.
     *
     * @this Scroller
     */
    detach: function() {
        if (this.isIFrame)
            return;

        document.removeEventListener("mousemove", this.registeredHandler.thumbMove);
    }
};

export default onMouseMove;
