import _ from "lodash";
import Logger from "@/utils/Logger";

const debug = false;

/**
 * Konvertiert ein Datum in einen vergleichsfähigen String.
 *
 * @param {Date} date Das Datumsobjekt .
 *
 * @returns {String} Ein vergleichsfähiger String.
 */
const convertDateToComparable = date => {
    date = new Date(date);

    let year = date.getFullYear();
    let month = `0${date.getMonth() + 1}`.slice(-2);
    let day = `0${date.getDate()}`.slice(-2);

    return `${year}${month}${day}`;
};

const conditionMappings = {
    equals(a, b, field) {
        if ([null, undefined].includes(a) && typeof b === "string")
            a = "";

        if (_.isArray(a)) {
            let left = a.length;
            let right = ![null, undefined].includes(b) ? parseInt(b, 10) : 0;
            let result = left === right;

            if (debug) Logger.log(`Bedingung Equals (${field}): ${left} === ${right} -> ${result}`);

            return result;
        } else if (_.isString(a)) {
            let left = a.toLowerCase();
            let right = String(b).toLowerCase();
            let result = left === right;

            if (debug) Logger.log(`Bedingung Equals (${field}): ${left} === ${right} -> ${result}`);

            return result;
        } else if (_.isBoolean(a)) {
            let left = a;
            let right = String(b).toLowerCase() === "true";
            let result = left === right;

            if (debug) Logger.log(`Bedingung Equals (${field}): ${left} === ${right} -> ${result}`);

            return result;
        }

        let left = a;
        let right = b;
        let result = String(left) === String(right);

        if (debug) Logger.log(`Bedingung Equals (${field}): ${left} === ${right} -> ${result}`);

        return result;
    },

    notEquals(a, b, field) {
        if ([null, undefined].includes(a) && typeof b === "string")
            a = "";

        if (_.isArray(a)) {
            let left = a.length;
            let right = ![null, undefined].includes(b) ? parseInt(b, 10) : 0;
            let result = left !== right;

            if (debug) Logger.log(`Bedingung NotEquals (${field}): ${left} !== ${right} -> ${result}`);

            return result;
        } else if (_.isString(a)) {
            let left = a.toLowerCase();
            let right = String(b).toLowerCase();
            let result = left !== right;

            if (debug) Logger.log(`Bedingung NotEquals (${field}): ${left} !== ${right} -> ${result}`);

            return result;
        } else if (_.isBoolean(a)) {
            let left = a;
            let right = String(b).toLowerCase() === "true";
            let result = left !== right;

            if (debug) Logger.log(`Bedingung NotEquals (${field}): ${left} !== ${right} -> ${result}`);

            return result;
        }

        let left = String(a);
        let right = String(b);
        let result = left !== right;

        if (debug) Logger.log(`Bedingung NotEquals (${field}): ${left} !== ${right} -> ${result}`);

        return result;
    },

    fieldNotEquals(a, b, field) {
        if ([null, undefined].includes(a) && typeof b === "string")
            a = "";

        const rxDateLike = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}/;

        if (a instanceof Date || b instanceof Date || (a && a.match(rxDateLike)) || (b && b.match(rxDateLike))) {
            a = convertDateToComparable(a);
            b = convertDateToComparable(b);
        }

        if (_.isArray(a)) {
            let left = a.length;
            let right = ![null, undefined].includes(b) ? parseInt(b, 10) : 0;
            let result = left !== right;

            if (debug) Logger.log(`Bedingung FieldNotEquals (${field}): ${left} !== ${right} -> ${result}`);

            return result;
        } else if (_.isString(a)) {
            let left = a.toLowerCase();
            let right = String(b).toLowerCase();
            let result = left !== right;

            if (debug) Logger.log(`Bedingung FieldNotEquals (${field}): ${left} !== ${right} -> ${result}`);

            return result;
        } else if (_.isBoolean(a)) {
            let left = a;
            let right = String(b).toLowerCase() === "true";
            let result = left !== right;

            if (debug) Logger.log(`Bedingung FieldNotEquals (${field}): ${left} !== ${right} -> ${result}`);

            return result;
        }

        let left = String(a);
        let right = String(b);
        let result = left !== right;

        if (debug) Logger.log(`Bedingung FieldNotEquals (${field}): ${left} !== ${right} -> ${result}`);

        return result;
    },

    lessThan(a, b, field) {
        if ([null, undefined].includes(a) && typeof b === "string")
            a = "";

        if (_.isArray(a)) {
            let left = a.length;
            let right = ![null, undefined].includes(b) ? parseInt(b, 10) : 0;
            let result = left < right;

            if (debug) Logger.log(`Bedingung LessThan (${field}) ${left} < ${right} -> ${result}`);

            return result;
        } else if (_.isString(a)) {
            let left = a.toLowerCase();
            let right = String(b).toLowerCase();
            let result = left < right;

            if (debug) Logger.log(`Bedingung LessThan (${field}): ${left} < ${right} -> ${result}`);

            return result;
        }

        let left = a;
        let right = b;
        let result = left < right;

        if (debug) Logger.log(`Bedingung LessThan (${field}): ${left} < ${right} -> ${result}`);

        return result;
    },

    lessEquals(a, b, field) {
        if ([null, undefined].includes(a) && typeof b === "string")
            a = "";

        if (_.isArray(a)) {
            let left = a.length;
            let right = ![null, undefined].includes(b) ? parseInt(b, 10) : 0;
            let result = left <= right;

            if (debug) Logger.log(`Bedingung LessEquals (${field}): ${left} <= ${right} -> ${result}`);

            return result;
        } else if (_.isString(a)) {
            let left = a.toLowerCase();
            let right = String(b).toLowerCase();
            let result = left <= right;

            if (debug) Logger.log(`Bedingung LessEquals (${field}): ${left} <= ${right} -> ${result}`);

            return result;
        }

        let left = a;
        let right = b;
        let result = left <= right;

        if (debug) Logger.log(`Bedingung LessEquals (${field}): ${left} <= ${right} -> ${result}`);

        return result;
    },

    greaterThan(a, b, field) {
        if ([null, undefined].includes(a) && typeof b === "string")
            a = "";

        if (_.isArray(a)) {
            let left = a.length;
            let right = ![null, undefined].includes(b) ? parseInt(b, 10) : 0;
            let result = left > right;

            if (debug) Logger.log(`Bedingung GreaterThan (${field}): ${left} > ${right} -> ${result}`);

            return result;
        } else if (_.isString(a)) {
            let left = a.toLowerCase();
            let right = String(b).toLowerCase();
            let result = left > right;

            if (debug) Logger.log(`Bedingung GreaterThan (${field}): ${left} > ${right} -> ${result}`);

            return result;
        }

        let left = a;
        let right = b;
        let result = left > right;

        if (debug) Logger.log(`Bedingung GreaterThan (${field}): ${left} > ${right} -> ${result}`);

        return result;
    },

    greaterEquals(a, b, field) {
        if ([null, undefined].includes(a) && typeof b === "string")
            a = "";

        if (_.isArray(a)) {
            let left = a.length;
            let right = ![null, undefined].includes(b) ? parseInt(b, 10) : 0;
            let result = left >= right;

            if (debug) Logger.log(`Bedingung GreaterEquals (${field}): ${left} >= ${right} -> ${result}`);

            return result;
        } else if (_.isString(a)) {
            let left = a.toLowerCase();
            let right = b.toLowerCase();
            let result = left >= right;

            if (debug) Logger.log(`Bedingung GreaterEquals (${field}): ${left} >= ${right} -> ${result}`);

            return result;
        }

        let left = a;
        let right = b;
        let result = left >= right;

        if (debug) Logger.log(`Bedingung GreaterEquals (${field}): ${left} >= ${right} -> ${result}`);

        return result;
    },

    empty(a, b, field) {
        let result = _.isEmpty(a);

        if (debug) Logger.log(`Bedingung Empty (${field}): ${a} -> ${result}`);

        return result;
    },

    default(a, b, field) {
        if (debug) Logger.log(`Bedingung Default (${field}): default -> true`);

        return true;
    },

    response(a, b, field) {
        let left = a;
        let right = (b || []);
        let result = right.includes(left);

        if (debug) Logger.log(`Bedingung Response (${field}): ${right} beinhaltet ${left}`);

        return result;
    }
};

export { conditionMappings };

/**
 * Prüft, ob die Bedingungen eines Ereignisses erfüllt sind.
 *
 * @param {Array} conditions Die Bedingungen eines Ereignisses.
 * @param {Object} control Das betroffene Steuerelement.
 * @param {Boolean} debug Ausgaben in der Konsole anzeigen.
 *
 * @returns {Boolean} Der Wert, ob die Bedingung erfüllt ist.
 */
export const conditionsFullfilled = function(conditions, control = null) {
    let _this = this;
    let isFullfilled = true;

    if (conditions.length > 0) {
        conditions.forEach((condition, conditionIndex) => {
            let field = _.get(condition, "field", null);
            let comparisonValue = 1;
            let currentValue = 0;

            if (field === "control.data") {
                let affectedItem = _(_.get(control, "items", [])).filter(item => _.get(item, "data", undefined) === condition.value).value();

                if (affectedItem.length > 0) {
                    comparisonValue = _(_.get(control, "items", [])).filter(item => _.get(item, "data", undefined) === condition.value).first().value;
                    currentValue = _.get(condition, "currentValue", _this.getEntityByMember(_.get(control, "member", _.get(control, "id", null))));
                }
            } else {
                let keys = Object.keys(condition);

                if (keys.includes("value"))
                    comparisonValue = condition.value;
                else if(keys.includes("compareField"))
                    comparisonValue = _this.getEntityByMember(condition.compareField);

                currentValue = _.get(condition, "currentValue", typeof _this.getEntityByMember === "function" ? _this.getEntityByMember(field) : null);
            }

            let methodName = _.get(condition, "$type", "default");

            let comparisonMethod = _.get(conditionMappings, methodName, conditionMappings.default);
            let operator = conditionIndex > 0 ? _.get(condition, "operator", _this.defaultOperator) : null;
            let conditionFullfilled = comparisonMethod.call(_this, currentValue, comparisonValue, field);

            isFullfilled = conditionIndex === 0 ? conditionFullfilled : (operator === "or" ? isFullfilled || conditionFullfilled : isFullfilled && conditionFullfilled);
        });
    }

    return isFullfilled;
};
