import Scroller from "../index";

/**
 * Ein Typ zur Beschreibung der Parameter für die Methode {@linkcode attachNodes}
 */
type AttachNodesParams = {
    /**
     * Das {@link HTMLElement}, das ummantelt werden soll
     */
    element: HTMLElement

    /**
     * Die CSS-Klassen, die dem {@link HTMLElement} hinzugefügt werden sollen
     */
    cssClasses?: string

    /**
     * Die Breite des {@link HTMLElement}
     */
    width?: string | number

    /**
     * Die Höhe des {@link HTMLElement}
     */
    height?: string | number

    /**
     * Der linke Abstand des {@link HTMLElement}
     */
    left?: string

    /**
     * Der obere Abstand des {@link HTMLElement}
     */
    top?: string
}

/**
 * Ein Typ zur Beschreibung des Rückgabewertes der Methode {@linkcode attachNodes}
 */
type AttachNodesReturn = {
    /*
     * Das ummantelnde {@link HTMLElement}
     */
    scrollContainer: HTMLElement

    /**
     * Das {@link HTMLElement}, das ummantelt wurde
     */
    scrollArea: HTMLElement

    /**
     * Das Container-Element für die Skrollbalken
     */
    barContainer?: HTMLElement

    /**
     * Das horizontale Skrollbalken-Element
     */
    barHoz?: HTMLElement

    /**
     * Das vertikale Skrollbalken-Element
     */
    barVert?: HTMLElement

    /**
     * Das horizontale Scrollthumb-Element
     */
    thumbHoz?: HTMLElement

    /**
     * Das vertikale Scrollthumb-Element
     */
    thumbVert?: HTMLElement
}

/**
 * Private Methode der Klasse {@linkcode Scroller}
 *
 * Ummantelt das scrollbare {@linkcode HTMLElement} mit den erforderlichen Skrollbarelementen {@linkcode HTMLElement}
 *
 * @param params Die Parameter für die Methode
 * @param params.element Das {@linkcode HTMLElement}, das ummantelt werden soll
 * @param params.cssClasses Die CSS-Klassen, die dem {@linkcode HTMLElement} hinzugefügt werden sollen
 * @param params.width Die Breite des {@linkcode HTMLElement}
 * @param params.height Die Höhe des {@linkcode HTMLElement}
 * @param params.left Der linke Abstand des {@linkcode HTMLElement}
 * @param params.top Der obere Abstand des {@linkcode HTMLElement}
 *
 * @returns Die ummantelnden {@linkcode HTMLElement}e
 */
function attachNodes(this: Scroller, {element, cssClasses = "", width = "100%", height = "100%", left = "", top = ""}: AttachNodesParams): AttachNodesReturn | undefined {
    if (!element.parentNode)
        return;

    const scrollContainer = document.createElement("div") as HTMLDivElement;
    scrollContainer.classList.add("scrollcontainer", "scrollcontainer--no-hoz", "scrollcontainer--no-vert");

    if (cssClasses.length)
        scrollContainer.className += ` ${cssClasses}`;

    scrollContainer.style.height = typeof height === "number" ? `${height}px` : height;
    scrollContainer.style.width = typeof width === "number" ? `${width}px` : width;

    if (top.length)
        scrollContainer.style.top = top;

    if (left.length)
        scrollContainer.style.left = left;

    const scrollArea = document.createElement("div");
    scrollArea.style.width = "100%";
    scrollArea.style.height = "100%";
    scrollArea.style.position = "relative";
    scrollArea.className = "scrollarea";

    scrollContainer.appendChild(scrollArea);

    element.parentNode.insertBefore(scrollContainer, element);
    scrollArea.appendChild(element);

    if (this.isIFrame)
        return {
            scrollContainer,
            scrollArea
        };

    const barContainer = document.createElement("div");
    barContainer.className = "scrollbars";

    const barHoz = document.createElement("div");
    barHoz.className = "scrollhoz";

    const barVert = document.createElement("div");
    barVert.className = "scrollvert";

    const thumbHoz = document.createElement("span");
    thumbHoz.className = "scrollthumb";

    const thumbVert = document.createElement("span");
    thumbVert.className = "scrollthumb";

    barHoz.appendChild(thumbHoz);
    barVert.appendChild(thumbVert);
    barContainer.appendChild(barHoz);
    barContainer.appendChild(barVert);
    scrollContainer.appendChild(barContainer);

    return {
        scrollContainer,
        scrollArea,
        barContainer,
        barHoz,
        barVert,
        thumbHoz,
        thumbVert
    };
};

export default attachNodes;
