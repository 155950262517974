import Scroller from "../../index";

const onBottom = {
    /**
     * Registriert das Scroll-Ereignis bei erreichen des Endes des sichtbaren Bereichs
     * der vertikalen Achse
     *
     * @this Scroller
     */
    attach: function() {
        if (!this.isIFrame)
            return this.element.addEventListener("scroll", this.registeredHandler.onBottom.handler);

        this.element.addEventListener("load", this.registeredHandler.iframeBottomReady);
    },

    /**
     * Entfernt das Scroll-Ereignis bei erreichen des Endes des sichtbaren Bereichs
     * der vertikalen Achse
     *
     * @this Scroller
     */
    detach: function() {
        if (!this.element)
            return;

        if (!this.isIFrame)
            return this.element.removeEventListener("scroll", this.registeredHandler.onBottom.handler);

        this.element.removeEventListener("load", this.registeredHandler.iframeBottomReady);
        this.element.contentDocument.removeEventListener("scroll", this.registeredHandler.onBottom.handler);
    }
};

export default onBottom;
