import Drive from "../api";
import DriveFileSystemObject from "./DriveFileSystemObject";
import DriveUser from "./DriveUser.js";

/**
 * Stellt ein Dateiobjekt zur Verfügung.
 */
class DriveFile extends DriveFileSystemObject {
    /**
     * Eindeutige Kennung des Typs des Dateisystemobjekts.
     */
    readonly type = "file";

    /**
     * Gibt an, ob die Datei durch einen Kunden hochgeladen wurde.
     */
    isUpload = false;

    /**
     * Informationenen zur Art des Dateiinhalts.
     */
    contentType = {
        category: "generic",
        displayName: "Binärdatei",
        mimeType: "application/octet-stream"
    };

    /**
     * Gibt die Dateigröße in Bytes an.
     */
    size = 0;

    /**
     * Die Tags der Datei.
     */
    tags:IDriveTag[] = [];

    /**
     * Der Uploader der Datei
     */
    uploader:DriveUser = new DriveUser();

    /**
     * Gibt an, ob die Datei durch einen Berater schon zur Kenntnis genommen wurde.
     */
    unseen = false;

    /**
     * C'tor
     *
     * @param options - Die Konfiguration.
     * @param options.shareable - Gibt an, ob die Datei geteilt werden kann.
     * @param options.contactId - Die Kontakt-ID.
     * @param options.overlay - Ein optionales Overlay-Symbol für einen Ordner.
     * @param options.description - Die Beschreibung.
     * @param options.access - Der Zugriffstyp.
     * @param options.shareType - Der Share-Typ.
     * @param options.contentType - Die Art des Dateiinhalts.
     * @param options.created - Das Erstelldatum.
     * @param options.deleted - Das Löschdatum.
     * @param options.id - Die ID.
     * @param options.isDirty - Gibt an, ob das Objekt verändert wurde.
     * @param options.isNew - Gibt an, ob das Objekt neu ist.
     * @param options.name - Der Name.
     * @param options.path - Der Pfad.
     * @param options.pending - Gibt an, ob das sich die Datei im Ladezustand befindet.
     * @param options.size - Die Dateigröße.
     * @param options.tags - Die Tags.
     * @param options.uploader - Der Uploader.
     * @param options.virtual - Gibt an, ob es sich um eine virtuelle Datei handelt.
     *
     * @param baseUrl - Die Basis-URL.
     */
    constructor({
        access = null,
        contactId,
        contentType = null,
        created = Date.now(),
        deleted = Date.now(),
        description,
        overlay,
        id = null,
        isDirty = false,
        isNew = false,
        name = null,
        path = null,
        pending = false,
        shareable = false,
        shareType = "none",
        size = 0,
        tags = [],
        unseen = false,
        uploader = null,
        virtual = false
    }:IDriveFileConstructorParameters,
        baseUrl:string|null = null
    ) {
        super({shareable, contactId, overlay, access, description, created, id, isDirty, isNew, deleted, name, path, pending, shareType, type: "file", virtual}, baseUrl);

        this.contentType = contentType || this.contentType;
        this.size = size;
        this.tags = tags;
        this.unseen = unseen;
        this.uploader = uploader || new DriveUser();
    }

    /**
     * Liefert den Pfad zur Thumbnail zur Datei.
     *
     * @returns Der Pfad zur Thumbnail zur Datei.
     */
    getThumb() {
        const isRecycleBin = Drive.Path?.isRecycleBin;
        const isUserActivities = Drive.Path?.isUserActivities;

        let path = this.path;

        if (isRecycleBin)
            path = `${Drive.Path?.RECYCLE_PATH}/${this.id}`;
        else if (isUserActivities)
            path = `${Drive.Path?.USER_ACTIVITIES_PATH}/${this.id}`;
        else
            path = this.path;

        path = `files/${path}`.replace(/\/{2,}/g, "/");

        return `${path}?preview=Thumb`;
    }
}

export default DriveFile;
