import type FilterModel from "../Filter/FilterModel";
import ActionHandler from "./ActionHandler";
import Constant from "../Constant";
import ConditionVerifier from "./ConditionVerifier";
import Logger from "@/utils/Logger";
import MissingPlugin from "./Errors/MissingPlugin";

/**
 * Die Namen der laufenden Ereignisse.
 */
let runningEvents: TEventName[] = [];

/**
 * Die Ereignisse, die in der Warteschlange stehen.
 */
let queuedEvents: Function[] = [];

/**
 * Stellt einen Ereignisbehandler dar.
 */
class EventHandler {
    /**
     * Ein Filterobjekt, das die Filter für die Aktionen bereitstellt.
     */
    private readonly Filters?: FilterModel[];

    /**
     * Die vom Benuzter zur Verfügung gestellten Aktionen
     */
    private readonly Hooks?: Partial<TEventHandlerHooks>;

    /**
     * Die Plugins, die der Ereignisbehandler verwenden kann.
     */
    private readonly Plugins?: TPlugins;

    /**
     * Der Auslöser des Ereignisses.
     */
    private readonly Trigger: TControl;

    /**
     * Eine Funktion, die einen Mitgliedsnamen als Parameter erhält und einen unbekannten Wert zur Prüfung der Bedingungen zurückgibt.
     */
    private readonly ConditionValueResolver: TConditionValueResolver;

    /**
     * Der Initialwert, der an die erste Aktion übergeben wird.
     */
    private InitialValue: KeyValuePair<unknown> = {};

    /**
     * Die Komponente, die den Ereignisbehandler verwendet.
     */
    private Component?: Record<string, any>;

    /**
     * Der gemeinsame Store für alle Aktionen.
     */
    private Store: KeyValuePair<unknown> = {};

    /**
     * Gibt an, ob der Ereignisbehandler die Ereignisse protokollieren soll.
     */
    public Log: boolean = false;

    /**
     * Erstellt eine neue Instanz der EventHandler Klasse.
     *
     * @param component - Die Komponente, die den Ereignisbehandler verwendet.
     * @param initialValue - Der Initialwert, der an die erste Aktion übergeben wird.
     * @param filters - Ein Filterobjekt, das die Filter für die Aktionen bereitstellt.
     * @param plugins - Die Plugins, die der Ereignisbehandler verwenden kann.
     * @param trigger - Der Auslöser des Ereignisses.
     * @param store - Der gemeinsame Store für alle Aktionen.
     * @param conditionValueResolver - Eine Funktion, die einen Mitgliedsnamen als Parameter erhält und einen unbekannten Wert zur Prüfung der Bedingungen zurückgibt.
     * @param hooks - Die vom Benuzter zur Verfügung gestellten Aktionen
     * @param log - Gibt an, ob der Ereignisbehandler die Ereignisse protokollieren soll.
     */
    constructor({ plugins, trigger, hooks, component, initialValue = {}, filters, store = {}, log = false, conditionValueResolver }: TEventHandlerConfig) {
        this.Component = component;
        this.Filters = filters;
        this.Hooks = hooks;
        this.Log = log;
        this.InitialValue = initialValue;
        this.Plugins = plugins;
        this.Store = store;
        this.Trigger = trigger;

        this.ConditionValueResolver = conditionValueResolver;
    }

    /**
     * Zeigt eine Bestätigungsmeldung an und gibt eine Promise zurück, die das Ergebnis der Bestätigung enthält.
     *
     * @param message Die Nachricht, die in der Bestätigungsmeldung angezeigt werden soll.
     *
     * @returns Eine Promise, die true zurückgibt, wenn die Bestätigung angenommen wurde, oder false, wenn sie abgelehnt wurde.
     *
     * @throws {MissingPlugin} Wenn das Plugin '$modal' fehlt, das benötigt wird, um die Bestätigung anzuzeigen.
     */
    private async confirm(message: string): Promise<boolean> {
        if (!this.Plugins?.$modal)
            throw new MissingPlugin("Das Plugin '$modal' fehlt. Es wird benötigt, um die Bestätigung anzuzeigen.");

        return new Promise<boolean>((resolve) => {
            this.Plugins?.$modal!.show("dialog", {
                title: "Bestätigung",
                message,

                buttons: {
                    apply: {
                        caption: Constant.Button.OK.Caption,
                        accesskey: Constant.Button.OK.Accesskey,
                        hint: Constant.Button.OK.Hint,

                        callback: () => resolve(true)
                    },

                    cancel: {
                        caption: Constant.Button.CANCEL.Caption,
                        accesskey: Constant.Button.CANCEL.Accesskey,
                        hint: Constant.Button.CANCEL.Hint,

                        callback: () => resolve(false)
                    }
                }
            });
        });
    }

    /**
     * Gibt alle Ereignisse vom Typ {@linkcode TEventName} zurück.
     *
     * @param eventName - Der Name des Ereignisses.
     * @param events - Die Ereignisse, die ausgeführt werden sollen.
     *
     * @returns Die Ereignisse, die durch den Auslöser ausgelöst werden.
     */
    private eventsByEventName({ eventName, events }: { eventName: TEventName, events: TEventData[] }): TEventData[] {
        return events.filter((event) => event.trigger === eventName || event.type === eventName);
    }

    /**
     * Überprüft die Bedingungen {@linkcode TCondition} und gibt das Ergebnis zurück.
     *
     * @param conditions - Die Bedingungen, die überprüft werden sollen.
     *
     * @returns Ein Promise, das ein boolesches Ergebnis enthält.
     */
    private async verifyConditions(conditions?: TCondition[]): Promise<boolean> {
        let isFulfilled = true;

        this.Log && Logger.info("");
        this.Log && Logger.info("Suche nach Bedingungen ...");

        if (!conditions || conditions.length === 0) {
            this.Log && Logger.info(`Keine Bedingungen gefunden. Kehre zurück!`);

            return true;
        }

        this.Log && Logger.table(`${conditions.length} Bedingung${conditions.length === 1 ? '' : 'en'} gefunden.`, conditions!);

        for (let index = 0; index < conditions.length; index++) {
            const condition = conditions[index];

            const verifier = new ConditionVerifier({
                log: this.Log,
                condition
            });

            const verified = await verifier.verifyEventCondition({
                trigger: this.Trigger,
                valueResolver: this.ConditionValueResolver,
            });

            const operator = index > 0 ? condition?.operator ?? "or" : null;

            isFulfilled = index === 0 ? verified : (operator === "or" ? isFulfilled || verified : isFulfilled && verified);
        }

        this.Log && Logger.info(`Ergebnis aller Bedingungen: ${isFulfilled}`);

        return isFulfilled;
    }

    /**
     * Führt die Aktionen {@linkcode TAction} des Auslösers {@linkcode TEventName} aus,
     * wenn die Bedingungen {@linkcode TCondition} erfüllt sind.
     *
     * @param trigger - Der Auslöser des Ereignisses.
     * @param events - Die Ereignisse, die ausgeführt werden sollen.
     * @param confirmation - Die Bestätigung, die der Benutzer geben muss, um die Ereignisse auszuführen.
     * @param verifyAll - Gibt an, ob alle Bedingungen aller Eregnisse des Typs {@link TEventName} erfüllt sein müssen, um die Aktionen auszuführen.
     */
    public async handle({ eventName, events, confirmation, verifyAll = false, initial = false }: { eventName: TEventName, events: TEventData[], confirmation?: string, verifyAll?: boolean, initial?: boolean }) {
        if (confirmation && !(await this.confirm(confirmation)))
            return;

        this.Log && Logger.info("");
        this.Log && Logger.info("");
        this.Log && Logger.table("Beginne mit der Ausführung der Ereignisse des Auslösers:", this.Trigger, "background-color: #5a189a; color: #FFFFFF");

        if (this.Hooks?.beforeActions) {
            this.Log && Logger.info("");
            this.Log && Logger.info("Führe 'beforeActions' aus...");

            await this.Hooks.beforeActions();
        }

        this.Log && Logger.info("");
        this.Log && Logger.table(`Suche nach Ereignis > ${eventName} <`, events);

        const eventsByEventName = !initial
            ? this.eventsByEventName({ eventName, events })
            : this.eventsByEventName({ eventName, events }).filter(event => event.onInit);

        this.Log && Logger.info(`${eventsByEventName.length} Ereignis${eventsByEventName.length === 1 ? '' : 'se'} gefunden.`);

        if (!eventsByEventName.length)
            return;

        if (eventName === "onLoad" && runningEvents.some(e => e === "onUnload")) {
            queuedEvents.push(this.handle.bind(this, { eventName, events, confirmation }));

            return this.Log && Logger.info(`Das Ereignis 'onLoad' wird in die Warteschlange verschoben, da das Ereignis 'onUnload' ausgeführt wird.`);
        }

        if (!runningEvents.some(e => e === eventName))
            runningEvents.push(eventName);

        let result = this.InitialValue;

        let validConditions: boolean | boolean[] = false;

        if (verifyAll)
            validConditions = await Promise.all(eventsByEventName.map(async (event) => await this.verifyConditions(event.conditions)));

        for (const [idx, event] of eventsByEventName.entries()) {
            if (!verifyAll)
                validConditions = await this.verifyConditions(event.conditions);
            else if (validConditions instanceof Array && !validConditions.at(idx))
                continue;

            if (!validConditions)
                continue;

            this.InitialValue = result;

            if (this.Log) {
                Logger.info("");

                event.actions.length > 0 && Logger.table(`Auszuführende Aktion${event.actions.length === 1 ? '' : 'en'}`, event.actions);
                event.actions.length === 0 && Logger.info(`Keine auszuführenden Aktionen gefunden, dessen Bedingungen erfüllt sind!`);
            }

            try {
                const actionHandler = new ActionHandler({
                    eventName,
                    actions: event.actions,
                    component: this.Component,
                    filters: this.Filters,
                    hooks: this.Hooks?.actions as TActionHooks,
                    initialValue: this.InitialValue,
                    log: this.Log,
                    mergeResults: event.mergeResults ?? false,
                    plugins: this.Plugins,
                    store: this.Store,
                    trigger: this.Trigger,

                    conditionValueResolver: this.ConditionValueResolver
                });

                result = await actionHandler.handle(result);
            } catch (error) {
                throw error;
            } finally {
                if (runningEvents.some(e => e === eventName))
                    runningEvents.splice(runningEvents.findIndex(e => e === eventName), 1);
            }
        }

        this.Log && Logger.info("");
        this.Log && Logger.info("");
        this.Log && Logger.info("Ende der Ausführung der Ereignisse!", "background-color: #5a189a; color: #FFFFFF");
        this.Log && Logger.table("Gebe folgendes Ergebnis zurück:", result, "background-color: #5a189a; color: #FFFFFF");

        if (queuedEvents.length === 0)
            return result;

        const queuedEvent = queuedEvents.shift()!;

        this.Log && Logger.info("Das in Warteschlange nächst befindliche Ereignis wird ausgeführt.");

        return queuedEvent();
    }
}

export default EventHandler;
