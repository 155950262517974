// eslint-disable-next-line
import UploadFile from "./UploadFile";

/**
 * Ein Fehlerdatei-Model.
 */
class FailedFile {
    /**
     * Das Dateisystemobjekt.
     *
     * @type {UploadFile}
     */
    uploadFile;

    /**
     * Der Typ des Fehlers.
     *
     * @type {"Conflict"|"Error"|"Boundings"}
     */
    problem;

    /**
     * Der Zustand des erneuten Versuchs des Dateitransfers.
     *
     * @type {Boolean}
     */
    retrying;

    /**
     * Die Beschreibung des Fehlers.
     *
     * @type {String}
     */
    detail;

    /**
     * C'Tor
     *
     * @param {{detail: String, uploadFile: UploadFile, problem: "Conflict"|"Error"|"Boundings", retrying: Boolean}} options
     */
    constructor({detail, uploadFile = null, problem = "Error", retrying = false}) {
        this.detail = detail;
        this.uploadFile = uploadFile;
        this.problem = problem;
        this.retrying = retrying;
    }
}

export default FailedFile;
