import { nextTick } from "vue";
import _ from "lodash";
import FilterModel from "@/utils/Filter/FilterModel";

export default {
    /**
     * Aktualisiert die Toolbar.
     */
    async refresh() {
        return new Promise(resolve => {
            this.toolbar.disabled = false;

            this.toolbar.left = _(JSON.parse(JSON.stringify(_.get(this.plan, "diagrams", []))))
                .map((diagram) => {
                    return {
                        $type: "button",
                        command: diagram.$type || diagram.type,
                        hidden: false,
                        hint: diagram.title,
                        icon: diagram.icon,
                        sourceType: _.get(diagram, "sourceType", "rows"),
                        label: null,
                        url: diagram.url
                    };
                }).value();

            let controls = this.plan.toolbar || [];

            /**
             * @type {IButtonControl}
             */
            const btnRefresh = {
                $type: "button",
                hint: "Aktualisieren",
                icon: "refresh",
                mainButton: true,
                setActive: false,
                events: [{
                    type: "onClick",
                    actions: [{
                        $type: "refreshView"
                    }]
                }]
            };

            /**
             * @type {IButtonControl}
             */
            const btnExport = {
                $type: "button",
                hint: "Exportieren",
                icon: "downloadwhite1",
                mainButton: true,
                setActive: false,
                events: [{
                    type: "onClick",
                    actions: [{
                        $type: "export"
                    }]
                }]
            };

            controls.push(...[btnRefresh, btnExport]);

            /**
             * @type {FilterModel[]}
             */
            const filters = this.cache.id(this.$page.top.id()).get("filters") || [];

            controls
                .filter(({member, type}) => filters.find(({name}) => name === member) && type === "SelectFilter")
                .forEach(control => {
                    const selected = filters.find(({name}) => name === control.member).value;

                    if (!control.items.map(({value}) => value).includes(selected))
                        return;

                    control.selected = selected;
                });

            this.toolbar.right = controls;

            nextTick(resolve);
        });
    }
};
