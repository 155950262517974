import Drive from "@/apis/drive/api";

/**
 * Stellt ein Freigabeobjekt einer Datei zur Verfügung.
 */
class DriveFileRelease {
    /**
     * Die möglichen Zugriffsrechte einer Dateifreigabe.
     */
    static AccessLevel = { Read: "read", ReadWrite: "readWrite", ReadWriteDelete: "readWriteDelete" };

    /**
     * Der optionale Kontoname des Benutzers, mit dem die Datei geteilt wird oder null, um die Datei mit allen Benutzern zu teilen.
     */
    contactId:string|null = null;

    /**
     * Zugriffsrechte einer Dateifreigabe.
     */
    access: "Read"|"ReadWrite"|"ReadWriteDelete"|null;

    /**
     * Der übergeordnete Ordner der vererbten Freigabe falls vorhanden.
     */
    inheritedBy:string|null = null;

    /**
     * C'tor.
     *
     * @param options - Die Optionen.
     * @param options.contactId - Die Kontakt-ID.
     * @param options.access - Der Zugriffstyp.
     * @param options.inheritedBy - Der übergeordnete Ordner der vererbten Freigabe falls vorhanden.
     */
    constructor({contactId = null, access = <IAccessType>DriveFileRelease.AccessLevel.ReadWriteDelete, inheritedBy = null}:{contactId:string|null, access:IAccessType|null, inheritedBy:string|null}) {
        this.contactId = contactId;
        this.access = access;

        const uploadPath = Drive.Path?.USER_UPLOAD_PATH.replace("/", "");
        const generalDocsPath = Drive.Path?.GENERAL_DOCUMENTS_PATH.replace("/", "");

        const rxGeneralGuest = `^(${uploadPath}/([a-fA-F0-9\-]+)/${generalDocsPath})`.replace(/\//g, "\\/").replace(/\./g, "\\.");
        const matchGeneralAsGuest = inheritedBy
            ? inheritedBy.match(new RegExp(rxGeneralGuest))
            : null;

        if (matchGeneralAsGuest && matchGeneralAsGuest.length) {
            this.inheritedBy = inheritedBy!.replace(matchGeneralAsGuest[0], Drive.Path?.GENERAL_DOCUMENTS_PATH_NAME ?? "");

            return;
        }

        const rxGeneralAdvisor = `^(${generalDocsPath}\\/)`.replace(/\./g, "\\.");
        const matchGeneralAsAdvisor = inheritedBy
            ? inheritedBy.match(new RegExp(rxGeneralAdvisor))
            : null;

        if (matchGeneralAsAdvisor && matchGeneralAsAdvisor.length) {
            this.inheritedBy = inheritedBy!.replace(matchGeneralAsAdvisor[0], `${Drive.Path?.GENERAL_DOCUMENTS_PATH_NAME ?? ""}/`);

            return ;
        }

        const rxUploadAdvisor = `(^${uploadPath}\\/[a-fA-F0-9\\-]+\\/)`.replace(/\./g, "\\.");
        const matchUploadAdvisor = inheritedBy
            ? inheritedBy.match(new RegExp(rxUploadAdvisor))
            : null;

        if (matchUploadAdvisor && matchUploadAdvisor.length) {
            this.inheritedBy = `${inheritedBy!.replace(matchUploadAdvisor[0], "")}/`;

            return;
        }

        this.inheritedBy = inheritedBy;
    }
}

export default DriveFileRelease;
