import tinycolor from "tinycolor2";
import filter from "lodash/filter";
import get from "lodash/get";
import ColorGenerator from "../ColorGenerator";
import BaseChart from "./BaseChart";
import FilterUtils from "@/utils/Filter/index";
import FilterModel from "@/utils/Filter/FilterModel";
import { toInteger } from "lodash";

/**
 * Eine Klase zur Generierung der Highcharts-Optionen einer Bar-Chart.
 */
export default class PieChart extends BaseChart {
    /**
     * C'Tor
     *
     * @param {TChartConstructorParameters} caller
     */
    constructor(caller: TChartConstructorParameters) {
        super(caller);
    }

    /**
     * Setzt die Highcharts-Option zur Darstellung des Tooltips eines Werts im Diagramm.
     */
    setTooltip() {
        const _this = this;

        this.tooltip = {
            useHTML: true,
            headerFormat: "{point.key}<br>",
            backgroundColor: "rgba(255, 255, 255, 1)",
            pointFormatter: function () {
                const decimals = this.value.template.toLowerCase() === "percent" ? 2 : 0;
                const labelValue = _this.caller.formatCell({
                    template: this.value.template,
                    value: this.value.value,
                    decimals,
                });

                if (this.value.value > 0)
                    return `<span>${labelValue}</span>`;

                return `<span class="negative">${labelValue}</span>`;
            }
        };

        return this;
    }

    /**
     * Bereitet die darzustellenden Daten der Chart vor.
     *
     * Die Grundlage der Bezeichnung der Kreisausschnitte sind die Spalten der Daten.
     * Die Grundlage der Daten sind die Summentwerte der Zeilen der jeweilgen Spalten.
     *
     * @param {data:ReportRow[]} data Die Rohdaten
     */
    prepareSeriesByRows(data: ReportRow[]): this {
        // Aktuell ausgewälter Index (Jahresauswahl)
        const pieIndex = this.caller.cache.reactive.get("piechart.index") || 0;

        // Daten der zu berücksichtigenden Ebene
        let slices:(ReportRow & {value?: { value: number, template: string, percentage: number}})[]  = data
            .filter(slice => slice.level === this.level);

        // Farben der Kreisausschnitte berechnen
        const colorsToFill = ColorGenerator(this.baseColors?.at(0) ?? "#000000", this.baseColors?.at(1) ?? "#CCCCCC", slices.length);

        // Farben den Kreisausschnitten zuordnen
        slices.forEach((slice, idx) => {
            slice.color = `#${tinycolor(colorsToFill[idx]).toHex()}`;
        });

        // Daten ohne 0-Werte
        slices = slices
            .filter(slice => (slice.cells[pieIndex].value || 0) !== 0);

        // Absolut kummulierter Wert der Y-Werte zur Berechnung der Prozentwerte.
        const total = slices
            .map(slice => Math.abs((slice.cells.at(pieIndex)?.value ?? 0) as number))
            .reduce((prev, current) => prev + current, 0);

        // Wert-, Namens- und Prozentzuweisung der Kreisausschnitte
        slices
            .forEach(slice => {
                const value = (slice.cells[pieIndex].value || 0) as number;
                const percentage = Math.round((value / total) * 10000) / 100;

                // @ts-ignore
                slice.y = Math.max(1, percentage);

                slice.value = {
                    value,
                    percentage,
                    template: slice.cells[pieIndex].template
                };
            });

        const sumPercentage = slices
            // @ts-ignore
            .map(slice => Math.abs(slice.y))
            .reduce((prev, current) => prev + current, 0);

        // Nachjustierung der Prozentwerte, wenn Summe kleiner 100%
        if (sumPercentage < 100) {
            const addPercentage = (100 - sumPercentage) / slices.length;

            // @ts-ignore
            slices.forEach(slice => slice.y += addPercentage);
        }

        const seriesData = slices.map(slice => {
            // @ts-ignore
            const { name, y, color, value } = slice;

            return {
                name,
                y,
                color,
                value,
                drilldown: true
            };
        });

        this.series = [{
            name: "",
            animation: this.animationDuration,
            data: seriesData
        }];

        return this;
    }

    /**
     * Bereitet die darzustellenden Daten der Chart vor.
     *
     * Die Grundlage der Bezeichnung der Kreisausschnitte sind die Spalten der Daten.
     * Die Grundlage der Daten sind die Summentwerte der Zeilen der jeweilgen Spalten.
     *
     * @param {data:ReportRow[]} data Die Rohdaten
     */
    prepareSeriesByColumns(data: ReportRow[]): this {
        // Spalten der Daten
        const columns = this.caller.columns;

        // Farben der Kreisausschnitte berechnen
        const colorsToFill = ColorGenerator(this.baseColors?.at(0) ?? "#000000", this.baseColors?.at(1) ?? "#CCCCCC", columns.length);

        // Daten der zu berücksichtigenden Ebene
        const filtered = data
            .filter(slice => slice.level === this.level)
            .map(({cells}) => cells);

        // Absolut kummulierter Wert zur Berechnung der Prozentwerte.
        const total = filtered
            .map(cells => cells)
            .flat()
            .map(({value}) => Math.abs(value as number))
            .reduce((prev, current) => prev + current, 0);

        const slices:{y: number, name: string, color: string, value: { value: number, template: string, percentage: number}}[] = [];

        filtered
            .forEach(cells => {
                cells.forEach((cell, idx) => {
                    if (slices.length - 1 < idx)
                        slices.push({
                            y: 0,
                            name: "",
                            color: "",
                            value: {
                                value: 0,
                                template: cell.template,
                                percentage: 0
                            },
                        });

                    const value = slices[idx].value.value + (cell.value as number);
                    const percentage = slices[idx].value.percentage + Math.round((value / total) * 10000) / 100;

                    slices[idx].name = columns[idx].name as string;
                    slices[idx].color = `#${tinycolor(colorsToFill[idx]).toHex()}`;
                    slices[idx].y += Math.max(1, percentage);
                    slices[idx].value = {
                        value,
                        percentage,
                        template: cell.template
                    };
                })
            });

        const sumPercentage = slices
            // @ts-ignore
            .map(({y}) => Math.abs(y))
            .reduce((prev, current) => prev + current, 0);

        // Nachjustierung der Prozentwerte, wenn Summe kleiner 100%
        if (sumPercentage < 100) {
            const addPercentage = (100 - sumPercentage) / slices.length;

            // @ts-ignore
            slices.forEach(slice => slice.y += addPercentage);
        }

        const seriesData = slices.map(slice => {
            // @ts-ignore
            const { name, y, color, value } = slice;

            return {
                name,
                y,
                value,
                color,
                drilldown: true
            };
        });

        this.series = [{
            name: "",
            animation: this.animationDuration,
            data: seriesData
        }];

        return this;
    }

    /**
     * Bereitet die darzustellenden Daten der Chart vor.
     *
     * @param {data:ReportRow[]} data Die Rohdaten
     */
    prepareSeries(data: ReportRow[]): this {
        if (this.sourceType === "rows")
            return this.prepareSeriesByRows(data);

        return this.prepareSeriesByColumns(data);
    }

    /**
     * Liefert die Highcharts-Optionswerte zur Darstellung des Diagramms.
     *
     * @returns {Highcharts.options} Die Highchart-Optionswerte.
     */
    getPrepared(): Highcharts.Options {
        let caller = this.caller;
        let presentation  = caller.plan.diagrams.find(diagram => diagram.$type.toLowerCase() === this.caller.visualizationMode)?.presentation ??  "donut";

        return <Highcharts.Options>{
            chart: {
                type: "pie",
                inverted: false,

                options3d: {
                    enabled: presentation == "3d",
                    alpha: 50,
                    beta: 0
                },
                events: {
                    drilldown: function (e) {
                        const name = get(e, "point.name", undefined);
                        const row = filter(caller.rows, ["name", name])[0];
                        const type = get(row, "ref", "else");
                        let value = row.cells[caller.cache.get("pie.index") || 0].value;

                        if (toInteger(value) < 2 || e.seriesOptions || type !== "report")
                            return;

                        const layoutFilters = caller.layout.filters || [];
                        const layoutParameters = FilterUtils.mapJson(caller.layout.parameters || {});
                        const rootFilter = FilterUtils.mapJson({ root: caller.layout.source || "" });

                        let filters = FilterUtils.assign(layoutFilters, layoutParameters, rootFilter);

                        if (row.item)
                            filters = FilterUtils.assign(...filters, ...[new FilterModel({ name: "item", value: row.item })]);

                        caller.cache.set("plan", {
                            type,
                            source: type === "report" ? get(row, "report", undefined) : get(row, "url", undefined),
                            filters
                        });

                        caller.refreshView();
                    }
                }
            },

            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    depth: 65,
                    startAngle: 90,
                    showInLegend: false,
                    borderWidth: 0,
                    innerSize: presentation == "donut" ? `50%` : 0,

                    dataLabels: {
                        useHTML: true,
                        enabled: true,
                        distance: 10,

                        formatter(): string {
                            let name = get(this, "key", "");

                            // @ts-ignore
                            const percentage = this.point.series.data[this.point.index].options.value.percentage;

                            if (name.length > 30)
                                name = `${name.slice(0, 27)}...`;

                            if (percentage >= 0) {
                                return `${name}<span style="opacity:0.5">: ${percentage.toFixed(2)}%</span>`;
                            }

                            return `${name} (<span class="negative">${percentage.toFixed(2)}%</span>)`;
                        }
                    },

                    states: {
                        inactive: {
                            opacity: 1
                        }
                    }
                }
            },

            legend: {},
            sourceType: this.sourceType,
            tooltip: this.tooltip,
            series: this.series
        };
    }
}
