/**
 * Wandert den Dom-Baum bis zur Wurzel zurück und
 * liefert aufgrund der vorhandenen Nachbar und Elternknoten eine
 * eindeutige Positions-Id des Elements.
 *
 * @param $el - Das Zielelement.
 * @param id - Die letzte Id der Traversion.
 *
 * @returns Die Positions-Id des Elements.
 */
const getId = ($el: HTMLElement | null, id: string): string => {
    if (!$el?.tagName)
        return "undefined";

    if ($el?.tagName.toLowerCase() === "nav")
        return id
            .split(".")
            .filter(Boolean)
            .reverse()
            .join(".");

    if (!id)
        id = "";

    if ($el.tagName === "UL")
        return getId($el?.parentElement, id);

    const siblings = Array
        .from($el.parentNode?.childNodes ?? [])
        .filter((child: Node) => child.nodeType === Node.ELEMENT_NODE && ["UL", "LI"].includes((child as HTMLElement).tagName));

    const idxElement = siblings.indexOf($el);

    if (idxElement > -1)
        id += `${idxElement}.`;

    return getId($el?.parentElement, id);
};

export default getId;
