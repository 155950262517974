/**
 * Logische Vergleichsoperatoren eines Filters
 */
const FilterOperator:{[key: string]: string} = {
    contains: " ~= ",
    equals: " == ",
    greaterEquals: " >= ",
    greaterThan: " > ",
    lessEquals: " <= ",
    lessThan: " < ",
    notEquals: " != "
};

export default FilterOperator;
