import type FilterModel from "@/utils/Filter/FilterModel";
import { isPromiseMethod } from "@/utils/typeGuards";
import ConditionVerifier from "../ConditionVerifier";
import Logger from "@/utils/Logger";
import MissingAction from "../Errors/MissingAction";
import BaseAction from "../BaseAction";

/**
 * Eine Klasse zur Ausführung von Aktionen.
 */
class ActionHandler {
    /**
     * Die Aktionen, die ausgeführt werden sollen.
     */
    private readonly Actions: TAction<TActionName>[];

    /**
     * Der Name des Ereignisses, das die Aktionen ausgelöst hat.
     */
    private readonly EventName: TEventName

    /**
     * Ein Filterobjekt, das die Filter für die Aktionen bereitstellt.
     */
    private readonly Filters?: FilterModel[];

    /**
     * Die Hooks, die den Aktionen bereitgestellt werden.
     */
    private readonly Hooks?: TActionHooks;

    /**
     * Der Wert, der für die erste Aktion des Parameter "lastResult" verwendet wird.
     */
    private readonly InitialValue?: unknown;

    /**
     * Gibt an, ob die Ergebnisse der Aktionen zusammengeführt werden sollen.
     */
    private readonly MergeResults: boolean = false;

    /**
     * Die Plugins, die von den Aktionen verwendet werden.
     */
    private readonly Plugins?: TPlugins;

    /**
     * Gibt an, ob der Ereignisbehandler die Ereignisse protokollieren soll.
     */
    private readonly Log: boolean = false;

    /**
     * Das auslösende Steuerelement.
     */
    private readonly Trigger: TControl;

    /**
     * Eine Funktion, die einen Mitgliedsnamen als Parameter erhält und einen unbekannten Wert zur Prüfung der Bedingungen zurückgibt.
     */
    private readonly ConditionValueResolver: TConditionValueResolver;

    /**
     * Die Komponente, die den Ereignisbehandler verwendet.
     */
    private Component?: Record<string, any>;

    /**
     * Der zu verwendende Store (bspw. Formulardaten eines Formulars).
     */
    private Store: KeyValuePair<unknown> = {};

    /**
     * C'Tor
     *
     * @param actions Die Aktionen, die ausgeführt werden sollen.
     * @param trigger Das auslösende Steuerelement.
     * @param hooks Die Hooks, die den Aktionen bereitgestellt werden.
     * @param initialValue Der Wert, der für die erste Aktion des Parameter "lastResult" verwendet wird.
     * @param plugins Die Plugins, die von den Aktionen verwendet werden.
     * @param mergeResults Gibt an, ob die Ergebnisse der Aktionen zusammengeführt werden sollen.
     * @param eventName Der Name des Ereignisses, das die Aktionen ausgelöst hat.
     * @param store Der zu verwendende Store (bspw. Formulardaten eines Formulars).
     * @param log Gibt an, ob der Ereignisbehandler die Ereignisse protokollieren soll.
     */
    constructor({ actions, trigger, hooks, plugins, initialValue, component, filters, mergeResults = false, eventName, conditionValueResolver, store = {},  log = false }: TActionHandlerConfig) {
        this.Actions = actions;
        this.Component = component;
        this.EventName = eventName;
        this.Filters = filters;
        this.Hooks = hooks;
        this.InitialValue = initialValue;
        this.Log = log;
        this.MergeResults = mergeResults;
        this.Plugins = plugins;
        this.Trigger = trigger;
        this.Store = store;

        this.ConditionValueResolver = conditionValueResolver;
    }

    /**
     * Überprüft die Bedingungen {@linkcode TCondition} und gibt das Ergebnis zurück.
     *
     * @param conditions Die Bedingungen, die überprüft werden sollen.
     *
     * @returns Ein Promise, das ein boolesches Ergebnis enthält.
     */
    private async verifyConditions(conditions: TCondition[], value: { [key: string]: unknown }): Promise<boolean> {
        let isFulfilled = true;

        this.Log && Logger.info("");
        this.Log && Logger.info("Prüfe Bedingungen...");

        if (!conditions || conditions.length === 0) {
            this.Log && (conditions?.length ?? 0 === 0) && Logger.info(`Keine Bedingungen gefunden. Kehre zurück!`, "#006d77");

            return true;
        }

        this.Log && Logger.table(`${conditions.length} Bedingung${conditions.length === 1 ? '' : 'en'} gefunden.`, conditions!);

        for (let index = 0; index < conditions.length; index++) {
            const condition = conditions[index];

            const verifier = new ConditionVerifier({
                log: this.Log,
                condition
            });

            const verified = await verifier.verifyActionCondition({ value });
            const operator = index > 0 ? condition?.operator ?? "and" : null;

            isFulfilled = index === 0 ? verified : (operator === "or" ? isFulfilled || verified : isFulfilled && verified);
        }

        this.Log && Logger.info(`Ergebnis aller Bedingungen: ${isFulfilled}`);

        return isFulfilled;
    }

    /**
     * Importiert die Klasse oder führt die bestehende Funktion in den Hooks aus und gibt das Ergebnis zurück.
     *
     * @param actionName Der Name der Aktion.
     * @param actionParams Die Parameter, die an die Aktion übergeben werden.
     *
     * @returns Ein Promise, das das Ergebnis der Aktion enthält.
     */
    private async getActionResult<T extends TActionName>({actionName, actionParams}: {actionName: TActionName, actionParams: TActionParams<T>}) {
        let actionMethod = this.Hooks?.[actionName] as TActionHooks[T];

        if (actionMethod !== undefined) {
            this.Log && Logger.info(`Aktion > ${actionName} < gefunden. Führe Aktion aus!`);
            this.Log && Logger.info("");

            return actionMethod(actionParams);
        }

        let instance:BaseAction<T>
        let importedClass;

        try {
            const importableFile = `${actionName.slice(0, 1).toUpperCase()}${actionName.slice(1)}`;

            importedClass = (await import(`./Actions/${importableFile}.ts`)).default;
        } catch (error) {
            throw new MissingAction(`Die Aktion > ${actionName} < ist nicht definiert.`);
        }

        instance = new importedClass(actionParams);

        this.Log && Logger.info(`Aktion > ${actionName} < gefunden. Führe Aktion aus!`);
        this.Log && Logger.info("");

        if (isPromiseMethod(instance, "execute"))
            return await instance.execute.bind(instance)();

        return instance.execute.bind(instance)() as TActionHooks[T];
    }

    /**
     * Prüft, ob die Aktion existiert. Falls nicht, wird dies über einen Proxy nachgeholt, führt die Aktion aus und gibt das Ergebnis zurück.
     *
     * @param action Die Aktion, die ausgeführt werden soll.
     * @param lastAction Die letzte ausgeführte Aktion.
     * @param lastResult Das Ergebnis der letzten Aktion.
     *
     * @returns Ein Promise, das das Ergebnis der Aktion enthält.
     */
    private async executeAction<T extends TActionName>({ action, lastAction, lastResult }: { action: TAction<T>, lastAction?: TAction<TActionName>, lastResult: { [key: string]: unknown } }) {
        this.Log && Logger.info("");
        this.Log && Logger.info(`Prüfe auf Existenz der Aktion > ${action.$type} <`);

        const actionParams:TActionParams<T> = {
            action,
            component: this.Component,
            filters: this.Filters,
            lastAction,
            lastResult,
            plugins: this.Plugins,
            log: this.Log,
            trigger: this.Trigger,
            initialValue: this.InitialValue,
            store: this.Store,
            eventName: this.EventName,
            hooks: {
                action: new Proxy<TActionHooks>({} as TActionHooks, {
                    get: (target, prop: TActionName) => {
                        if (this.Hooks?.[prop] === undefined)
                            return async (actionParams: TActionParams<T>) =>
                                await this.getActionResult({ actionName: prop, actionParams });

                        return this.Hooks[prop] as TActionHooks[TActionName];
                    }
                })
            },

            conditionValueResolver: this.ConditionValueResolver
        };

        return await this.getActionResult({ actionName: action.$type, actionParams });
    }

    /**
     * Verarbeitet die Aktionen und gibt das Ergebnis zurück.
     *
     * @param initialValue Der initiale Wert, der an die erste Aktion übergeben wird.
     *
     * @returns Ein Promise, das das Ergebnis der Aktionen enthält.
     */
    public async handle(initialValue: { [key: string]: unknown }) {
        let lastResult = initialValue !== undefined ? JSON.parse(JSON.stringify(initialValue)) : undefined;

        for (let index = 0; index < this.Actions.length; index++) {
            if (this.Actions[index].conditions && !(await this.verifyConditions(this.Actions[index].conditions, lastResult)))
                continue;

            try {
                const result = await this.executeAction({
                    action: this.Actions[index],
                    lastResult: lastResult !== undefined ? JSON.parse(JSON.stringify(lastResult)) : undefined,
                    lastAction: index > 0 ? this.Actions.at(index - 1) : undefined
                });

                if (!this.MergeResults)
                    lastResult = result;
                else
                    lastResult = { ...lastResult, ...(result as any)}
            } catch (error) {
                if (error instanceof Error && error.name === "ActionError")
                    break;

                throw error;
            }
        }

        return lastResult;
    }
}

export default ActionHandler;
