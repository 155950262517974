export default {
    /**
     * Aktualisiert die Breadcrumb.
     */
    refresh() {
        // let breadcrumb = [];
        const breadcrumb = (this.plan.path || [])
            .map(({name, path, url, item, navigatable}) => {
                return {
                    title: name,
                    path,
                    url,
                    item: item || null,
                    navigatable
                };
            });

        this.cache.set("breadcrumb", breadcrumb);
        this.breadcrumb = breadcrumb;
    }
};
