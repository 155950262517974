import { createApp } from "vue";
import { provideUtility } from "@/utils/utility.helper";
import { ApmVuePlugin } from "@elastic/apm-rum-vue";
import { isMobile, isTouchDevice } from "@/utils/browser";
import App from "@/App.vue";
import Drive from "@/apis/drive/api";
import LeftMenu from "@/components/UI/Navigation/LeftNavigation/LeftMenu.vue";
import Panel from "@/components/UI/Panel.vue";

(async function() {
    const get = (await import("lodash/get")).default;
    const configuration = (await import("@/config/index.js")).default;
    const Argos = (await import("@/apis/argos")).default;
    const Axios = (await import("axios")).default;

    const initRouter = (await import("./router")).default;
    const store = (await import("./store")).default;
    const TemplateMixin = (await import("./mixins/mixin.template")).default;
    const Vue3TouchEvents = (await import("vue3-touch-events")).default;
    const VueAxios = (await import("vue-axios")).default;
    const vueDebounce = (await import("vue-debounce")).default;
    const VuePluginAuth = (await import("./authentication/vue.plugin")).default;
    const VuePluginBreadcrumbs = (await import("./components/UI/Breadcrumb/vue.plugin")).default;
    const VuePluginCache = (await import("./caching/vue.plugin.cache")).default;
    const VuePluginConfig = (await import("./config/vue.plugin")).default;
    const VuePluginConsentApi = (await import("./components/Modals/Consent/vue.plugin")).default;
    const VuePluginCropper = (await import("./components/Modals/Cropper/vue.plugin")).default;
    const VuePluginHelp = (await import("./components/Modals/Help/vue.plugin")).default;
    const VuePluginModel = (await import("./store/vue.plugin")).default;
    const VuePluginModal = (await import("./components/Modals/vue.plugin")).default;
    const VuePluginPage = (await import("./caching/vue.plugin.page")).default;
    const VuePluginReactiveCache = (await import("./caching/vue.plugin.cache.reactive")).default;
    const VuePluginRequest = (await import("./communication/vue.plugin")).default;
    const VuePluginReleaseNotes = (await import("@/components/UI/ReleaseNotes/vue.plugin")).default;
    const VuePluginThemer = (await import("./components/Modals/Themer/vue.plugin")).default;

    const version = ():{build: string} => {
        /* @ts-expect-error */
        return APP_VERSION || { build: "1"};
    }

    const reload = () => {
        if (process.env.NODE_ENV === "development")
            return false;

        // eslint-disable-next-line
        const deployedBuild = (version()["build"] || "").toString();
        const cachedBuild = (localStorage.getItem("build") || "").toString();

        if (cachedBuild !== deployedBuild || cachedBuild.length === 0) {
            localStorage.setItem("build", deployedBuild || "1");

            return true;
        }

        return false;
    };

    if (reload()) {
        // Force-Reload
        const form = document.createElement("form");

        form.method = "POST";
        form.action = location.href;
        form.style.visibility = "hidden";
        form.style.position = "fixed";
        form.style.width = "100vw";
        form.style.height = "100vh";
        form.style.left = "-100vw";
        form.style.top = "-100vh";

        document.body.appendChild(form);

        setTimeout(() => {
            form.submit();
        }, 1);
    } else {
        let config = await configuration();

        const app = createApp(App);

        app.component("Panel", Panel);
        app.component("LeftMenu", LeftMenu);

        app.use(VuePluginModel);

        Drive.CrmUrl = get(config, "services.crm.baseUrl", "");
        Drive.Config = get(config, "services.drive", "");
        Drive.BaseUrl = get(config, "services.drive.baseUrl", "");
        Argos.BaseUrl = get(config, "services.argos.baseUrl", "");

        const $model = app.config.globalProperties.$model;

        $model.register("Auth", false);
        $model.register("Breadcrumbs", false);
        $model.register("Contact");
        $model.register("Cache", false);
        $model.register("Drive");
        $model.register("Help");
        $model.register("Modal");
        $model.register("Page");
        $model.register("ReactiveCache");
        $model.register("RequestCache", false);
        $model.register("Settings");
        $model.register("SourcesHistory", false);
        $model.register("SplashScreen");
        $model.register("ThemeCache", false);
        $model.register("Themer");
        $model.register("Parameters");

        let modelSettings = $model.get("Settings");
        modelSettings.set("environment", process.env.NODE_ENV);
        modelSettings.set("isDevEnv", process.env.NODE_ENV === "development");
        modelSettings.set("isProdEnv", process.env.NODE_ENV === "production");
        modelSettings.set("browser", {});
        modelSettings.set("browser.isMobile", isMobile);
        modelSettings.set("browser.isTouchDevice", isTouchDevice);

        modelSettings.commit();

        let modelSplashScreen = $model.get("SplashScreen");

        modelSplashScreen.set("isLoading", false);
        modelSplashScreen.set("isReady", false);
        modelSplashScreen.set("errorOnLoad", false);
        modelSplashScreen.set("requestMessage", "Willkommen");

        modelSplashScreen.commit();

        app.use(VuePluginConfig, {
            config: config
        });

        app.use(VueAxios, Axios);
        provideUtility("Axios", app.config.globalProperties.axios, app.provide);
        // Vue2 Backwards Compatibility
        app.provide("axios", app.config.globalProperties.axios);

        app.use(VuePluginRequest, {
            config: config,
            services: config.services,

            cacheModel: $model.get("RequestCache"),
            storage: localStorage,

            headers: {"Accept": "application/json"}
        });

        const $request = app.config.globalProperties.$request;

        // @ts-ignore
        app.use(Vue3TouchEvents);

        app.use(VuePluginPage, {
            model: $model.get("Page"),
            requestObject: $request
        });

        app.use(VuePluginCache, {
            model: $model.get("Cache")
        });

        app.use(VuePluginBreadcrumbs, {
            model: $model.get("Breadcrumbs")
        });

        app.use(VuePluginReactiveCache, {
            model: $model.get("ReactiveCache")
        });

        app.use(VuePluginHelp, {
            model: $model.get("Help")
        });

        app.use(VuePluginThemer, {
            model: $model.get("Themer")
        });

        app.use(VuePluginModal, {
            model: $model.get("Modal")
        });

        app.use(VuePluginCropper);

        app.use(VuePluginReleaseNotes);

        app.use(VuePluginConsentApi);

        app.use(vueDebounce);

        app.use(VuePluginAuth, {
            config: config
        });

        app.use(ApmVuePlugin, {
            // @ts-ignore
            config: {
                serviceName: "App",
                serverUrl: config.apm,
                serviceVersion: version().build || "0",
                distributedTracingOrigins: Object
                    .keys(config.services)
                    .map(k => new URL(config.services[k].baseUrl).origin)
            }
        });

        app.config.globalProperties.$store = store;

        TemplateMixin(app);

        app
            .use(initRouter(app))
            .use(store);

        app
            .mount("#app");
    };
})();
