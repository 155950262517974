<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from "vue";
import { injectUtility } from "@/utils/utility.helper";
import List from "@/components/UI/List.vue";

const $model = injectUtility("Model");

/**
 * Der Scrollcontainer der Intervall-Auswahl
 */
const scrollcontainer = ref<HTMLElement | null>(null);

const emit = defineEmits(["select"]);

const props = defineProps({
    /**
     * Der aktive Intervall-Index
     */
    active: {
        type: Number,
        default: 0
    },

    /**
     * Die Interval-Elemente
     */
    items: {
        type: Array,
        default: () => []
    },

    /**
     * Die Eigenschaft zur Darstellug des Textes in einem Interval-Element.
     * Falls keine Eigenschaft angegeben wurde, dann handelt es sich um ein einfaches Array.
     */
    use: String,
});

/**
 * Bewegt die Navigationsleiste in den sichtbaren Bereich des aktiven Elements.
 */
const adjustNavigation = () => {
    if (!scrollcontainer.value)
        return;

    // Dom-Element
    const list = scrollcontainer.value.querySelector("ul");
    const activeItem = list?.querySelector("li.active");
    const items = list?.querySelectorAll("li");

    if (!items?.length)
        return;

    const firstElement = Array.from(items).at(0);
    const lastElement = Array.from(items).at(-1);

    if (!list || !activeItem || !firstElement || !lastElement)
        return;

    // Breiten- und Positionswerte
    const nLeft = scrollcontainer.value.getBoundingClientRect().left;
    const lLeft = list.getBoundingClientRect().left;
    const aLeft = activeItem?.getBoundingClientRect().left;

    const nWidth = parseFloat(getComputedStyle(scrollcontainer.value).getPropertyValue("width"));
    const aWidth = parseFloat(window.getComputedStyle(activeItem).getPropertyValue("width"));
    const lWidth = parseFloat(window.getComputedStyle(list).getPropertyValue("width"));

    const firstLeft = firstElement.getBoundingClientRect().left;
    const lastLeft = lastElement.getBoundingClientRect().left;
    const lastWidth = parseFloat(window.getComputedStyle(lastElement).getPropertyValue("width"));

    let diff = null;

    // List auf Position 0, falls kleiner als sichtbarer Bereich.
    if (lWidth <= nWidth)
        diff = 0;

    // Aktives Element links ausserhalb des sichbaren Bereichs oder Lücke zwischen erstem Element und Navigation?
    else if (aLeft < nLeft || firstLeft > nLeft)
        diff = -1 * (aLeft - lLeft);

    // Aktives Element rechts ausserhalb des sichbaren Bereichs?
    else if (aLeft + aWidth > nLeft + nWidth)
        diff = -1 * (aLeft + aWidth - lLeft - nWidth);

    // Lücke zwischen letztem Element und Navigation?
    else if (lastLeft + lastWidth < nLeft + nWidth)
        diff = -1 * (aLeft + aWidth - lLeft - nWidth);

    if (diff === null)
        return;

    // Transformation ausführen
    transformElement({
        $element: list,
        x: diff,
        y: 0
    });
};

/**
 * Bewegt die Navigationsleiste in Abhängigkeit der Mauskoordinaten, sofern die Navigationsleiste überlappt.
 *
 * @param e - Das Mausereignis-Objekt.
 */
const moveNavigation = (e: MouseEvent) => {
    if (!scrollcontainer.value)
        return;

    // @ts-ignore
    if ($model.get("Settings").get("browser.isTouchDevice"))
        return;

    // Dom-Element
    const list = scrollcontainer.value.querySelector("ul");

    if (!list)
        return;

    // Breiten- und Positionswerte
    const nLeft = scrollcontainer.value.getBoundingClientRect().left;
    const nWidth = parseFloat(window.getComputedStyle(scrollcontainer.value).getPropertyValue("width"));
    const lWidth = parseFloat(window.getComputedStyle(list).getPropertyValue("width"));

    // Kalkulation neue Position
    const ratio = lWidth / nWidth;
    const mPos = e.clientX - nLeft;
    const vPos = mPos * ratio;
    const diff = vPos - mPos;

    // Transformation ausführen
    transformElement({
        $element: list,
        x: diff * -1,
        y: 0
    });
};

/**
 * Transformiert ein Element per Css-Transformation an die gegebenen Koordinaten.
 *
 * @param options - Die Optionen.
 * @param options.$element - Das Element, welches transformiert werden soll.
 * @param options.x - Die X-Koordinate.
 * @param options.y - Die Y-Koordinate.
 */
const transformElement = ({$element, x, y}:{$element:HTMLElement, x: number, y: number}) => {
    $element.style.transform = `translate3d(${x}px, ${y}px, 0px)`;
    ($element.style as any)["-webkit-transform"] = `translate3d(${x}px, ${y}px, 0px)`;
    ($element.style as any)["-ms-transform"] = `translate3d(${x}px, ${y}px, 0px)`;
};

defineExpose({
    adjustNavigation
});

onMounted(() => {
    window.addEventListener("resize", adjustNavigation);
});

onBeforeUnmount(() => {
    window.removeEventListener("resize", adjustNavigation);
});
</script>

<template lang="pug">
div(
    ref="scrollcontainer"

    v-on:mousemove="moveNavigation"
    v-on:mouseleave="adjustNavigation"
)
    List.navigation(
        :activeIndex="active"
        :listItems="items"
        :propValue="use"

        @click="item => emit('select', item)"
    )
</template>

<style lang="scss" scoped>
div {
    position: absolute;
    left: 0px;
    width: 100%;
    top: 0px;
    bottom: auto;
    height: auto;

    background-color: var(--color-background);
}
</style>
