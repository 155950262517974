import type { AuthManager } from "@/authentication/AuthManager";
import type { ConsentApi } from "@/apis/consent/api";
import { inject, InjectionKey, provide } from "vue";
import { PageApi } from "@/caching/page";
import { ReleaseNotes } from "@/apis/releasenotes/api";

/**
 * Die InjectionKeys für die Utility-Plugins.
 */
const Utility = {
    Authentication: Symbol("VueAuthPlugin"),
    Axios: Symbol("VueAxiosPlugin"),
    Cache: Symbol("VueCachePlugin"),
    Config: Symbol("VueConfigPlugin"),
    ConsentApi: Symbol("VueConsentApiPlugin"),
    Help: Symbol("VueHelpPlugin"),
    Modal: Symbol("VueModalPlugin"),
    Model: Symbol("VueModelPlugin"),
    Page: Symbol("VuePagePlugin"),
    ReactiveCache: Symbol("VueReactiveCachePlugin"),
    Request: Symbol("VueRequestPlugin"),
    Releasenotes: Symbol("VueReleasenotesPlugin"),
    Themer: Symbol("VueThemerPlugin")
};

type Injectable = keyof typeof Utility;
type UtilityInterface<T extends Injectable> =
    T extends "Authentication" ? AuthManager :
    T extends "Axios" ? AxiosInstance :
    T extends "Cache" ? ICache :
    T extends "Config" ? IConfigApi :
    T extends "ConsentApi" ? ConsentApi :
    T extends "Help" ? IHelpApi :
    T extends "Modal" ? IModalApi :
    T extends "Model" ? IModel :
    T extends "Page" ? PageApi :
    T extends "ReactiveCache" ? ICache :
    T extends "Request" ? IRequestApi :
    T extends "Releasenotes" ? ReleaseNotes :
    T extends "Themer" ? IThemerDialogApi :
    never;

/**
 * Injiziert ein Utility-Plugin.
 *
 * @param name - Der Name des Utility-Plugins.
 *
 * @returns Das injizierte Utility-Plugin.
 */
const injectUtility = <T extends Injectable>(name: T) => {
    const utility = Utility[name] as InjectionKey<UtilityInterface<T>>;
    const resolved = inject(utility);

    if (!resolved)
        throw new Error(`Could not resolve ${utility.description}`);

    return resolved;
};

/**
 * Stellt ein Utility-Plugin bereit.
 *
 * @param key - Der InjectionKey des Utility-Plugins.
 * @param utility - Das Utility.
 */
const provideUtility = <T extends Injectable>(name: T, utility: UtilityInterface<T>, _provide?:(name: InjectionKey<UtilityInterface<T>>, utility: UtilityInterface<T>) => void) => {
    const key = Utility[name] as InjectionKey<UtilityInterface<T>>;

    _provide ? _provide(key, utility) : provide(key, utility);
};

export { injectUtility, provideUtility };
