import Scroller from "../index";
import onMouseDown from "./events/onMouseDown";
import onMouseLeave from "./events/onMouseLeave";
import onMouseMove from "./events/onMouseMove";
import onMouseUp from "./events/onMouseUp";
import onScroll from "./events/onScroll";

/**
 * Registriert die Benutzererignisse der proprietären Skrollbar
 *
 * @this Scroller
 */
const attachEvents = function () {
    onMouseDown.attach.call(this);
    onMouseLeave.attach.call(this);
    onMouseMove.attach.call(this);
    onMouseUp.attach.call(this);
    onScroll.attach.call(this);
};

export default attachEvents;
