<script setup>
import { computed, ref, watch } from "vue";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import merge from "lodash/merge";
import AnnotationModule from "highcharts/modules/annotations";
import ExportingModule from "highcharts/modules/exporting";
import Drilldown from "highcharts/modules/drilldown";
import Highcharts from "highcharts/highstock";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsMap from "highcharts/highmaps";
import Highcharts3d from "highcharts/highcharts-3d";
import Logger from "@/utils/Logger";

HighchartsMore(Highcharts);

/**
 * Das 3D Modul an Highcharts binden.
 */
Highcharts3d(Highcharts);

/**
 * Das Drilldown Modul an Highcharts binden.
 */
Drilldown(Highcharts);

AnnotationModule(Highcharts);
AnnotationModule(HighchartsMap);

ExportingModule(Highcharts);
ExportingModule(HighchartsMap);


const emit = defineEmits(["ready"]);

/**
 * @type {{id: String, chart: {}, show: Boolean, type: String}}
 */
const props = defineProps({
    /**
     * Die Node-ID des Balkendiagrams.
     *
     * @type {String}
     */
    id: {
        type: String,
        default: "bar-chart",
    },

    /**
     * Die Konfiguration der Chart.
     *
     * @type {Object}
     */
    chart: {
        type: Object,
        default: () => {
            return {};
        },
    },

    /**
     * Der Anzeigestatus des Diagramms
     *
     * @type {String}
     */
    show: {
        type: Boolean,
        default: false,
    },

    /**
     * Der Typ der Chart.
     *
     * @type {String}
     */
    type: {
        type: String,
        default: "bar",
    }
});

/**
 * Standardwerte der BarChart.
 *
 * @type {{value: {credits: {enabled: Boolean}, chart: {type: String}, title: {text: String}, xAxis: {categories: [], labels: {}}, yAxis: {title: {text: String}, categories: [], labels: {tickInterval: Function}}, navigation: { buttonOptions: { enabled: Boolean}}}}}
 */
const defaults = ref({
    credits: {
        enabled: false,
    },

    title: { text: "" },

    navigation: {
        buttonOptions: {
            enabled: false
        }
    }
});

/**
 * Speichert das Highcharts-Objekt, um spätere Änderungen (bspw. destroy) durchführen zu können.
 *
 * @type {{value: Object}}
 */
const highchart = ref(null);

/**
 * Liefert einen Wert, der angibt, ob die Chart-Navigation angezeigt werden soll.
 *
 * @type {{value: Boolean}}
 */
const showNavigation = computed(() =>
    get(props.chart, "sourceType", "rows") !== "columns" || props.type === "map"
);

/**
 * Erzeugt die Chart
 */
const createChart = () => {
    let _chart = cloneDeep(props.chart);
    let _defaults = cloneDeep(defaults.value);

    if (props.show && document.querySelector(`#${props.id}`)) {
        highchart.value && highchart.value.destroy();

        const initHighcharts =
            ["map"].indexOf(props.type) === -1
                ? Highcharts.chart
                : HighchartsMap.mapChart;

        let options =
            ["map"].indexOf(props.type) === -1
                ? merge(_defaults, _chart)
                : _chart;

        options.chart = options.chart || { events: {}};
        options.exporting = {
            buttons: {
                contextButton: {
                    enabled: false
                }
            },

            sourceWidth: 1024,
            sourceHeight: 768
        };

        options.chart.events = {...options.chart.events, ...{
            load(event) {
                if (!this.renderer.forExport) {
                    try {
                        emit("ready", event.target);
                    } catch (error) {
                        Logger.error("Chart: An error has occured during initialization:", error);
                    }
                }
            }
        }};

        highchart.value = initHighcharts(props.id, options);
    }
};

/**
 * Überwacht die Eigenschaft zur Generierung und Anzeigen der BarChart.
 */
watch(
    () => props.chart,
    () => createChart(),
    { flush: "post" }
);
</script>

<template lang="pug">
section.chart-container(ref="chartContainer")
    template(v-if="showNavigation")
        slot(name="top")

    .chart(:id="id")
</template>
