import type { ReportName } from "@/utils/ColorMappings";
import { ColorMappings } from "@/utils/ColorMappings";
import { niceScale } from "./helper";
import $ from "@/utils/dom";
import BaseChart from "./BaseChart";
import Color from "@/utils/color";
import ColorGenerator from "../ColorGenerator";
import colorHelper from "@/utils/colorHelper";
import Highcharts, { TooltipFormatterContextObject } from "highcharts";
import tinycolor from "tinycolor2";

/**
 * Eine Klase zur Generierung der Highcharts-Optionen einer Area-Chart.
 */
export default class AreaChart extends BaseChart {
    private minValue: number = 0;
    private maxValue: number = 0;
    private refValue: number = 1;

    public series: Array<any> = [];

    private color = {
        range: "#000000",
        success: "var(--color-success)",
        error: "var(--color-error)"
    };

    /**
     * C'Tor
     *
     * @param {TChartConstructorParameters} caller
     */
    constructor(caller: TChartConstructorParameters) {
        super(caller);

        const colorForecast = ColorMappings.getColorsByReportType((caller.layout.colorScheme || "liquidity") as ReportName).base1;
        this.color.range = new Color(colorHelper(colorForecast).hex2rgba(0.65)).rgb("#FFFFFF").hex().toString();
    }

    public setTooltip() {
        const _this = this;

        this.tooltip = {
            useHTML: true,
            crosshairs: true,
            split: true,

            formatter: function (this: TooltipFormatterContextObject) {
                if (!this.points)
                    return "";

                const idxForecast = this.points.findIndex((point) => point.series.name === "Prognosewert");
                const ptForecast = this.points[idxForecast];

                // @ts-ignore
                const tplForecast = ptForecast.series.userOptions.template || "Currency";

                let forecast = tplForecast === "Percent" && ptForecast.y !== 0 ? ptForecast.y! / 100 : ptForecast.y;
                forecast = _this.caller.formatCell({
                    value: forecast,
                    template: tplForecast
                });

                const fracForecast = ptForecast.y! / _this.refValue - 1;
                const percForecast = _this.caller.formatCell({
                    value: fracForecast,
                    template: "Percent"
                });

                const ptLowHigh = this.points[this.points.length - 1];

                // @ts-ignore
                const tplLowHigh = ptLowHigh.series.userOptions.template || "Currency";

                // @ts-ignore
                let low = tplLowHigh === "Percent" && ptLowHigh.point.low !== 0 ? ptLowHigh.point.low / 100 : ptLowHigh.point.low;
                low = _this.caller.formatCell({
                    value: low,
                    template: tplLowHigh
                });

                // @ts-ignore
                const fracLow = ptLowHigh.point.low! / _this.refValue - 1;
                const percLow = _this.caller.formatCell({
                    value: fracLow,
                    template: "Percent"
                });


                // @ts-ignore
                let high = tplLowHigh === "Percent" && ptLowHigh.point.high !== 0 ? ptLowHigh.point.high / 100 : ptLowHigh.point.high;
                high = _this.caller.formatCell({
                    value: high,
                    template: tplLowHigh
                });

                // @ts-ignore
                const fracHigh = ptLowHigh.point.high! / _this.refValue - 1;
                const percHigh = _this.caller.formatCell({
                    value: fracHigh,
                    template: "Percent"
                });

                const base = _this.caller.formatCell({
                    value: _this.refValue,
                    template: "Currency"
                });

                return `
                    <table>
                        <thead>
                            <tr>
                                <td>&nbsp;</td>
                                <td style="text-decoration: underline; text-align:right"><b>${this.x}</b></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style="text-align:left">Maximum:</td>
                                <td style="text-align:right">
                                    <span style="display: block; padding-top: 12px; font-weight: 700; color: ${fracHigh !== 0 ? (fracHigh > 0 ? _this.color.success : _this.color.error) : 'unset'}">${percHigh}</span>
                                    <span>${high}</span>
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align:left">Prognosewert:</td>
                                <td style="text-align:right">
                                    <span style="display: block; padding-top: 12px; font-weight: 700; color: ${fracForecast !== 0 ? (fracForecast > 0 ? _this.color.success : _this.color.error) : 'unset'}">${percForecast}</span>
                                    <span>${forecast}</span>
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align:left">Minimum:</td>
                                <td style="text-align:right">
                                    <span style="display: block; padding-top: 12px; font-weight: 700; color: ${fracLow !== 0 ? (fracLow > 0 ? _this.color.success : _this.color.error) : 'unset'}">${percLow}</span>
                                    <span>${low}</span>
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align:left; padding-top: 12px;">Depotwert:</td>
                                <td style="text-align:right"><span style="display: block; padding-top: 12px;">${base}</span></td>
                            </tr>
                        </tbody>
                    </table>
                `;
            }
        };

        return this;
    }

    /**
     * Bereitet die darzustellenden Daten der Chart vor.
     *
     * @param {ReportRow[]} data Die Zeilendaten vom Service
     *
     * @returns {Object} Die Klasseninstanz.
     */
    private prepareRange(data: ReportRow[]): ExtendedSeriesAreaRangeOptions | undefined {
        const rows = data.filter(row => row.level === 2)

        if (rows.length !== 2)
            return;

        const minimums: ReportRow | undefined = rows[0];
        const minCells: ReportCell[] | undefined = minimums?.cells;

        const maximums: ReportRow | undefined = rows[1];
        const maxCells: ReportCell[] | undefined = maximums?.cells;

        const mins = minCells?.map(min => parseFloat(min.value as string)) || [];
        const maxs = maxCells?.map(max => parseFloat(max.value as string)) || [];
        const ranges: number[][] = [];

        this.minValue = Math.min(...mins);
        this.maxValue = Math.max(...maxs);

        mins.forEach((min, idx) => ranges.push([min, maxs[idx] ?? 0]));

        return {
            type: "arearange",
            name: "Bereich",
            data: ranges,
            lineWidth: 1,
            yAxis: 1,
            level: 2,
            color: rows[0].backgroundColor ?? `${this.color.range}`,
            fillOpacity: 0.2,
            zIndex: 0,
            marker: {
                enabled: false
            }
        };
    }

    /**
     * Bereitet die darzustellenden Daten der Chart vor.
     *
     * @param {ReportRow[]} data Die Zeilendaten vom Service
     *
     * @returns {Object} Die Klasseninstanz.
     */
    prepareSeries(data: ReportRow[]) {
        const colorsToFill = ColorGenerator(this.baseColors![0]!, this.baseColors![1]!, 2);

        data
            .forEach((row, idx) => {
                if ([2, 3].includes(row.level))
                    return;

                const cells: ReportCell[] | undefined = row.cells;

                const serie: ExtendedSeriesLineOptions = {
                    type: "line",
                    name: row?.name,
                    data: cells?.map(avg => parseFloat(avg.value as string)) || [],
                    color: row.backgroundColor ?? `#${tinycolor(colorsToFill[idx]).toHex().toUpperCase()}`,
                    zIndex: 1,
                    template: row.cells[0].template,
                    level: row.level,
                    marker: {
                        enabled: false,
                        fillColor: `#${tinycolor(colorsToFill[idx]).toHex().toUpperCase()}`,
                        lineColor: `#${tinycolor(colorsToFill[idx]).toHex().toUpperCase()}`,
                        lineWidth: 2
                    }
                };

                if (row.dashStyle)
                    serie.dashStyle = row.dashStyle;

                this.series.push(serie);
            });

        this.series.push(this.prepareRange(data));

        return this;
    }

    /**
     * Liefert die Highcharts-Optionswerte zur Darstellung des Diagramms.
     *
     * @returns {Highcharts.options} Die Highchart-Optionswerte.
     */
    getPrepared(): Highcharts.Options | undefined {
        const _this = this;
        const template = this.series.find(data => data.level === 1).template || "Currency";
        const symbols = {
            Currency: "€",
            Percent: "%",
            Storage: "GByte"
        };

        /** @todo Name sollte nicht ID sein, klären */
        this.refValue = this.series
            .find(row => row.name === "Prognosewert")
            .data[0]

        const getKeyValue = <T extends object, U extends keyof T>(obj: T) => (key: U) =>
            obj[key];

        const symbol = getKeyValue(symbols)(template);

        const categories = (_this.caller.columns || []).map(({ template, name }) => {
            if (template === "Percent" && parseInt(name as string) !== 0)
                name = parseInt(name as string) / 100;

            return _this.caller.formatCell({
                value: name,
                template
            });
        });

        let { min, max, tickInterval } = niceScale({
            min: this.minValue,
            max: this.maxValue
        });

        const step = Math.ceil((this.series.find(s => s).data.length || 10) / 10);
        const yTicks: number[] = [];

        for (var i = min; i <= max; i = i + tickInterval) {
            yTicks.push(i);
        }

        yTicks.sort((a, b) => a - b > 1 ? 1 : -1);

        return <Highcharts.Options>{
            title: {
                text: "Monte Carlo Simulation"
            },

            xAxis: {
                gridLineColor: $().cssVar("silver"),
                gridLineWidth: 0.5,
                categories,
                tickInterval: step,
                startOnTick: true,
                labels: {
                    style: {
                        color: $().cssVar("dove-gray"),
                        "text-decoration": "none"
                    }
                }
            },

            yAxis: [
                {
                    lineWidth: 1,
                    title: "",
                    min: this.minValue,
                    max: this.maxValue,
                    tickPositions: yTicks,
                    labels: {
                        formatter: function () {
                            let value: number = <number>this.value;

                            if (template === "Percent" && value !== 0)
                                value = value / 100;

                            return value !== 0 ? _this.caller.formatCell({ value: value, template: "Currency" }) : `0 ${symbol}`;
                        },

                        style: {
                            color: $().cssVar("boulder")
                        }
                    },

                    plotLines: [{
                        color: "#000000",
                        dashStyle: "solid",
                        value: 0,
                        width: 0.5,
                        zIndex: 5
                    }],

                    gridLineColor: $().cssVar("silver"),
                    gridLineWidth: 0.5
                }, {
                    lineWidth: 1,
                    opposite: true,
                    title: "",
                    linkedTo: 0,
                    tickPositions: yTicks,
                    max: this.maxValue,
                    labels: {
                        formatter: function (context) {
                            if (typeof context.value === "string")
                                return context.value;

                            const template = "Percent";
                            const percent = context.value / _this.refValue - 1;
                            const symbol = getKeyValue(symbols)(template);

                            return percent !== 0 ? _this.caller.formatCell({ value: percent, template }) : `0 ${symbol}`;
                        },

                        style: {
                            color: $().cssVar("boulder")
                        }
                    },

                plotLines: [{
                    color: "#000000",
                    dashStyle: "solid",
                    value: 0,
                    width: 0.5,
                    zIndex: 5
                }],

                gridLineColor: $().cssVar("silver"),
                gridLineWidth: 0.5
            }],

            tooltip: this.tooltip,
            series: this.series,

            legend: {
                itemStyle: {
                    fontWeight: "normal"
                }
            }
        };
    }
}
