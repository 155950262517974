import Scroller from "../../index";

const onScroll = {
    /**
     * Registriert das Scroll-Ereignis.
     *
     * @this Scroller
     */
    attach: function () {
        if (!this.isIFrame)
            this.element.addEventListener("scroll", this.registeredHandler.scroll);
        else
            this.element.addEventListener("load", this.registeredHandler.iframeScrollReady);

        if (this.scroll === "normal" || this.isIFrame)
            return;

        this.element.addEventListener("wheel", this.registeredHandler.wheel);
        this.element.addEventListener("touchmove", this.registeredHandler.determineTouchDirection);
        this.element.addEventListener("touchstart", this.registeredHandler.touchstart);
        this.element.addEventListener("mousedown", this.registeredHandler.mousedown);
        this.element.addEventListener("keydown", this.registeredHandler.keydown);
    },

    /**
     * Entfernt das Scroll-Ereignis.
     *
     * @this Scroller
     */
    detach: function() {
        if (!this.element)
            return;

        if (!this.isIFrame)
            this.element.removeEventListener("scroll", this.registeredHandler.scroll);
        else {
            this.element.removeEventListener("load", this.registeredHandler.iframeScrollReady);
            this.element.contentDocument.removeEventListener("scroll", this.registeredHandler.scroll);
        }

        if (this.scroll === "normal" || this.isIFrame)
            return;

        this.element.removeEventListener("wheel", this.registeredHandler.wheel);
        this.element.removeEventListener("touchmove", this.registeredHandler.determineTouchDirection);
        this.element.removeEventListener("touchstart", this.registeredHandler.touchstart);
        this.element.removeEventListener("mousedown", this.registeredHandler.mousedown);
        this.element.removeEventListener("keydown", this.registeredHandler.keydown);
    }
};

export default onScroll;
