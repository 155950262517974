<script lang="ts" setup>
import type { PropType } from "vue";
import { computed, nextTick, ref } from "vue";
import Button from "@/components/UI/Button/Button.vue";

const emit = defineEmits(["click", "files"]);

const props = defineProps({
    /**
     * Die Button-Signalfarbe (optional).
     */
    signalType: {
        type: String as PropType<"error" | "success">,
        default: null
    },

    /**
     * Ein Button-Text (optional).
     */
    caption: {
        type: String,
        default: ""
    },

    /**
     * Eine Sammlung von CSS-Klassenbezeichnern (optional).
     */
    css: {
        type: Object,
        default: () => { return {}; }
    },

    /**
     * Der Deaktiviert-Status des Buttons.
     */
    disabled: {
        type: Boolean,
        default: false
    },

    /**
     * Die zugelassen Dateitypen (optional).
     */
    fileTypes: {
        type: Array as PropType<{ type: String }[]>,
        default: () => []
    },

    /**
     * Der native Tooltip-Text (optional).
     */
    hint: {
        type: String,
        default: ""
    },

    /**
     * Das darzustellende Icon (optional).
     */
    icon: {
        type: String,
        default: ""
    },

    /**
     * Stellt die Form des Buttons zur Verfügung.
     *
     * @example
     *
     * # Runder Button:
     * shape: "round"
     *
     * # Rechteckiger Button
     * shape: "rect"
     */
    shape: {
        type: String as PropType<"round" | "rect">,
        default: "rect"
    }
});

/**
 * Der Button
 */
const button = ref<HTMLButtonElement>();

/**
 * Das Dateiupload-Eingabefeld.
 */
const fileinput = ref<HTMLInputElement>();

/**
 * Gibt die möglichen Dateitypen für den Dateiupload zurück.
 */
const validFileTypes = computed(() =>
    (props.fileTypes || [])
        .map(fileType => fileType.type || "")
        .join(",")
);

/**
 * Ein Wert, der angibt, ob sich die Komponente im Wartezustand befindet.
 */
const pending = ref(false);

/**
 * Öffnet den Dateiauswahl-Dialog.
 */
const handleClick = () => {
    if (fileinput.value)
        fileinput.value.value = "";

    fileinput.value?.click();
    emit("click");
};

/**
 * Emittiert die ausgewählten Dateien an die Elternkomponente.
 */
const emitFiles = () => {
    emit(
        "files",

        Array.from(fileinput.value?.files ?? []),

        /**
         * Ladeanimation anzeigen.
         */
        () => nextTick(() => pending.value = true),

        /**
         * Ladeanimation verbergen.
         */
        () => nextTick(() => pending.value = false)
    );

    if (fileinput.value)
        fileinput.value.value = "";
};
</script>

<template lang="pug">
.fileupload(
    :class="{'disabled': disabled}"
)
    Button(
        ref="button"

        :pending="pending"
        :disabled="disabled"
        :signalType="signalType"
        :caption="caption"
        :class="css"
        :hint="hint"
        :icon="icon"
        :shape="shape"

        @click="handleClick"
    )

    input.fileupload__input(
        ref="fileinput"
        type="file"
        tabindex="-1"

        :multiple="true"
        :accept="validFileTypes"


        @change="emitFiles"
    )
</template>

<style lang="scss">
/** BEM - Block */
.fileupload {
    position: relative;
    float: left;
    background-color: transparent;
    cursor: pointer;
    border: none;
    overflow: hidden;

    /** BEM - Element: Die Dateiupload-Eingabe */
    &__input {
        position: absolute;
        visibility: hidden;
        left: -100000px;
        top: -100000px;
        pointer-events: none;
        touch-action: none;
    }
}
</style>
