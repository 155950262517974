import type Select from "./index";

/**
 * Stellt Hilfsmethoden des Select-Platzhalters zur Verfügung.
 *
 * @param select - Die Select-Instanz.
 */
export default function (select: Select) {
    let $select = typeof select.target === "string"
        ? document.getElementById(select.target)
        : select.target;

    let $placeholder = $select?.querySelector(".select__placeholder");
    let $input = $select?.querySelector(".select__input");

    return {
        /**
         * Entfernt den Text im Platzhalter.
         */
        clearText() {
            if (!$placeholder)
                return;

            $placeholder.innerHTML = "";
        },

        /**
         * Setzt den Text des Platzhalters auf den aktuellen Text der ausgewählten Option.
         */
        syncText() {
            if (!$placeholder)
                return;

            $placeholder.innerHTML = select.selectedText;
        },

        /**
         * Zeigt den Platzhalter.
         */
        show() {
            if (!$input || !$placeholder || !select.showLastSelection)
                return;

            $placeholder.classList.add("select__placeholder--show");
            $input.classList.add("select__input--bottom");
        },

        /**
         * Versteckt den Platzhalter.
         */
        hide() {
            if (!$input || !$placeholder || !select.showLastSelection)
                return;

            $placeholder.classList.remove("select__placeholder--show");
            $input.classList.remove("select__input--bottom");
        }
    };
}
