import $ from "@/utils/dom";

/**
 * Hebt die aktuell vorselektierte Zeile grafisch hervor.
 *
 * @param {Event} e Das Ereignisobjekt.
 */
export default function (e) {
    let target = e.target;
    let isHead = $(e.target).parent("thead").get(0) !== undefined;

    if (!isHead) {
        let tr = target.tagName && target.tagName.toLowerCase() === "tr"
            ? target
            : $(target).parent("tr").get(0);

        let trPrev = $(tr).prev("tr");

        $(this.tableBody).find("tr.next-preselect").removeClass("next-preselect");
        $(this.tableBody).find("tr.preselect").removeClass("preselect");

        trPrev.addClass("next-preselect");
        $(tr).addClass("preselect");
    }
}
