import type ToolkitTool from "./ToolkitTool";
import CaptureEventsTool from "./tools/CaptureEventsTool";
import DecodeQueryTool from "./tools/DecodeQueryTool";
import DifferenceTool from "./tools/DifferenceTool";
import RepeatTool from "./tools/RepeatTool";
import RetryTool from "./tools/RetryTool";

/**
 * Eine Liste von Tools zur Instanziierung eines Tools
 */
const Tools = {
    captureEvents: CaptureEventsTool,
    difference: DifferenceTool,
    decodeQuery: DecodeQueryTool,
    repeat: RepeatTool,
    retry: RetryTool
}

/**
 * Ein Typ zur Beschreibung der Konstruktoren der Tools
 */
type ToolType = {
    [key in keyof typeof Tools]: typeof Tools[key];
}

/**
 * Ein Toolkit-Facroty zur Bereitsstellung von Tools
 */
class Toolkit {
    /**
     * Liefert ein Tool zurück
     *
     * @param tool Das Tool, das geliefert werden soll
     * @param params Die Parameter des Tools
     *
     * @returns Die Instanz des Tools
     */
    public static tool<T extends keyof typeof Tools>(
        tool: T,
        params?: ConstructorParameters<ToolType[T]>[0]
    ) {
        const ToolClass = Tools[tool] as new (...args: any[]) => ToolkitTool<any>;

        return new ToolClass(params) as InstanceType<ToolType[T]>;
    }
}

export default Toolkit;
