import { updateScrollView, navigateVerticalByArrow } from "../lib/navigate";
import { navigateSelection, selectByArrow, selectBySpaceKey, selectByShiftKey, startSelectionByShift, startSelectionByCtrl } from "../lib/selection";
import getRowAmount from "../lib/getRowAmount";
import KEY from "../lib/KEY";
import Scroller from "@/utils/scroll/index";

export default function (e) {
    if (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement)
        return;

    if (!e.getModifierState)
        return;

    if (!document.activeElement)
        return;

    if (!document.activeElement.closest(".data-view") || document.activeElement.closest(".data-view").querySelector(".table.sticky > table") !== this.table)
        return;

    let ctrlKeyPressed = e.getModifierState("Control");
    let shiftKeyPressed = e.getModifierState("Shift");

    if (ctrlKeyPressed) startSelectionByCtrl.call(this);
    else this.state.ctrl.active = false;

    if (shiftKeyPressed) startSelectionByShift.call(this);
    else this.state.shift.active = false;

    /**
     * Wertzuweisung, ob gerade die Shift- oder Ctrl-Taste gedrückt wird:
     */
    let isSpecialKey = this.state.shift.active || this.state.ctrl.active;
    let isSelection = !this.state.ctrl.active || this.state.shift.active;

    /**
     * Wenn Pfeil links- bzw. rechts gedrückt, dann Navigation ausführen.
     */
    if ([KEY.LEFT, KEY.RIGHT].includes(e.keyCode))
        navigateVerticalByArrow.call(this, e.keyCode === KEY.RIGHT ? 1 : -1);
    /**
     * Wenn Seitenende bzw. Seitenanfang gedrückt, dann Navigation ausführen.
     */
    else if ([KEY.PAGE_END, KEY.PAGE_START].includes(e.keyCode)) {
        /**
         * @type {Scroller}
         */
        const scrollInstance = this.ctx.scrollInstance;

        navigateVerticalByArrow.call(this, e.keyCode === KEY.PAGE_END ? scrollInstance.width() : -scrollInstance.width());
    }
    /**
     * Wenn Pfeil hoch- bzw. runter gedrückt, dann Einfachselektion ausführen.
     */
    else if (!isSpecialKey && [KEY.UP, KEY.DOWN].includes(e.keyCode))
        selectByArrow.call(this, e.keyCode === KEY.DOWN ? 1 : -1);
    /**
     * Wenn Seite hoch- bzw. runter gedrückt, dann Einfachselektion ausführen.
     */
    else if (!isSpecialKey && [KEY.PAGE_UP, KEY.PAGE_DOWN].includes(e.keyCode))
        selectByArrow.call(this, e.keyCode === KEY.PAGE_DOWN ? getRowAmount.call(this) : -getRowAmount.call(this));
    /**
     * Wenn Mutliselect und Pfeil hoch- bzw. runter bei gedrückter Ctrl-Taste, dann Vorselektion ausführen.
     */
    else if (this.multiselect && this.state.ctrl.active && [KEY.UP, KEY.DOWN].includes(e.keyCode))
        navigateSelection.call(this, e.keyCode === KEY.DOWN ? 1 : -1);
    /**
     * Wenn kein Mutliselect und Pfeil hoch- bzw. runter bei gedrückter Ctrl-Taste, dann Einfachselektion ausführen.
     */
    else if (!this.multiselect && this.state.ctrl.active && [KEY.UP, KEY.DOWN].includes(e.keyCode))
        selectByArrow.call(this, e.keyCode === KEY.DOWN ? 1 : -1);
    /**
     * Wenn Multiselect, Ctrl-Taste und Leertaste gedrückt wurde, dann die aktuelle Zeile markiert setzen.
     */
    else if (this.multiselect && this.state.ctrl.active && e.keyCode === KEY.SPACE)
        selectBySpaceKey.call(this);
    /**
     * Wenn Multiselect sowie Pfeil hoch- bzw runter bei gedrückter Shift-Taste, dann Mehrfachselektion ausführen.
     */
    else if (this.multiselect && this.state.shift.active && [KEY.UP, KEY.DOWN].includes(e.keyCode))
        selectByShiftKey.call(this, e.keyCode === KEY.DOWN ? 1 : -1);
    /**
     * Wenn kein Multiselect sowie Pfeil hoch- bzw runter bei gedrückter Shift-Taste, dann Einfachselektion ausführen.
     */
    else if (!this.multiselect && this.state.shift.active && [KEY.UP, KEY.DOWN].includes(e.keyCode))
        selectByArrow.call(this, e.keyCode === KEY.DOWN ? 1 : -1);
    /**
     * Wenn Multiselect sowie Seite hoch- bzw runter bei gedrückter Shift-Taste, dann Mehrfachselektion ausführen.
     */
    else if (this.multiselect && this.state.shift.active && [KEY.PAGE_UP, KEY.PAGE_DOWN].includes(e.keyCode))
        selectByShiftKey.call(this, e.keyCode === KEY.PAGE_DOWN ? getRowAmount.call(this) : -getRowAmount.call(this));
    /**
     * Wenn kein Multiselect sowie Seite hoch- bzw runter bei gedrückter Shift-Taste, dann Einfachselektion ausführen.
     */
    else if (!this.multiselect && this.state.shift.active && [KEY.PAGE_UP, KEY.PAGE_DOWN].includes(e.keyCode))
        selectByArrow.call(this, e.keyCode === KEY.PAGE_DOWN ? getRowAmount.call(this) : -getRowAmount.call(this));

    let stateType = null;

    if (this.state.ctrl.active)
        stateType = "preselect";
    else if (this.state.shift.active)
        stateType = "shiftselect";
    else if (isSelection)
        stateType = "select";

    if (stateType && [33, 34, 37, 38, 39, 40].includes(e.keyCode)) {
        updateScrollView.call(this, e, stateType);
    } else if ([35, 36].includes(e.keyCode))
        e.preventDefault();
 }
