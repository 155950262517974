import type { ReportName } from "@/utils/ColorMappings";
import { ColorMappings } from "@/utils/ColorMappings";
import tinycolor from "tinycolor2";
import colorHelper from "@/utils/colorHelper";

/**
 * Generiert ein CSS-Stylesheet und bindet diesen in den HTML-Baum ein.
 *
 * @param {string} id Die ID des HTML-Stylesheet-Elements.
 * @param {string} rule Die zu schreibenden CSS-Regeln.
 */
const injectStyles = (id:string, rule:string) => {
    const fragment = document.createDocumentFragment();
    const div = document.createElement("div");
    const style = document.createElement("style");
    const styleId = `style-${id}`;

    const existingStyle = document.getElementById(styleId);

    existingStyle?.parentNode?.removeChild(existingStyle);

    div.id = styleId;
    div.className = "style-report__view";
    div.style.display = "none";

    style.innerHTML = rule;

    div.appendChild(style);
    fragment.appendChild(div);

    document.querySelector("body")?.appendChild(fragment);
};

/**
 * Generiert CSS-Regeln des Editors für Dokumentvorlagen .
 *
 * @param {ReportName} reportType
 */
const generateTemplateEditorStyle = (reportType:ReportName) => {
    const base1 = ColorMappings.getColorsByReportType(reportType).base1;
    const className = reportType !== "default"
        ? `.${reportType}`
        : "";

    return `
        #template-editor-chartselection${className} section.group.first .divider h4 {
            color: ${base1};
        }

        #template-editor-chartselection${className} section.group.first .btn.btn--primary {
            background-color: ${base1} !important;
        }

        #template-editor-chartselection${className} section.group.first .btn.btn--secondary {
            border: 2px ${tinycolor(base1).setAlpha(0.55)} solid !important;
            transition: border-color 125ms ease-in !important;
            background-color: transparent !important;
        }

        #template-editor-chartselection${className} section.group.first .btn.btn--secondary svg path {
            fill: ${base1} !important;
            stroke: none !important;
            -webkit-transition: fill 200ms ease;
            transition: fill 200ms ease;
            -o-transition: fill 200ms ease;
        }

        #template-editor-chartselection${className} section.group.first .btn.btn--secondary.active svg path {
            fill: #FFFFFF !important;
            -webkit-transition: fill 200ms ease !important;
            transition: fill 200ms ease !important;
            -o-transition: fill 200ms ease !important;
        }

        #template-editor-chartselection${className} section.group.first .btn:hover {
            border-color: ${base1} !important;
            transition: border-color 125ms ease-in !important;
        }

        #template-editor-chartselection${className} section.group.first .btn.active {
            background-color: ${base1} !important;
            -webkit-transition: background-color 200ms ease !important;
            transition: background-color 200ms ease !important;
            -o-transition: background-color 200ms ease !important;
        }
    `;
};

/**
 * Generiert die ReportView-Toolbar CSS-Regeln.
 *
 * @param {ReportName} reportType Der Report-Typ.
 *
 * @returns {string} Die Css-Regeln.
 */
const generateToolbarButtonStyle = (reportType:ReportName):string => {
    const base1 = ColorMappings.getColorsByReportType(reportType).base1;
    const className = reportType !== "default"
        ? `.${reportType}`
        : "";

    return `
        .report${className} .toolbar .btn.btn--primary {
            background-color: ${base1};
        }

        .report${className} .toolbar .btn.btn--primary:hover {
            background-color: ${tinycolor(base1).setAlpha(0.55)};
        }

        .report${className} .toolbar .btn.btn--secondary {
            border-color: ${tinycolor(base1).setAlpha(0.55)};
        }

        .report${className} .toolbar .btn.btn--secondary.btn--pending {
            background-color: ${base1};
        }

        .report${className} .toolbar .btn.active.btn--secondary.btn--pending {
            background-color: ${base1};
        }

        .report${className} .toolbar .btn.btn--secondary svg path {
            fill: ${base1} !important;
            stroke: none !important;
            -webkit-transition: fill 200ms ease;
            transition: fill 200ms ease;
            -o-transition: fill 200ms ease;
        }

        .report${className} .toolbar .btn.btn--secondary.active svg path {
            fill: #FFFFFF !important;
            -webkit-transition: fill 200ms ease;
            transition: fill 200ms ease;
            -o-transition: fill 200ms ease;
        }

        .report${className} .toolbar .btn:hover {
            border-color: ${base1};
        }

        .report${className} .toolbar .btn.active {
            background-color: ${base1};
            -webkit-transition: background-color 200ms ease;
            transition: background-color 200ms ease;
            -o-transition: background-color 200ms ease;
        }
    `;
};

/**
 * Generiert die ReportView-Toolbar CSS-Regeln für Buttons.
 *
 * @param {ReportName} reportType Der Report-Typ.
 *
 * @returns {string} Die Css-Regeln.
 */
const generateToolbarSelectStyle = (reportType:ReportName):string => {
    const base1 = ColorMappings.getColorsByReportType(reportType).base1;
    const className = reportType !== "default" ? `.${reportType}` : "";
    const classString = reportType !== "default" ? `${reportType}` : "";

    return `
        .report${className} .toolbar .toolbar__select.select__selection .select__border {
            border-color: ${tinycolor(base1).setAlpha(0.55)};
        }

        .report${className} .toolbar .toolbar__select.select__selection.select--hovering .select__border,
        .report${className} .toolbar .toolbar__select.select__selection.select--open .select__border {
            border-color: ${base1};
        }

        .report${className} .toolbar .toolbar__select.select__selection .select__border-custom {
            border-color: ${base1};
        }

        .toolbar__select.toolbar__select--report-${classString}.select__list {
            border-color: ${base1};
        }

        .toolbar__select.toolbar__select--report-${classString}.select__list .simplebar-track .simplebar-scrollbar:before {
            background-color: ${base1};
        }
    `;
};

/**
 * Generiert die ReportView-Breadcrumb CSS-Regeln.
 *
 * @param {ReportName} reportType Der Report-Typ.
 *
 * @returns {string} Die Css-Regeln.
 */
const generateBreadcrumbStyle = (reportType:ReportName):string => {
    const base1 = ColorMappings.getColorsByReportType(reportType).base1;
    const className = reportType !== "default" ? `.${reportType}` : "";

    return `
        .report${className} .breadcrumb ul li .icon__html > svg path {
            fill: ${base1}
        }
    `;
};

/**
 * Generiert die ReportView-Tabelle CSS-Regeln.
 *
 * @param {ReportName} reportType Der Report-Typ.
 *
 * @returns {string} Die CSS-Regeln
 */
const generateTableStyle = (reportType:ReportName):string => {
    const base1 = ColorMappings.getColorsByReportType(reportType).base1;
    const colorLvl2 = colorHelper(base1).hex2rgba(0.15);
    const colorLvl3 = colorHelper(base1).hex2rgba(0.07);
    const colorLvl4 = colorHelper(base1).hex2rgba(0.02);

    let className = reportType !== "default" ? `.${reportType}` : "";

    return `
        :root {
            --color-report-${reportType}-level1: ${base1}
        }

        .report${className} .report__view .report__table .report__table-content .table-body table tbody tr > td > *,
        .report${className} .report__view .report__table .report__table-content .table-sidebar table tbody tr > td > *,
        .report${className} .report__view .report__table table tbody tr > th > *,
        .report${className} .report__view .report__table table tbody tr > td > * {
            background-color: ${colorLvl3};
        }

        .report${className} .report__view .report__table .report__table-content .table-body table tbody tr.level4 > td > *,
        .report${className} .report__view .report__table .report__table-content .table-sidebar table tbody tr.level4 > td > *,
        .report${className} .report__view .report__table table tbody tr.level4 > th > *,
        .report${className} .report__view .report__table table tbody tr.level4 > td > * {
            background-color: ${colorLvl4};
        }

        .report${className} .report__view .report__table .report__table-content .table-body table tbody tr.level3 > td > *,
        .report${className} .report__view .report__table .report__table-content .table-sidebar table tbody tr.level3 > td > *,
        .report${className} .report__view .report__table table tbody tr.level3 > th > *,
        .report${className} .report__view .report__table table tbody tr.level3 > td > * {
            background-color: ${colorLvl3};
        }

        .report${className} .report__view .report__table .report__table-content .table-body table tbody tr.level2 > td > *,
        .report${className} .report__view .report__table .report__table-content .table-sidebar table tbody tr.level2 > td > *,
        .report${className} .report__view .report__table table tbody tr.level2 > th > *,
        .report${className} .report__view .report__table table tbody tr.level2 > td > * {
            background-color: ${colorLvl2};
        }

        .report${className} .report__view .report__table .report__table-content .table-body table tbody tr.level1 > td > *,
        .report${className} .report__view .report__table .report__table-content .table-sidebar table tbody tr.level1 > td > *,
        .report${className} .report__view .report__table table tbody tr.level1 > th > *,
        .report${className} .report__view .report__table table tbody tr.level1 > td > * {
            background-color: ${base1};
        }


        .report${className} .report__view .report__table .report__table-content .table-sidebar .report--action:hover,
        .report${className} .report__view .report__table table tbody tr .report--action:hover {
            background-color: ${colorLvl2} !important;
        }

        .report${className} .report__view .report__table table.table--exporting .report__table-content .table-sidebar .report--action:hover,
        .report${className} .report__view .report__table table.table--exporting tbody tr .report--action:hover {
            background-color: transparent !important;
            color: initial !important
        }

        .report${className} .report__view .report__table .report__table-content .table-sidebar table tbody tr td .help:hover,
        .report${className} .report__view .report__table table tbody tr th .help:hover,
        .report${className} .report__view .report__table table tbody tr td .help:hover {
            background-color: ${colorLvl2};
        }
    `;
};

/**
 * Generiert die ReportView-Tabellem-Scrollbar CSS-Regeln.
 *
 * @param {ReportName} reportType Der Report-Typ.
 *
 * @returns {string} Die CSS-Regeln
 */
const generateScrollbarStyle = (reportType:ReportName):string => {
    let color = ColorMappings.getColorsByReportType(reportType).base1;
    let className = reportType !== "default" ? `.${reportType}` : "";

    return `
        .report${className} .report__view .scrollcontainer .scrollbars .scrollthumb {
            background-color: ${color};
        }
    `;
};

/**
 * Generiert alle notwendigen CSS-Regeln des ReportView-Steuerelements.
 */
const ReportViewStyleGenerator = () => {
    let reportTypes = ColorMappings.getReportTypes();
    let defaultStylesheet = "";

    defaultStylesheet += generateToolbarButtonStyle("default");
    defaultStylesheet += generateBreadcrumbStyle("default");
    defaultStylesheet += generateTableStyle("default");
    defaultStylesheet += generateScrollbarStyle("default")
    defaultStylesheet += generateTemplateEditorStyle("default");

    injectStyles("report__view-default", defaultStylesheet);

    reportTypes.forEach(reportType => {
        let reportStylesheet = generateToolbarButtonStyle(reportType);

        reportStylesheet += generateBreadcrumbStyle(reportType);
        reportStylesheet += generateTableStyle(reportType);
        reportStylesheet += generateScrollbarStyle(reportType);
        reportStylesheet += generateToolbarSelectStyle(reportType);
        reportStylesheet += generateTemplateEditorStyle(reportType);

        injectStyles(reportType, reportStylesheet);
    });
};

export default ReportViewStyleGenerator;
