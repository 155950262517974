export enum DisplayMode {
    Default = 0,
    Configure = 1,
    New = 2
}

export enum EditMode {
    Color = 0,
    New = 1,
    None = 2
}
