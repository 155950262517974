<script lang="ts" setup>
import type { PropType } from "vue";
import { computed, onMounted, ref, watch } from "vue";
import { injectUtility } from "@/utils/utility.helper";
import { kebabCase } from "lodash";
import { slugify } from "@/utils/string";
import getId from "./id";
// @ts-ignore
import Icon from "@/components/UI/Icon.vue";
import useCacheUtils from "@/mixins/composition.cache";

const $cache = injectUtility("Cache");
const $page = injectUtility("Page");
const $reactiveCache = injectUtility("ReactiveCache");

/**
 * Der Container des Menüelements.
 */
const container = ref<HTMLElement>();

/**
 * Die Komponenten Id
 */
const componentId = ref<string | undefined>();

/**
 * Die Cache-Api
 */
const cache = ref<ICacheApi | undefined>();

const props = defineProps({
    /**
     * Die Daten des Menüs.
     *
     * @type {{title: String, subSections: []}}
     */
    menu: {
        type: Object as PropType<TLeftMenuItem>,
        default() { return {}; }
    },

    /**
     * Der Index des Menüs bzw Menügruppe. Dieser entspricht der vertikalen Index-Position
     * im Dom (top-down).
     *
     * @type {Number}
     */
    index: {
        type: Number,
        default: -1
    },

    /**
     * Gibt an, ob es sich bei diesem Menü um die erste Menügruppe des aktiven Moduls handelt.
     *
     * @type {Boolean}
     */
    first: {
        type: Boolean,
        default: false
    },

    /**
     * Liefert den geöffnet-Status des Elternmenüs (=eigene Menügruppe).
     *
     * @type {Boolean}
     */
    visible: {
        type: Boolean,
        default: false
    }
});

/**
 * Liefert die Id der aktiven Seite.
 */
const pageId = computed(() => $page.top.id());

/**
 * Liefert eine Id zur Identifizierung eines geöffneten
 * (nicht zu verwechseln mit einem aktiven) Menüs im Modul.
 */
const toggleId = computed(() => {
    if (!container.value)
        return "";

    if (!container.value.parentNode?.childNodes)
        return "";

    const index = Array
        .from(container.value.parentNode.childNodes as NodeListOf<HTMLElement>)
        .filter(child => child.tagName && child.tagName.toLowerCase() === "li")
        .indexOf(container.value);

    return `${slugify(props.menu.title)}.${index}`;
});

/**
 * Stellt die Untermenüs der Menügruppe zur Verfügung.
 */
const submenus = computed(() => props.menu.subSections || []);

/**
 * Prüft, ob das Menü aktiv ist.
 */
const active = computed(() => $page.left.id() === componentId.value);

/**
 * Prüft, ob das Menü geöffnet ist.
 */
const opened = computed(() => cache.value?.reactive.get("opened") ?? false);

/**
 * Sektion laden, wenn es sich hierbei nicht um eine Menügruppe handelt.
 */
const activate = () => {
    if (submenus.value.length)
        return;

    if (!componentId.value)
        return;

    if ($page.left.id() === componentId.value)
        return;

    $page.left.activate(componentId.value, props.menu);
};

/**
 * Initialisiert das Menü.
 */
const init = async () => {
    if (!container.value)
        return;

    componentId.value = `${$page.top.id()}.${slugify(props.menu.title)}.${getId(container.value, "")}`;

    cache.value = useCacheUtils({
        componentId: {
            value: componentId.value,
        },
        cache: $cache,
        reactiveCache: $reactiveCache,
    });


    /**
     * Den Geöffnet-Status des Menüs setzen:
     */
    if (!props.menu.isCollapsible || submenus.value.length && cache.value.reactive.get("opened") === undefined && ($page.left.menus().find(menu => menu.subSections.length) || { toggleId: undefined }).toggleId === toggleId.value) {
        cache.value.reactive.set("opened", true);
        cache.value.reactive.commit();
    }

    /**
     * Den Aktiv-Status des Menüs setzen:
     */
    if (!cache.value.id($page.top.id()).get("activating") && (!$page.top.hasActive() && props.index === 0 || $page.left.id() === componentId.value)) {
        cache.value.id($page.top.id()).set("activating", true);

        await $page.left.activate(componentId.value, props.menu);

        cache.value.id($page.top.id()).set("activating", true);
    }
};

/**
 * Öffnet/Schließt das Menü.
 */
const toggle = () => {
    if (!props.menu.isCollapsible)
        return;

    if (!submenus.value.length)
        return;

    const menus = cache.value?.reactive.id(pageId.value).get();

    Object
        .keys(menus)
        .forEach(menu => {
            cache.value?.reactive.id(`${pageId.value}.${menu}.${Object.keys(menus[menu])[0]}`).set("opened", false);
        });

    cache.value?.reactive.set("opened", !opened.value);
    cache.value?.reactive.commit();
};

/**
 * Liefert die Bezeichnung des Menüs für das Logging.
 *
 * @returns Die Bezeichnung des Menüs für das Logging.
 */
const getApmName = () =>
    kebabCase(`menu-${props.menu.title}`);

/**
* Emittiert die hinzuzufügende Höhe an die Elternkomponente zur Animation des Akkordion-Menüs,
* sobald dieses Menü in den Dom eingehangen wurde.
*/
onMounted(() => {
    if (submenus.value.length)
        cache.value?.reactive.set("opened", false);

    init();
});

/**
 * Überwacht den Wechsel des Menüs.
 */
watch(
    () => props.menu,
    () => {
        init();
        cache.value?.reactive.get("active") && activate();
    },

    { flush: "post" }
);
</script>

<template lang="pug">
//- Eine Menüelement
li.section__link-item(
    ref="container"

    :class="{'section__link-item--divider': menu.isDivider, 'section__link-item--collapsible': !menu.isDivider && submenus.length > 0, 'section__link-item--opened': opened, 'section__link-item--visible': visible, 'section__link-item--active': active}"
)
    a.section__link-trigger(
        v-if="!menu.isDivider"

        :class="{'section__link-trigger--disabled': submenus.length > 0 && !menu.isCollapsible}"
        :name="getApmName()"

        @click.prevent="toggle(); activate();"
    )
        Icon(
            v-if="!menu.isDivider && submenus.length === 0"

            :icon="menu.icon"
        )

        span {{ menu.title }}

        svg.toggle(
            v-if="!menu.isDivider && submenus.length > 0 && menu.isCollapsible"

            viewBox="0 -1 14 9"
            width="14"
            height="8"
        )
            path(
                d="M0 7 L7 0 M6 0 L13 7"

                stroke="#FFFFFF"
                stroke-width="2"
            )

    LeftMenu(
        v-if="!menu.isDivider && submenus.length > 0"

        :opened="opened"
        :menus="submenus"
    )

    span(
        v-if="menu.isDivider"
    ) {{ menu.title }}
</template>

<style lang="scss">
.section__link {
    &-item {
        position: relative;
        float: left;
        clear: left;
        width: 100%;
        height: auto;
        cursor: pointer;
        display: block;
        border: none;

        /* Element hover, selected */
        &--active:not(.section__link-item--collapsible),
        &:hover:not(.section__link-item--collapsible) {
            >.section__link-trigger {
                background-color: var(--color-navleft-active-bg);
                transition: background-color 250ms ease-out;

                &:before {
                    transform: translate3d(0, 0, 0);
                    transition: transform 250ms ease-out;
                }

                /** BEM - Block: Icon */
                .icon {

                    /** BEM - Element: Der Html Container für das darzustellende Icon */
                    &__html {

                        /** HTML - Element: SVG Element (Der Icon-Inhalt) */
                        >svg {

                            polygon,
                            path,
                            rect {
                                fill: var(--color-navleft-active-icon) !important;

                                transition: fill 250ms ease-out;
                            }
                        }
                    }
                }

                span {
                    color: var(--color-navleft);
                }
            }
        }

        &--selected {
            >.section__link-trigger {
                background-color: transparent;
            }
        }

        &--disabled {
            cursor: default;
        }

        /* Collapsible = Menu mit Untermenüpunkten */
        &--collapsible {
            >.section__link {
                &-trigger {
                    &::before {
                        display: none;
                    }

                    >span {
                        font-family: "TheMix Caps", Arial, sans-serif;
                        font-size: 82%;
                        font-weight: 800;
                        color: var(--color-navleft-collapsible);
                        width: 76%;
                        padding-left: 22px;

                        transition: color 250ms ease-out;
                    }

                    >svg.toggle {
                        position: absolute;
                        top: calc(50% - 4px);
                        right: 25px;

                        transform: rotate3d(1, 0, 0, 180deg) scale(0.9);

                        path {
                            stroke: var(--color-navleft-collapsible);
                            transform: scale(1.1);
                            transition: stroke 250ms ease-out;
                        }
                    }

                    &:hover:not(.section__link-trigger--disabled) {
                        >span {
                            color: var(--color-navleft-collapsible-hover);

                            transition: color 250ms ease-out;
                        }

                        >svg.toggle {
                            path {
                                stroke: var(--color-navleft-collapsible-hover);

                                transition: stroke 250ms ease-out;
                            }
                        }
                    }
                }

                &-menu {
                    height: 0;

                    &.section__link-menu--opened {
                        height: auto;
                    }
                }
            }

            &.section__link-item--opened {
                >.section__link-trigger {
                    >svg.toggle {
                        transform: rotate3d(1, 0, 0, 0deg) scale(0.9);
                    }
                }
            }
        }

        &--divider {
            cursor: default;
            display: flex;
            height: 32px;
            align-items: center;
            margin-top: 16px;

            &::before {
                content: "";
                position: absolute;
                right: 0px;
                top: 50%;
                transform: translateY(-50%);
                height: 1px;
                width: calc(100% - 50px);
                z-index: -1;
                background-color: var(--color-navleft-collapsible);
            }

            span {
                font-family: "TheMix Caps", Arial, sans-serif;
                font-size: 67%;
                font-weight: 800;
                color: var(--color-navleft-collapsible);
                background-color: var(--color-navleft-bg);
                padding: 0px 12px 0 24px;
            }
        }
    }

    &-trigger {
        position: relative;
        float: left;
        width: 100%;
        display: block;
        color: var(--color-navleft);
        background-color: transparent;
        transition: background-color 250ms ease-out;

        &::before {
            content: '';
            position: absolute;
            left: 0px;
            top: 0px;
            height: 100%;
            width: 3px;
            transform: translate3d(-3px, 0px, 0px);
            background-color: var(--color-navleft-active-icon);
            transition: transform 250ms ease-out;
        }

        i,
        span {
            position: relative;
            float: left;
            line-height: 50px;
        }

        span {
            width: calc(100% - 86px);
            text-align: left;
            overflow: hidden;
            font-family: inherit;
            font-weight: 500;
            text-overflow: ellipsis;
            font-size: 0.86em;
            white-space: nowrap;
        }

        /** BEM - Element: Der Inline Container für das darzustellende Icon */
        .icon {
            /** BEM - Element: Der Icon-Wrapper */
            &__wrapper {
                width: 18px;
                height: 100%;
                margin: 0px 18px 0px 24px;
                font-size: 0.8125em;
                height: 50px;
            }

            /** BEM - Element: Der Html Container für das darzustellende Icon */
            &__html {
                height: 22px;
                width: 22px;

                text-align: center;

                /** HTML - Element: SVG Element (Der Icon-Inhalt) */
                >svg {
                    polygon,
                    path,
                    rect {
                        fill: var(--color-navleft) !important;

                        transition: fill 250ms ease-out;
                    }
                }
            }
        }
    }
}
</style>
