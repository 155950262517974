<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';

const container = ref<HTMLElement>()

const props = defineProps<{
    html: HTMLElement
}>();

/**
 * Hängt ein {@link HTMLElement} in die Komponente als Kindelement ein
 * 
 * @param html Das einzuhängende {@link HTMLElement}
 */
const render = async (html?: HTMLElement) => {
    if (!container.value)
        return;
    
    container.value.innerHTML = "";

    if (!html)
        return;

    container.value.appendChild(html);
};

watch(
    () => props.html,
    () => render(props.html)
);

onMounted(() => render(props.html));

</script>

<template lang="pug">
div(
    ref="container"
)
</template>
