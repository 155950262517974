import get from "lodash/get";

/**
 * Stellt eine Funktionssammlung zur Verfügung, um Service-Zieladressen gemäß Gschwind-Konvention
 * aufzulösen.
 *
 * @param services Die zugrundeliegenden Service-Zieladressen.
 * @param $config Die zugrundeliegende Konfiguration der Service-Basisadressen.
 */
export default function (services: IService, $config: IConfigApi) {
    const baseUrls = {
        data: get($config.get("services"), `${get(services, "data.serviceType", "crm")}.baseUrl`, ""),
        view: get($config.get("services"), `${get(services, "view.serviceType", "crm")}.baseUrl`, "")
    };

    return  {
        /**
         * Bereich Daten
         */
        Data: {
            /**
             * Liefert die Url zur Erzeugung eines neuen Objekts.
             */
            create: () => `${baseUrls.data}${get(services, "data.urls.create", "")}`,

            /**
             * Liefert die Url zur Aktualisierung eines bestehenden Objekts.
             */
            update: () => `${baseUrls.data}${get(services, "data.urls.update", "")}`,

            /**
             * Liefert die Url zum Löschen eines Objekts.
             */
            delete:() => `${baseUrls.data}${get(services, "data.urls.delete", "")}`,

            /**
             * Liefert die Url zur Abfrage eines einzelnen bestehenden Objekts.
             *
             * HINWEIS: Hierbei handelt es sich um einen GET-Request.
             */
            getItem: () => `${baseUrls.data}${get(services, "data.urls.read", "")}`,

            /**
             * Liefert die Url zur Abfrage meherer bestehender Objekte.
             *
             * HINWEIS: Hierbei handelt es sich um einen POST-Request.
             */
            getItems: () => `${baseUrls.data}${get(services, "data.urls.query", "")}`
        },

        /**
         * Bereich View
         */
        View: {
            /**
             * Liefert die Url zur Abfrage der Konfiguration einer View (derzeitig nur in der DataView für die Toolbar-Konfiguration).
             */
            config: () => `${baseUrls.view}${get(services, "view.urls.config", "")}`,

            /**
             * Liefert die Url zur Abfrage eines Formulars zur Erzeugung eines neuen Objekts.
             */
            create: () => `${baseUrls.view}${get(services, "view.urls.new", "")}`,

            /**
             * Liefert die Url zur Abfrage eines Formulars zur Bearbeitung eines neuen Objekts.
             */
            edit: () => `${baseUrls.view}${get(services, "view.urls.edit")}`
        }
    };
}
