import flow from "lodash/flow";
import groupBy from "lodash/groupBy";
import last from "lodash/last";
import map from "lodash/map";
import maxBy from "lodash/maxBy";
import minBy from "lodash/minBy";
import { OhlcData, SingleValueData } from "lightweight-charts";
import SecurityDataPoint from "./security-data-point";
import moment from "moment";
export type Timeframe = "auto" | "all" | "year" | "yeartodate" | "halfyear" | "quarter" | "month";

const SamplingMap:[string, Function][] = [
    ["month", (days:number) => days <= 31],
    ["quarter", (days:number) => days <= 93],
    ["halfyear", (days:number) => days <= 183],
    ["year", (days:number) => days <= 730],
    ["all", () => true]
];

const SeriesParametersHelper = new Map<string, {sampling: Function}>([
    ["all", {
        sampling: (d:moment.Moment) => d.startOf("month")
    }],
    ["year", {
        sampling: (d:moment.Moment) => d.startOf("week")
    }],
    ["halfyear", {
        sampling: (d:moment.Moment) => d.startOf("week")
    }],
    ["quarter", {
        sampling: (d:moment.Moment) => d.startOf("week")
    }],
    ["month", {
        sampling: (d:moment.Moment) => d.startOf("week")
    }]
]);


export default class SecurityInfo {
    prices;

    constructor(prices: {d: Date, v: number}[]) {
        this.prices = prices;
    }

    static daysYdt() {
        const now = moment(moment.now());
        const startOfYear = moment(moment().startOf("year"));

        return now.diff(startOfYear, "days");
    }

    daysSinceStart() {
        const now = moment(moment.now());
        let firstDate = this.prices
            .map(({d}) => d)
            .sort((a, b) => new Date(a) < new Date(b) ? -1 : 1)
            .find(date => date);

        if (firstDate)
            return now.diff(moment(firstDate), "days");

        return SecurityInfo.daysYdt();
    }

    createSeries(timeframe: Timeframe, type: "Area" | "Candlestick") {
        timeframe = timeframe || "month";
        type = type || "Candlestick";

        let parameters:{ sampling: Function } | undefined;


        if (type === "Candlestick" && timeframe === "yeartodate")
            timeframe = SamplingMap.find(([s, fnc]) => fnc(SecurityInfo.daysYdt()))!.find(v => v) as Timeframe;
        else if (type === "Candlestick" && timeframe === "all")
            timeframe = SamplingMap.find(([s, fnc]) => fnc(this.daysSinceStart()))!.find(v => v) as Timeframe;

        parameters = type === "Area"
            ? { sampling: (d:moment.Moment) => d.startOf("day") }
            : SeriesParametersHelper.get(timeframe);

        if (!parameters)
            return {
                type: "Area"
            };

        const convert = flow(
            series => map(series, dp => SecurityInfo.parse(dp)),
            series => groupBy(series, dp => {
                const d = SecurityInfo.toBusinesDay(parameters!.sampling(dp.date));

                return `${d.year}-${d.month}-${d.day}`;
            }),
            groups => map(groups, dp => {
                if (type === "Candlestick")
                    return <OhlcData>{
                        time: SecurityInfo.toBusinesDay(parameters!.sampling(dp[0].date)),
                        open: dp[0].value,
                        high: maxBy(dp, val => val.value)?.value,
                        low: minBy(dp, val => val.value)?.value,
                        close: last(dp)?.value
                    };

                return <SingleValueData>{
                    time: SecurityInfo.toBusinesDay(parameters!.sampling(dp[0].date)),
                    value: dp[0].value
                };
            })
        );

        return {
            type,
            data: convert(this.prices)
        };
    }

    static parse(dp: SecurityDataPoint) {
        return {
            date: moment(new Date(dp.d)),
            value: Number.parseFloat(dp.v)
        };
    }

    static toBusinesDay(dt:moment.Moment) {
        return {
            year: dt.year(),
            month: dt.month() + 1,
            day: dt.date()
        };
    }
}
