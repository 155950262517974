import { isKeyValuePair } from "@/utils/typeGuards";
import assign from "lodash/assign";
import cloneDeep from "lodash/cloneDeep";
import each from "lodash/each";
import FilterUtils from "@/utils/Filter";
import get from "lodash/get";
import set from "lodash/set";

/**
 * Liefert Hilfsfunktionen für den Zugriff der Parameter der übergebenen Komponenten-Id.
 *
 * @param {{componentId: {value: String}, model: Object, pageId: {value: String}, menuId: {value: String}}} options
 *
 * @returns {IParameterUtils}
 */
export default ({componentId, model, pageId, menuId}) => {
    const utils = {
        /**
         * Löscht alle Parameter einer Seite aus den Cache.
         *
         * @param {String} pageId
         */
        remove(pageId) {
            model.remove(`menu.${pageId}`);
            model.remove(`page.${pageId}`);
            model.remove(`component.${pageId}`);

            model.commit();
        },

        /**
         * Ersetzt Parameterwerte in einem String oder JSON-Objekt.
         *
         * @param {String|Object} subject
         * @param {FilterModel[]|Record<string, unknown>} custom
         * @param {Boolean} flatten
         *
         * @returns {String}
         */
        resolve(subject, custom, flatten) {
            if (typeof subject === "number")
                return subject;

            if (typeof subject === "string") {
                custom = isKeyValuePair(custom) ? custom : FilterUtils.toJson(custom);

                const combined = assign(this.combined(), custom = custom || {});

                (subject.match(/\{(\b[^}]*)\}/ig) || []).forEach(match => {
                    subject = subject.replace(match, get(combined, match.replace("{", "").replace("}", ""), null));
                });

                return subject;
            }

            let prepared = {};

            each(subject, (value, key) => {
                if (!flatten)
                    set(prepared, key, this.resolve(value, custom));
                else
                    prepared[key] = this.resolve(value, custom);
            });

            return prepared;
        },

        /**
         * Erzeugt aus einem Objekt ein Query-String und ersetzt alle Platzhalter
         * mit den Seitenparameterwerten.
         *
         * @param {Object} subject
         *
         * @returns {String}
         */
        toQuery(subject) {
            const mappings = {"number" : "intParser", "boolean": "boolParser", "object": "objectParser"};
            const conditionMappings = {"equals" : " == ", "notEquals" : " != ", "greaterThan" : " > ", "lessThan" : " < ", "greaterEquals" : " >= ", "lessEquals" : " <= "};

            const parser = {
                defaultParser: (key, value) =>
                    key.indexOf("~=") === -1 ? ` == "${value}"` : `"${value}"`,

                intParser: (key, value) =>
                    key.indexOf("~=") === -1 ? ` == ${parseInt(value, 10)}` : `${parseInt(value, 10)}`,

                boolParser: (key, value) =>
                    key.indexOf("~=") === -1 ? ` == ${value}` : `${value}`,

                objectParser: (key, objValue) =>
                    key.indexOf("~=") === -1
                        ? `${conditionMappings[objValue.operator || "equals"]}"${objValue.value || null}"`
                        : `"${objValue.value || null}"`
            };

            let query = "";

            each(subject, (value, key) => {
                const tOf = Object.keys(mappings).indexOf(typeof value);
                const method = tOf > -1 ? parser[mappings[typeof value]] : parser.defaultParser;

                query += value === null ? `${key} && ` : `${key}${method(key, value)} && `;
            });

            if (query.length > 0)
                query = query.slice(0, -4);

            return this.resolve(query);
        },

        page: {
            /**
             * Liefert die Parameter der Seite.
             *
             * @param {String} key
             *
             * @returns {Object}
             */
            get: key => {
                const value = model.get(`page.${pageId.value}${key ? `.${key}` : ""}`);

                return value !== undefined ? value : {};
            },

            /**
             * Setzt einen Parameterwert der aktiven Seite.
             *
             * @param {String} key
             * @param {Any} value
             */
            set: (key, value) => {
                model.set(`page.${pageId.value}.${key}`, value);
                model.commit();
            }
        },

        menu: {
            /**
             * Liefert Parameter des aktiven Menüs.
             *
             * @param {String} key
             *
             * @returns {Object}
             */
            get: key => {
                const value = model.get(`menu.${pageId.value}.${menuId.value}${key ? `.${key}` : ""}`);

                return value !== undefined ? value : {};
            },

            /**
             * Setzt einen Parameterwert des Menüs.
             *
             * @param {String} key
             * @param {Any} value
             */
            set: (key, value) => {
                if (value !== null && value !== undefined)
                    model.set(`menu.${pageId.value}.${menuId.value}.${key}`, value);
                else
                    model.remove(`menu.${pageId.value}.${menuId.value}.${key}`);

                model.commit();
            }
        },

        component: {
            /**
             * Liefert die Parameter der Komponente.
             *
             * @param {String} key
             *
             * @returns {Object}
             */
            get: key => {
                const value = model.get(`component.${componentId.value}${key ? `.${key}` : ""}`);

                return value !== undefined ? value : {};
            },

            /**
             * Setzt einen Parameterwert der Komponente.
             *
             * @param {String} key
             * @param {Any} value
             */
            set: (key, value) => {
                model.set(`component.${componentId.value}.${key}`, value);
                model.commit();
            }
        },

        /**
         * Verschmelzt die Parameter und gibt diese zurück.
         *
         * @param {String} [key=null] [optional] Der ParameterSchlüssel.
         *
         * @returns {Object} Die verschmelzten Parameter.
         */
        combined: key => {
            let pageParams = cloneDeep(utils.page.get());
            let menuParams = cloneDeep(utils.menu.get());
            let componentParams = cloneDeep(utils.component.get());
            let combined = assign({}, pageParams, menuParams, componentParams);

            return key ? combined[key] : combined;
        }
    };

    return utils;
};
