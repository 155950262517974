/**
 * Liefert einen gecachten Wert.
 *
 * @param {ICache} $cache Die Cache-Bibliothek.
 * @param {string} id Die Id des Caches.
 * @param {string} key Der Zugriffsschlüssel des Caches.
 *
 * @returns {any} Der Wert des Caches.
 */
const get = ($cache, id, key) => {
    return $cache.get(id, key, undefined);
};

/**
 * Setzt einen zu cachenden Wert.
 *
 * @param {ICache} $cache Die Cache-Bibliothek.
 * @param {string} id Die Id des Caches.
 * @param {string} key Der Zugriffsschlüssel des Caches.
 * @param {any} value Der zu setzende Wert des Caches.
 */
const set = ($cache, id, key, value) => {
    $cache.set(id, key, value);
};

/**
 * Ein Mixin zur Bereitstellung des Caches der einbindenden Komponente.
 */
export default {
    data() {
        return {};
    },

    computed: {
        cache() {
            return {
                /**
                 * Liefert alle Einträge des Caches
                 *
                 * @returns Die Einträge des Caches
                 */
                getAll: () => this.$cache.getAll(),

                /**
                 * Liefert den gecachten Wert eines Cache-Schlüssels der Komponente.
                 *
                 * @param {string} key Der Cache-Schlüssel.
                 *
                 * @returns {any} Der Wert des Caches.
                 */
                get: key => key ? get(this.$cache, this.componentId, key) : get(this.$cache, this.componentId),

                /**
                 * Setzt den Wert des Caches eines Cache-Schlüssels der Komponente.
                 *
                 * @param {string} key Der Cache-Schlüssel.
                 * @param {any} value Der zu setzende Wert des Caches.
                 */
                set: (key, value) => {
                    set(this.$cache, this.componentId, key, value);
                },

                /**
                 * Liefert die Cache-Methoden einer individuellen Id.
                 *
                 * @param {string} id Die individuelle Id.
                 */
                id: id => {
                    return {
                        /**
                         * Liefert den gecachten Wert eines Cache-Schlüssels der Komponente.
                         *
                         * @param {string} key Der Cache-Schlüssel.
                         *
                         * @returns {any} Der Wert des Caches.
                         */
                        get: key => key ? get(this.$cache, id, key) : get(this.$cache, id),

                        /**
                         * Setzt den Wert des Caches eines Cache-Schlüssels der Komponente.
                         *
                         * @param {string} key Der Cache-Schlüssel.
                         * @param {any} value Der zu setzende Wert des Caches.
                         */
                        set: (key, value) => set(this.$cache, id, key, value)
                    };
                },

                /**
                 * Liefert Zugriffsmethoden für einen reaktiven Cache (btr. VueJs-Reactivity).
                 */
                reactive: {
                    /**
                     * Liefert alle Einträge des reaktiven Caches
                     *
                     * @returns Die Einträge des reaktiven Caches
                     */
                    getAll: () => this.$reactiveCache.getAll(),

                    /**
                     * Liefert den gecachten Wert eines Cache-Schlüssels der Komponente.
                     *
                     * @param {string} key Der Cache-Schlüssel.
                     *
                     * @returns {any} Der Wert des Caches.
                     */
                    get: key => key ? get(this.$reactiveCache, this.componentId, key) : get(this.$reactiveCache, this.componentId),

                    /**
                     * Setzt den Wert des Caches eines Cache-Schlüssels der Komponente.
                     *
                     * @param {string} key Der Cache-Schlüssel.
                     * @param {any} value Der zu setzende Wert des Caches.
                     */
                    set: (key, value) => set(this.$reactiveCache, this.componentId, key, value),

                    /**
                     * Übernimmt die Cacheänderungen.
                     */
                    commit: () => this.$reactiveCache.commit(),

                    /**
                     * Liefert die Cache-Methoden einer individuellen Id.
                     *
                     * @param {string} id Die individuelle Id.
                     */
                    id: id => {
                        return {
                            /**
                             * Liefert den gecachten Wert eines Cache-Schlüssels der Komponente.
                             *
                             * @param {string} key Der Cache-Schlüssel.
                             *
                             * @returns {any} Der Wert des Caches.
                             */
                            get: key => key ? get(this.$reactiveCache, id, key) : get(this.$reactiveCache, id),

                            /**
                             * Setzt den Wert des Caches eines Cache-Schlüssels der Komponente.
                             *
                             * @param {string} key Der Cache-Schlüssel.
                             * @param {any} value Der zu setzende Wert des Caches.
                             */
                            set: (key, value) => set(this.$reactiveCache, id, key, value)
                        };
                    }
                }
            };
        }
    }
};
