import filter from "lodash/filter";
import get from "lodash/get";
import map from "lodash/map";
import tinycolor from "tinycolor2";
import BaseChart from "./BaseChart";
import ColorGenerator from "../ColorGenerator";

/**
 * Eine Klase zur Generierung der Highcharts-Optionen einer Bar-Chart.
 */
export default class BarChart extends BaseChart {
    /**
     * C'Tor
     *
     * @param {Function} caller Die aufrufende Funktion.
     */
    constructor(caller) {
        super(caller);

        this.level = 1;
    }

    /**
     * Bereitet die darzustellenden Daten der Chart vor.
     *
     * @param {Array} data Die Rohdaten
     *
     * @returns {Object} Die Klasseninstanz.
     */
    prepareSeries(data) {
        let rows = (filter(data, item => item.level === this.level && item.operator !== "none") || []);
        let addRecommendation = (rows[0] || {standardName: false}).standardName !== undefined;
        let colorsToFill = ColorGenerator(this.baseColors[0], this.baseColors[1], 2);
        let recommendation = this.caller.parameters().combined().recommendationIndex;

        (rows || []).forEach(row => {
            let prepareData = (nameKey, valueKey, stackPosition) => {
                let recommendationTitle = null;

                if (recommendation && recommendation !== "0")
                    recommendationTitle = rows[0].recommendationName || `Empfehlung ${recommendation}`;

                let name = recommendationTitle && stackPosition !== "left" ? recommendationTitle : (row[nameKey] || "");

                return {
                    name: name,
                    data: map(get(row, "cells", []), cell => {
                        let template = get(cell, "template", "Currency");
                        let cellValue = this.caller.formatCell({value: cell[valueKey], template: template});
                        let castedValue = template === "Currency" ? parseInt(cellValue.replace(/\./g, ""), 10) : parseFloat(cellValue.replace(/,/g, "."));

                        return {
                            name: name,
                            y: castedValue,
                            drilldown: true,
                            stack: name
                        };
                    }),
                    stack: stackPosition,
                    color: `#${tinycolor(colorsToFill[stackPosition === "left" ? 0 : 1]).toHex().toUpperCase()}`,
                    animation: this.animationDuration
                };
            };

            if (addRecommendation) {
                this.series.push(prepareData("standardName", "standardValue", "left", this.baseColors[0]));
                this.series.push(prepareData("name", "value", "right", `#${tinycolor(colorsToFill[1]).toHex().toUpperCase()}`));
            } else
                this.series.push(prepareData("name", "value", "left", this.baseColors[0]));
        });

        return this;
    }

    /**
     * Liefert die Highcharts-Optionswerte zur Darstellung des Diagramms.
     *
     * @returns {Highchart.options} Die Highchart-Optionswerte.
     */
    getPrepared() {
        return {
            chart: {
                type: "column",
                inverted: false
            },

            plotOptions: {
                series: {
                    stacking: "normal"
                }
            },

            legend: {
                symbolRadius: 0,
                itemStyle: {
                    fontWeight: "normal"
                }
            },

            sourceType: this.sourceType,
            tooltip: this.tooltip,
            series: this.series
        };
    }
}
