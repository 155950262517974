import type Select from "./index";

export const store = {
    /**
     * Ein Wert, der angibt, ob die Dokumenten-Ereignisse für alle Selects
     * bereits initialisiert wurden.
     */
    listener: false,

    /**
     * Ein Container aller registierten und auf der Seite eingehangenen Selects.
     */
    lists: [] as { id: string, select: Select }[]
};
