<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref, watch } from "vue";
import { nodeRendered } from "@/utils/dom";
import PendingAnimator from "@/components/UI/Pending/utils/animations";

const txtSpans = ref<HTMLElement>();

let pendingAnimator: PendingAnimator | undefined;

const props = defineProps({
    /**
     * Zeit mit der die Animation verzögert gestartet werden soll.
     */
    delay: {
        type: Number,
        default: 0
    },

    /**
    * Die von der Elternkomponente übergebenen Nachrichten.
    */
    message: {
        type: String,
        default: ""
    },

    /**
    * Wert, der angibt, ob die Pending-Komponente angezeigt werden soll.
    */
    pending: {
        type: Boolean,
        default: false
    },
});

watch(
    () => props.message,
    () => {
        pendingAnimator?.setMessage(props.message)
    }
);

onMounted(async () => {
    await nodeRendered(txtSpans.value as Node);

    if (!txtSpans.value)
        return;

    txtSpans.value.style.setProperty('--anim-delay', `${props.delay}ms`);

    pendingAnimator = new PendingAnimator(txtSpans.value);
    pendingAnimator.setMessage(props.message);
    pendingAnimator.play();
});

onBeforeUnmount(() => pendingAnimator?.destroy());

</script>

<template lang="pug">
.messages(
    ref="txtSpans"
)
    span.out
    span.in

</template>

<style lang="scss" scoped>
.messages {
    --anim-delay: 1000ms;

    padding: 0px;
    overflow: visible;
    color: var(--color-foreground);
    font-size: 0.8em;
    font-weight: 500;

    list-style-type: none;
    position: relative;
    margin: 0;

    height: 25.63px;
    text-align: center;
    white-space: nowrap;

    opacity: 0;
    animation: fadeIn 500ms var(--anim-delay) forwards;

    .out,
    .in {
        position: absolute;
        backface-visibility: hidden;
        transform-style: preserve-3d;
    }

    .out {
        transform: translateX(-50%) scale(1);
    }

    .in {
        opacity: 0;
        transform: translateX(-50%) translateY(100%);
    }

    &.fade-in-out {
        .in {
            animation: fade-from-bottom var(--duration) forwards linear;
        }

        .out {
            animation: fade-out-backwards var(--duration) forwards linear;
        }
    }

    &.blur {
        .out {
            animation: blur-anim var(--duration) linear forwards;
        }
    }
}

@keyframes fade-from-bottom {
    0% {
        opacity: 0;
        transform: translateX(-50%) translateY(100%);
    }

    25% {
        opacity: 0;
        transform: translateX(-50%) translateY(100%) scale(1);
        animation-timing-function: cubic-bezier(.42, .82, .39, .98);
    }

    50% {
        opacity: 1;
        transform: translateX(-50%) translateY(0%);
    }

    100% {
        opacity: 1;
        transform: translateX(-50%) translateY(0%);
    }
}

@keyframes fade-out-backwards {
    0% {
        transform: translateX(-50%) scale(1);
    }

    50% {
        opacity: 0;
        transform: translateX(-50%) scale(.5);

    }

    100% {
        opacity: 0;
        transform: translateX(-50%) scale(.5);
        animation-timing-function: cubic-bezier(.42, .82, .39, .98);
    }
}

@keyframes blur-anim {
    0% {
        filter: blur(0);
        opacity: 1;
        transform: translateX(-50%);
        transition: filter 1s ease-out;
    }

    50% {
        filter: blur(4px);
        opacity: .5;
        transform: translateX(-50%);
        transition: filter 1s ease-out;
    }

    75% {
        filter: blur(0);
        opacity: .8;
        transform: translateX(-50%);
        transition: filter 1s ease-out;
    }

    100% {
        filter: blur(0);
        opacity: 1;
        transform: translateX(-50%);
        transition: filter 1s ease-out;
    }
}

</style>
