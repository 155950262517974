import $ from "@/utils/dom";
import Scroller from "@/utils/scroll/index";

/**
 * Navigiert die Sicht nach link bzw rechts.
 *
 * @param {Number<(-1|1)>} direction Die Navigationsrichtung.
 */
export function navigateVerticalByArrow (direction) {
    /**
     * @type {Scroller}
     */
    const scrollInstance = this.ctx.scrollInstance;

    if (!scrollInstance)
        return;

    scrollInstance.scrollHorizontal(scrollInstance.scrollPosition("horizontal") + (direction * 16));
}

/**
 * Scrollt die Sicht soweit, dass die aktuell gehoverte bzw- selektierte
 * Zeile sichbar wird, sofern sich diese ausserhalb des Sichtfelds befindet.
 *
 * @param {Event} e Das Ereignis-Objekt.
 * @param {"hover"|"preselect"|"select"} [stateType="hover"] Die Art der Navigation bzw. Selektion.
 */
export function updateScrollView (e, stateType)  {
    e.preventDefault();

    /**
     * @type {Scroller}
     */
    const scrollInstance = this.ctx.scrollInstance;

    const stateTypeMappings = {
        preselect: this.state.ctrl.position,
        select: this.state.select.position,
        shiftselect: this.state.shift.position
    };

    // Die aktuell gehoverte Zeile:
    let row = $(this.tableBody).find(`tr:nth-child(${stateTypeMappings[stateType] + 1})`).get(0);

    if (row && scrollInstance) {
        // Dom Elemente:

        // Tabellenheader Höhe:
        let thHeight = this.tableHead.getBoundingClientRect().height;

        // Zeilenboundings:
        let rowTop = row.offsetTop;
        let rowHeight = row.getBoundingClientRect().height;

        // ScrollView-Boundings:
        let scrollPos = scrollInstance.scrollPosition();
        let scrollHeight = scrollInstance.height();
        let scrollTo = scrollPos;

        /** Zeile befindet sich unterhalb des sichtbaren Bereichs */
        if (rowTop + rowHeight > scrollPos + scrollHeight)
            scrollTo = rowTop + rowHeight - scrollHeight;
        /** Zeile befindet sich oberhalb des sichtbaren Bereichs */
        else if (scrollPos > rowTop - thHeight)
            scrollTo = rowTop - thHeight;

        /** Die Scrollposition soll sich ändern */
        if (scrollTo !== scrollPos)
            scrollInstance.scrollVertical(scrollTo);
    }
}
