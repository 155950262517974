import Scroller from "../../index";
import updateThumbSize from "./updateThumbSize";

/**
 * Aktualisiert die Thumbgrößen
 *
 * @this Scroller
 */
const updateThumbSizes = function() {
    if (this.isIFrame)
        return;

    ["horizontal", "vertical"]
        .forEach(axis => updateThumbSize.call(this, axis));
};

export default updateThumbSizes;
