import { escapePath, unescapePath } from "../utils/pathConverter";
import axios, { ResponseType } from "axios";
// @ts-ignore
import combineURLs from "axios/lib/helpers/combineURLs";
import fileDownload from "js-file-download";
import DriveAuth from "./DriveAuth";
import DriveDirectory from "../models/DriveDirectory";
import Uploader from "../utils/Uploader";

/**
 * Stellt eine Datei-Klasse zur Verfügung.
 */
class DriveFiles {
    /**
     * Privates Feld: Authorisierungsmethoden
     */
    _auth: DriveAuth | null = null;

    /**
     * Privates Feld: Basisadresse.
     */
    _baseUrl: string | null = null;

    /**
     * Endpunkt.
     */
    _endpoint: string;

    /**
     * Maximale Transfergröße pro Dateitransfer.
     */
    _maxTransferSize = 0;

    /**
     * C'tor
     *
     * Setzt die Basisadresse für den Api-Aufruf.
     *
     * @param options - Die Konfiguration.
     * @param options.auth - Die Authorisierungsmethoden.
     * @param options.baseUrl - Die Basisadresse.
     * @param options.maxTransferSize - Die maximale Transfergröße pro Dateitransfer.
     */
    constructor({ auth, baseUrl, maxTransferSize = (1024 * 1024 * 10), endpoint = "files" }:{auth: DriveAuth, baseUrl: string, maxTransferSize: number, endpoint?: string}) {
        this._auth = auth;
        this._baseUrl = baseUrl;
        this._endpoint = endpoint;
        this._maxTransferSize = maxTransferSize;
    }

    /**
     * Liefert die Vorschaudaten einer Datei.
     *
     * @param options - Die Konfiguration.
     * @param options.path - Der vollständige Zielpfad der Datei.
     * @param options.version - Die Versionsnummer der Datei.
     * @param options.abortController - Der AbortController.
     * @param options.responseType - Der erwartete Response-Typ.
     *
     * @returns Die Daten zu Vorschau
     */
    async preview({ path, version = 0, abortController, responseType = "arraybuffer" }:{path: string, version: number, abortController: AbortController, responseType: ResponseType}) {
        let parts = path.split("?");

        path = `${escapePath(parts[0])}${parts[1] ? `?${parts[1]}` : ""}`;

        const result = await axios.get(combineURLs(this._baseUrl, `${path}${version ? `&revision=${version}` : ""}`), {
            signal: abortController.signal,
            responseType: responseType,
            headers: {
                Authorization: `Bearer ${this._auth?.AccessToken}`
            }
        });

        return {
            code: result.status,
            content: result.data,
            contentType: result.headers["content-type"] as TMimeType
        };
    }

    /**
     * Erzeugt ein Anchor-Element und löst ein Klick zum Download der Datei aus.
     *
     * @param path - Der vollständige Zielpfad der Datei.
     * @param rev - Die Versionsnummer der Datei.
     */
    async download(path: string, rev = 0) {
        const dest = escapePath(`${this._endpoint}/${path}`);
        const response = await axios.get(combineURLs(this._baseUrl, `${dest}${rev > 0 ? `?revision=${rev}` : ""}`), {
            responseType: "blob",
            headers: {
                Authorization: `Bearer ${this._auth?.AccessToken}`
            }
        });

        const matches = (response.headers["content-disposition"] ?? "").match(/filename\*\s*=UTF-8''(.*?;|.*)/im);
        const filename = matches
            ? matches[1].replace(";", "")
            : path.split("/").reverse().splice(0, 1).join("");

        fileDownload(response.data, decodeURI(filename));
    }

    /**
     * Sendet eine Datei an ein Postfach.
     *
     * @param path Der Pfad der zu sendenden Datei.
     */
    async sendToPostbox(path: string) {
        const dest = escapePath(`digitalMailbox/${path}`);

        await axios.put(combineURLs(this._baseUrl, dest), {}, {
            headers: {
                Authorization: `Bearer ${this._auth?.AccessToken}`
            }
        });
    }

    Uploader() {
        const uploader = new Uploader({
            auth: this._auth,
            baseUrl: this._baseUrl,
            maxTransferSize: this._maxTransferSize,
        });

        uploader.endpoints.upload = this._endpoint;

        return uploader;
    }

    /**
     * Löscht Dateisystemobjekt(e).
     *
     * @param options - Die Konfiguration.
     * @param options.paths - Die vollständigen Zielpfade der Dateisystemobjekte.
     * @param options.permanent - Gibt an, ob die Dateisystemobjekte permanent gelöscht werden sollen.
     */
    async delete({ paths, permanent = false }:{paths: string[], permanent: boolean}) {
        await axios.delete(combineURLs(this._baseUrl, this._endpoint), {
            headers: {
                Authorization: `Bearer ${this._auth?.AccessToken}`
            },

            data: {
                paths,
                permanent
            }
        });
    }

    /**
     * Kopiert ein Dateisystemobjekt.
     *
     * @param options - Die Konfiguration.
     * @param options.src - Der vollständige Quellpfad des Dateisystemobjekts.
     * @param options.dest - Der vollständige Zielpfad des Dateisystemobjekts.
     * @param options.overwrite - Gibt an, ob das Dateisystemobjekt überschrieben werden soll.
     * @param options.qsa - Die optionalen Query-String-Parameter.
     */
    async copy({ src, dest, overwrite = false, qsa }: {src: string, dest: string, overwrite: boolean, qsa?: Record<string, string>}) {
        let url = combineURLs(this._baseUrl, "cp");
        let qsaString = qsa ? new URLSearchParams(qsa).toString() : "";

        if (overwrite && qsaString.length > 0)
            qsaString += `&overwrite=${overwrite}`;
        else if (overwrite)
            qsaString = `overwrite=${overwrite}`;

        if (qsaString.length > 0)
            url += `?${qsaString}`;

        await axios.post(url, {
            source: src,
            destination: dest
        }, {
            headers: {
                Authorization: `Bearer ${this._auth?.AccessToken}`
            }
        });
    }

    /**
     * Verschiebt ein Dateisystemobjekt.
     *
     * @param options - Die Konfiguration.
     * @param options.src - Der vollständige Quellpfad des Dateisystemobjekts.
     * @param options.dest - Der vollständige Zielpfad des Dateisystemobjekts.
     * @param options.overwrite - Gibt an, ob das Dateisystemobjekt überschrieben werden soll.
     * @param options.qsa - Die optionalen Query-String-Parameter.
     */
    async move({ src, dest, overwrite = false, qsa }: {src: string, dest: string, overwrite: boolean, qsa?: Record<string, string>}) {
        let url = combineURLs(this._baseUrl, "mv");
        let qsaString = qsa ? new URLSearchParams(qsa).toString() : "";

        if (overwrite && qsaString.length > 0)
            qsaString += `&overwrite=${overwrite}`;
        else if (overwrite)
            qsaString = `overwrite=${overwrite}`;

        if (qsaString.length > 0)
            url += `?${qsaString}`;

        await axios.post(url, {
            source: src,
            destination: dest
        }, {
            headers: {
                Authorization: `Bearer ${this._auth?.AccessToken}`
            }
        });
    }

    /**
     * Erzeugt einen Ordner
     *
     * @param directoryPath - Der Zielpfad des Ordners.
     * @param qsaOptions - Die optionalen Query-String-Parameter.
     *
     * @returns Der erzeugte Ordner.
     */
    async create(directoryPath:string, qsaOptions?: {directoryType?: "contact" | "finance", objectId?: string | number }):Promise<DriveDirectory> {
        directoryPath = escapePath(directoryPath);

        const createPath = !qsaOptions?.directoryType
            ? directoryPath
            : `${directoryPath}?directoryType=${qsaOptions.directoryType}&objectId=${qsaOptions.objectId}`;

        const result = await axios.post(combineURLs(this._baseUrl, `md/${createPath}`.replace(/\/{2,}/g, "/")), {}, {
            headers: {
                Authorization: `Bearer ${this._auth?.AccessToken}`
            }
        });

        let path = (result.headers || { location: directoryPath }).location;
        const combinedUrl = combineURLs(`${this._baseUrl}`, "ls");

        if (path.startsWith(combinedUrl))
            path = path.replace(combinedUrl, "");

        const now = Date.now();

        // @ts-ignore
        return new DriveDirectory({
            contactId: result.data.contactId,
            name: result.data.name,
            path: unescapePath(path),
            hasChildren: result.data.hasChildren || false,
            overlay: result.data.overlay,
            created: now,
            deleted: now,
            type: "Directory",
            shareType: result.data.shareType,
            access: result.data.access,
            shareable: result.data.shareable
        });
    }

    /**
     * Sendet an den Service den "Gesehen"-Status aller Dateien
     *
     * @param perceived - Gesehen oder nicht gesehen.
     */
    async markAsPerceived(ids:(string | number)[], perceived: boolean) {
        if (!Array.isArray(ids))
            return;

        if (!ids.length)
            return;

        const dest = escapePath(`files/.user-activities/${perceived ? "seen" : "unseen"}`);
        const url = combineURLs(this._baseUrl, `${dest}`.replace(/\/{2,}/g, "/"));

        await axios.put(url, ids, {
            headers: {
                Authorization: `Bearer ${this._auth?.AccessToken}`
            }
        });
    }
}

export default DriveFiles;
