import Drive from "../api/index";
import DriveFileSystemObject from "./DriveFileSystemObject";
import imgPaths from "@/apis/drive/utils/imgPaths";
import DriveFileRelease from "./DriveFileRelease";

/**
 * Stellt ein Verzeichnisobjekt zur Verfügung.
 */
class DriveDirectory extends DriveFileSystemObject implements IDriveDirectory {
    /**
     * Die Anzahl der Dateissystemobjekte im Ordner.
     *
     * @property {Boolean} hasChildren
     */
    hasChildren = false;

    /**
     * C'tor
     *
     * @param {{shareable:boolean, contactId: String, overlay: String, description:string|undefined, access: "read"|"readWrite"|"readWriteDelete", created: Date, deleted: Date, hasChildren: Boolean, id: Number, isDirty: Boolean, isNew: Boolean, name: String, path: String, pending: Boolean, shareType: "none"|"concrete"|"inherited", virtual: Boolean}} fsoObject
     * @param {String} baseUrl Die Basis-Adresse.
     */
    constructor({
        shareable,
        contactId,
        overlay,
        access = <IAccessType>DriveFileRelease.AccessLevel.ReadWriteDelete,
        created = Date.now(),
        deleted = Date.now(),
        description,
        id = null,
        isDirty = false,
        isNew = false,
        hasChildren = false,
        name = null,
        path,
        pending = false,
        shareType = "none",
        virtual = false
    }: IDriveDirectoryConstructorParameters,
        baseUrl: string | null
    ) {
        super({ shareable, contactId, overlay, description, access, created, deleted, id, isDirty, isNew, name, path, pending, shareType, type: "directory", virtual }, baseUrl);

        this.hasChildren = hasChildren;
    }

    /**
     * @inheritdoc
     */
    getThumb(): URL {
        if (!Drive.Path)
            return imgPaths.grid[this.hasChildren ? "folder" : "folder-empty"];

        if (`/${this.name ?? ""}` === Drive.Path.RECYCLE_PATH)
            return imgPaths.grid[this.hasChildren ? "recycle-bin" : "recycle-bin-empty"];

        if (`/${this.name ?? ""}` === Drive.Path.USER_ACTIVITIES_PATH)
            return imgPaths.grid[this.hasChildren ? "folder-uploads" : "folder-uploads-empty"];

        if (`/${this.name ?? ""}` === Drive.Path.CONTACTS_PATH)
            return imgPaths.grid[this.hasChildren ? "contacts" : "contacts-empty"];

        if (`/${this.name ?? ""}` === Drive.Path.GENERAL_DOCUMENTS_PATH)
            return imgPaths.grid[this.hasChildren ? "general-docs" : "general-docs-empty"];

        if (`/${this.name ?? ""}` === Drive.Path.FINANCE_DATA_PATH)
            return imgPaths.grid[this.hasChildren ? "finance-data" : "finance-data-empty"];

        if (`/${this.name}` === Drive.Path.REPORTS_PATH)
            return imgPaths.grid[this.hasChildren ? "reports" : "reports-empty"];

        return imgPaths.grid[this.hasChildren ? "folder" : "folder-empty"];
    }
}

export default DriveDirectory;
