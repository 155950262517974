import Scroller from "../../index";

const onMouseLeave = {
    /**
     * Registriert das {@linkcode MouseEvent} beim verlassen des Mauszeigers aus dem Skrollbereich.
     *
     * @this Scroller
     */
    attach: function() {
        if (this.isIFrame)
            return;

        document.addEventListener("mouseleave", this.registeredHandler.dragState);
    },

    /**
     * Entfernt das {@linkcode MouseEvent} beim verlassen des Mauszeigers aus dem Skrollbereich.
     *
     * @this Scroller
     */
    detach: function() {
        if (this.isIFrame)
            return;

        document.removeEventListener("mouseleave", this.registeredHandler.dragState);
    }
};

export default onMouseLeave;
