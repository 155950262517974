import axios from "axios";
import combineURLs from "axios/lib/helpers/combineURLs";
import { escapePath } from "../utils/pathConverter";
// eslint-disable-next-line
import DriveTag from "../models/DriveTag";

class DriveTags {
    /**
     * Privates Feld: Authorisierungsmethoden
     *
     * @property {DriveAuth} [_auth=null]
     */
    _auth = null;

    /**
     * Privates Feld: Basisadresse.
     *
     * @type {String}
     */
    _baseUrl;

    /**
     * Axios Abbruch-Controller
     *
     * @type {AbortController}
     */
    AbortController = null;

    /**
     * C'tor
     *
     * Setzt die Basisadresse für den Api-Aufruf.
     *
     * @param {String} baseUrl
     * @param {DriveAuth} auth
     */
    constructor(baseUrl, auth) {
        this._auth = auth;
        this._baseUrl = baseUrl;
    }

    /**
     * Liefert alle vorhanden Tags.
     *
     * @returns {Promise<DriveTag[]>} Die Tags.
     */
    async list() {
        if (this.AbortController)
            this.AbortController.abort();

        this.AbortController = new AbortController();

        return await axios.get(combineURLs(this._baseUrl, "tags"), {
            signal: this.AbortController.signal,
            headers: {
                Authorization: `Bearer ${this._auth.AccessToken}`
            }
        });
    }

    /**
     * Speichert ein Tag.
     *
     * @param {DriveTag} tag Das Tag.
     */
    async save(tag) {
        await axios.put(combineURLs(this._baseUrl, "tags"), {
            label: tag.label,
            color: tag.background
        }, {
            headers: {
                Authorization: `Bearer ${this._auth.AccessToken}`
            }
        });
    }

    /**
     * Ändert die Tags (umbenennen, hinzufügen sowie entfernen) einer Datei.
     *
     * @param {String} path Der Dateipfad.
     * @param {DriveTag[]} tags Die Tags der Datei.
     */
    async modify(path, tags) {
        let tagNames = [];

        (tags || []).forEach(tag => tagNames.push(tag.label));

        path = escapePath(`tags/${path}`);

        await axios.put(`${combineURLs(this._baseUrl, path)}`, tagNames, {
            headers: {
                Authorization: `Bearer ${this._auth.AccessToken}`
            }
        });
    }

    /**
     * Entfernt ein Tag.
     *
     * @param {DriveTag} tag Das Tag
     */
    async remove(tag) {
        await axios.delete(`${combineURLs(this._baseUrl, "tags")}`, {
            data: JSON.stringify(tag.label),

            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this._auth.AccessToken}`
            }
        });
    }
}

export default DriveTags;
