import DriveAuth from "./DriveAuth";
import DriveFiles from "./DriveFiles";
import DriveUsers from "./DriveUsers";
import DriveRevision from "./DriveRevision";
import DriveReleases from "./DriveReleases";
import DriveTags from "./DriveTags";
import DrivePath from "./DrivePath";

/**
 * Drive-Api (Singleton)
 */
class Drive {
    /**
     * Die Instanz des Singletons.
     */
    private static Instance: Drive;

    /**
     * Die Drive-Konfiguration.
     */
    _config: IDriveConfig | null = null;

    /**
     * Der Autorisierungs-Wrapper
     */
    Auth: DriveAuth | null = null;

    /**
     * Die Basis-Adresse der Api.
     */
    BaseUrl: string | null = null;

    /**
     * Die Crm-Basis-Adresse der Api
     */
    CrmUrl: string | null = null;

    /**
     * Der Files-Wrapper.
     */
    Files: DriveFiles | null = null;

    /**
     * Der Pfad-Wrapper.
     */
    Path: DrivePath | null = null;

    /**
     * Der Datefreigabe-Wrapper.
     */
    Releases: DriveReleases | null = null;

    /**
     * Der Revision-Wrapper.
     */
    Revision: DriveRevision | null = null;

    /**
     * Die Services-Adressen der Api
     */
    Services: EndpointSettings | null = null;

    /**
     * Der Tags-Wrapper.
     */
    Tags: DriveTags | null = null;

    /**
     * Der Benutzer-Wrapper.
     */
    Users: DriveUsers | null = null;

    /**
     * Setzt die Konfiguration der Drive-Api.
     *
     * @param config - Die Konfiguration.
     */
    set Config(config: IDriveConfig | null) {
        this._config = config;

        if (!config?.baseUrl)
            throw new Error("Die Basis-Url der Drive-API muss angegeben werden.");

        this.BaseUrl = config.baseUrl;
        this.Auth = new DriveAuth();
        this.Files = new DriveFiles({ baseUrl: this.BaseUrl, auth: this.Auth, maxTransferSize: this._config?.maxTransferSize ?? 1024 * 1024 * 10 });
        this.Users = new DriveUsers(this.CrmUrl, this.Auth);
        this.Path = new DrivePath(this.BaseUrl, this.Auth);
        this.Releases = new DriveReleases(this.BaseUrl, this.Auth);
        this.Revision = new DriveRevision(this.BaseUrl, this.Auth);
        this.Tags = new DriveTags(this.BaseUrl, this.Auth);
    }

    /**
     * Liefert die Konfiguration der Drive-Api.
     *
     * @returns Die Konfiguration.
     */
    get Config(): IDriveConfig | null { return this._config; }

    /**
     * C'Tor
     */
    private constructor() {}

    public static getInstance() {
        if (this.Instance)
            return this.Instance;

        this.Instance = new Drive();

        return this.Instance;
    }
}

export default Drive.getInstance();
