import $ from "@/utils/dom";
import preSelectRow from "./preSelectRow";
import selectRow from "./selectRow";

/**
 * Erzeugt ein Array anhand einer Reichweite.
 *
 * @param {Number} start Reichweitenstart.
 * @param {Number} end Reichweitenende.
 *
 * @returns {Array} Das erzeugte Array.
 */
const Range = (start, end) => {
    let length = 1 + end - start;

    return Array.from({ length }, (_, i) => start + i);
};

/**
 * Aktiviert den Status, dass aktuell eine Selektion stattfindet.
 */
export function startSelectionByShift () {
    this.state.shift.active = true;

    if (this.state.shift.position === -1)
        this.state.shift.position = this.state.select.position;
}

/**
 * Aktiviert den Status, dass aktuell eine Selektions-Navigation stattfindet.
 */
export function startSelectionByCtrl () {
    this.state.ctrl.active = true;
}

/**
 * Deaktiviert den Status, dass aktuell eine Selektions-Navigation stattfindet.
 */
export function stopSelectionByCtrl () {
    this.state.ctrl.active = false;
    this.state.ctrl.position = -1;

    $(this.tableBody).find("tr.next-preselect").removeClass("next-preselect");
    $(this.tableBody).find("tr.preselect").removeClass("preselect");
}

/**
 * Führt die Selektions-Navigation in die gewünschte Richtung aus.
 *
 * @param {Number<(-1|1)>} direction Die Navigationsrichtung.
 */
export function navigateSelection (direction) {
    if (this.state.table.focus) {
        let lastPosition = 0;

        if (this.state.ctrl.position > -1)
            lastPosition = this.state.ctrl.position;
        else if (this.state.selected.length > 0)
            lastPosition = this.state.selected[this.state.selected.length - 1];

        this.state.ctrl.position = this.state.ctrl.marker = Math.min(Math.max(0, lastPosition + direction), this.ctx.rows.length - 1);

        preSelectRow.call(this, {target: $(this.tableBody).find(`tr:nth-child(${this.state.ctrl.position + 1})`).get(0)});
    }
}

/**
 * Markiert die vorherige bzw. nächsten Zeile.
 *
 * @param {Number<(-1|1)>} direction Die Navigationsrichtung.
 */
export function selectByArrow (direction) {
    if (document.activeElement.closest(".data-view").querySelector(".table.sticky > table") !== this.table)
        return;

    let lastPosition = 0;

    if (this.state.ctrl.position > -1)
        lastPosition = this.state.ctrl.position;
    else if (this.state.selected.length > 0)
        lastPosition = this.state.selected[this.state.selected.length - 1];
    else
        lastPosition = 0;

    clearSelection.call(this);
    clearPreSelection.call(this);

    this.state.ctrl.position = this.state.select.position = Math.min(Math.max(0, lastPosition + direction), this.ctx.rows.length - 1);
    this.state.selected = [this.state.ctrl.position];

    selectRow.call(this, {target: $(this.tableBody).find(`tr:nth-child(${this.state.ctrl.position + 1})`).get(0)});

    this.callback(true);
}

/**
 * Markiert die angeklickte Zeile.
 *
 * @param {Event} e Das Ereignis-Objekt.
 * @param {Boolean} [singleSelect=true] Ein Wert, der angibt, ob derzeit nur eine Zeile oder mehrere Zeilen markiert werden sollen.
 */
export function selectByClick (e, singleSelect) {
    if (this.state.table.focus) {
        let row = e.target.tagName.toLowerCase() === "tr"
            ? e.target
            : $(e.target).parent("tr").get(0);

        let rowIndex = parseInt($(row).data("row"), 10);

        this.state.ctrl.marker = -1;
        this.state.ctrl.position = this.state.select.position = this.state.shift.position = Math.min(Math.max(0, rowIndex), this.ctx.rows.length - 1);

        // Zeile nur markieren, wenn nicht bereits erfolgt:
        if (!isSelected.call(this, e)) {
            if (singleSelect)
                clearSelection.call(this);

            addSelected.call(this, this.state.ctrl.position);
            selectRow.call(this, {target: row}, singleSelect);
        }

        this.callback();
    }
}

/**
 * (De-)Markiert die aktuelle Zeile per Leertaste.
 */
export function selectBySpaceKey () {
    if (this.state.table.focus) {
        let row = $(this.tableBody).find(`tr:nth-child(${this.state.ctrl.position + 1})`).get(0);
        let rowIndex = parseInt($(row).data("row"), 10);

        this.state.ctrl.marker = -1;
        this.state.select.position = this.state.shift.position = rowIndex;

        if (!isSelected.call(this, {target: row})) {
            addSelected.call(this, this.state.ctrl.position);
            selectRow.call(this, {target: row});
        } else {
            removeSelected.call(this, rowIndex);
            deselectRow.call(this, {target: row});
        }

        this.callback();
    }
}

/**
 * (De-)Markiert die Zeilen bei einem Klick mit gedrückter Shift-Taste.
 *
 * @param {Event} e Das Ereignis-Objekt.
 */
export function selectByShiftClick(e) {
    if (this.state.table.focus) {
        let row = e.target.tagName.toLowerCase() === "tr"
            ? e.target
            : $(e.target).parent("tr").get(0);

        let rowIndex = parseInt($(row).data("row"), 10);
        let startRange = Math.min(rowIndex, this.state.select.position);
        let endRange = Math.max(rowIndex, this.state.select.position);

        this.state.selected = Range(startRange, endRange);

        $(this.tableBody).find("tr.next-preselect").removeClass("next-preselect");
        $(this.tableBody).find("tr.preselect").removeClass("preselect");
        $(this.tableBody).find("tr.selected").removeClass("selected");

        for (var i = startRange; i <= endRange; i++)
            selectRow.call(this, {target: $(this.tableBody).find(`tr:nth-child(${i + 1})`).get(0)});

        this.callback();
    }
}

/**
 * (De-)Markiert die aktuelle Zeile unter Beibehaltung der aktuellen Auswahl per Shift-Taste.
 *
 * @param {Number} direction Die Navigationsrichtung.
 */
export function selectByShiftKey(direction) {
    if (this.state.table.focus) {
        this.state.shift.position = Math.min(Math.max(0, this.state.shift.position + direction), this.ctx.rows.length - 1);

        let startRange = 0;
        let endRange = 0;

        if (this.state.ctrl.marker === -1) {
            startRange = Math.min(this.state.select.position, this.state.shift.position);
            endRange = Math.max(this.state.select.position, this.state.shift.position);

            this.state.ctrl.position = this.state.shift.position;
        } else {
            startRange = Math.min(this.state.ctrl.marker, this.state.select.position);
            endRange = Math.max(this.state.ctrl.marker, this.state.select.position);

            this.state.ctrl.position = this.state.shift.position = this.state.ctrl.marker;
            this.state.ctrl.marker = -1;
        }

        this.state.selected = Range(startRange, endRange);

        $(this.tableBody).find("tr.next-preselect").removeClass("next-preselect");
        $(this.tableBody).find("tr.preselect").removeClass("preselect");
        $(this.tableBody).find("tr.selected").removeClass("selected");

        for (var i = startRange; i <= endRange; i++)
            selectRow.call(this, {target: $(this.tableBody).find(`tr:nth-child(${i + 1})`).get(0)});

        this.callback();
    }
}

/**
 * Demarkiert eine vormals ausgewählte Zeile.
 *
 * @param {Event} e Das Ereignis-Objekt.
 */
export function deselectRow (e) {
    let row = e.target.tagName.toLowerCase() === "tr"
        ? e.target
        : $(e.target).parent("tr").get(0);

    let rowIndex = parseInt($(row).data("row"), 10);

    if (this.state.ctrl.active)
        this.state.select.position = this.state.shift.position = rowIndex;

    removeSelected.call(this, rowIndex);

    $(row).removeClass("selected");
}

/**
 * Gibt an, ob eine Zeile ausgewählt ist.
 *
 * @param {Event} e Das Ereignis-Objekt.
 *
 * @returns {Boolean} Der Wert, ob die Zeile markiert ist.
 */
export function isSelected(e) {
    let row = e.target.tagName.toLowerCase() === "tr"
        ? e.target
        : $(e.target).parent("tr").get(0);

    let rowIndex = parseInt($(row).data("row"), 10);

    return this.state.selected.includes(rowIndex);
}

/**
 * Fügt dem Container der ausgewählten Zeilen eine Zeile hinzu.
 *
 * @param {Number} index Der hinzuzufügende Zeilenindex.
 */
export function addSelected(index) {
    if (!this.state.selected.includes(index))
        this.state.selected.push(index);
}

/**
 * Entfernt aus dem Container der ausgewählten Zeilen eine Zeile.
 *
 * @param {Number} index Der zu entfernende Zeilenindex.
 */
export function removeSelected(index) {
    if (this.state.selected.includes(index))
        this.state.selected.splice(this.state.selected.indexOf(index), 1);
}

/**
 * Hebt die aktuelle Auswahl auf.
 */
export function clearSelection() {
    $(this.tableBody).find("tr.selected").removeClass("selected");

    this.state.selected = [];
    this.state.shift.position = -1;
}

/**
 * Hebt die aktuelle Vorauswahl auf.
 */
export function clearPreSelection() {
    $(this.tableBody).find("tr.next-preselect").removeClass("next-preselect");
    $(this.tableBody).find("tr.preselect").removeClass("preselect");

    this.state.ctrl.position = -1;
}
