/**
 * Berechnet die Dunkelheit eines Farbwerts.
 *
 * @param {String} color Die zu prüfende Farbe.
 *
 * @returns {Boolean} Der Wert, ob die Farbe dunkel ist.
 */
const isDark = function(color) {
    let r, g, b, a;

    if (color.match(/^rgb/)) {
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

        r = color[1];
        g = color[2];
        b = color[3];
        a = color[4];
    } else {
        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = + ("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

        r = color >> 16;
        g = color >> 8 & 255;
        b = color & 255;
        a = 1;
    }

    // HSP (Highly Sensitive) equation from http://alienryderflex.com/hsp.html
    let hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    ) / a;

    // Using the HSP value, determine whether the color is light or dark
    // return true;
    return hsp < 200;
};

/**
 * Konvertiert einen RGB-Farbwert in einen Hex-Farbwert.
 *
 * @param {{r: 0, g: 0, b: 0}} color
 *
 * @returns {String}
 */
const rgb2hex = ({r = 0, g = 0, b = 0}) => {
    const rHex = `0${r.toString(16)}`.slice(-2);
    const gHex = `0${g.toString(16)}`.slice(-2);
    const bHex = `0${b.toString(16)}`.slice(-2);

    return `#${rHex}${gHex}${bHex}`;
};

/**
 * Konvertiert einen Hex-Farbewert in einen RGBA-Farbwert.
 *
 * @param {String} hex Der Hex-Farbwert.
 * @param {Number} alpha Der Transparenzwert.
 *
 * @returns {String} Der konvertierte Farbwert.
 */
const hex2rgba = function(hex, alpha) {
    if (hex.charAt(0) === "#") hex = hex.substr(1);
    if ((hex.length < 2) || (hex.length > 6)) return hex;

    let values = hex.split("");
    let r = 0;
    let g = 0;
    let b = 0;

    if (hex.length === 2) {
        r = parseInt(values[0].toString() + values[1].toString(), 16);
        g = r;
        b = r;
    } else if (hex.length === 3) {
        r = parseInt(values[0].toString() + values[0].toString(), 16);
        g = parseInt(values[1].toString() + values[1].toString(), 16);
        b = parseInt(values[2].toString() + values[2].toString(), 16);
    } else if (hex.length === 6) {
        r = parseInt(values[0].toString() + values[1].toString(), 16);
        g = parseInt(values[2].toString() + values[3].toString(), 16);
        b = parseInt(values[4].toString() + values[5].toString(), 16);
    } else {
        return false;
    }

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

/**
 * Eine Sammlung von Farb-Hilfsfunktionen.
 *
 * @param {String} color Die Farbe.
 *
 * @returns {{isDark:Function, hex2rgba:Function, rgb2hex:Function}} Eine Sammlung von Hilfsfunktionen.
 */
function colorHelper(color) {
    let _color = color;

    return {
        /**
         * Gibt an, ob es sich um eine dunkle Farbe handelt.
         *
         * @returns {Bool} Der Wert, ob die Farbe dunkel ist.
         */
        isDark: () => isDark(_color),

        /**
         * Konvertiert einen Hex-Farbewert in einen RGBA-Farbwert.
         *
         * @param {Number} [alpha=1] Der Transparenzwert (optional).
         *
         * @returns {String} Der konvertierte Farbwert.
         */
        hex2rgba: (alpha) => hex2rgba(_color, alpha || 1),

        /**
         * Konvertiert einen RGB-Farbwert in einen Hex-Farbwert.
         *
         * @returns {String} Der konvertierte Farbwert.
         */
        rgb2hex: () => rgb2hex(_color)
    };
}

export default colorHelper;
