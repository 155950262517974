/**
 * Eine Verzeichnisklasse
 */
class Directory {
    /**
     * Der übertragene Pfad der Datei.
     *
     * @type {String}
     */
    path;

    /**
     * Der tatsächlich hinterlegte Serverpfad.
     *
     * @type {String}
     */
    location;

    /**
     * Ein Wert, der angibt, ob das Verzeichnis über Kindelemente verfügt.
     *
     * @type {Boolean}
     */
    hasChildren;

    /**
     * C'Tor
     *
     * @param {{path: String, location: String, hasChildren}} options
     */
    constructor({path, location, hasChildren = false}) {
        this.path = path;
        this.location = location;
        this.hasChildren = hasChildren;
    }
}

export default Directory;
