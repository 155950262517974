<script setup>
import { computed, nextTick, ref, watch } from "vue";
import { injectUtility } from "@/utils/utility.helper";
import { ColorMappings } from "@/utils/ColorMappings";
import colorHelper from "@/utils/colorHelper";
import Formatter from "@/utils/formatter";
import Icon from "@/components/UI/Icon.vue";
import useCacheUtils from "@/mixins/composition.cache";
import useComponentUtils from "@/mixins/composition.component";
import useParameterUtils from "@/mixins/composition.parameters";

/**
 * @typedef {{name: String, cells: {template: String, value: Number}[], help: String, level: Number, operator: String, operatorText: String, ref: String, report: String, url: String}} BalanceEntry
 */

const $cache = injectUtility("Cache");
const $help = injectUtility("Help");
const $model = injectUtility("Model");
const $page = injectUtility("Page");

/**
 * Das Wurzelelement {@linkcode HTMLElement} der Komponente
 *
 * @type {{value: HTMLElement}}
 */
const root = ref(null);

const { componentId, pageId, menuId } = useComponentUtils({
    node: root,
    plugins: {
        page: $page,
    }
});

const parameterUtils = useParameterUtils({
    model: $model.get("Parameters"),
    componentId,
    pageId,
    menuId,
});

const cache = useCacheUtils({
    componentId,
    cache: $cache
});

/**
 * Vue Emitter
 *
 * @param {{"click", Any}}
 */
const emit = defineEmits(["click"]);

/**
 * @type {{colorScheme: String, columns: [], idxYear: Number, values: BalanceEntry[]}}
 */
const props = defineProps({
    /**
     * Der Farbschema der Ansicht.
     *
     * @type {String}
     */
    colorScheme: {
        type: String,
        default: "liquidity"
    },

    /**
     * Die Spalteninformationen zur Breitenjustierung der Spalten.
     *
     * @type {[]}
     */
    columns: {
        type: Array,
        default: () => []
    },

    /**
     * Der Index des ausgewählten Jahres.
     *
     * @type {Number}
     */
    idxYear: {
        type: Number,
        default: 0
    },

    /**
     * Die Daten des T-Kontos.
     *
     * @type {BalanceEntry[]}
     */
    values: {
        type: Array,
        default: () => []
    }
});

/**
 * Die Aktiva des T-Kontos.
 *
 * @type {{value: BalanceEntry[]}}
 */
const actives = ref([]);

/**
 * Die Passiva des T-Kontos.
 *
 * @type {{value: BalanceEntry[]}}
 */
const passives = ref([]);

/**
 * Die Spaltenbreiten der Passiva des T-Kontos
 *
 * @type {{value: String[]|Number[]}}
 */
const passivesWidths = ref([]);

/**
 * Liefert die Anzahl der darzustellenden Zeilen.
 *
 * Hinweis:
 *
 * Die Anzahl der darzustellenden Zeilen ist die Länge desjenigen Arrays (TAccount.actives/TAccount.passives)
 * mit der größten Anzahl von Elementen.
 *
 * @type {{value: Number}}
 */
const amountOfRows = computed(() => Math.max(actives.value.length, passives.value.length));

/**
 * Liefert den kumulierten Wert der Bilanz.
 *
 * Hinweis:
 *
 * Die Gesamtsumme der Aktivpositionen muss gem. GoBs immer der Gesammtsumme
 * der Passivpositionen entsprechen, wehalb für die Rückgabe nur die Aktivpositionen
 * berücksichtigt werden müssen.
 *
 * @type {{value: Number}}
 */
const total = computed(() => {
    return {
        actives: actives.value.length
            ? actives.value.filter(activa => activa.level === 2)[0].cells[props.idxYear].value
            : 0,

        passives: passives.value.length
            ? passives.value.filter(passiva => passiva.level === 2)[0].cells[props.idxYear].value
            : 0
    };
});

/**
 * Liefert die prozentualen Verhältnisse einer Kategeorie geordnet unter Aktiva und Passiva.
 *
 * @type {{value: Number}}
 */
const proportions = computed(() => {
    const proportions = {
        actives: [],
        passives: []
    };

    ["actives", "passives"]
        .forEach(accessor => {
            const balanceEntries = accessor === "actives"
                ? actives
                : passives;

            /**
             * Erzeugt ein Objekt-Array der Aktiva- bzw. Passiva Positionen zur weiteren Verarbeitung.
             *
             * @type {{idx: Number, value: Number, florWeight: Number, level: Number, percentage: Number}}
             */
            let rows = balanceEntries.value
                .map((balanceEntry, idx) => {
                    const value = balanceEntry.cells[props.idxYear].value;
                    const floor = idx > 0 ? Math.floor(value) : value;

                    return {
                        idx: idx,
                        value: balanceEntry.cells[props.idxYear].value,
                        level: balanceEntry.level,
                        percentage: Math.round(value) > 0 ? Math.max(0.1, Math.floor(floor * 1000 / total.value[accessor]) / 10) : 0,
                        operator: balanceEntry.operator
                    };
                });


            /**
             * Die Prozentlücke, ausgelöst durch das abrunden der echten Werte eine Position in den Aktivas bzw. Passivas.
             *
             * @type {Number}
             */
            let gap = (100 - rows
                .filter(row => [2, 3].indexOf(row.level) > -1 && row.idx > 0 && row.operator !== "none")
                .reduce((value, row) => value + row.percentage, 0)).toFixed(2);

            /**
             * Priorisierung der Aktiva- bzw. Passivapositionen zur Füllung der Prozentlücke.
             */
            rows
                .sort((rowA, rowB) => rowA.value - rowB.value > 0 ? -1 : 1);

            /**
             * Alle Aktiv- bzw. Passivpositionen der Level 2 und 3, ausser der ersten.
             *
             * @type {{idx: Number, value: Number, floorWeight: Number, level: Number, percentage: Number}[]}
             */
            const filtered = rows
                .filter(row => [2, 3].indexOf(row.level) > -1 && row.idx > 0 && row.operator !== "none");

            /**
             * Schließt die Prozentlücke, indem über die priorisierten Aktiva- bzw. Passivaposition
             * iteriert und der Wert 1 hinzugefügt wird.
             *
             * @param {{gap: Number, idx: Number}} options
             */
            const closeGap = ({gap, idx}) => {
                if (gap < 0.1)
                    return;

                if (filtered[idx].value > 0) {
                    filtered[idx].percentage += 0.1;

                    gap = (gap - 0.1).toFixed(2);
                }

                closeGap({
                    gap,
                    idx: idx + 2 > filtered.length ? 0 : idx + 1
                });
            };

            /**
             * Endlosschleife vermeiden.
             */
            if (filtered.filter(row => row.value > 0).length)
                closeGap({
                    gap,
                    idx: 0
                });

            /**
             * Sortierung nach Index wiederherstellen.
             */
            rows
                .sort((rowA, rowB) => rowA.idx - rowB.idx < 0 ? -1 : 1);

            proportions[accessor] = rows;
        });

    return proportions;
});

/**
 * Liefert die Spaltenbreite der Währungen des T-Kontos.
 *
 * @type {{value: (String|Number)}}
 */
const currencyWidth = computed(() => `${(props.columns.find(column => column.template.toLowerCase() === "currency") || {width: "50px"}).width}`);

/**
 * Ordnet die Daten den Aktiva- und Passiva-Array zu.
 */
const assign = () => {
    const assignableActives = props.values.filter(value => value.level !== 1);

    if (assignableActives.filter(activa => activa.level === 2).length !== 2)
        return;

    assignableActives.reverse();

    const assignablePassives = assignableActives.splice(0, assignableActives.findIndex(activa => activa.level === 2) + 1);

    assignablePassives.reverse();
    assignableActives.reverse();

    actives.value = assignableActives;
    passives.value = assignablePassives;
};

/**
 * Liefert die Css-Klassen einer Zeile einer Passiva- oder Aktiva-Position des
 * aktuell ausgewählten Jahres des übergebenen Zeilenindex.
 *
 * @param {{accessor: "actives"|"passives", idx: Number}} options
 *
 * @returns {String}
 */
const css = ({accessor, idx}) => `level${level({accessor, idx}) - 1}`;

/**
 * Liefert die Beschreibung einer Zeile einer Passiva- oder Aktiva-Position des
 * aktuell ausgewählten Jahres des übergebenen Zeilenindex.
 *
 * @param {{accessor: "actives"|"passives", idx: Number, whitespace: String}} options
 *
 * @returns {String}
 */
const description = ({accessor, idx, whitespace = "&nbsp;"}) => {
    const balanceEntry = accessor === "actives"
        ? actives.value[idx]
        : passives.value[idx];

    const name = (balanceEntry || {name: ""}).name;

    return name.length > 0
        ? name
        : whitespace;
};

/**
 * Emittiert eine Zelle einer Passiva- oder Aktiva-Position des
 * aktuell ausgewählten Jahres des übergebenen Zeilenindex an die Elternkomponente
 *
 * @param {{accessor: "actives"|"passives", idx: Number}} options
 */
const emitBalanceEntry = ({accessor, idx}) => {
    if (!reference({accessor, idx}))
        return;

    const balanceEntry = accessor === "actives"
        ? actives.value[idx]
        : passives.value[idx];

    emit("click", balanceEntry);
};

/**
 * Liefert den Eintrag der Hilfe einer Zeile einer Passiva- oder Aktiva-Position des
 * aktuell ausgewählten Jahres des übergebenen Zeilenindex.
 *
 * @param {{accessor: "actives"|"passives", idx: Number}} options
 *
 * @returns {String}
 */
const getHelp = ({accessor, idx}) => {
    const balanceEntry = accessor === "actives"
        ? actives.value[idx]
        : passives.value[idx];

    return (balanceEntry || {help: null}).help;
};

/**
 * Liefert die Füllfarbe eines Icons einer Zeile einer Passiva- oder Aktiva-Position des
 * aktuell ausgewählten Jahres des übergebenen Zeilenindex.
 *
 * @param {{accessor: "actives"|"passives", idx: Number}} options
 *
 * @returns {String}
 */
const iconFillColor = ({accessor, idx}) =>
    level({accessor, idx}) === 2
        ? "#FFFFFF"
        : ColorMappings.getColorsByReportType(props.colorScheme).base1;

/**
 * Liefert den Level einer Zeile einer Passiva- oder Aktiva-Position des
 * aktuell ausgewählten Jahres des übergebenen Zeilenindex.
 *
 * @param {{accessor: "actives"|"passives", idx: Number}} options
 *
 * @returns {String}
 */
const level = ({accessor, idx}) => {
    const balanceEntry = accessor === "actives"
        ? actives.value[idx]
        : passives.value[idx];

    return (balanceEntry || {level: 4}).level;
};

/**
 * Öffnet die Hilfe einer Zeile einer Passiva- oder Aktiva-Position des
 * aktuell ausgewählten Jahres des übergebenen Zeilenindex.
 *
 * @param {{accessor: "actives"|"passives", idx: Number}} options
 */
const openHelp = ({accessor, idx}) =>
    $help.show({
        id: getHelp({accessor, idx}),
        title: description({accessor, idx})
    });

/**
 * Liefert den prozentualen Anteil einer Zeile am Gesamtbetrag einer Passiva- oder Aktiva-Position des
 * aktuell ausgewählten Jahres des übergebenen Zeilenindex.
 *
 * @param {{accessor: "actives"|"passives", idx: Number, whitespace: String}} options
 *
 * @returns {String}
 */
const proportion = ({accessor, idx, whitespace = "&nbsp;"}) => {
    if (idx === 0)
        return "100 %";

    const balanceEntry = accessor === "actives"
        ? actives.value[idx]
        : passives.value[idx];

    if ([2, 3].indexOf((balanceEntry || {level: null}).level) === -1)
        return whitespace;

    if (total.value[accessor] === 0)
        return whitespace;

    if ((balanceEntry || {operator: "add"}).operator === "none")
        return whitespace;

    const percentage = (proportions.value[accessor][idx] || {percentage: 0}).percentage / 100;

    return Formatter(percentage).asPercent({
        minDecimals: 1,
        maxDecimals: 1
    });
};

/**
 * Liefert den Referenzstatus zur Darstellung eines Links einer Zeile am Gesamtbetrag einer Passiva- oder Aktiva-Position des
 * aktuell ausgewählten Jahres des übergebenen Zeilenindex.
 *
 * @param {{accessor: "actives"|"passives", idx: Number}} options
 *
 * @returns {String}
 */
const reference = ({accessor, idx}) => {
    const balanceEntry = accessor === "actives"
        ? actives.value[idx]
        : passives.value[idx];

    return balanceEntry && balanceEntry.ref;
};

/**
 * Liefert den Css-Style einer Zeile am Gesamtbetrag einer Passiva- oder Aktiva-Position des
 * aktuell ausgewählten Jahres des übergebenen Zeilenindex.
 *
 * @param {{accessor: "actives"|"passives", idx: Number}} options
 * @param {Object} customCss
 *
 * @returns {String}
 */
const style = ({accessor, idx}, customCss = {}) => {
    const balanceEntry = accessor === "actives"
        ? actives.value[idx]
        : passives.value[idx];

    const brightColor = "#FFFFFF";
    const darkColor = "#111111";

    const rowLevel = level({accessor, idx});
    const color = (balanceEntry|| {color: null}).color;

    let generatedStyle = {};
    let fallbackColor = ColorMappings.getColorsByReportType(props.colorScheme).base1;

    if (rowLevel === 3) fallbackColor = colorHelper(fallbackColor).hex2rgba(0.15);
    else if (rowLevel === 4) fallbackColor = colorHelper(fallbackColor).hex2rgba(0.07);

    const bgColor = color || fallbackColor;

    generatedStyle.backgroundColor = bgColor;

    generatedStyle.color = colorHelper(bgColor).isDark()
        ? brightColor
        : darkColor;

    Object.keys(customCss).forEach(key => {
        generatedStyle[key] = customCss[key];
    });

    return generatedStyle;
};

/**
 * Liefert den Wert in EUR einer Zeile einer Passiva- oder Aktiva-Position des
 * aktuell ausgewählten Jahres des übergebenen Zeilenindex.
 *
 * @param {{accessor: "actives"|"passives", idx: Number, whitespace: String}} options
 *
 * @returns {String}
 */
const value = ({accessor, idx, whitespace = "&nbsp;"}) => {
    const balanceEntry = accessor === "actives"
        ? actives.value[idx]
        : passives.value[idx];

    const cell = (balanceEntry || {cells: []}).cells[props.idxYear];

    if (!cell)
        return whitespace;

    return Formatter(cell.value)
        .asCurrency({
            minDecimals: 0,
            maxDecimals: 0
        });
};

/**
 * Überwacht die Änderung der T-Konten-Werte und bereitet bei Änderung
 * die Zeilen zur Darstellung der Tabelle vor.
 */
watch(
    () => props.values,
    () => assign(),
    { flush: "post" }
);

/**
 * Überwacht die Änderung des Jahrs und bereitet
 * die Zeilen zur Darstellung der Tabelle vor.
 */
watch(
    () => props.idxYear,
    () => {
        actives.value = [];
        passives.value = [];

        nextTick(assign);
    },
    { flush: "post" }
);

defineExpose({
    amountOfRows,
    style,
    level,
    description,
    proportion,
    value
});
</script>

<template lang="pug">
table.report__taccount(
    ref="root"
)
    thead
        tr
            th
            th
                span Bezeichnung
            th.--text-right
                span Anteil
            th.--text-right
                span EUR

            th.th--divider
                span

            th
            th
                span Bezeichnung
            th.--text-right
                span Anteil
            th.--text-right
                span EUR

    tbody
        tr(
            v-for="idx in amountOfRows"

            :key="idx"
        )
            td
                .wrapper(:style="style({accessor: 'actives', idx: idx - 1})")
                    span.report__icon(:class="{'report--action': getHelp({accessor: 'actives', idx: idx - 1})}")
                        Icon(
                            v-if="getHelp({accessor: 'actives', idx: idx - 1})"

                            icon="report-info"

                            :fill="iconFillColor({accessor: 'actives', idx: idx - 1})"
                            :opacity="level({accessor: 'actives', idx: idx - 1}) !== 2 ? 0.45 : 1"

                            @click="openHelp({accessor: 'actives', idx: idx - 1})"
                        )

            td(
                :class="css({accessor: 'actives', idx: idx - 1})"

                @click="emitBalanceEntry({accessor: 'actives', idx: idx - 1})"
            )
                .wrapper(:style="style({accessor: 'actives', idx: idx - 1}, {width: '250px'})")
                    div(
                        :class="({'report--action': reference({accessor: 'actives', idx: idx - 1})})"
                    )
                        span.report__cell-text(v-html="description({accessor: 'actives', idx: idx - 1})")
                        .report__icon.report__icon--xs(
                            v-if="['report', 'finance'].includes(reference({accessor: 'actives', idx: idx - 1}))"
                        )
                            Icon(
                                v-if="reference({accessor: 'actives', idx: idx - 1}) === 'report'"

                                icon="report-drilldown"

                                :fill="iconFillColor({accessor: 'actives', idx: idx - 1})"
                                :opacity="level({accessor: 'actives', idx: idx - 1}) !== 2 ? 0.45 : 1"
                            )

                            Icon(
                                v-else

                                icon="report-cashflow"

                                :fill="iconFillColor({accessor: 'actives', idx: idx - 1})"
                                :opacity="level({accessor: 'actives', idx: idx - 1}) !== 2 ? 0.45 : 1"
                            )

            td(:class="css({accessor: 'actives', idx: idx - 1})")
                .wrapper.--text-right(:style="style({accessor: 'actives', idx: idx - 1}, {width: '86px'})")
                    span(v-html="proportion({accessor: 'actives', idx: idx - 1})")

            td(:class="css({accessor: 'actives', idx: idx - 1})")
                .wrapper.--text-right(:style="style({accessor: 'actives', idx: idx - 1}, {width: currencyWidth})")
                    span(v-html="value({accessor: 'actives', idx: idx - 1})")

            td.td--divider
                span

            td
                .wrapper(:style="style({accessor: 'passives', idx: idx - 1})")
                    span.report__icon(:class="{'report--action': getHelp({accessor: 'passives', idx: idx - 1})}")
                        Icon(
                            v-if="getHelp({accessor: 'passives', idx: idx - 1})"

                            icon="report-info"

                            :fill="iconFillColor({accessor: 'passives', idx: idx - 1})"
                            :opacity="level({accessor: 'passives', idx: idx - 1}) !== 2 ? 0.45 : 1"

                            @click="openHelp({accessor: 'passives', idx: idx - 1})"
                        )

            td(
                :class="css({accessor: 'passives', idx: idx - 1})"

                @click="emitBalanceEntry({accessor: 'passives', idx: idx - 1})"
            )
                .wrapper(:style="style({accessor: 'passives', idx: idx - 1}, {width: '250px'})")
                    div(
                        :class="({'report--action': reference({accessor: 'passives', idx: idx - 1})})"
                    )
                        span.report__cell-text(v-html="description({accessor: 'passives', idx: idx - 1})")
                        .report__icon.report__icon--xs(
                            v-if="['report', 'finance'].includes(reference({accessor: 'passives', idx: idx - 1}))"
                        )
                            Icon(
                                v-if="reference({accessor: 'passives', idx: idx - 1}) === 'report'"

                                icon="report-drilldown"

                                :fill="iconFillColor({accessor: 'passives', idx: idx - 1})"
                                :opacity="level({accessor: 'passives', idx: idx - 1}) !== 2 ? 0.45 : 1"
                            )

                            Icon(
                                v-else

                                icon="report-cashflow"

                                :fill="iconFillColor({accessor: 'passives', idx: idx - 1})"
                                :opacity="level({accessor: 'passives', idx: idx - 1}) !== 2 ? 0.45 : 1"
                            )

            td(:class="css({accessor: 'passives', idx: idx - 1})")
                .wrapper.--text-right(:style="style({accessor: 'passives', idx: idx - 1}, {width: '86px'})")
                    span(v-html="proportion({accessor: 'passives', idx: idx - 1})")

            td(:class="css({accessor: 'passives', idx: idx - 1})")
                .wrapper.--text-right(:style="style({accessor: 'passives', idx: idx - 1}, {width: currencyWidth})")
                    span(v-html="value({accessor: 'passives', idx: idx - 1})")
</template>

<style lang="scss">
.report.balance {
    .report__view {
        .report__table {
            table.report__taccount {
                thead tr th:first-child,
                .report__icon:first-of-type {
                    border-right: none;
                }

                tbody {
                    td.level1 {
                        span {
                            font-weight: 700;
                        }
                    }

                    td.level2 {
                        span {
                            font-weight: 600;
                        }
                    }
                }

                .wrapper {
                    &:hover {
                        .report--action {
                            .report__cell-text {
                                text-decoration: underline;
                                cursor: pointer;
                            }
                        }
                    }

                    .report--action {
                        position: relative;
                        float: left;
                        display: flex;
                        width: 100%;
                        height: 100%;
                    }

                    span {
                        width: 100%;
                    }
                }

                .th--divider,
                .td--divider {
                    border: none;

                    span {
                        padding: 0;
                        width: 16px;
                        background-color: $white;
                    }

                    & + th {
                        border: none;
                        box-shadow: 1px 0px 0px 0px $white inset, 2px 0px 0px 0px $athens-gray inset;
                    }

                    & + td {
                        border-left: 1px $white solid;
                    }
                }
            }
        }
    }
}
</style>
