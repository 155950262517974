import Scroller from "../index";

/**
 * Liefert einen Wert, der angibt, ob in der Richtung der übergebenen Skrollachse
 * geskrollt werden kann
 *
 * @this Scroller
 *
 * @param {"horizontal"|"vertical"} direction
 *
 * @returns {Boolean}
 */
const isScrollable = function(direction) {
    if (!this.isIFrame)
        return direction === "vertical"
            ? this.element.scrollHeight > this.element.clientHeight
            : this.element.scrollWidth > this.element.clientWidth && this.horizontal;

    return direction === "vertical"
        ? this.element.contentDocument.body.scrollHeight > this.element.clientHeight
        : this.element.contentDocument.body.scrollWidth > this.element.clientWidth && this.horizontal;
};

export default isScrollable;
