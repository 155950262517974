import Scroller from "../..";

function updateThumbBlocks(this: Scroller) {
    const scrollTrack = this.elements.barVert;

    if (!scrollTrack)
        return;

    const blockHeight = (scrollTrack.getBoundingClientRect().height / this.scrollerState.pages) - this.scrollerState.gap;
    const blockOffset = this.scrollerState.gap * this.scrollerState.pages / (this.scrollerState.pages - 1);

    if (scrollTrack.querySelectorAll(".scrollbar__thumb-block").length === this.scrollerState.pages)
        return scrollTrack
            .querySelectorAll<HTMLElement>(".scrollbar__thumb-block")
            .forEach((block, index) => {
                block.style.height = `${blockHeight}px`;
                block.style.top = `${index * (blockHeight + blockOffset)}px`;
            });

    scrollTrack.querySelectorAll(".scrollbar__thumb-block").forEach((block) => block.remove());

    new Array(this.scrollerState.pages)
        .fill(0)
        .forEach((_, index) => {
            const block = document.createElement("div");

            block.className = "scrollbar__thumb-block";

            block.style.height = `${blockHeight}px`;
            block.style.top = `${index * (blockHeight + blockOffset)}px`;

            block.addEventListener("click", () => {
                this.element.scrollTo({
                    top: (index * (this.element.scrollHeight - this.element.getBoundingClientRect().height)) / (this.scrollerState.pages - 1),
                    behavior: "instant" as ScrollBehavior
                });

                this.scrollerState.page = index;
            });

            scrollTrack.appendChild(block);
        });
}

export default updateThumbBlocks;
