import $ from "@/utils/dom";
import Scroller from "@/utils/scroll/index";

/**
 * Berechnet die Anzahl der vollständig sichtbaren Zeilen in der DataView.
 *
 * @returns {Number}
 */
const getAmount = function () {
    /**
     * @type {Scroller}
     */
    const scrollInstance = this.ctx.scrollInstance;

    const height = scrollInstance.height() - this.tableHead.getBoundingClientRect().height;
    const rowHeight = $(this.tableBody).find("tr:first-child").get(0).getBoundingClientRect().height;

    return Math.floor(height / rowHeight);
};

export default getAmount;
