/**
 * Eine Klasse um eine Pending-Animation anzuzeigen.
 */
class PendingAnimator {
    /**
     * Ein Container für Span-Elemente in dem die Nachricht angezeigt wird.
     */
    private container: HTMLSpanElement;

    /**
    * Die Nachricht die angezeigt werden soll.
    */
    private message: string = "";

    /**
     * Erzeugt eine Pending-Animation.
     *
     * @param {HTMLSpanElement} container Ein Container für Span-Elemente in dem die Nachricht angezeigt wird.
     */
    constructor(container: HTMLSpanElement) {
        this.play = this.play.bind(this);
        this.fadeInOut = this.fadeInOut.bind(this);
        this.blur = this.blur.bind(this);
        this.toggleAnimation = this.toggleAnimation.bind(this);

        this.container = container;
    }

    /**
    * Setzt eine neu Nachricht.
    *
    * @param message Neue Nachricht
    */
    setMessage(message:string) { this.message = message }

    /**
     * Spielt die Animation ab.
     */
    public play() {
        const boxIn = this.container.querySelector(".in") as HTMLSpanElement;
        const boxOut = this.container.querySelector(".out") as HTMLSpanElement;

        boxIn.innerHTML = this.message;

        requestAnimationFrame(() => {
            this.container.classList.add("fade-in-out");
            boxOut.addEventListener("animationend", this.fadeInOut);
        });
    }

    /**
     * Startet die Fade-Animation.
     */
    fadeInOut() {
        const boxIn = this.container.querySelector(".in") as HTMLSpanElement;
        const boxOut = this.container.querySelector(".out") as HTMLSpanElement;

        boxIn.removeEventListener("animationend", this.fadeInOut);
        boxOut.removeEventListener("animationend", this.fadeInOut);

        this.container.classList.remove("fade-in-out");

        this.toggleCssClasses();
        this.toggleAnimation();
    };

    /**
     * Wechselt die CSS-Klassen.
     */
    toggleCssClasses() {
        const boxIn = this.container.querySelector(".in");
        const boxOut = this.container.querySelector(".out");

        boxIn?.classList.add("out");
        boxIn?.classList.remove("in");

        boxOut?.classList.add("in");
        boxOut?.classList.remove("out");
    };

    /**
     * Wechselt die Animation.
     */
    toggleAnimation() {
        const boxOut = this.container.querySelector(".out");
        const boxIn = this.container.querySelector(".in");

        boxIn?.removeEventListener("animationend", this.toggleAnimation);
        boxOut?.removeEventListener("animationend", this.toggleAnimation);

        this.container.classList.remove("fade-in-out");
        this.container.classList.remove("blur");

        const oldText = boxOut?.innerHTML;

        if (oldText === this.message)
            return requestAnimationFrame(this.blur);

        requestAnimationFrame(this.play);
    };

    /**
     * Startet die Blur-Animation.
     */
    blur() {
        const boxOut = this.container.querySelector(".out")!;

        requestAnimationFrame(() => {
            this.container.classList.add("blur");
            boxOut.addEventListener("animationend", this.toggleAnimation);
        });
    };

    /**
     * Entfernt alle Ereignishandler
     */
    destroy() {
        const boxOut = this.container.querySelector(".out");
        const boxIn = this.container.querySelector(".in");

        boxIn?.removeEventListener("animationend", this.toggleAnimation);
        boxOut?.removeEventListener("animationend", this.toggleAnimation);

        boxIn?.addEventListener("animationend", this.fadeInOut);
        boxOut?.addEventListener("animationend", this.fadeInOut);
    }
}

export default PendingAnimator;
