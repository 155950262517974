import $ from "@/utils/dom";
import debounce from "lodash/debounce";
import isScrollable from "../private.isScrollable";
import Scroller from "../../index";
import updateThumbBlocks from "./updateThumbBlocks";
import updateThumbSize from "./updateThumbSize";
import updateThumbPosition from "./updateThumbPosition";
import notifyState from "./notifyState";

/**
 * Eine Klasseninstanz, die Größenänderungen des Skrollcontainers überwacht
 */
class ScrollerResizeWatcher {
    private scroller: Scroller;
    private destroyed = false;

    /**
     * C'tor
     *
     * @param scroller Die Scroller-Instanz
     */
    constructor(scroller: Scroller) {
        this.scroller = scroller;

        this.watch();;
    }

    /**
     * Überwacht Größenänderungen des Skrollcontainers zur Anpassung
     * der Höhen sowie Breiten der Skrollbalken und Skrollthumbs.
     */
    private watch = debounce(() => {
        if (this.scroller.isIFrame)
            return;

        if (this.destroyed)
            return;

        requestAnimationFrame(() => {
            if (!this.scroller.element)
                return this.watch();

            if (!this.scroller.elements.scrollContainer)
                return this.watch();

            const boundings = {
                width: this.scroller.element.getBoundingClientRect().width,
                height: this.scroller.element.getBoundingClientRect().height
            };

            const callbackResize = this.scroller.callbacks.resize || (() => {});

            let hasChanged = false;

            if (this.scroller.scrollerState.width !== boundings.width) {
                hasChanged = true;

                this.scroller.scrollerState.width = boundings.width;
                callbackResize();
            }

            if (this.scroller.scrollerState.height !== boundings.height) {
                hasChanged = true;

                this.scroller.scrollerState.height = boundings.height;
                callbackResize();
            }

            const scrollSize = {
                vertical: !this.scroller.isIFrame
                    ? this.scroller.element.scrollHeight
                    : (this.scroller.element as HTMLIFrameElement).contentDocument?.body.scrollHeight,

                horizontal: !this.scroller.isIFrame
                    ? this.scroller.element.scrollWidth
                    : (this.scroller.element as HTMLIFrameElement).contentDocument?.body.scrollWidth
            };

            if (this.scroller.scrollerState.horizontal.lastScrollSize !== scrollSize.horizontal) {
                hasChanged = true;

                this.scroller.scrollerState.horizontal.lastScrollSize = scrollSize.horizontal ?? 0;
            }

            if (this.scroller.scrollerState.vertical.lastScrollSize !== scrollSize.vertical) {
                hasChanged = true;

                this.scroller.scrollerState.vertical.lastScrollSize = scrollSize.vertical ?? 0;
            }

            if (hasChanged)
                this.scroller.scrollerState.pages = Math.ceil(Math.round(this.scroller.element.scrollHeight / this.scroller.element.getBoundingClientRect().height * 100) / 100);

            const vertScrollable = isScrollable.call(this.scroller, "vertical");
            const hozScrollable = isScrollable.call(this.scroller, "horizontal");

            if (vertScrollable) {
                if (this.scroller.elements.scrollContainer.classList.contains("scrollcontainer--no-vert"))
                    $(this.scroller.elements.scrollContainer).removeClass("scrollcontainer--no-vert");

                if (hasChanged) {
                    updateThumbSize.call(this.scroller, "vertical");
                    updateThumbPosition.call(this.scroller, {
                        axis: "vertical",
                        scrollTo: !this.scroller.isIFrame
                            ? this.scroller.element.scrollTop
                            : (this.scroller.element as HTMLIFrameElement).contentDocument?.body.scrollTop ?? 0
                    });

                    if (this.scroller.scroll === "page") {
                        updateThumbBlocks.call(this.scroller);

                        this.scroller.element.scrollTo({
                            top: this.scroller.scrollerState.page * this.scroller.element.getBoundingClientRect().height
                        });
                    }
                }
            } else if (!this.scroller.elements.scrollContainer.classList.contains("scrollcontainer--no-vert"))
                $(this.scroller.elements.scrollContainer).addClass("scrollcontainer--no-vert");


            if (hozScrollable) {
                if (this.scroller.elements.scrollContainer.classList.contains("scrollcontainer--no-hoz"))
                    $(this.scroller.elements.scrollContainer).removeClass("scrollcontainer--no-hoz");

                if (hasChanged) {
                    updateThumbSize.call(this.scroller, "horizontal");
                    updateThumbPosition.call(this.scroller, {
                        axis: "horizontal",
                        scrollTo: !this.scroller.isIFrame
                            ? this.scroller.element.scrollLeft
                            : (this.scroller.element as HTMLIFrameElement).contentDocument?.body.scrollLeft ?? 0
                    });
                }
            } else if (!this.scroller.elements.scrollContainer.classList.contains("scrollcontainer--no-hoz"))
                $(this.scroller.elements.scrollContainer).addClass("scrollcontainer--no-hoz");

            hasChanged && notifyState.call(this.scroller);

            if (!this.scroller.registeredHandler.resize.destroyed)
                this.watch();
        })
    }, 250)

    destroy() {
        this.destroyed = true;
    }
}

export default ScrollerResizeWatcher;
