import Scroller from "../../index";

type ScrollerBarKeys = {
    vertical: {
        $track: HTMLElement;
        thumb: HTMLElement;
        thumbSize: number;
        thumbOffset: number;
        lastThumbPosition: number;
        pointerPosition: number;

        trackSize: "clientHeight";
        extendSize: "scrollHeight";
        viewportSize: "clientHeight";
        pointerDirection: "clientY";
        scrollDirection: "scrollTop";
        translation: string;
    };

    horizontal: {
        $track: HTMLElement;
        thumb: HTMLElement;
        thumbSize: number;
        thumbOffset: number;
        lastThumbPosition: number;
        pointerPosition: number;

        trackSize: "clientWidth";
        extendSize: "scrollWidth";
        viewportSize: "clientWidth";
        pointerDirection: "clientX";
        scrollDirection: "scrollLeft";
        translation: string;
    };
}

/**
 * Verschiebt die Scrollthumbs bei gedrückter Maustaste und Bewegung des Mauszeigers
 *
 * @this Scroller
 */
function dragThumbOnMove(this: Scroller, e: MouseEvent) {
    if (!this.scrollerState.vertical.isDragging && !this.scrollerState.horizontal.isDragging)
        return;

    if (!this.element)
        return;

    e.stopPropagation();
    e.preventDefault();

    const barKeys: ScrollerBarKeys = {
        vertical: {
            $track: this.elements.barVert,
            thumb: this.elements.thumbVert,
            thumbSize: this.scrollerState.vertical.thumbHeight,
            thumbOffset: this.scrollerState.vertical.top,
            lastThumbPosition: this.scrollerState.vertical.lastThumbPosition,
            pointerPosition: this.scrollerState.vertical.pointerPos,

            trackSize: "clientHeight",
            extendSize: "scrollHeight",
            viewportSize: "clientHeight",
            pointerDirection: "clientY",
            scrollDirection: "scrollTop",
            translation: "translate3d(0,px,0)"
        },

        horizontal: {
            $track: this.elements.barHoz,
            thumb: this.elements.thumbHoz,
            thumbSize: this.scrollerState.horizontal.thumbWidth,
            thumbOffset: this.scrollerState.horizontal.left,
            lastThumbPosition: this.scrollerState.horizontal.lastThumbPosition,
            pointerPosition: this.scrollerState.horizontal.pointerPos,

            trackSize: "clientWidth",
            extendSize: "scrollWidth",
            viewportSize: "clientWidth",
            pointerDirection: "clientX",
            scrollDirection: "scrollLeft",
            translation: "translate3d(px,0, 0)"
        }
    };

    const options = this.scrollerState.vertical.isDragging ? barKeys.vertical : barKeys.horizontal;

    // Die Größe des Tracks (Höhe, falls vertikal, Breite, falls horizontal):
    const trackSize = options.$track[options.trackSize] - options.thumbSize - options.thumbOffset;

    // Die noch verfügbare Scrollhöhe bzw. Breite:
    const scrollSize = !this.isIFrame
        ? this.element[options.extendSize] - this.element[options.viewportSize]
        : (this.scrollerState.vertical.isDragging ? (this.element as HTMLIFrameElement).contentDocument?.body.scrollHeight  ?? 0 : (this.element as HTMLIFrameElement).contentDocument?.body.clientWidth ?? 0) - this.element[options.viewportSize];

    // Der zurückgelegte Weg des Mauszeigers:
    const mouseDistance = e[options.pointerDirection] - options.pointerPosition + options.lastThumbPosition - options.thumbOffset;

    // Der zurückgelegte Weg des Mauszeigers in Prozent:
    const percentage = mouseDistance / trackSize;

    // Die neue Position:
    const position = percentage * scrollSize;

    if (this.scroll === "normal")
        return window.requestAnimationFrame(() => {
            !this.isIFrame
                ? this.element[options.scrollDirection] = position
                : (this.element as HTMLIFrameElement).contentDocument && ((this.element as HTMLIFrameElement).contentDocument!.body[options.scrollDirection] = position);
        });

    const clamped = Math.min(Math.max(0, mouseDistance), trackSize);
    const transformation = "translate3d(0,px,0)".replace("px", `${clamped}px`);
    const pageSize = (options.$track[options.trackSize] / this.scrollerState.pages);
    const page = Math.round(clamped / pageSize);

    if (page !== this.scrollerState.page) {
        this.element.scrollTo({
            top: (page * (this.element.scrollHeight - this.element.getBoundingClientRect().height)) / (this.scrollerState.pages - 1),
            behavior: "smooth"
        });

        this.scrollerState.page = page;
    }

    this.scrollerState[this.scrollerState.vertical.isDragging ? "vertical" : "horizontal"].thumbPosition = clamped;

    window.requestAnimationFrame(() => options.thumb.style["transform"] = transformation);
};

export default dragThumbOnMove;
