<script setup lang="ts">
import type { CandlestickData, LineData, IChartApi, ISeriesApi, SeriesType, UTCTimestamp } from "lightweight-charts";
import type { Timeframe } from "./security-info";
import { onMounted, ref, watch, onBeforeUnmount } from "vue";
import { createChart, CrosshairMode, LineStyle } from "lightweight-charts";
import SecurityInfo from "./security-info";
import moment from "moment";
import Color from "@/utils/color";

const emit = defineEmits(["ready"]);

const container = ref<HTMLElement>();

let chart:IChartApi | null;
let series:ISeriesApi<SeriesType> | null;

const props = defineProps<{
    agio?: number,
    isin: string,
    name: string,
    prevmonth?: number,
    prevyear?: number,
    security?: SecurityInfo,
    timeframe: Timeframe,
    type: "Area" | "Candlestick"
}>();

/**
 * Liefert eine Map von Methode n zur Ermittlung des Startdatums der
 * darzustellenden (zoomende) Daten in der Chart
 */
const StartDateMap = new Map<string, Function>([
    ["year", () => moment(moment.now()).subtract({ year: 1 }).unix()],
    ["yeartodate", () => moment(moment.now()).startOf("year").unix()],
    ["halfyear", () => moment(moment.now()).subtract({ months: 6 }).unix()],
    ["quarter", () => moment(moment.now()).subtract({ months: 3 }).unix()],
    ["month", () => moment(moment.now()).subtract({ months: 1 }).unix()]
]);

const configureTimeframe = () => {
    if (!chart)
        return;

    chart.priceScale("right").applyOptions({
        scaleMargins: {
            top: 0.2,
            bottom: 0.1,
        },
    });

    chart.timeScale().applyOptions({
        fixLeftEdge: true,
        fixRightEdge: true
    });

    if (props.timeframe !== "all") {
        const startDate = StartDateMap.get(props.timeframe || "month") || (() => moment(moment.now()).unix());

        return chart.timeScale().setVisibleRange({
            from: startDate() as UTCTimestamp,
            to: moment(moment.now()).unix() as UTCTimestamp
        });
    }

    chart.timeScale().fitContent();
};

const updateChart = ({value, type, timeframe}:{value?: SecurityInfo, type: "Area" | "Candlestick", timeframe: Timeframe}) => {
    if (!chart)
        return;

    if (!value)
        return;

    if (series)
        chart.removeSeries(series);

    let params = value.createSeries(timeframe, type);
    let s: ISeriesApi<SeriesType> | undefined;

    s = type === "Area"
        ? chart.addAreaSeries()
        : chart.addCandlestickSeries();

    const lineColor = getComputedStyle(document.body)
        .getPropertyValue("--color-report-security-level1")
        .trim();

    const baseColor = new Color(lineColor);
    const topColor = baseColor.alpha(.5).toString();
    const bottomColor = baseColor.alpha(0).toString();

    s.applyOptions({
        lineColor,
        topColor,
        borderVisible: false,
        bottomColor,
        priceLineVisible: false
    });

    if (props.agio)
        s.createPriceLine({
            price: props.agio,
            color: baseColor.toString(),
            lineWidth: 1,
            lineStyle: LineStyle.Dotted,
            lineVisible: true,
            axisLabelVisible: true,
            title: ""
        });

    if (type === "Area")
        s.setData(params.data as LineData[]);
    else
        s.setData(params.data as CandlestickData[]);

    series = s;
};

watch(
    [
        () => props.security,
        () => props.type,
        () => props.timeframe
    ],
    () => {
        updateChart({
            type: props.type,
            value: props.security,
            timeframe: props.timeframe
        });

        configureTimeframe();
    }
);

const onResize = () => {
    if (!chart)
        return;

    if (!container.value)
        return;

    chart.resize(container.value.clientWidth, container.value.clientHeight);
};

onMounted(async () => {
    if (!container.value)
        return;

    const crosshairColor = getComputedStyle(document.body)
        .getPropertyValue("--color-report-security-level1")
        .trim();

    chart = createChart(container.value, {
        localization: {
            locale: "de"
        },
        grid: {
            horzLines: {
                visible: false
            },
            vertLines: {
                visible: false
            },
        },
        timeScale: {
            lockVisibleTimeRangeOnResize: true
        },
        crosshair: {
            horzLine: {
                color: "black",
                labelBackgroundColor: crosshairColor
            },
            vertLine: {
                color: "black",
                labelBackgroundColor: crosshairColor
            },
            mode: CrosshairMode.Magnet
        },
        handleScale: {
            mouseWheel: false,
            pinch: false
        }
    });

    onResize();

    window.addEventListener("resize", onResize);

    updateChart({
        type: props.type,
        value: props.security,
        timeframe: props.timeframe
    });

    configureTimeframe();

    emit("ready", chart);
});

onBeforeUnmount(() => {
    window.removeEventListener("resize", onResize);

    if (!chart)
        return;

    chart.remove();
    chart = null;
});
</script>

<template lang="pug">
.chart
    div(
        ref="container"
    )

    .info
        h1 {{ name }}
        p
            span {{ isin }}

            PerformanceLabelVue(
                v-if="prevmonth"
                suffix="(M)"

                :value="prevmonth"
            )

            PerformanceLabelVue(
                v-if="prevyear"
                suffix="(J)"

                :value="prevyear"
             )
</template>

<style lang="scss" scoped>
    div {
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    .overlay {
        background: rgba(white, .75);
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;

        & > svg {
            height: 1.5em;
            fill: var(--color-highlight);
            animation: spin 1s linear infinite;

            @keyframes spin {
                100% {
                    transform: rotate(1turn);
                }
            }
        }
    }

    .info {
        left: 2em;
        top: 2em;
        right: unset;
        bottom: unset;
        z-index: 1;
        width: auto;
        height: auto;
        text-align: left;

        h1 {
            font-weight: 400;
            font-size: 1.5em;
            color: var(--color-accent-1);
            padding: 0;
            margin: 0;
            margin-bottom: .25em;
        }

        p {
            font-family: "Roboto Mono", monospace;
            font-size: .85em;
            margin: 0;
            padding: 0;
            margin-top: .25em;
            color: var(--color-breadcrumb-text);

            span {
                margin-right: 1ch;
            }
        }
    }

    .timeframe {
        position: absolute;
        right: 85px;
        top: 2em;
        bottom: unset;
        left: unset;
        z-index: 3;
    }
</style>
