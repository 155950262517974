import { escapePath } from "../pathConverter";
import axios from "axios";
import combineURLs from "axios/lib/helpers/combineURLs";
import Directory from "./models/Directory";

/**
 * Weist den DMS-Service an, Ordner zu erzeugen.
 *
 * @param {{url: String, folders: String[], axiosConfig: Object, next: Function, cancelled: Function}}} options
 */
const createFolders = async({url, folders = [], axiosConfig, next = () => { return true; }, cancelled = () => {}}) => {
    for (let i = 0; i < folders.length; i++) {
        const folder = folders[i];
        const abortController = new AbortController();

        axiosConfig.signal = abortController.signal;

        try {
            const response = await axios.post(
                combineURLs(url, escapePath(folder).replace(/\/{2,}/g, "/")),
                {},
                axiosConfig,
            );

            const directory = new Directory({
                path: folder,
                location: response.headers && response.headers.location
            });

            if (!next({abortController, directory}))
                return;
        } catch (e) {
            if (axios.isCancel(e)) {
                cancelled();

                return;
            }

            if (!next({abortController}))
                return;
        }
    }
};

export default createFolders;
