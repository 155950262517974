/**
 * Kodiert Sonderzeichen eines Pfads in ein URI-freundliches Format.
 *
 * @param {String} path
 *
 * @returns {String}
 */
const escapePath = (path = "") => {
    path = path.replace(/\/{2,}/g, "/");

    let parts = path.split("/");

    parts = parts.map(p => encodeURIComponent(p));

    return parts.join("/");
};

/**
 * Enkodiert Sonderzeichen einer URI.
 *
 * @param {String} path
 *
 * @returns {String}
 */
const unescapePath = (path = "") => {
    path = path
        .replace(/\/{2,}/g, "/");

    let parts = path.split("/");

    parts = parts.map(p => decodeURIComponent(p));

    return parts
        .join("/")
        .replace(/\+/g, " ");
};

export { escapePath, unescapePath };
