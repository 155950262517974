import FilterModel from "./FilterModel";
import FilterOperator from "./FilterOperator";

/**
 * Eine Hilfsklasse zur Vereinfachung der Handhabung von Filtern in Ansichten
 */
class FilterUtils {
    /**
     * Wandelt ein Objekt zum Typ {@linkcode FilterModel} um
     *
     * @param {{name: String, value: Any, operator: FilterOperator}}
     *
     * @returns {FilterModel}
     */
    static mapFilter({ name, value, operator = "equals", scope }: { name: string, value: any, operator: TComparisionMethod, scope: ClientScope }): FilterModel {
        return new FilterModel({
            name,
            operator,
            scope,
            value
        });
    }

    /**
     * Wandelt eine Liste von Objekten zu einer List vom Typ {@linkcode FilterModel[]}
     *
     * @param {{name: string, value: any, operator: string}[]} filters
     *
     * @returns {FilterModel}
     */
    static mapFilters(filters: IFilter[]): FilterModel[] {
        return filters && filters.map(filter => new FilterModel(filter)) || [];
    }

    /**
     * Konvertiert ein Json-Element in eine Liste von {@linkcode FilterModel}
     *
     * @param {[key: string]: any} json Das zu mappende Json-Element
     */
    static mapJson(json: { [key: string]: any }): FilterModel[] {
        return Object.entries(json || {}).map(entry => {
            if (entry instanceof FilterModel)
                return entry;

            return new FilterModel({
                name: entry[0],
                value: entry[1]
            });
        });
    }

    /**
     * Erzeugt aus einer Liste von {@linkcode FilterModel} einen Query-String
     *
     * @param {FilterModel[]} filters
     *
     * @returns {string}
     */
    static toString(filters: FilterModel[]): string {
        const parser: { [key: string]: (value: any | string) => string | number } = {
            "default": (value: any) => `"${value}"`,
            "number": (value: string) => parseInt(value, 10)
        };

        const query = filters
            .map(({ name, value, operator }: { name: string, value: any, operator: TComparisionMethod }) => {
                if (value === null)
                    return name;

                const usableParser = parser[typeof value] ?? parser.default;

                return `${name} ${FilterOperator[operator]} ${usableParser(value)}`;
            })
            .join(" && ");

        return query;
    }

    /**
     * Erzeugt aus einer Liste von {@linkcode FilterModel} oder einem {@linkcode FilterModel} ein JSON-Objekt
     *
     * @param {FilterModel[]|FilterModel} filters
     *
     * @returns {[key:string]:any}
     */
    static toJson(filters: FilterModel[] | FilterModel = []): { [key: string]: any } | undefined {
        const isValidArray = Array.isArray(filters) && !filters.map(filter => filter instanceof FilterModel).includes(false);
        const isValidModel = filters instanceof FilterModel;

        if (![isValidArray, isValidModel].includes(true))
            return;

        if (isValidArray) {
            const mappedFilters = new Map(filters.map(({ name, value }) => [name, value]));

            return Object.fromEntries(mappedFilters);
        }

        return (filters as FilterModel).toJson();
    }

    /**
     * Vereint {@linkcode FilterModel}.
     * Dabei werden {@linkcode FilterModel} von rechts nach links überschrieben
     *
     * @param {...FilterModel[]} args Die zu vereinenden Filter
     *
     * @returns {FilterModel[]}
     */
    static assign(...args: FilterModel[] | FilterModel[][]): FilterModel[] {
        const uniqueFilters = [...new Map(args.flat().map(filter => [filter.name, filter])).values()];

        return this.mapFilters(uniqueFilters);
    }
}

export default FilterUtils;
