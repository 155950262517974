import axios from "axios";
import combineURLs from "axios/lib/helpers/combineURLs";
import DriveFileRelease from "../models/DriveFileRelease";
import { escapePath } from "../utils/pathConverter";

class DriveReleases {
    /**
     * Privates Feld: Authorisierungsmethoden
     *
     * @property {DriveAuth} [_auth=null]
     */
    _auth = null;

    /**
     * Privates Feld: Basisadresse.
     *
     * @property {String} [_baseUrl=null]
     */
    _baseUrl = null;

    /**
    * C'tor
    *
    * Setzt die Basisadresse für den Api-Aufruf.
    *
    * @param {String} baseUrl
    * @param {DriveAuth} auth
    */
    constructor(baseUrl, auth) {
        this._auth = auth;
        this._baseUrl = baseUrl;
    }

    /**
    * Ruft alle Berechtigungen einer Datei ab.
    *
    * @param {{path: String, abortController?: AbortController}} options
    *
    * @returns {Promise<DriveFileRelease[]>} Die Dateiberechtigungen
    */
    async getReleases({path, abortController = null}) {
        path = escapePath(`share/${path}`);

        const { data = [] } = await axios.get(combineURLs(this._baseUrl, path), {
            signal: abortController.signal,
            headers: {
                Authorization: `Bearer ${this._auth.AccessToken}`
            }
        });

        let driveFileReleases = [];

        data.forEach(release => driveFileReleases.push(new DriveFileRelease({ contactId: release.contactId, access: release.access, inheritedBy: release.inheritedBy })));

        return driveFileReleases;
    }

    /**
    * Speichert eine Dateifreigabe.
    *
    * @param {{path: String, release: DriveFileRelease}} options
    */
    async saveRelease({path, release}) {
        path = escapePath(`share/${path}`);

        await axios.put(combineURLs(this._baseUrl, path), {
            access: release.access
        }, {
            headers: {
                Authorization: `Bearer ${this._auth.AccessToken}`
            }
        });
    }

    /**
    * Entfernt eine Dateifreigabe.
    *
    * @param {{path: String, user: String}} options
    */
    async removeRelease({path, user}) {
        let qsa = user ? `?user=${user}` : "";
        path = escapePath(`share/${path}`);

        await axios.delete(combineURLs(this._baseUrl, `${path}${qsa}`), {
            headers: {
                Authorization: `Bearer ${this._auth.AccessToken}`
            }
        });
    }
}

export default DriveReleases;
