type CrmContactConstructorArgs = {
    id: string
    guid: string
    clientNumber?: string
    firstName?: string
    lastName: string
    image?: string
    gender?: "male" | "female"

    address: Address
    emailAddresses?: EmailAddress[]
    phoneNumbers?: PhoneNumber[]
}

/**
 * Ein Crm Kontakt-Model
 */
export default class CrmContact {
    public readonly id: string;
    public readonly guid: string;
    public readonly clientNumber?: string;

    public address: Address;
    public emailAddresses?: EmailAddress[];
    public phoneNumbers?: PhoneNumber[];
    public lastName: string;

    public image?: string;
    public firstName?: string;
    public gender?: "male" | "female";

    constructor({ id, clientNumber, firstName, lastName, image, gender, guid, address, emailAddresses, phoneNumbers }: CrmContactConstructorArgs) {
        this.id = id;
        this.guid = guid;
        this.clientNumber = clientNumber;
        this.firstName = firstName;
        this.lastName = lastName;
        this.image = image;
        this.gender = gender;
        this.address = address;
        this.emailAddresses = emailAddresses;
        this.phoneNumbers = phoneNumbers;
    }
};
