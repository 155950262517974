import Scroller from "../../index";
import updateThumbPosition from "./updateThumbPosition";

/**
 * Aktualisiert die Positionen der proprietären Thumbs und ruft bei Bedarf
 * die gespeicherten {@linkcode Scroller.callbacks}-Callbackfunktionen
 * onScrollHorizontal und/oder onScrollVertical auf
 *
 * @this Scroller
 *
 * @param {[Number, Number]} scrollPositions
 */
const updateThumbPositions = function(scrollPositions) {
    const scrollTop = scrollPositions[0];
    const scrollLeft = scrollPositions[1];
    const lastScrollTop = this.scrollerState.vertical.lastNativeScrollPos;
    const lastScrollLeft = this.scrollerState.horizontal.lastNativeScrollPos;

    this.scrollerState.vertical.lastNativeScrollPos = scrollTop;
    this.scrollerState.horizontal.lastNativeScrollPos = scrollLeft;

    if (scrollTop !== lastScrollTop) {
        !this.isIFrame && updateThumbPosition.call(this, {
            axis: "vertical",
            scrollTo: scrollTop
        });

        this.callbacks.onScrollVertical.forEach((callback) => callback(scrollTop, this.scrollerState.page + 1));
    }

    if (scrollLeft !== lastScrollLeft) {
        !this.isIFrame && updateThumbPosition.call(this, {
            axis: "horizontal",
            scrollTo: scrollLeft
        });

        this.callbacks.onScrollHorizontal.forEach((callback) => callback(scrollLeft));
    }
};

export default updateThumbPositions;
