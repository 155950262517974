import { nextTick } from "vue";
import Scroller from "@/utils/scroll";

export default {
    /**
     * Aktualisiert die Tabelle.
     */
    refresh() {
        nextTick(() => {
            if (!this.$refs.reportView)
                return;

            if (!this.$refs.reportView.querySelector(".report__table.taccount"))
                return;

            this.scrollInstance && this.scrollInstance.destroy();

            this.scrollInstance = new Scroller({
                element: this.$refs.reportView.querySelector(".report__table.taccount"),
                height: "calc(100% - 36px)",

                bars: {
                    top: 29,
                    minSize: 50
                }
            });
        });
    }
};
