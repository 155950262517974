import Scroller from "../index";

/**
 * Prüft, ob der Inhaltsbereich in eine angefragte Richtung geskrollt werden kann.
 *
 * @this Scroller
 *
 * @param {"top"|"bottom"} direction
 *
 * @returns {Boolean}
 */
const overflows = function(direction) {
    if (direction === "bottom")
        return !this.isIFrame
            ? this.element.scrollHeight - this.element.scrollTop > this.element.clientHeight
            : this.element.contentDocument.body.scrollHeight - this.element.contentDocument.body.scrollTop > this.element.clientHeight;


    return this.element.scrollTop - (parseFloat(window.getComputedStyle(this.element, null).getPropertyValue("padding-top")) || 0) + 10  > 0;
};

export default overflows;
