const KEY = {
    UP: 38,
    DOWN: 40,
    LEFT: 37,
    RIGHT: 39,
    PAGE_UP: 33,
    PAGE_DOWN: 34,
    PAGE_END: 35,
    PAGE_START: 36,
    SPACE: 32
};


export default KEY;
