import { isIOS } from "@/utils/browser";
import Scroller from "../index";

/**
 * Hängt das Stylesheet zum ausblenden der nativen Scrollbar ein.
 */
const appendStylesheet = () => {
    if (document.querySelector("style#scrollarea"))
        return;

    const style = document.createElement("style");
    style.id = "scrollarea";

    style.innerHTML = `
        .scrollarea > *::-webkit-scrollbar,
        .scrollarea > *::-webkit-scrollbar-corner {
            display: none !important;
            width: 0px !important;
            height: 0px !important;
            visibility: hidden !important;
            background: transparent !important;
        },
        .scrollarea > * {
            -ms-overflow-style: none;
        }`;

    document.body.appendChild(style);
};

/**
 * Private Methode der Klasse {@linkcode Scroller}
 *
 * Bereitet das {@linkcode HTMLElement} für die Nutzung der proprietären Skrollbar vor.
 *
 * @param {{element: HTMLElement, scroll: "normal" | "page"}} options
 */
const setupBrowser = ({element, scroll}) => {
    if (!element.style)
        return;

    appendStylesheet();

    element.style.overflow = scroll === "normal" ? "scroll" : "hidden";
    element.style["scrollbar-width"] = "none";

    if (isIOS)
        element.style.WebkitOverflowScrolling = scroll === "normal" ? "touch" : "hidden";
};

export default setupBrowser;
