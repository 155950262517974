import Scroller from "../index";

/**
 * Ersetzt den Html-Baum der proprietären Skrollbar mit der nativen Skrollbar
 *
 * @this Scroller
 */
const detachNodes = function() {
    if (!this.element)
        return;

    if (!this.elements.scrollContainer)
        return;

    if (!this.elements.scrollContainer.parentNode)
        return;

    this.elements.scrollContainer.parentNode.insertBefore(this.element, this.elements.scrollContainer);
    this.elements.scrollContainer.remove();
};

export default detachNodes;
