/**
 * Formatiert ein Datum und liefert das Ergebnis als Object zurück.
 *
 * @param date - Das Datumsobjekt.
 *
 * @returns Das formatierte Datum.
 */
const formatDate = (date: Date | string | number): IFormattedDate => {
    let d = new Date(date);
    let year = `0000${d.getFullYear()}`.slice(-4);
    let month = `00${d.getMonth() + 1}`.slice(-2);
    let day = `00${d.getDate()}`.slice(-2);
    let hour = `00${d.getHours()}`.slice(-2);
    let minutes = `00${d.getMinutes()}`.slice(-2);

    return {
        date: `${day}.${month}.${year}`,
        time: `${hour}:${minutes}`,
        nativeDate: d
    };
};

export default formatDate;
