/**
 * Eine benutzerdefinierte Fehlerklasse, die verwendet wird, um anzuzeigen, dass ein Plugin fehlt.
 */
class MissingPlugin extends Error {
    /**
     * @inheritdoc
     */
    constructor(message: string) {
        super(message);

        this.name = "MissingPlugin";
    }
}

export default MissingPlugin;
