import { v4 as uuidv4 } from "uuid";
import cloneDeep from "lodash/cloneDeep";
import difference from "lodash/difference";
import remove from "lodash/remove";
import uniq from "lodash/uniq";
import Simplebar from "simplebar";
import "simplebar/dist/simplebar.css";
import placeholder from "./placeholder";
import { store } from "./store";
import {
    clearSelection,
    createList,
    createOptions,
    bindEvents,
    closeLists,
    updateOptions,
    highlightOptions,
    adjustWidth,
    adjustSelection
} from "./lib";
import Toolkit from "@/utils/Toolkit";

Simplebar.removeObserver();

/**
 * Eine Klasse zur Generierung eines verbesserten Select-Controls.
 */
export default class Select {
    /**
     * Ein Wert, ob die Breite der Liste animiert werden soll.
     */
    animate: boolean

    /**
     * Ein Wert, ob die Breite der Liste automatisch an die Breite des Inhalts angepasst werden soll
     */
    autoAdjust: boolean

    /**
     * Die berechnete Breite der Liste.
     */
    calcedWidth: number

    /**
     * Ein Wert, der angibt, ob die Auswahl aufgehoben werden kann
     */
    clearable: boolean = false

    /**
     * Gibt an, ob der Abbruch der Auswahl durchgeführt wird.
     */
    cancelling: boolean = false;

    /**
     * Ein Wert, der angibt, ob nach Aufhebung der Auswahl das Steuerelement horizontal verkleinert werden
     * soll
     */
    collapsible: boolean = false

    /**
     * Zu ergänzende Css-Klassen.
     */
    cssClasses: string

    /**
     * Der Status, ob die Einblenden-Animation der Liste verzögert werden soll.
     */
    delay: boolean

    /**
     * Der Selektor, in dem das neue Select in den Dom eingehangen wird.
     */
    dest: string

    /**
     * Der Deaktiviert-Status des Selects.
     */
    disabled: boolean
    /**
     * Der Fokus-Status des Selects.
     */
    focused: boolean

    /**
     * Der Hover-Status.
     */
    hovering: boolean

    /**
     * @inheritdoc
     */
    iconized: boolean

    /**
     * Der Status, dass es sich um ein Select mit Darstellung von Icons handelt.
     */
    id: string

    /**
     * Die Dom-Id oder das Dom-Element des nativen Selects.
     */
    target: string | HTMLElement

    /**
     * Der aktuelle Wert der Eingabe.
     */
    input: string

    /**
     * Die horizontale Ausrichtung der Optionselement-Texte.
     */
    itemAlignment: "left" | "center" | "right"

    /**
     * Die zulässige maximale Breite des Selects.
     */
    maxWidth: string

    /**
     * Die zulässige minimale Breite des Selects.
     */
    minWidth: string

    /**
     * Der Geöffnet-Status.
     */
    open: boolean

    /**
     * Der Nach-Oben-Öffnen-Status.
     */
    openup: boolean

    /**
     * Die Auswahloptionen der Liste.
     */
    options: ISelectOption[]

    /**
     * @inheritdoc
     */
    searchable: boolean

    /**
     * Ein Wert, der angibt, ob in der Liste gesucht werden kann
     */
    selectable: boolean

    /**
     * Der Wert der Option, die ausgewählt ist.
     */
    selected: string | number

    /**
     * Der Text der ausgewählten Option.
     */
    selectedText: string

    /**
     * Der Status, ob die letzte Auswahl bei einer Eingabe weiterhin angezeigt werden soll.
     */
    showLastSelection: boolean

    /**
     * Die Simplebar-Instanz.
     */
    simplebar: any

    /**
     * Die Standardbreite des Selects.
     */
    width: string

    /**
     * Eine Funktion zum Laden von Icons.
     *
     * @returns Ein Versprechen, das den Quelltext (plain) zum Icon zurückgibt.
     */
    iconLoader: (icon: string) => Promise<string | null> = (): Promise<string | null> => Promise.resolve("")

    /**
     * Die aufzurufende Funktion vor Auswahl eines Listenelements.
     */
    onBeforeSelect: () => Promise<void> | void = (): Promise<void> | void => Promise.resolve()

    /**
     * Die aufzurufende Funktion, nachdem die Liste an das Ende gescrollt wurde.
     */
    onBottom: (value: string | null, options: ISelectOption[]) => Promise<void> = (): Promise<void> => Promise.resolve()

    /**
     * Die aufzurufende Funktion bei Abbuchung der Auswahl.
     */
    onCancel?: () => void

    /**
     * Die aufzurufende Funktion nach Schließen der Liste.
     */
    onClose: () => void = (): void => { }

    /**
     * Die aufzurufende Funktion zur Bestätigung der Auswahl
     */
    onConfirmSelection: (optionValue: string) => Promise<boolean> | boolean = (): Promise<boolean> | boolean => Promise.resolve(true)

    /**
     * Die aufzurufende Funktion bei Fokussierung des Eingabefeldes.
     */
    onInputFocus?: ((input: HTMLInputElement) => void) | undefined;

    /**
     * Die aufzurufende Funktion vor Auswahl eines Listenelements zu Vorschauzwecken.
     */
    onPreview?: (selected: ISelectOption | null) => void;

    /**
     * Die aufzurufende Funktion nach Öffnen der Liste.
     */
    onOpen: () => void = (): void => { }

    /**
     * Die aufzurufende Funktion nach Auswahl eines Listenelements.
     */
    onSelect: (selected: ISelectOption | null) => void = (): void => { }

    /**
     * Die aufzurufende Funktion nach Eingabe eines Werts im Eingabefeld.
     */
    onValue?: (inputValue: string | null, byEnterKey: boolean) => void = (): void => { }

    /**
     * Eine Funktion zum Entfernen der Ereignisbehandlung des Selects.
     */
    removeListeners: () => void = (): void => { }

    /**
     * Die aufzurufende Funktion, wenn die Standardoption ausgewählt werden soll.
     */
    setDefault?: () => void

    /**
     * C'tor.
     *
     * @param options - Die FormValidation Optionen.
     */
    constructor(options: ISelectConstructorParameters) {
        /** Zuordnung: Options-Parameter */
        this.autoAdjust = options.autoAdjust;
        this.clearable = options.clearable === true;
        this.collapsible = options.collapsible === true;
        this.cssClasses = options.cssClasses;
        this.dest = options.dest ?? "";
        this.iconized = options.iconized === true;
        this.target = options.target;
        this.itemAlignment = options.itemAlignment;
        this.maxWidth = options.maxWidth;
        this.minWidth = options.minWidth;
        this.searchable = options.searchable;
        this.selectable = options.selectable !== false;
        this.selected = options.selected;
        this.showLastSelection = options.showLastSelection;
        this.width = options.width;
        this.id = typeof this.target === "string" ? this.target : uuidv4();

        if (this.target instanceof HTMLElement)
            this.target.dataset.id = this.id;
        else
            document.getElementById(this.target)?.setAttribute("data-id", this.id);

        this.iconLoader = options.iconLoader;
        this.onBottom = options.onBottom;

        this.onBeforeSelect = options.onBeforeSelect ?? (() => { });
        this.onCancel = options.onCancel;
        this.onClose = typeof options.onClose === "function" ? options.onClose : () => { };
        this.onInputFocus = options.onInputFocus;
        this.onOpen = typeof options.onOpen === "function" ? options.onOpen : () => { };
        this.onPreview = options.onPreview;
        this.onSelect = options.onSelect;
        this.onValue = options.onValue;
        this.setDefault = options.setDefault;

        /** Zuordnung: Intialisierungs-Parameter */
        this.disabled = false;
        this.calcedWidth = 0;
        this.animate = options.animate !== false;
        this.delay = false;
        this.focused = false;
        this.hovering = false;
        this.input = "";
        this.open = false;
        this.openup = false;
        this.options = [];
        this.selectedText = "";

        store.lists.push({ id: this.id, select: this });

        /**
         * 1) Die Liste (ul) erzeugen.
         */
        createList.call(this);

        if (!this.selectable)
            return;

        const list = document.getElementById(`list-${this.id}`)

        /**
         * 2) Die Simplebar-Instanz (scroll) erzeugen.
         */
        if (list)
            this.simplebar = new Simplebar(list);

        /**
         * 3) Die Ereignisse binden:
         */
        this.removeListeners = bindEvents.call(this);

        if (store.listener)
            return;

        store.listener = true;

        window.addEventListener("resize", closeLists, true);
        document.addEventListener("scroll", closeLists, true);
        document.addEventListener("mousedown", closeLists, true);
    }

    /**
     * @inheritdoc
     */
    removeOptions() {
        this.options = [];

        const measurement = document.getElementById(`list__measurement-${this.target}`);
        const content = document.getElementById(`list-${this.id}`)?.querySelector(".simplebar-content");

        if (measurement)
            measurement.innerHTML = "";

        if (content)
            content.innerHTML = "";
    }

    /**
     * @inheritdoc
     */
    async appendOptions(options: ISelectOption[]) {
        if (this.selectable && options.length > 0) {
            /** 1) Optionen speichern. */
            this.options.push(...cloneDeep(options));

            /** 2) Optionen in den Dom einhängen. */
            await createOptions({
                id: this.id,
                iconLoader: this.iconLoader,
                options: cloneDeep(options)
            });

            updateOptions.call(this);
            highlightOptions.call(this);

            Toolkit.tool("repeat", {
                callback: () => {
                    if (!this.getState("open"))
                        return;

                    adjustWidth.call(this);
                },

                duration: 1250,
                interval: 6
            }).repeat();
        }
    }

    /**
     * @inheritdoc
     */
    clear(closeList: boolean = true) {
        clearSelection.call(this, closeList);

        return this;
    }

    /**
     * @inheritdoc
     */
    setSelected(option: ISelectOption, animate: boolean = true, emit: boolean = false) {
        const list = typeof this.target === "string" ? document.getElementById(this.target) : this.target;

        if (!list)
            return;

        animate = animate !== false;
        emit = emit === true;

        this.setState("selected", true);
        this.selectedText = option.text;

        const input = list.querySelector(".select__input") as HTMLInputElement | null;

        if (input && this.getState("searchable") === true)
            input.value = option.text;

        placeholder(this).syncText();

        if (this.selectable)
            adjustWidth.call(this);

        adjustSelection.call(this, animate);

        if (emit && typeof this.onSelect === "function")
            this.onSelect(option);
    }

    /**
     * @inheritdoc
     */
    remove() {
        /** 1) Die Liste aus dem Store entfernen */
        remove(store.lists, list => list.id === this.target);

        if (store.lists.length === 0) {
            store.listener = false;

            window.removeEventListener("resize", closeLists, true);
            document.removeEventListener("scroll", closeLists, true);
            document.removeEventListener("mousedown", closeLists, true);
        }

        this.removeListeners();

        /** 2) Die Liste aus dem Dom entfernen */
        const list = document.getElementById(`list-${this.id}`);
        const measurement = document.getElementById(`list__measurement-${this.target}`);

        if (list)
            list.remove();

        if (measurement)
            measurement.remove();
    }

    /**
     * @inheritdoc
     */
    removeCssClasses(cssClasses: string[]) {
        const selection = typeof this.target === "string" ? document.getElementById(this.target) : this.target;;
        const list = document.getElementById(`list-${this.id}`);

        const selectionClasses = selection?.className.split(" ");
        const listClasses = list?.className.split(" ");

        const newSelectionClasses = difference(selectionClasses, cssClasses);
        const newListClasses = difference(listClasses, cssClasses);

        if (selection)
            selection.className = newSelectionClasses.join(" ");

        if (list)
            list.className = newListClasses.join(" ");
    }

    /**
     * @inheritdoc
     */
    addCssClasses(cssClasses: string[]) {
        const selection = typeof this.target === "string" ? document.getElementById(this.target) : this.target;;
        const list = document.getElementById(`list-${this.id}`);

        let selectionClasses = selection?.className.split(" ") ?? [];
        let listClasses = list?.className.split(" ") ?? [];

        selectionClasses.push(...cssClasses);
        listClasses.push(...cssClasses);

        selectionClasses = uniq(selectionClasses);
        listClasses = uniq(listClasses);

        if (selection)
            selection.className = selectionClasses.join(" ");

        if (list)
            list.className = listClasses.join(" ");
    }

    /**
     * @inheritdoc
     */
    adjust() {
        adjustSelection.call(this, true);
    }

    /**
     * @inheritdoc
     */
    setState(state: string, enable: any) {
        if (this.selectable) {
            const elements = [document.getElementById(`list-${this.id}`), typeof this.target === "string" ? document.getElementById(this.target) : this.target];
            const cssClass = `select--${state.toLocaleLowerCase()}`;
            const cssMethod = enable ? "add" : "remove";

            elements.forEach(element => {
                if (!element)
                    return;

                element.classList[cssMethod](cssClass);
            });
        }

        if (this.hasOwnProperty(state))
            (this as any)[state] = enable;
    }

    /**
     * @inheritdoc
     */
    getState(state: string): any {
        if (!this.hasOwnProperty(state))
            return;

        return (this as any)[state];
    }

    /**
     * @inheritdoc
     */
    enable() {
        this.disabled = false;
    }

    /**
     * @inheritdoc
     */
    disable() {
        this.disabled = true;
    }
}
