/* eslint-disable */
import filter from "lodash/filter";
import map from "lodash/map";
import get from "lodash/get";
import BaseChart from "./BaseChart";
import tinycolor from "tinycolor2";
import worldmap from "@highcharts/map-collection/custom/world-palestine-highres.geo.json";

/**
 * Eine Klasse zur Generierung der Highcharts-Optionen einer Bar-Chart.
 */
export default class MapChart extends BaseChart {
    color = "#FFFFFF";
    mappings = {}
    template = " None";

    /**
     * C'Tor
     *
     * @param {Function} caller Die aufrufende Funktion.
     */
    constructor(caller) {
        super(caller);
    }

    /**
     * Setzt die Highcharts-Option zur Darstellung des Tooltips eines Werts im Diagramm.
     *
     * @param {Object} tooltip Die Tooltip-Optionen
     *
     * @returns {Object} Die eigene Klasseninstanz.
     */
    setTooltip() {
        const _this = this;

        this.tooltip = {
            useHTML: true,
            headerFormat: "{point.key}<br>",
            backgroundColor: "rgba(255, 255, 255, 1)",
            formatter: function() {
                const region = _this.mappings[this.point["hc-key"]];
                const value = _this.caller.formatCell({value: this.point.value, template: _this.template});

                return `${region} <b>${value}</b>`;
            }
        };

        return this;
    }

    /**
     * Bereitet die darzustellenden Daten der Chart vor.
     *
     * @param {Array} data Die Rohdaten
     *
     * @returns {Object} Die Klasseninstanz.
     */
    prepareSeries(data) {
        let rows = filter(data, item => item.level === this.level);
        let cells = map(rows, row => { return get(row, "cells", []); });

        rows.forEach(row => {
            this.mappings[row.mapping] = row.name;
        });

        this.template = cells.length && cells[0].length && cells[0][0].template || "XYZ";

        const level = this.caller.cache.reactive.get("mapchart.index") || 0;
        const values = [];

        cells.forEach((cell, idx) => {
            if (cell[level].value > 0)
                values.push([rows[idx].mapping, cell[level].value])
        });

        this.color = this.baseColors[0]

        this.series = [{
            name: "",
            animation: this.animationDuration,
            mapData: worldmap,
            data: values
        }];

        return this;
    }

    /**
     * Liefert die Highcharts-Optionswerte zur Darstellung des Diagramms.
     *
     * @returns {Highchart.options} Die Highchart-Optionswerte.
     */
    getPrepared() {
        return {
            credits: {
                enabled: false
            },

            title: {
                text: ""
            },

            series: this.series,

            colorAxis: {
                min: 0,
                minColor: tinycolor(this.color).brighten(30).toString(),
                maxColor: tinycolor(this.color).desaturate(30).toString()
            },

            legend: {
                layout: "vertical",
                align: "right",
                verticalAlign: "middle",
                itemStyle: {
                    fontWeight: "normal"
                }
            },

            mapNavigation: {
                enabled: true,
                buttonOptions: {
                    verticalAlign: "bottom"
                }
            },

            tooltip: this.tooltip,
        }
    }
}
