import MessageProvider from "../MessageProvider";

let handleId = null;
let type = null;

/**
 * Eine Klasse zur Verwendung des Clipboards.
 */
class Clipboard {
    /**
     * Setzt die Metadaten.
     *
     * @param {Object} meta Die Metadaten.
     */
    set metaData(meta) { this._metaData = meta; }

    /**
     * Liefert die Metadaten.
     *
     * @returns {Object} Die Metadaten.
     */
    get metaData() { return this._metaData; }

    /**
     * Setzt die Textarea für die Clipboard-Daten.
     *
     * @param {HTMLTextAreaElement} textarea Die Textarea.
     */
    set textarea(textarea) { this._textarea = textarea; }

    /**
     * Liefert die Textarea für die Clipboard-Daten.
     *
     * @returns {HTMLTextAreaElement} Die Textarea.
     */
    get textarea() { return this._textarea; }

    /**
     * C'tor
     */
    constructor() {
        if (!document.getElementById("clipboard-textarea")) this.injectClipboard();

        this.clear();
    }

    /**
     * Erzeugt die Html-Elemente des Clipboards.
     */
    injectClipboard() {
        let textarea = document.createElement("textarea");

        textarea.id = "clipboard-textarea";
        textarea.setAttribute("tabindex", "-1");
        textarea.tabindex = -1;
        textarea.style.display = "block";
        textarea.style.position = "fixed";
        textarea.style.left = "-100000px";
        textarea.style.top = "-100000px";
        textarea.style.width = "1px";
        textarea.style.height = "1px";

        document.body.appendChild(textarea);

        this.textarea = document.getElementById("clipboard-textarea");
    }

    /**
     * Liefert die Id der Komponente, die zuletzt ein Copy bzw. Cut-Ereignis ausgelöst hat.
     *
     * @returns {String} Die Id der Komponente.
     */
    getLastHandle() {
        return handleId;
    }

    /**
     * Entfernt das Heml-Clipboard-Objekt aus dem DOM.
     */
    // eslint-disable-next-line
    destroy() {
        if (!document.getElementById("clipboard-textarea")) return;

        document.body.removeChild(document.getElementById("clipboard-textarea"));
    }

    /**
     * Löscht den Inhalt des  Clipboards.
     */
    // eslint-disable-next-line
    clear() {
        if (!document.getElementById("clipboard-textarea")) return;

        this.metaData = {};
        this.textarea.value = "";

        type = null;

        MessageProvider.notify("clipboard.topic.reset");
    }

    /**
     * Liefert die Metadaten.
     *
     * @returns {any} Die Metadaten
     */
    // eslint-disable-next-line
    meta() {
        // eslint-disable-next-line
        if (!document.getElementById("clipboard-textarea")) return;

        // eslint-disable-next-line
        return this.metaData;
    }

    /**
     * Liefert den aktuellen Clipboard-Vorgangstyp.
     *
     * @returns {"copy"|"cut"} Der Clipboard-Vorgangstyp.
     */
    type() {
        return type;
    }

    /**
     * Liefert die Clipboard-Daten.
     *
     * @returns {any[]} Die Clipboard-Daten
     */
    // eslint-disable-next-line
    data() {
        // eslint-disable-next-line
        if (!document.getElementById("clipboard-textarea")) return;

        // eslint-disable-next-line
        return this.textarea.value ? JSON.parse(this.textarea.value) : null;
    }

    /**
     * Registriert eine aufzurufende Funktion im Falle von vorhandenen Clipboard-Daten.
     *
     * @param {String} topic Das zu registrierende Thema.
     * @param {String} id Die Id des Empfängers.
     * @param {Function} callback Die aufzurufende Funktion.
     */
    attach(topic, id, callback) {
        MessageProvider.attach("clipboard.topic.reset", id, callback);
        MessageProvider.attach(`clipboard.topic.updated(${topic})`, id, callback);
    }

    /**
     * Entfernt eine registrierte aufzurufende Funktion im Rahmen von Clipboard-Daten.
     *
     * @param {String} topic Das zu betroffene Thema.
     * @param {String} id Die Id des Empfängers.
     */
    detach(topic, id) {
        MessageProvider.detach("clipboard.topic.reset", id);
        MessageProvider.detach(`clipboard.topic.updated(${topic})`, id);
    }

    /**
     * Setzt die Clipboarddaten für einen Kopiervorgang.
     *
     * @param {String} id Die Id des Auslösers.
     * @param {String} topic Das Clipboard-Thema.
     * @param {Any} data Clipboarddaten (werden beim Einfügen in das Zielobjekt geschrieben).
     */
    // eslint-disable-next-line
    copy(id, topic, data) {
        handleId = id;
        type = "copy";

        let activeElement = document.activeElement;

        if (!document.getElementById("clipboard-textarea")) return;

        this.metaData = { topic: topic };

        this.textarea.value = JSON.stringify(data);
        this.textarea.focus();
        this.textarea.selectionStart = 0;
        this.textarea.selectionEnd = this.textarea.value.length;

        document.execCommand("copy");
        activeElement.focus();
        MessageProvider.notify(`clipboard.topic.updated(${topic})`);
    }

    /**
     * Setzt die Clipboarddaten für einen Ausschneidevorgang
     * und benachrichtigt alle Komponenten, die sich unter demselben Thema
     * registriert haben über die Clipboardänderung.
     *
     * @param {String} id Die Id des Auslösers.
     * @param {String} topic Das Clipboard-Thema.
     * @param {Any} data Clipboarddaten (werden beim Einfügen in das Zielobjekt geschrieben).
     * @param {Any} meta Zusatzdaten für alle Komponenten, die sich beim Clipboard unter demselben Thema registriert haben..
     */
    // eslint-disable-next-line
    cut(id, topic, data, meta) {
        handleId = id;
        type = "cut";

        let activeElement = document.activeElement;

        if (!document.getElementById("clipboard-textarea")) return;

        this.metaData = meta;
        this.metaData.topic = topic;

        this.textarea.value = JSON.stringify(data);
        this.textarea.focus();
        this.textarea.selectionStart = 0;
        this.textarea.selectionEnd = this.textarea.value.length;

        document.execCommand("copy");
        activeElement.focus();
        MessageProvider.notify(`clipboard.topic.updated(${topic})`);
    }
}

export default new Clipboard();
