<script lang="ts" setup>
import type { Span } from "@elastic/apm-rum";
import { apmBase } from "@elastic/apm-rum";
import { inject, onBeforeMount } from "vue";
import { injectUtility } from "@/utils/utility.helper";
import Logger from "@/utils/Logger";
import ReportViewStyleGenerator from "@/utils/ReportViewStyleGenerator";
import Startup from "@/components/Startup.vue";

ReportViewStyleGenerator();

const $apm = inject<typeof apmBase>("$apm")!;
const $model = injectUtility("Model");

Logger.setApm($apm);

/**
 * Filtert alle SVG-Anfragen raus, damit diese nicht in den ELK-Stack (Logging) erscheinen.
 */
onBeforeMount(async () => {
    $apm?.addFilter(payload => {
        payload.transactions.forEach((entry: {spans: Span[]}) =>
            entry.spans = entry.spans.filter(s => !s.name.includes("cdn.gschwind-software.de"))
        );

        return payload;
    });

    const modelSplashScreen = $model.get("SplashScreen");
    modelSplashScreen.set("requestMessage", "Willkommen");
    modelSplashScreen.set("isLoading", true);

    modelSplashScreen.commit();
});
</script>

<template lang="pug">
Startup
router-view
</template>

<style lang="scss">
#app {
    text-align: center;
    color: var(--color-foreground);

    width: 100%;
    min-height: 100vh;
}

html,
body {
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

body {
    font-family: $font-family-body;
    font-size: $font-size-body;
    line-height: 1;
    background: #FFF;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    margin: 0px;
    padding: 0px;

    overflow: hidden;

    * {
        box-sizing: border-box;
    }

    input,
    select,
    textarea {
        -webkit-appearance: none;
        user-select: text;
    }

    input,
    select,
    textarea,
    button {
        font-family: inherit;

        &::-moz-focus-inner,
        &::-moz-focus-outer {
            outline: none;
        }
    }

    main {
        section {
            position: relative;
            float: left;
            width: 100%;
        }

        .tabs {
            height: 100%;

            .tabs__content {
                height: calc(100% - 35px);

                > .panel {
                    height: 100%;
                }
            }

            &.stack {
                .tabs_content {
                    .panel-stack {
                        .stack {
                            &.horizontal {
                                margin-top: 0px;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }

        .data-view {
            height: 100%;
        }
    }
}

@mixin controlLabel() {
    display: block;
    color: var(--color-dialog-label);
    height: 24px;
    line-height: 24px;
    text-align: right;
    padding-right: 8px;

    flex-grow: 0;
    flex-shrink: 0;

    font-size: 0.75em;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    cursor: pointer;
}

.control {
    position: relative;
    float: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    .row {
        .table {
            > label {
                @include controlLabel();
            }
        }
    }

    > label {
        @include controlLabel();
    }

    textarea {
        font-family: $font-family-body;
    }
}

*:not(input) {
    user-select: none
}

.container {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
}

table,
tbody {
    &:focus {
        outline: none;
    }
}

fieldset {
    min-inline-size: unset;
}

input,
textarea {
    &::-webkit-input-placeholder {
        color: var(--color-disabled) !important;
        font-size: 0.75em !important;
        opacity: 1 !important; /* Firefox */
    }

    &::-moz-placeholder {
        color: var(--color-disabled) !important;
        font-size: 0.75em !important;
        opacity: 1 !important; /* Firefox */
    }

    &::-ms-placeholder {
        color: var(--color-disabled) !important;
        font-size: 0.75em !important;
        opacity: 1 !important; /* Firefox */
    }

    &::placeholder {
        color: var(--color-disabled) !important;
        font-size: 0.75em !important;
        opacity: 1 !important; /* Firefox */
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
        color: var(--color-disabled) !important;
        font-size: 0.75em !important;
    }

    &::-ms-clear {
        display: none;
    }

    &:-moz-ui-invalid,
    &:invalid {
        box-shadow: none;
    }
}

h1 {
    font-weight: normal;
    margin: 0px;
    padding: 0px;

    font-size: 1.2em;
    line-height: 1.2em;
}

aside {
    &.disabled {
        form {
            > header {
                background-color: var(--color-disabled);

                * {
                    color: var(--color-disabled-content);
                }

                svg path {
                    fill: var(--color-disabled-content);
                }
            }
        }
    }
}

.buttons {
    position: relative;
    margin-top: 24px;
    margin-bottom: 24px;
    float: left;
    clear: left;
}

/** Sichtbarkeit **/
.vis {
    &--hidden {
        visibility: hidden;
    }

    &--visible {
        visibility: visible;
    }
}

.\-m-no-display {
    display: none !important;
}

.\-m-no-events {
    touch-action: none;
    -ms-touch-select: none;
    pointer-events: none;
}

/** Ausrichtung **/
.--text-left {
    text-align: left !important;
    justify-content: flex-start;

    /** BEM - Element: Der Icon-Wrapper */
    > .icon__wrapper {
        float: none;
    }
}

.--text-center {
    text-align: center !important;
    justify-content: center;

    /** BEM - Element: Der Icon-Wrapper */
    > .icon__wrapper {
        float: none;
    }
}

.--text-right {
    text-align: right !important;
    justify-content: flex-end;

    /** BEM - Element: Der Icon-Wrapper */
    > .icon__wrapper {
        float: none;
    }
}

.pull-left,
.pull-right {
    position: relative;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 250ms ease-out;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>
