import $ from "@/utils/dom";
import Scroller from "../../index";

/**
 * Verarbeitet das {@linkcode MouseEvent} mousedown auf dem Skrollbalken der horizontalen Achse
 *
 * @this {Scroller}
 *
 * @param {MouseEvent} e
 */
const horizontalMouseDown = function (e) {
    e.preventDefault();

    this.scrollerState.horizontal.isDragging = true;
    this.scrollerState.horizontal.pointerPos = e.clientX;
    this.scrollerState.horizontal.lastThumbPosition = this.scrollerState.horizontal.thumbPosition;

    if ($(this.elements.scrollContainer).hasClass("scrollcontainer--scrolling"))
        return;

    $(this.elements.scrollContainer).addClass("scrollcontainer--scrolling");
};

export default horizontalMouseDown;
