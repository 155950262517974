<script lang="ts" setup>
import type { PropType } from "vue";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { injectUtility } from "@/utils/utility.helper";
import EventHandler from "@/utils/EventHandler";
import get from "lodash/get";
import Icon from "@/components/UI/Icon.vue";
import MessageProvider from "@/utils/MessageProvider";
import set from "lodash/set";
import useCacheUtils from "@/mixins/composition.cache";
import useComponentUtils from "@/mixins/composition.component";
import useParameterUtils from "@/mixins/composition.parameters";
import { isNullOrUndefined } from "@/utils/typeGuards";
import FilterUtils from "@/utils/Filter";

const emit = defineEmits(["click"]);

/**
 * Das Wurzelelement {@linkcode HTMLElement} der Komponente
 */
const root = ref<HTMLElement>();

const $auth = injectUtility("Authentication");
const $cache = injectUtility("Cache");
const $reactiveCache = injectUtility("ReactiveCache");
const $config = injectUtility("Config");
const $modal = injectUtility("Modal");
const $model = injectUtility("Model");
const $page = injectUtility("Page");
const $request = injectUtility("Request");

const { componentId, pageId, menuId } = useComponentUtils({
    node: root,
    plugins: {
        page: $page,
    }
});

const cache = useCacheUtils({
    componentId,
    cache: $cache,
    reactiveCache: $reactiveCache,
});

const parameterUtils = useParameterUtils({
    model: $model.get("Parameters"),
    componentId,
    pageId,
    menuId,
});

/**
 * Gibt an, ob eine Ladeanimation angezeigt werden soll (Kann von externen Komponenten manipuliert werden).
 */
const pending = ref(false);

/**
 * Stellt das Icon für die Icon-Komponente bereit.
 *
 * HINWEIS:
 *
 * Initial wird das Icon aus der Prop-Eigenschaft "icon" übernommen,
 * kann aber an beliebiger Stelle in dieser Komponente überschrieben werden.
 */
const localIcon = ref<string|undefined>();

/**
 * Stellt den Tooltip-Text für die Icon-Komponente bereit.
 *
 * HINWEIS:
 *
 * Initial wird der Tooltip-Text aus der Prop-Eigenschaft "hint" übernommen,
 * kann aber an beliebiger Stelle in dieser Komponente überschrieben werden.
 */
const localHint = ref<string|undefined>();

/**
 * Stellt die Metadaten für die Event-Handler bereit.
 *
 * HINWEIS:
 *
 * Initial werden die Metadaten aus der Prop-Eigenschaft "metaData" übernommen,
 * können aber an beliebiger Stelle in dieser Komponente überschrieben werden.
 */
const localMeta = ref<Record<string, unknown>>();

const props = defineProps({
    /**
     * Liefert die Hintergrundfarbe eines Buttons (falls angegeben).
     */
    background: {
        type: String,
        default: null
    },

    /**
     * Gibt an, ob der Button blinken soll.
     */
    blink: {
        type: Boolean,
        default: false
    },

    /**
     * Liefert den darzustellenden Text eines Buttons (falls angegeben) und gibt diesen an das Icon-Steuerelement weiter.
     */
    caption: {
        type: String,
        default: ""
    },

    /**
     * Gibt an, ob der Button aktiviert oder deaktiviert ist.
     * Im deaktiviertem Zustand werden keine Benutzer Ereignisse auf den Button ausgelöst.
     */
    disabled: {
        type: Boolean,
        default: false
    },

    /**
     * Liefert die Ereignisse, welche bei einem Ereignistyp ausgelöst werden sollen
     *
     * HINWEIS:
     *
     * In der beforeMount-Phase werden alle Ereignisse vom Ereignistyp "onLoad" ausgeführt.
     * Alle anderen Ereignisse werden an die Methode {@linkcode click} delegiert.
     */
    events: {
        type: Array as PropType<TEventData[]>,
        default: () => []
    },

    /**
     * Liefert einen optionalen Tooltip-Text des Buttons.
     */
    hint: {
        type: String,
        default: ""
    },

    /**
     * Liefert den Hoverstatus der Elternkomponente.
     */
    hover: {
        type: Boolean,
        default: false
    },

    /**
     * Ein optionales Icon, welcher links vom Button abgebildet wird.
     */
    icon: {
        type: String,
        default: ""
    },

    /**
     * Gibt zurück, ob die Quelle der Icon-Resource (falls durch die Elternkomponente ein Icon vorgegeben wurde)
     * lokal vorliegt.
     */
    local: {
        type: Boolean,
        default: false
    },

    /**
     * Liefert Metadaten des Buttons.
     */
    metaData: {
        type: Object as PropType<Record<string, unknown>>,
        default: () => ({})
    },

    /**
     * Gibt an, bo der Button sich im Ladenzustand (Ladeanimation) befindet.
     */
    pending: {
        type: Boolean,
        default: false
    },

    /**
     * Das verknüpfet Popover-Element
     */
    popovertarget: {
        type: String,
        default: null
    },

    /**
     * Stellt den Empfänger der Aktionen bereit. Wird diese Eigenschaft nicht gesetzt, so wird das Click-Ereignis
     * an die Elternkomponente weitergeleitet. Sonst wird der MessageProvider verwendet, um die Aktionen an den
     * Empfänger zu senden.
     */
    receiver: {
        type: String,
        default: null
    },

    /**
     * Stellt die Form des Buttons bereit. Mögliche Werte sind:
     *
     * - round -> Kreisbutton
     * - rect -> rechteckiger Button
     */
    shape: {
        type: String as PropType<"rect" | "round">,
        default: "rect"
    },

    /**
     * Liefert die Hintergrundfarbe eines Buttons (falls angegeben).
     */
    signalType: {
        type: String as PropType<"error" | "success">,
        default: null
    },


    /**
     * Gibt die Größe des Buttons an.
     */
    size: {
        type: String,
        default: ""
    },

    /**
     * Liefert den Typ des Buttons.
     *
     * - "primary": Button mit ausgefülltem Hintergrund.
     * - "secondary": Button mit transparentem Hintergrund.
     */
    type: {
        type: String as PropType<"primary" | "secondary">,
        default: "primary"
    },

    /**
     * Gibt die vertikale Ausrichtung des Buttons zurück und kann durch die
     * Elternkomponente eingestellt werden.
     */
    valign: {
        type: String,
        default: ""
    },

    /**
     * Gibt an, ob alle Ereignisse verifiziert werden sollen, bevor die Ereignisse ausgeführt werden.
     * Standardmäßig ist dieser Wert auf "false" gesetzt und die Bedingungen des jew. Ereignisses werden bei jeder Iteration ausgeführt.
     */
    verifyAll: {
        type: Boolean,
        default: false
    }
});

/**
 * Liefert die für den Button relevanten CSS-Klassenbezeichnungen.
 *
 * @returns Die CSS-Klassenbezeichnungen.
 */
const cssClassObject = computed(() => {
    const cssClasses:{[key:string]:boolean} = {
        "btn--iconized": props.icon !== "",
        "btn--pending": pending.value === true || props.pending,
        "btn--disabled": props.disabled,
        "btn--hover": props.hover && !pending.value,
        "btn--blink": props.blink,
        "pull-left": props.valign === "left",
        "pull-right": props.valign === "right",
        "--row": props.size === "row"
    };

    if (props.signalType)
        cssClasses[`btn--bg-${props.signalType}`] = true;

    cssClasses[`btn--shape-${props.shape}`] = true;
    cssClasses[`btn--${props.type}`] = true;

    if (props.size !== "")
        cssClasses[`btn--${props.size}`] = true;

    return cssClasses;
});

/**
 * Löst das Click-Ereignis aus. Dabei existieren 3 Möglichkeiten der Verarbeitung:
 *
 * 1. Dem Button wurde ein Empfänger zugewiesen. In diesem Fall werden die Aktionen des Ereignistyps "OnClick" an den Empfänger gesendet.
 *
 * 2. Dem Button wurden Ereignisse zugewiesen, welche bei einem Click-Ereignis ausgelöst werden sollen.
 *    In diesem Fall werden die Ereignisse im Button ausgeführt

 * 3. Dem Button wurden weder Ereignisse noch ein Empfänger zugewiesen. In diesem Fall wird das Click-Ereignis
 *    an die Elternkomponente weitergeleitet.
 *
 * @param e - Das Mausereignis
 */
const click = async (e:MouseEvent) => {
    if (props.disabled || pending.value)
        return;

    if (props.receiver)
        return MessageProvider.notifyId("element.click", `${props.receiver}.${$page.left.id()}`, {
            setPending: (isPending:boolean) =>
                nextTick(() => pending.value = isPending),

            actions: props.events
                .filter(event => event.trigger === "onClick" || event.type === "onClick")
                .map(({actions}) => actions)
                .flat()
        });

    if (props.events.some(event => event.trigger === "onClick" || event.type === "onClick")) {
        return await handleEvent({
            eventName: "onClick"
        });
    }

    emit(
        "click",

        /**
         * Ladeanimation anzeigen.
         */
        () => nextTick(() => pending.value = true),

        /**
         * Ladeanimation verbergen.
         */
        () => nextTick(() => pending.value = false),

        e
    );
};

/**
 * Zeigt die Ladeanimation des Buttons an.
 */
const showLoader = () => pending.value = true;

/**
 * Versteckt die Ladeanimation des Buttons.
 */
const hideLoader = () => pending.value = false;

/**
 * Verarbeitet Ereignisse des Typs {@linkcode TEventName}, sofern diese in der Button-Komponente definiert wurde.
 *
 * @param options Die Parameter für die Ereignisverarbeitung.
 * @param options.eventName Der Name des Ereignisses.
 * @param options.log Ausgabe der Ereignisverarbeitung in der Konsole.
 */
const handleEvent = async ({eventName, log = false}: {eventName: TEventName, log?: boolean}) => {
    const eventHandler = new EventHandler({
        log,

        conditionValueResolver: (member:string) =>
            get(localMeta.value, member),

        initialValue: localMeta.value,
        store: localMeta.value,
        filters: FilterUtils.mapJson(parameterUtils.combined()),

        trigger: { $type: "button" },

        plugins: {
            $auth,
            $cache: cache,
            $config,
            $modal,
            $parameters: parameterUtils,
            $request
        },

        hooks: {
            actions: {
                set: ({action}) => {
                    if (isNullOrUndefined(localMeta.value))
                        return localMeta.value;

                    if (isNullOrUndefined(action))
                        return localMeta.value;

                    if (isNullOrUndefined(action?.field))
                        return localMeta.value;

                    set(localMeta.value, action.field, action.value)

                    return localMeta.value;
                },

                setIcon: ({action}) => {
                    localIcon.value = action?.icon

                    return localMeta.value;
                },

                setHint: ({action}) => {
                    localHint.value = action?.hint;

                    return localMeta.value;
                }
            }
        }
    });

    showLoader();

    try {
        await eventHandler.handle({
            eventName,
            events: props.events,
            verifyAll: props.verifyAll
        });
    } finally {
        hideLoader();
    }
}

watch(
    () => props.icon,
    async () => localIcon.value = props.icon
);

watch(
    () => props.hint,
    async () => localHint.value = props.hint
);

watch(
    () => props.metaData,
    async () => localMeta.value = props.metaData
);

onMounted(async () => {
    localIcon.value = props.icon;
    localHint.value = props.hint;
    localMeta.value = props.metaData;

    await handleEvent({
        eventName: "onLoad"
    });
});

defineExpose({
    showLoader,
    hideLoader
});
</script>

<template lang="pug">
//- Eine Button-Komponente.
button.btn(
    type="button"
    ref="root"

    :class="cssClassObject"
    :title="localHint"
    :disabled="disabled"
    :popovertarget="popovertarget"
    :popovertargetaction="popovertarget ? 'show' : null"

    @click="click"
)
    Icon(
        v-if="localIcon"

        :icon="localIcon"
    )

    span.btn__caption(
        v-if="caption !== ''"
        v-html="caption"
    )

    slot
</template>

<style lang="scss">
$button-height: 32px;
$icon-height: $button-height * 0.5;

/** BEM - Block: Button */
 .btn {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    width: auto;
    height: auto;
    pointer-events: auto;
    cursor: pointer;
    background-color: transparent;
    border: none;
    overflow: hidden;

    /** BEM - Element: Button Text */
    &__caption {
        color: var(--color-base-3);
        min-width: 90px;
        font-size: 0.8em;
        margin: 0 4px;
    }

    &.btn--primary,
    &.btn--secondary {
        &.btn--shape {
            /** BEM - Modifizierer: Runder Button */
            &-round,
            &-rect {
                &:before,
                &:after {
                    content: "";
                    display: none;
                }
            }
        }
    }

    /** BEM - Modifizierer: Primär-Button (gefüllte Hintergrundfarbe)*/
    &--primary {
        background-color: var(--color-button-primary, hsl(210.29999999999995, 92%, 31%));
        border-color: var(--color-button-primary, hsl(210.29999999999995, 92%, 31%));

        transition: background-color 125ms ease-in, border-color 125ms ease-in;

        svg path {
            fill: var(--color-button-primary-content) !important;
        }

        .btn__caption {
            color: var(--color-button-primary-content, white);
        }

        /** BEM - Block: Button */
        &.btn {
            /** BEM - Modifizierer: Hintergundfarbe */
            &\--bg {
                /** Buttonsignalfarbe Positivaktion */
                &-success {
                    background-color: var(--color-success);
                    border-color: var(--color-success);

                    svg path {
                        fill: var(--color-button-primary-success-content) !important;
                    }

                    .btn__caption {
                        color: var(--color-button-primary-success-content);
                    }
                }

                /** Buttonsignalfarbe Negativaktion */
                &-error {
                    background-color: var(--color-error);
                    border-color: var(--color-error);

                    svg path {
                        fill: var(--color-button-primary-error-content) !important;
                    }

                    .btn__caption {
                        color: var(--color-button-primary-error-content);
                    }
                }
            }
        }
    }

    /** BEM - Modifizierer: Sekundär-Button (transparente Hintergrundfarbe; Rahmen um Button)*/
    &--secondary {
        border: 2px solid var(--color-button-secondary-border);

        transition: background-color 125ms ease-in, border-color 125ms ease-in;

        svg path {
            fill: var(--color-button-primary) !important;
        }
    }

    /** BEM - Modifizierer: Formen */
    &--shape {
        /**
         * BEM - Modifizierer:
         *
         * - Rechteckiger Button
         * - Runder Button
         */
        &-rect,
        &-round {
            min-width: $button-height;
            height: $button-height;
        }

        /** BEM - Modifizierer: Rechteckiger Button */
        &-rect {
            border-radius: 3px;

            /** BEM - Modifizierer: Rechteckiger kleiner Button */
            &.btn--small {
                min-width: 24px;
                height: 24px;
            }

            /** BEM - Modifizierer: Rechteckiger sehr kleiner Button */
            &.btn--xs {
                min-width: 20px;
                height: 20px;
            }
        }

        /** BEM - Modifizierer: Runder Button */
        &-round {
            border-radius: #{$button-height * 0.5};
            width: $button-height;
            padding: 0;


            /** BEM - Modifizierer: Rechteckiger kleiner Button */
            &.btn--small {
                min-width: 22px;
                height: 22px;

                .icon__wrapper {
                    width: 10px;
                    height: 10px;
                }
            }

            /** BEM - Modifizierer: Rechteckiger sehr kleiner Button */
            &.btn--xs {
                min-width: 20px;
                width: 20px;
                height: 20px;
            }
        }
    }

    /** BEM - Modifizierer: Button beschäftigt */
    &--pending {
        cursor: progress;

        /**
         * BEM - Elemente:
         *
         * - Button Text
         * - Icon Container
         */
        &.btn__caption,
        .icon__wrapper {
            opacity: 0;
        }

        /**
         * BEM - Modifizierer:
         *
         * - Primär-Button (gefüllte Hintergrundfarbe)
         * - Sekundär-Button (transparente Hintergrundfarbe; Rahmen um Button)
         */
        &.btn--primary,
        &.btn--secondary {
            /** BEM - Modifizierer: Formen */
            &.btn--shape {
                /** BEM - Modifizierer: Runder Button */
                &-round {
                    &:before,
                    &:after {
                        content: "";

                        display: block;
                        opacity: 0.1;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-color: #FFFFFF;
                        transform: scale(0);
                    }

                    &:before {
                        animation: cssload-loader 2000ms infinite ease-in-out;
                    }

                    &:after {
                        animation: cssload-loader 2000ms infinite 500ms ease-in-out;
                    }
                }

                /** BEM - Modifizierer: Rechteckiger Button */
                &-rect {
                    &:before {
                        content: "";

                        position: absolute;
                        display: block;

                        left: 0px;
                        top: 0px;
                        width: 200px;

                        height: 100%;
                        background-color: rgba(255,255,255, 0.25);

                        transform: translate3d(-100%, 0, 0);
                        transform-origin: 0% 0%;

                        animation: loading 2000ms linear infinite;

                        z-index: 1;
                    }

                    /** BEM - Element: Button Text */
                    .btn__caption {
                        color: var(--color-button-caption);
                    }
                }
            }
        }

        &.btn--primary {
            /** BEM - Modifizierer: Formen */
            &.btn--shape {
                /** BEM - Modifizierer: Runder Button */
                &-round {
                    /** BEM - Modifizierer */
                    &.btn {
                        /** BEM - Modifizierer: Hintergundfarbe */
                        &\--bg {
                            /** Buttonsignalfarbe bei Positivaktion */
                            &-success {
                                &:before,
                                &:after {
                                    background-color: var(--color-button-primary-success-content);
                                }
                            }

                            /** Buttonsignalfarbe bei Negativagtion */
                            &-error {
                                &:before,
                                &:after {
                                    background-color: var(--color-button-primary-error-content);
                                }
                            }
                        }
                    }
                }
            }
        }

        &.btn--primary.btn--shape-round:before,
        &.btn--primary.btn--shape-round:after {
            background-color: var(--color-button-primary-content);
        }

        /** BEM - Modifizierer: Sekundär-Button (transparente Hintergrundfarbe; Rahmen um Button)*/
        &.btn--secondary {
            /** BEM - Modifizierer: Formen */
            &.btn--shape {
                /** BEM - Modifizierer: Runder Button */
                &-round {
                    border-color: var(--color-accent-1);
                    background-color: var(--color-accent-1);

                    /** BEM - Modifizierer */
                    &.btn {
                        /** BEM - Modifizierer: Hintergundfarbe */
                        &\--bg {
                            /** Buttonsignalfarbe bei Positivaktion */
                            &-success {
                                border-color: var(--color-success);
                                background-color: var(--color-success);
                            }

                            /** Buttonsignalfarbe bei Negativaktion */
                            &-error {
                                border-color: var(--color-base-3);
                                background-color: var(--color-base-3);
                            }
                        }
                    }
                }
            }
        }
    }

    &--blink {
        animation: button-blink 3s infinite;
    }

    /** BEM - Modifizierer: Button mit individuellem Icon */
    &--iconized {
        /** BEM - Element: Der Html Container für das darzustellende Icon */
        .icon__html {
            /** HTML - Element: SVG Element (Der Icon-Inhalt) */
            > svg {
                pointer-events: none;
                touch-action: none;
            }
        }

        /** BEM - Block: Icon */
        .icon {
            /** BEM - Element: Der Icon-Wrapper */
            &__wrapper {
                height: $icon-height;
                width: $icon-height;

                transition: opacity 150ms ease-out;
            }

            /** BEM - Element: Der Html Container für das darzustellende Icon */
            &__html {
                display: block;

                margin: 0px auto;

                /** HTML - Element: SVG Element (Der Icon-Inhalt) */
                > svg {
                    display: block;

                    transition: transform 150ms ease-out;
                }
            }

            /** BEM - Modifizierer: Schließen Icon */
            &--close {
                /** BEM - Element: Der Icon-Wrapper */
                &.btn__wrapper {
                    width: 20px;
                    height: 20px;
                }
            }

            /** BEM - Modifizierer: Kopieren Icon */
            &--copy {
                /** BEM - Element: Der Icon-Wrapper */
                &.btn__wrapper {
                    width: 18px;
                    height: 18px;
                }
            }

            /** BEM - Modifizierer: Hochladen Icon */
            &--copy {
                /** BEM - Element: Der Icon-Wrapper */
                &.btn__wrapper {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    /** Pseudo-Selektoren: Browser Resets */
    &:active,
    &:focus {
        outline: none;
    }

    &::-moz-focus-inner  {
        border: 0;
    }

    &:focus:not(.btn--pending):not(.btn--disabled),
    &--hover:not(.btn--pending):not(.btn--disabled) {
        /** BEM - Modifizierer */
        &.btn {
            /** BEM - Modifizierer: Primär-Button (gefüllte Hintergrundfarbe)*/
            &--primary {
                border: none;
                transition: background-color 125ms ease-out;
                background-color: var(--color-button-primary-hover);

                /** BEM - ELEMENT: Button */
                &.btn {
                    /** BEM - Modifizierer: Hintergundfarbe */
                    &\--bg {
                        /** Buttonsignalfarbe bei Positivaktion */
                        &-success {
                            border-color: var(--color-button-primary-success-hover);
                            background-color: var(--color-button-primary-success-hover);
                        }

                        /** Buttonsignalfarbe bei Negativaktion */
                        &-error {
                            background-color: var(--color-button-primary-error-hover);
                            border-color: var(--color-button-primary-error-hover);
                        }
                    }
                }
            }

            /** BEM - Modifizierer: Sekundär-Button (transparente Hintergrundfarbe; Rahmen um Button)*/
            &--secondary {
                border: 2px solid var(--color-button-primary);
                transition: border-color 125ms ease-in;
            }
        }
    }

    /**
     * BEM - Modifizierer + Status: Button deaktiviert.
     */
    &--disabled,
    &[disabled="disabled"] {
        cursor: default;
        background-color: var(--color-disabled);
        border-color: var(--color-disabled);

        touch-action: none !important;
        pointer-events: none !important;

        /**
         * BEM - Status: Button Fokussieren.
         * BEM - Status: Button Hover.
         * BEM - Modifizierer: Button Hover.
         */
        &:focus,
        &.btn--hover {
            background-color: var(--color-disabled);
        }

        /**
         * BEM - Block: Button
         */
        &.btn {
            /**
             * BEM - Modifizierer:
             *
             * - Primär-Button (gefüllte Hintergrundfarbe)
             * - Sekundär-Button (transparente Hintergrundfarbe; Rahmen um Button)
             */
            &--primary,
            &--secondary {
                /** BEM - ELEMENT: Button */
                &.btn {
                    /** BEM - Element: Der Html Container für das darzustellende Icon */
                    .icon__html {
                        /** HTML - Element: SVG Element (Der Icon-Inhalt) */
                        > svg {
                            path {
                                fill: var(--color-disabled);
                            }
                        }
                    }
                }
            }

            /**
             * BEM - Modifizierer: Primär-Button (gefüllte Hintergrundfarbe)
             */
            &--primary {
                background-color: var(--color-disabled);
                border-color: var(--color-disabled);

                /**
                 * BEM - Status: Button Fokussieren.
                 * BEM - Status: Button Hover.
                 * BEM - Modifizierer: Button Hover.
                 */
                &:focus,
                &.btn--hover {
                    background-color: var(--color-disabled);
                    border-color: var(--color-disabled);
                    opacity: 0.5;
                }

                /*
                 * BEM - Block: Button.
                 */
                &.btn {
                    /** BEM - Modifizierer: Hintergundfarbe */
                    &--bg {
                        /** Buttonsignalfarbe bei Positivaktion */
                        &-success {
                            background-color: var(--color-disabled);
                            border-color: var(--color-disabled);

                            /**
                             * BEM - Status: Button Fokussieren.
                             * BEM - Status: Button Hover.
                             * BEM - Modifizierer: Button Hover.
                             */
                            &:focus,
                            &.btn--hover {
                                background-color: var(--color-disabled);
                                border-color: var(--color-disabled);
                            }
                        }

                        /** Buttonsignalfarbe bei Negativaktion */
                        &-error {
                            background-color: var(--color-disabled);
                            border-color: var(--color-disabled);

                            /**
                             * BEM - Status: Button Fokussieren.
                             * BEM - Status: Button Hover.
                             * BEM - Modifizierer: Button Hover.
                             */
                            &:focus,
                            &.btn--hover {
                                background-color: var(--color-disabled);
                                border-color: var(--color-disabled);
                            }
                        }
                    }
                }
            }

            /** BEM - Modifizierer: Sekundär-Button (transparente Hintergrundfarbe; Rahmen um Button)*/
            &--secondary {
                background-color: transparent !important;
                border-color: var(--color-disabled) !important;

                /**
                 * BEM - Status: Button Fokussieren.
                 * BEM - Status: Button Hover.
                 * BEM - Modifizierer: Button Hover.
                 */
                &:focus,
                &.btn--hover {
                    background-color: transparent;
                    border-color: var(--color-disabled);
                }

                /*
                 * BEM - Block: Button.
                 */
                &.btn {
                    /** BEM - Modifizierer: Hintergundfarbe */
                    &--bg {
                        /** Buttonsignalfarbe bei Positivaktion */
                        &-success {
                            border-color: var(--color-disabled);

                            /**
                             * BEM - Status: Button Fokussieren.
                             * BEM - Status: Button Hover.
                             * BEM - Modifizierer: Button Hover.
                             */
                            &:focus,
                            &.btn--hover {
                                border-color: var(--color-disabled);
                            }
                        }

                        /** Buttonsignalfarbe bei Negativaktion */
                        &-error {
                            border-color: var(--color-disabled);

                            /**
                             * BEM - Status: Button Fokussieren.
                             * BEM - Status: Button Hover.
                             * BEM - Modifizierer: Button Hover.
                             */
                            &:focus,
                            &.btn--hover {
                                border-color: var(--color-disabled);
                            }
                        }
                    }
                }
            }
        }
    }
}

/**
 * iOS-Double-Tap-Fix (Chrome, Safari)
 *
 * Siehe: https://css-tricks.com/annoying-mobile-double-tap-link-issue/
 */
@media (hover) {
    .btn {
        &:hover:not(.btn--pending) {
            /** BEM - Modifizierer */
            &.btn {
                /** BEM - Modifizierer: Primär-Button (gefüllte Hintergrundfarbe)*/
                &--primary {
                    border: none;
                    transition: background-color 125ms ease-out;
                    background-color: var(--color-button-primary-hover, hsl(210.29999999999995, 92%, 41%));

                    /** BEM - ELEMENT: Button */
                    &.btn {
                        /** BEM - Modifizierer: Hintergundfarbe */
                        &\--bg {
                            /** Buttonsignalfarbe bei Positivaktion */
                            &-success {
                                border-color: var(--color-button-primary-success-hover);
                                background-color: var(--color-button-primary-success-hover);
                            }

                            /** Buttonsignalfarbe bei Negativaktion */
                            &-error {
                                background-color: var(--color-button-primary-error-hover);
                                border-color: var(--color-button-primary-error-hover);
                            }
                        }
                    }
                }

                /** BEM - Modifizierer: Sekundär-Button (transparente Hintergrundfarbe; Rahmen um Button)*/
                &--secondary {
                    border: 2px solid var(--color-button-primary);
                    transition: border-color 125ms ease-in;
                }
            }
        }

        /**
        * BEM - Modifizierer + Status: Button deaktiviert.
        */
        &--disabled,
        &[disabled="disabled"] {
            svg path {
                fill: var(--color-disabled-content) !important;
            }

            /**
             * BEM - Status: Button Fokussieren.
             * BEM - Status: Button Hover.
             * BEM - Modifizierer: Button Hover.
             */
            &:hover {
                background-color: var(--color-disabled);
            }

            /**
            * BEM - Block: Button
            */
            &.btn {
                /**
                 * BEM - Modifizierer: Primär-Button (gefüllte Hintergrundfarbe)
                 */
                &--primary {
                    &:hover {
                        background-color: var(--color-disabled);
                        border-color: var(--color-disabled);
                    }

                    /*
                     * BEM - Block: Button.
                     */
                    &.btn {
                        /** BEM - Modifizierer: Hintergundfarbe */
                        &--bg {
                            /** Buttonsignalfarbe bei Positivaktion */
                            &-success {
                                &:hover {
                                    background-color: var(--color-disabled);
                                    border-color: var(--color-disabled);
                                }

                                svg path {
                                    fill: var(--color-disabled-content) !important;
                                }
                            }

                            /** Buttonsignalfarbe bei Negativaktion */
                            &-error {
                                &:hover {
                                    background-color: var(--color-disabled);
                                    border-color: var(--color-disabled);
                                }

                                svg path {
                                    fill: var(--color-disabled-content) !important;
                                }
                            }
                        }
                    }
                }

                /** BEM - Modifizierer: Sekundär-Button (transparente Hintergrundfarbe; Rahmen um Button)*/
                &--secondary {
                    &:hover {
                        background-color: transparent;
                        border-color: var(--color-disabled);
                    }

                    /*
                    * BEM - Block: Button.
                    */
                    &.btn {
                        /** BEM - Modifizierer: Hintergundfarbe */
                        &--bg {
                            /** Buttonsignalfarbe bei Positivaktion */
                            &-success {
                                &:hover {
                                    border-color: var(--color-disabled);
                                }
                            }

                            /** Buttonsignalfarbe bei Negativaktion */
                            &-error {
                                &:hover {
                                    border-color: var(--color-disabled);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
