import { nextTick } from "vue";
import Constant from "@/utils/Constant";
import Logger from "@/utils/Logger";
import MessageProvider from "@/utils/MessageProvider";

/**
 * Wandert den Dom-Baum bis zur Wurzel zurück und
 * liefert aufgrund der vorhandenen Nachbar und Elternknoten eine
 * eindeutige Positions-Id des Elements.
 *
 * @param {HtmlElement} $el Das Zielelement.
 * @param {String} id Die letzte Id der Traversion.
 *
 * @returns {String} Die Positions-Id des Elements.
 */
const traverseTree = ($el, id) => {
    if (!$el.parentNode)
        return id || "undefined";

    if (!$el.tagName)
        return "undefined";

    if ($el.tagName.toLowerCase() === "body")
        return id
            .split(".")
            .filter(Boolean)
            .reverse()
            .join(".");

    if (!id)
        id = "";

    id += `${Array.from($el.parentNode.childNodes).indexOf($el)}.`;

    return traverseTree($el.parentNode, id);
};

/**
 * Entfernt Notifizierungsereignisse und bricht laufende Serverabfragen einer Komponente ab.
 */
const cleanUp = function () {
    this.unregisterNotificationEvents();
    this.cancelRequests();
};

/**
 * Ein Mixin zur Bereitstellung einer eindeutigen Komponenten-Id.
 *
 * Wissenswert: Die Id wird über die Modul-Id, Sektions-Id, horizontale sowie vertikale Position der Komponente
 * im Dom ermittelt.
 */
export default {
    data() {
        return {
            /**
             * Die Komponenten-Id.
             *
             * HINWEIS: Wird als interne Id verwende und dient hautpsächlich
             * zum cachen des Satus einer Komponente (wenn bspw. zwischen Menüs hin- un hergewechselt wird, dann
             * kann anhand der componentId der ursprüngliche Zustand wiederhergestellt werden.)
             *
             * @property {String}
             */
            componentId: null,

            /**
             * Die Dom-Id.
             *
             * HINWEIS: Kann als ID des Dom-Knotens verwendet werden.
             *
             * @property {String}
             */
            nodeId: null,

            /**
             * Gibt an, ob die Init-Methode ausgeführt wurde.
             *
             * @property {Boolean}
             */
            initialized: false,

            /**
             * Stellt Informationen über Serveranfragen der Komponente bereit.
             *
             * @property {Object}
             */
            request: {
                /**
                 * Stellt einen AbortController für eine Serveranfrage zur Verfügung.
                 *
                 * @property {Object}
                 */
                abortController: null,

                /**
                 * Der Zustand, ob aktive Serveranfragen der Komponente existieren.
                 *
                 * @property {Boolean}
                 */
                fetching: true,

                /**
                 * Der Zustand, ob während einer Serveranfrage ein Fehler entstanden ist.
                 *
                 * @property {Boolean}
                 */
                error: {
                    /**
                     * Fehler nach Ladevorgang der Komponente entstanden.
                     *
                     * @property {Boolean}
                     */
                    raised: false,

                    /**
                     * Anzuzeigender Button nach fehlerhaftem Ladevorgang der Komponente.
                     *
                     * @property {{title: String, event: Function}}
                     */
                    button: {
                        title: Constant.Button.RETRY.Caption,
                        event: async () => {
                            if (this.refreshView) {
                                try {
                                    await this.refreshView();
                                } catch (e) {
                                    if (!this.$request.hasCancelled(e)) throw e;
                                }
                            }
                        }
                    }
                },

                /**
                 * Die in der Ladeanimation anzuzeigenden Nachricht.
                 *
                 * @property {Object}
                 */
                message: {
                    /**
                     * Die Nachricht während des Ladevorgangs der Komponente.
                     *
                     * @property {String}
                     */
                    fetching: "Die Daten werden geladen...",

                    /**
                     * Die Nachricht im Falle eines Fehlers nach dem Ladevorgang der Komponente.
                     *
                     * @property {String}
                     */
                    error: "Die Daten konnten nicht geladen werden."
                }
            }
        };
    },

    computed: {
        /**
         * Liefert die Zuordnung der Modul- sowie Sektions-Id der Komponente.
         *
         * @returns {String} Die Menu-Id.
         */
        menuId() {
            return `${this.$page.left.id()}`;
        }
    },

    methods: {
        /**
         * Bricht alle aktiven Serveranfragen der Komponente ab.
         */
        async cancelRequests() {
            try {
                // Alle aktiven Serveranfragen abbrechen:
                await this.request.abortController.abort();
            } catch (e) {}

            // Einen neuen AbrotController zuweisen:
            this.request.abortController = this.$request.getAbortController();
        },

        /**
         * Führt Aktionen in Abhängigkeit der empfangenen Nachricht aus.
         *
         * @param {String} topic Der Grund der Nachricht.
         * @param {Srting} id Die Empfänger Id.
         * @param {Object} values Die Wertepaare.
         */
        async onNotify(topic, id, values) {
            if (id !== this.componentId)
                return;

            let items = this.layout.onNotify || [];

            for (var i = 0; i < items.length; i++) {
                let item = items[i];
                let actions = (item.topic === topic ? this.layout.onNotify[i].actions : []);

                for (var j = 0; j < actions.length; j++) {
                    let action = actions[j];
                    let method = action.$type || null;

                    if (typeof this[method] !== "function")
                        return Logger.error(`Methode nicht bekannt: ${method}`);

                    if (method === "refreshView" && this.initialized) {
                        try {
                            await this[method](topic, values);
                        } catch (e) {
                            Logger.error(`Fehler in der Methode: ${method}`, e);
                        }

                        continue;
                    }

                    if (method !== "refreshView"){
                        try {
                            this[method](topic, values);
                        } catch (e) {
                            Logger.error(`Fehler in der Methode: ${method}`, e);
                        }
                    }
                }
            }
        },

        /**
         * Entfernt alle Notifizierungs-Ereignisse.
         */
        unregisterNotificationEvents() {
            if (this.layout && this.layout.onNotify) {
                (this.layout.onNotify || []).forEach(item => {
                    MessageProvider.detach(item.topic, this.componentId);
                });
            }
        },

        /**
         * Registriert alle Notifizierungs-Ereignisse.
         */
        registerNotificationEvents() {
            if (this.layout && this.layout.onNotify) {
                (this.layout.onNotify || []).forEach(item => {
                    MessageProvider.attach(item.topic, this.componentId, this.onNotify);
                });
            }
        }
    },

    watch: {
        /**
         * Überwacht die Änderung der Menü-Id und ermittelt die zugehörige Komponenten-Id.
         */
        menuId: {
            handler () {
                cleanUp
                    .call(this);

                if (this.beforeInit)
                    this.beforeInit();

                if (this.menuId === "null")
                    return;

                const componentId = `${this.menuId}.${this.parentId ? `${this.parentId}.` : ""}${traverseTree(this.$el)}`;

                this.nodeId = componentId ? componentId.replace(/\./g, "_") : null;

                nextTick(() => this.componentId = componentId);
            },

            immediate: false
        },

        /**
         * Initialisiert die Komponente.
         */
        componentId: {
            async handler() {
                this.initialized = false;

                if (this.componentId && this.init) {
                    try {
                        await this.init();

                        this.registerNotificationEvents();
                        this.initialized = true;
                    } catch (e) {
                        Logger.error("Fehler in der Methode: init", e);
                    }
                } else if (this.componentId) {
                    this.registerNotificationEvents();
                    this.initialized = true;
                }
            },

            immediate: false
        }
    },

    /**
     * VueJs - Lifecyclehook.
     *
     * Wissenswert: Komponenteneigenschaften (data, props, computed)
     * stehen noch zur Verfügung.
     */
    beforeUnmount() {
        cleanUp.call(this);
    },

    /**
     * VueJs - Lifecyclehook.
     *
     * Wissenswert: Komponenteneigenschaften (data, props, computed)
     * stehen zur Verfügung.
     * Dom-Element stehen für den Zugriff NICHT zur Verfügung.
     */
    created() {
        this.request.abortController = this.$request.getAbortController();
    },

    /**
     * VueJs - Lifecyclehook.
     *
     * Wissenswert: Komponenteneigenschaften (data, props, computed) sowie
     * Dom-Element stehen für den Zugriff zur Verfügung.
     */
    mounted() {
        const componentId = `${this.menuId}.${this.parentId ? `${this.parentId}.` : ""}${traverseTree(this.$el)}`;

        if (this.beforeInit)
            this.beforeInit();

        this.nodeId = componentId ? componentId.replace(/\./g, "_") : null;

        nextTick(() => this.componentId = componentId);
    }
};
