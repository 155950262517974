<script lang="ts" setup>
import type { PropType } from "vue";
import { ref, computed } from "vue";
import { isEmptyWs } from "@/utils/string";
import Icon from "@/components/UI/Icon.vue";

const props = defineProps({
    /**
     * Ein Wert, der angibt, ob das Select nur lesbar ist.
     */
    disabled: {
        type: Boolean,
        value: false
    },

    /**
     * Gibt das darzustellende Icon oder den Text zurück, wenn das Select keine aktive Auswahl hat.
     */
    empty: {
        type: Object as PropType<ISelectOption>,
        default: null
    },

    /**
     * Gibt an, ob die Selektion ein Icon darstellen wird.
     */
    iconized: {
        type: Boolean,
        default: false
    },

    /**
     * Gibt an, ob das SelectControl über eine Eingabemöglichkeit (Input-Feld)
     * verfügen soll.
     */
    searchable: {
        type: Boolean,
        default: false
    },

    /**
     * Die ausgewählte Option.
     */
    selected: {
        type: [Object, null, undefined]  as PropType<ISelectOption | null | undefined>,
        default: () => {
            return {
                icon: null,
                text: "",
                value: ""
            };
        }
    },

    /**
     * Ein Wert, der angibt, ob die letzte Auswahl während einer Eingabe weiterhin als kleiner Infotext angezeigt werden soll.
     */
    showLastSelection: {
        type: Boolean,
        default: false
    }
});

/**
 * Liefert den Namen des optional darzustellenden Icons der ausgewählten Option.
 */
const icon = computed(() =>  props.selected?.icon ?? null);

/**
 * Liefert den darzustellenden Text der ausgewählten Option.
 */
const text = computed(() => props.selected?.text ?? "");

/**
 * Gibt an, ob ein Element ausgewählt wurde.
 */
const hasSelection = computed(() => props.selected && (Object.keys(props.selected) || []).length > 0);
</script>

<template lang="pug">
//- Ein Dropdown-Anzeigelement einer aktuellen Auswahl.
div.select__content
    Icon.select__icon-custom(
        v-if="icon"

        :icon="icon"
    )

    Icon.select__icon-custom(
        v-else-if="iconized && empty && empty.icon && !selected"

        :icon="empty.icon"
    )

    span.select__text-empty(
        v-else-if="empty && empty.text && !selected"
    ) {{ empty.text }}

    span.select__placeholder(
        v-if="showLastSelection"
    )

    span.select__text-measurement(
        v-if="empty && empty.text && !selected"

        v-html="empty.text"
    )

    span.select__text-measurement(
        v-else
    )

    input.select__input(
        v-if="!disabled"
        :tabindex="searchable ? 0 : -1"

        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
    )

    span.select__text(
        v-if="hasSelection && !searchable"
        v-html="text"
    )

    .select__icon-arrow
        Icon(
            icon="desc"
        )

    .select__icon-clear(
        v-if="!disabled"
    )
        Icon(
            icon="close"
        )
</template>
