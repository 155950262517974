<script setup>
import { computed, ref, watch } from "vue";
import Icon from "@/components/UI/Icon.vue";

const emit = defineEmits(["click"]);

/**
 * @type {{items: {}[]}}
 */
const props = defineProps({
    /**
     * Ein Array-Objekt zum speichern der Breadcrumb-Elemente.
     *
     * @type {{}[]}
     */
    items: {
        type: Array,
        default: () => []
    }
});

/**
 * Das aktive Element.
 *
 * @type {{value: Number}}
 */
const activeItemIndex = ref(props.items.length - 1);

/**
 * Die Anzahl der darzustellenden Toolbar-Steuerelemente.
 *
 * @type {{value: Number}}
 */
const itemCount = computed(() => props.items && props.items.length || 0);

/**
 * Emittiert das Klick-Ereignis an die Elternkomponente.
 *
 * @param {Any} item Das Breadcrumb-Objekt
 * @param {Number} itemIndex Der Index in der Breadcrumb-Elementliste
 */
const emitClickToParent = (item, itemIndex) => {
    if (!item.url)
        return;

    if (itemIndex === props.items.length - 1)
        return;

    activeItemIndex.value = itemIndex;

    emit("click", item);
};

/**
 * Zeigt ein Breadcrumbelement an.
 *
 * @param {Object} el Das anzuzeigende Element
 * @param {Function} done Eine Callbackfunktion nach Anzeige des Elements
 */
const showItem = (el, done) => {
    const duration = 250;
    const delay = -75 * (itemCount.value - el.dataset.index);

    el.style.transition = `opacity ${duration}ms cubic-bezier(0.445, 0.05, 0.55, 0.95) ${delay}ms`;

    window.setTimeout(() => {
        el.style.opacity = 1;

        window.setTimeout(() => {
            done();
        }, delay + duration + 10);
    }, 1);
};

/**
 * Enfernt die Elemente nacheinander aus dem DOM-Tree (animiert).
 *
 * @param {Object} el Das zu entfernende HTML-Element
 * @param {Function} done Eine Callback-Funktion nach entfernen des HTML-Elements
 */
const removeItem = (el, done) => {
    if (!el)
        return;

    let duration = 250;
    let delay = 75 * (itemCount.value - el.dataset.index);

    el.style.transition = `opacity ${duration}ms cubic-bezier(0.445, 0.05, 0.55, 0.95) ${delay}ms`;

    window.setTimeout(() => {
        el.style.opacity = 0;

        window.setTimeout(() => {
            if (el.parentNode)
                el.parentNode.removeChild(el);

            done();
        }, delay + duration + 10);
    }, 1);
};

/**
 * Überwacht die Eigenschaft "items" und setzt bei Änderung
 * die Anzahl der elemente (itemCount), sowie das aktive Element (activeItemIndex).
 */
watch(
    () => props.items,
    () => activeItemIndex.value = itemCount.value - 1,
    { flush: "post" }
);
</script>

<template lang="pug">

//- Eine wiederverwendbare Breadcrumb.
div.breadcrumb
    transition-group(
        :appear="true"
        tag="ul"

        name="staggered-fade"

        :css="false"

        v-on:enter="showItem"
        v-on:leave="removeItem"
    )
        li(
            v-for="(item, itemIndex) in items"

            :class="{active: itemIndex === activeItemIndex, disabled: !item.url}"
            :data-index="itemIndex"
            :key="'item' + itemIndex"

            @click="emitClickToParent(item, itemIndex)"
        )
            div.wrapper
                span {{ item.title }}
                span.spacer {{ item.title }}

            Icon(
                v-if="itemIndex < items.length - 1"
                icon="breadcrumb"
            )

</template>

<style lang="scss">
.breadcrumb {
    position: relative;
    float: left;
    width: auto;
    background-color: transparent;
    height: 36px;

    text-align: left;

    padding-left: 20px;
    padding-right: 32px;

    display: block;

    ul {
        position: relative;
        float: left;
        display: block;
        width: auto;
        white-space: nowrap;
        margin: 10px 0 0 0;
        padding: 0px;
        height: 15px;
        list-style-type: none;

        cursor: pointer;

        li {
            position: relative;
            float: left;

            display: block;
            height: 100%;
            padding: 0px 8px 0px 0px;
            font-size: 0.75em;
            color: var(--color-breadcrumb-text);

            opacity: 0;

            transition: opacity 150ms ease-out;

            &:first-child {
                padding-left: 12px;
            }

            > div {
                position: relative;
                float: left;
                display: block;
                height: 14px;
                overflow: visible;

                span {
                    position: absolute;
                    display: block;
                    line-height: 14px;
                    height: 14px;
                    width: 100%;
                    text-align: left;
                    transition: color 150ms ease-out;
                    white-space: nowrap;

                    &.spacer {
                        padding-right: 4px;
                        position: relative;
                        visibility: hidden;
                        font-weight: 600;
                    }
                }
            }

            /** BEM - Element: Der Html Container für das darzustellende Icon */
            .icon__html {
                width: 5px;
                height: 16px;

                /** HTML - Element: SVG Element (Der Icon-Inhalt) */
                > svg {
                    display: block;

                    path {
                        fill: var(--color-button-primary);
                        stroke: none;
                    }
                }
            }

            &.active,
            &:hover:not(.disabled) {
                span:not(.spacer) {
                    font-weight: 600;
                }
            }

            &:hover:not(.disabled)
            &:hover:not(.active) {
                span:not(.spacer) {
                    text-decoration: underline;
                }
            }

            &.disabled,
            &:last-child {
                cursor: default;
            }
        }
    }
}
</style>
