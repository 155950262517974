import Scroller from "../../index";

/**
 * Verschiebt die Position des Thumbs der übergebenen Achse
 *
 * @this Scroller
 *
 * @param {{axis: "horizontal"|"vertical", scrollTo: Number}} options
 */
const updateThumbPosition = function({axis, scrollTo}) {
    if (this.scroll === "page" && this.scrollerState[axis].isDragging)
        return;

    const props = {
        vertical: {
            extendSize: "scrollHeight",
            viewportSize: "height",
            thumbSize: this.scrollerState.vertical.thumbHeight,
            thumbOffset: this.scrollerState.vertical.top,
            lastScrollPosition: this.scrollerState.vertical.thumbPosition,
            translation: "translate3d(0,px,0)"
        },

        horizontal: {
            extendSize: "scrollWidth",
            viewportSize: "width",
            thumbSize: this.scrollerState.horizontal.thumbWidth,
            thumbOffset: this.scrollerState.horizontal.left,
            lastScrollPosition: this.scrollerState.horizontal.thumbPosition,
            translation: "translate3d(px,0,0)"
        }
    };

    const bar = axis === "horizontal" ? this.elements.barHoz : this.elements.barVert;
    const config = props[axis];
    const thumb = bar.querySelector(".scrollthumb");
    const scrollPercentage = !this.isIFrame
        ? scrollTo / (this.element[config.extendSize] - this.element.getBoundingClientRect()[config.viewportSize])
        : scrollTo / (this.element.contentDocument.body[config.extendSize] - this.element.contentDocument.body.getBoundingClientRect()[config.viewportSize]);

    const trackHeight = bar.getBoundingClientRect()[config.viewportSize] - config.thumbSize - config.thumbOffset;
    const pos = trackHeight * scrollPercentage + config.thumbOffset;

    this.scrollerState[axis].thumbPosition = pos;

    if (this.isIFrame)
        return;

    const translation = config.translation.replace("px", `${pos}px`);

    window.requestAnimationFrame(() => {
        thumb.style["transform"] = translation;
        thumb.style["webkitTransform"] = translation;
        thumb.style["mozTransform"] = translation;
        thumb.style["msTransform"] = translation;
        thumb.style["oTransform"] = translation;
    });
};

export default updateThumbPosition;
