/**
 *
 * Liefert eine "schöne" Zahl zur Skalierung einer Diagrammachse
 *
 * @param {number} value
 * @param round Kaufmännisches auf-/abrunden der "schönen Zahl"
 *
 * @returns {number}
 */
const niceNum = (value:number, round:boolean):number => {
    const exponent:number = Math.floor(Math.log10(value));
    const fraction:number = value / Math.pow(10, exponent);

    if (round) {
        if (fraction < 1.5)
            return Math.pow(10, exponent);

        if (fraction < 3)
            return 2 * Math.pow(10, exponent);

        if (fraction < 7)
            return 5 * Math.pow(10, exponent);

        return 10 * Math.pow(10, exponent);
    }

    if (fraction <= 1)
        return Math.pow(10, exponent);

    if (fraction <= 2)
        return 2 * Math.pow(10, exponent);

    if (fraction <= 5)
        return 5 * Math.pow(10, exponent);

    return 10 * Math.pow(10, exponent);
};

/**
 * Liefert Skalierungswerte einer Diagrammachse
 *
 * @param {{min:number, max:number, maxTicks?:number}}
 *
 * @returns {{tickInterval:number, min:number, max:number}}
 */
const niceScale = ({ min, max, maxTicks = 10 }: { min: number, max: number, maxTicks?: number }) => {
    const range:number = niceNum(Math.abs(max - min), false);
    const tickSpacing:number = niceNum(range / (maxTicks - 1), true);
    const niceMin:number = Math.floor(min / tickSpacing) * tickSpacing;
    const niceMax:number = Math.ceil(max / tickSpacing) * tickSpacing;

    return {
        tickInterval: tickSpacing,
        min: niceMin,
        max: niceMax
    };
};

export { niceScale };
