import { escapePath } from "../utils/pathConverter";
import axios from "axios";
import combineURLs from "axios/lib/helpers/combineURLs";

class DriveRevision {
    /**
     * Privates Feld: Authorisierungsmethoden
     *
     * @property {DriveAuth} [_auth=null]
     */
    _auth = null;

    /**
     * Privates Feld: Basisadresse.
     *
     * @property {String} [_baseUrl=null]
     */
    _baseUrl = null;

    /**
     * C'tor
     *
     * @param {String} baseUrl
     * @param {DriveAuth} auth
     */
    constructor(baseUrl, auth) {
        this._auth = auth;
        this._baseUrl = baseUrl;
    }

    /**
     * Liefert alle Revisionen einer Datei.
     *
     * @param {DriveFile} file Die Datei.
     * @param {Record<string, unknown>?} qsa Query-String-Parameter.
     */
    async list(file, qsa = null) {
        const queryParameters = qsa
            ? new URLSearchParams(qsa).toString()
            : "";

        const path = escapePath(`rev/${file.path}`);
        const url = new URL(combineURLs(this._baseUrl, path));
        url.search = queryParameters;

        return await axios.get(url.toString(), {
            headers: {
                Authorization: `Bearer ${this._auth.AccessToken}`
            }
        });
    }

    /**
     * Stellt die Datei(en)-/Ordnerversion(en) wieder her.
     *
     * @param {{paths: String[], versions: Number[], overwrite: Boolean}} options
     */
    async restore({paths, versions = null, overwrite = false}) {
        const path = `rev${overwrite ? "?overwrite=true" : ""}`;

        await axios.put(combineURLs(this._baseUrl, path), {
            paths,
            versions
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this._auth.AccessToken}`
            }
        });
    }
}

export default DriveRevision;
