import { stopSelectionByCtrl } from "../lib/selection";

export default function (e) {
    if (e.taret === this.table || e.target.closest("table") === this.table)
        return;

    this.state.table.focus = false;

    stopSelectionByCtrl.call(this);
}
