import Scroller from "../../index";

const onMouseDown = {
    /**
     * Registriert das {@linkcode MouseEvent} bei gedrückter Maustaste.
     *
     * @this Scroller
     */
    attach: function() {
        if (this.isIFrame)
            return;

        this.elements.barVert.querySelector(".scrollthumb").addEventListener("mousedown", this.registeredHandler.verticalMouseDown);
        this.elements.barHoz.querySelector(".scrollthumb").addEventListener("mousedown", this.registeredHandler.horizontalMouseDown);
    },

    /**
     * Entfernt das {@linkcode MouseEvent} bei gedrückter Maustaste.
     *
     * @this Scroller
     */
    detach: function() {
        if (this.isIFrame)
            return;

        if (this.elements.barVert)
            this.elements.barVert.querySelector(".scrollthumb").removeEventListener("mousedown", this.registeredHandler.verticalMouseDown);

        if (this.elements.barHoz)
            this.elements.barHoz.querySelector(".scrollthumb").removeEventListener("mousedown", this.registeredHandler.horizontalMouseDown);
    }
};

export default onMouseDown;
