/**
 * Ein Model zur Abbildung eines Filters
 */
class FilterModel {
    /**
     * Die zu filternde Eigenschaft
     */
    name: string;

    /**
     * Der logische Vergleichsoperator
     */
    operator: TComparisionMethod

    /**
     * Der Filterwert
     */
    value: any;

    /**
     * Der Gültigkeitsbereich des Filters
     */
    scope: ClientScope = "component";

    /**
     * C'tor
     *
     * @param {object} options - Die Initialisierungsoptionen
     * @param {string} options.name - Der Name des Filters
     * @param {any} [options.value=null] - Der Filterwert
     * @param {string} [options.operator="equals"] - Der logische Vergleichsoperator
     * @param {string} [options.scope="e"] - Der Gültigkeitsbereich des Filters
     */
    constructor({ name, value = null, operator = "equals", scope = "component"}:{name: string, value: any, operator?:TComparisionMethod, scope?: ClientScope}) {
        this.name = name;
        this.operator = operator;
        this.scope = scope;
        this.value = value;
    }

    /**
     * Erzeugt ein Key-Value-Paar aus {@linkcode name} und {@linkcode value}
     *
     * @returns {{}} Das Key-Value-Paar
     */
    toJson():{[key: string]: any} {
        const json:{[key: string]: any} = {};

        json[this.name] = this.value;

        return json;
    }
}

export default FilterModel;
