import type Scroller from "../..";

/**
 * Scrollt die Seite in die angegebene vertikale Richtung
 *
 * @param this Die Instanz der Scroller-Klasse
 * @param direction Die Richtung, in die gescrollt werden soll
 */
function scrollPage(this: Scroller, direction?: "up" | "down") {
    if (!direction)
        return

    const page = direction === "up" ? this.scrollerState.page - 1 : this.scrollerState.page + 1;

    this.scrollerState.page = Math.min(Math.max(page, 0), this.scrollerState.pages - 1);

    this.element.scrollTo({
        top: (this.scrollerState.page * (this.element.scrollHeight - this.element.getBoundingClientRect().height)) / (this.scrollerState.pages - 1),
        behavior: "instant" as ScrollBehavior
    });
}

export default scrollPage;
