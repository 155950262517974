import colorHelper from "@/utils/colorHelper";
import concat from "lodash/concat";
import filter from "lodash/filter";
import first from "lodash/first";
import flatten from "lodash/flatten";
import get from "lodash/get";
import map from "lodash/map";
import _ from "lodash";
import tinycolor from "tinycolor2";
import BaseChart from "./BaseChart";
import ColorGenerator from "../ColorGenerator";
import FilterUtils from "@/utils/Filter/index";
// import Highcharts from "highcharts";

/**
 * Eine Klase zur Generierung der Highcharts-Optionen einer Stack-Chart.
 */
export default class StackChart extends BaseChart {
    /**
     * C'Tor
     *
     * @param {Function} caller Die aufrufende Funktion.
     */
    constructor(caller) {
        super(caller);
    }

    /**
     * Bereitet die darzustellenden Daten der Chart vor.
     *
     * @param {Array} data Die Rohdaten
     *
     * @returns {Object} Die Klasseninstanz.
     */
    prepareSeries(data) {
        let rows = (filter(data, item => item.level === this.level) || []);
        let colorsToFill = ColorGenerator(this.baseColors[0], this.baseColors[1], rows.length);
        let rowIndex = -1;
        let parentSubtract = false;
        let isRecommendation = parseInt(this.caller.parameters().page.get("recommendationIndex")) > 0;

        const pushableSeries = [];

        (data || [])
            .filter(row => !row.chartType || row.chartType === "stackChart")
            .forEach((row) => {
                if (parseInt(row.level) > 1 && parseInt(row.level) < parseInt(this.level) && get(row, "operator", "subtract", "") === "subtract") parentSubtract = true;
                else if (parseInt(row.level) < parseInt(this.level)) parentSubtract = false;

                if (parseInt(row.level) === parseInt(this.level)) {
                    rowIndex++;

                    let prepareData = () => {
                        let name = row.name || "";
                        let cells = get(row, "cells", []);

                        return {
                            id: row.help,
                            name: name,
                            zIndex: rowIndex + 1,
                            data: map(cells, cell => {
                                let template = get(cell, "template", "Currency");
                                let isSubtract = get(row, "operator", "subtract", "") === "subtract" || parentSubtract;
                                let cellValue = this.caller.formatCell({ value: cell.value, template: template });
                                let castedValue = template === "Currency" ? parseInt(cellValue.replace(/\./g, ""), 10) : parseFloat(cellValue.replace(/,/g, "."));

                                if (isSubtract && castedValue > 0) castedValue *= -1;

                                return {
                                    name: name,
                                    y: castedValue,
                                    drilldown: true,
                                    stack: name
                                };
                            }),
                            stack: "left",
                            color: `#${tinycolor(colorsToFill[rowIndex]).toHex().toUpperCase()}`,
                            animation: this.animationDuration
                        };
                    };

                    pushableSeries.push(prepareData());

                }
            });

        (data || [])
            .filter(row => row.chartType && row.chartType === "areaChart")
            .forEach(
                /**
                 * @param {ReportRow} row
                 */
                (row, idx) => {

                    rowIndex++;

                    /**
                     * @type {ReportCell[]}
                     */
                    const cells = row.cells || [];
                    const name = row.name || "";

                    pushableSeries.push({
                        id: row.help || `area-${idx}`,
                        type: "area",
                        name,
                        zIndex: 0,
                        data: cells.map(cell => {
                            return {
                                name,
                                y: cell.value,
                            }

                        }),
                        color: `#${tinycolor(colorsToFill[rowIndex]).toHex().toUpperCase()}`,
                        animation: this.animationDuration,
                        lineWidth: 3,
                        fillColor: {
                            linearGradient:
                            {
                                x1: 0,
                                y1: 1,
                                x2: 0,
                                y2: 0
                            },
                            stops: [
                                [0, `#${tinycolor(colorsToFill[rowIndex]).toHex().toUpperCase()}`],
                                [0.8, colorHelper(`#${tinycolor(colorsToFill[rowIndex]).toHex().toUpperCase()}`).hex2rgba(0.15)],
                                [1, colorHelper(`#${tinycolor(colorsToFill[rowIndex]).toHex().toUpperCase()}`).hex2rgba(0.15)]
                            ]
                        }
                    });
                }
            );

        this.series.push(...pushableSeries);

        let hasNegative = Math.min(..._(this.series || [])
            .map("data")
            .flatten()
            .map("y")
            .value()) < 0;

        if (!hasNegative)
            return this;

        /**
         * Es handelt sich um eine Serie mit negativen Werten. Deshalb soll eine Line-Chart (Daten aus Level 1) ergänzend gezeichnet werden,
         */
        let filtered = (this.caller.rows || [])
            .filter(row => row.level === 1)

        if (isRecommendation)
            filtered = filter(filtered, row => row.recommendationName !== undefined);
        else
            filtered = [filtered.at(0)];

        let firstEntry = first(filtered);
        let splineName = get(firstEntry, "name") || "";

        let splineSeries = {
            type: "spline",
            id: firstEntry && firstEntry.help || "spline",
            showInLegend: true,
            name: splineName,
            zIndex: rowIndex + 1,
            data: (() => {
                let mapped = map(filtered, "cells");
                let flattened = flatten(mapped);

                mapped = map(flattened, cell => {
                    return {
                        name: splineName,
                        drilldown: false,
                        tooltip: false,
                        y: get(cell, "value", 0)
                    };
                });

                return mapped;
            })(),
            color: "#111111"
        };

        this.series = concat(this.series, splineSeries);

        return this;
    }

    /**
     * Liefert die Flags zur Darstellung im Diagramm.
     *
     * @param {{text: string, title: string, x: number, series?: string, position: "onAxis"|"onSeries"|"inPane"}[]} flags - Die Flags.
     * @param {{items: {name: string|number}[]}} xAxis - Die X-Achse des Diagramms.
     * @param {string} color - Die Farbe der Flags.
     */
    prepareFlags(flags, xAxis, color) {
        if (!flags || !flags.length)
            return this;

        const lo = parseFloat(xAxis.items.at(0).name);
        const hi = parseFloat(xAxis.items.at(-1).name);
        const normalized = hi - lo;

        const mapped = flags
            .map(flag => {
                const perc = (flag.x - lo) / normalized;
                const x = (xAxis.items.length - 1) * perc;

                return {
                    text: flag.text,
                    title: flag.title,
                    position: flag.position,
                    series: flag.series,
                    x
                };
            })
            .reduce((acc, flag) => {
                const position = flag.position;
                const series = flag.series;

                let idxAcc = acc.findIndex(item => item.position === position && item.onSeries === series);

                if (idxAcc === -1)
                    idxAcc = acc.findIndex(item => item.position === position && !item.onSeries);

                if (idxAcc === -1) {
                    const pushable = {
                        position,
                        type: "flags",
                        showInLegend: false,
                        color,
                        zIndex: this.series.length + 1,
                        data: [{
                            x: flag.x,
                            title: flag.title,
                            text: flag.text,
                        }],
                        allowOverlapX: false,
                        style: {
                            fontFamily: "ui-monospace, 'Cascadia Code', 'Source Code Pro', Menlo, Consolas, 'DejaVu Sans Mono', monospace",
                            fontWeight: "normal"
                        }
                    };

                    if (position === "onSeries")
                        pushable.onSeries = series;
                    else if (position === "inPane")
                        pushable.yAxis = 1;

                    acc.push(pushable);
                } else {
                    acc[idxAcc].data.push({
                        x: flag.x,
                        title: flag.title,
                        text: flag.text,
                    });
                }

                return acc;
            }, []);

        this.series = [...this.series, ...mapped];

        return this;
    }

    /**
     * Liefert die Highcharts-Optionswerte zur Darstellung des Diagramms.
     *
     * @returns {Highchart.options} Die Highchart-Optionswerte.
     */
    getPrepared() {
        let caller = this.caller;

        return {
            chart: {
                type: "column",
                inverted: false,
                events: {
                    drilldown: function (e) {
                        let name = get(e, "point.name", undefined);
                        let row = filter(caller.rows, ["name", name])[0];
                        let type = get(row, "ref", "else");

                        if (e.seriesOptions || type !== "report")
                            return;

                        const layoutFilters = caller.layout.filters || [];
                        const layoutParameters = FilterUtils.mapJson(caller.layout.parameters || {});
                        const rootFilter = FilterUtils.mapJson({ root: caller.layout.source || "" });

                        let filters = FilterUtils.assign(layoutFilters, layoutParameters, rootFilter);

                        if (row.item)
                            filters = FilterUtils.assign(filters, new FilterModel({ name: "item", value: row.item }));

                        caller.cache.set("plan", {
                            type,
                            source: type === "report" ? get(row, "report", undefined) : get(row, "url", undefined),
                            filters
                        });

                        caller.refreshView();
                    }
                }
            },

            plotOptions: {
                series: {
                    stacking: "normal",
                    dataLabels: {
                        enabled: false
                    },
                    states: {
                        inactive: {
                            opacity: 1
                        }
                    }
                }
            },

            legend: {
                symbolRadius: 0,

                itemStyle: {
                    fontWeight: "normal"
                }
            },

            sourceType: this.sourceType,
            tooltip: this.tooltip,
            series: this.series
        };
    }
}
