import Scroller from "../../index";

/**
 * @this Scroller
 *
 * @returns {Function}
 */
const handleOnBottom = function() {
    const clientHeight = !this.isIFrame ? this.element.clientHeight : this.element.contentDocument.body.clientHeight;
    const scrollHeight = !this.isIFrame ? this.element.scrollHeight : this.element.contentDocument.body.scrollHeight;
    const scrollTop = !this.isIFrame ?  this.element.scrollTop : this.element.contentDocument.body.scrollTop;
    const isBottom = !this.config.onBottom.isPercent
        ? clientHeight + scrollTop >= scrollHeight - this.config.onBottom.distance
        : clientHeight + scrollTop >= scrollHeight * this.config.onBottom.distance;

    if (isBottom && this.config.onBottom.lastPosition < scrollTop)
        this.config.onBottom.callback();

    this.config.onBottom.lastPosition = scrollTop;
};

export default handleOnBottom;
