import Urls from "@/utils/Urls";
import _ from "lodash";

/**
 * Ruft die Zeilen eines Service-Endpunkts ab.
 *
 * @param {Boolean} [takeAll=false] Ein Wert, der angibt, ob alle Zeilen bis zur aktuellen Seite angefragt werden sollen.
 *
 * @returns {Promise<Array>} Die Zeilen einer Abfrage.
 */
const loadRows = async function (takeAll) {
    takeAll = takeAll === true;

    /**
     * Die Spalten.
     */
    let columns = this.config.columns;

    /**
     * Die Sortierung.
     */
    let ordering = this.ordering;

    /**
     * Die vorbereiteten Filter für die Abfrage.
     */
    let filter = this.filters();

    /**
     * Suchquery.
     */
    let search = this.searchValue;

    /**
     * Aktuelle Seite.
     */
    let page = this.page;

    /**
     * Anzahl der Zeilen pro Abfrage.
     */
    let take = Math.max(50, this.pageSize() * (!takeAll ? 1 : page + 2));

    /**
     * Offset der Abfrage.
     */
    let skip = !takeAll ? this.rows.length : 0;

    let options = {
        include: [],
        take: take,
        ordering: [],
        skip: skip
    };

    let matches = [];

    if (filter.json.length)
        options.filter = this.parameters().resolve(filter.plain, filter.json);
    if (search) options.search = search;
    if (ordering && ordering.length > 0) options.ordering = ordering;

    if (columns && columns.length === 0)
        return matches;

    options.include = _(columns)
        .filter(column => typeof _.get(column, "member", undefined) !== "undefined")
        .map(column => column.member)
        .value();

    const url = this.parameters().resolve(Urls(this.services, this.$config).Data.getItems(), filter.json);
    const response = await this.$request.post({
        url: url,
        content: options,
        abortController: this.request.abortController
    });

    this.totalRows = response && response.data && response.data.total || 0;

    matches = _.get(response, "data.matches", []);

    const modelName = `Rows-${this.source || this.layout.title && this.layout.title.toLowerCase()}`;

    if (!modelName)
        return matches;

    this.$model.register(modelName);

    const model = this.$model.get(modelName);

    matches.forEach(match => model.set(match.id, _.merge(model.get(match.id, {}), match)));

    model.commit();

    return matches;
};

/**
 * Aktualisiert alle Zeilen und anschließend die Scrollbar.
 *
 * @param {Number} page Die abzufragende Seitennummer (pagination).
 * @param {Boolean} appendRows Wert, ob die Zeilen an die bestehenen angehangen oder ersetzt werden sollen.
 * @param {Boolean} [takeAll=false] Ein Wert, der angibt, ob alle Zeilen bis zur aktuellen Seite angefragt werden sollen.
 */
export async function fetchRows(page, appendRows, takeAll) {
    appendRows = appendRows === true;

    this.page = page;

    const rows = await loadRows.call(this, takeAll);

    this.rows = appendRows ? this.rows.concat(rows) : rows;
}
