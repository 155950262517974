import { isNullOrUndefined, isSelectControl } from "@/utils/typeGuards";
import get from "lodash/get";
import Logger from "@/utils/Logger";

/**
 * Eine Klasse, die Bedingungen überprüft.
 */
class ConditionVerifier {
    private readonly Condition: TCondition;
    private readonly Log: boolean = false;

    /**
     * Erstellt eine neue Instanz des ConditionVerifier.
     *
     * @param condition Die Bedingung, die überprüft werden soll.
     * @param trigger Das Control, das die Bedingung auslöst.
     * @param valueResolver Ein Resolver, der den Wert einer Bedingung auflöst.
     * @param log Gibt an, ob die Bedingung protokolliert werden soll.
     */
    constructor({ condition, log = false }: { condition: TCondition, log?: boolean }) {
        this.Condition = condition;
        this.Log = log;
    }

    /**
     * Überprüft die Bedingung einer Aktion und gibt das Ergebnis zurück.
     *
     * @param value Der zu prüfende Wert.
     *
     * @returns Ein Promise, das ein boolesches Ergebnis enthält.
     *
     * @throws {Error} Wenn das Feld 'field' der Bedingung nicht definiert ist.
     * @throws {Error} Wenn die Vergleichsmethode nicht definiert ist.
     */
    public async verifyActionCondition({value}:{value: { [key: string]: unknown }}): Promise<boolean> {
        const field = this.Condition.field;

        if (field === undefined)
            throw new Error("Das Feld 'field' der Bedingung ist nicht definiert.");

        const methodName = this.Condition.$type ?? "default";
        const currentValue = get(value, field);

        let comparisonMethod: (a: unknown, b: unknown) => boolean;

        try {
            comparisonMethod = methodName === "default"
                ? () => true
                : (await import(`./ComparisionMethods/${methodName}.ts`)).default;
        } catch (error) {
            throw new Error(`Die Vergleichmethode ${methodName} ist nicht definiert.`);
        }

        const result = comparisonMethod(currentValue, this.Condition.value);

        this.Log && Logger.log("");
        this.Log && Logger.log(`Vergleich von ${methodName}( ${currentValue}, ${currentValue} ) ergab: ${result}`);
        this.Log && Logger.log("");

        return result;
    }

    /**
     * Überprüft die Bedingung eines Ereignisses und gibt das Ergebnis zurück.
     *
     * @returns Ein Promise, das ein boolesches Ergebnis enthält.
     *
     * @throws {Error} Wenn das Feld 'field' der Bedingung nicht definiert ist.
     * @throws {Error} Wenn die Vergleichsmethode nicht definiert ist.
     */
    public async verifyEventCondition({ trigger, valueResolver }: { trigger: TControl, valueResolver: TConditionValueResolver }): Promise<boolean> {
        const field = this.Condition.field;

        if (field === undefined)
            throw new Error("Das Feld 'field' der Bedingung ist nicht definiert.");

        let comparisonValue: unknown = 1;
        let currentValue: unknown = 0;

        /**
         * @todo Diese Bedingung ist unintuitiv und sollte überarbeitet werden. Wenn field den Wert "control.data" hat, wird der Wert
         * aus dem Feld "data" der Eigenschaft "items" des Controls verwendet. Es gibt momentan nur einen Fall, in dem das so ist: LoanForm.cs (Zeile 39)
         */
        if (field === "control.data") {
            const selectControl = isSelectControl(trigger) ? trigger : null;
            const affectedItem = selectControl?.items?.find(item => item.data === this.Condition.value);
            const member = (trigger.member ?? trigger.id);

            if (isNullOrUndefined(member))
                throw new Error("Zur Prüfung der Bedingung, muss das auslösende Steuerelement eine 'member' oder 'id' Eigenschaft besitzen.");

            comparisonValue = affectedItem?.value ?? 1;
            currentValue = this.Condition.currentValue !== undefined ? this.Condition.currentValue : valueResolver(member);
        } else {
            if (this.Condition.value !== undefined)
                comparisonValue = this.Condition.value;
            else if (this.Condition.compareField !== undefined)
                comparisonValue = valueResolver(this.Condition.compareField);

            currentValue = this.Condition.currentValue !== undefined ? this.Condition.currentValue : valueResolver(field);
        }

        const methodName = this.Condition.$type ?? "default";

        let comparisonMethod: (a: unknown, b: unknown) => boolean;

        try {
            comparisonMethod = methodName === "default"
                ? () => true
                : (await import(`./ComparisionMethods/${methodName}.ts`)).default;
        } catch (error) {
            throw new Error(`Die Vergleichmethode ${methodName} ist nicht definiert.`);
        }

        const result = comparisonMethod(currentValue, comparisonValue);

        this.Log && Logger.log("");
        this.Log && Logger.log(`Vergleich von ${methodName}( ${currentValue}, ${comparisonValue} ) ergab: ${result}`);
        this.Log && Logger.log("");

        return result;
    };
}

export default ConditionVerifier;
