<script lang="ts" setup>
import { ref, watch } from "vue";
import get from "lodash/get";

const emit = defineEmits(["click"]);

const props = defineProps({
    /**
     * Ein Objekt zur Darstellung der Listen-Einträge.
     */
    listItems: {
        type: Array,
        default: () => []
    },

    /**
     * Eine Eigenschaft, die angibt, welche Eigenschaft eines Listen-Objekts zur Darstellung
     * des Wertes eines Listenelements herangezogen werden soll.
     */
    propValue: {
        type: String,
        default: null
    },

    /**
     * Eine Eigenschaft, die angibt, welches Listenelement initial aktiv ist.
     */
    activeIndex: {
        type: Number,
        default: 0
    }
});

/**
 * Speichert den Index des aktiven Listenelements.
 */
const activeListItemIndex = ref(props.activeIndex);

/**
 * Eine Methode, die das Klick-Ereignis eines Listeneintrags an die Elternkomponente weiterdelegiert.
 *
 * @param item -  Das Listenobjekt
 * @param index - Der Index des Listenobjekts
 */
const emitClickToParent = (item:any, index:number) =>
    emit("click", { item, index });

/**
 * Gibt den Wert eines Listenelements zurück.
 *
 * @param item - Das Listenobjekt
 *
 * @returns Der Wert eines Listenelements.
 */
const listItemValue = (item:any):string => {
    if (!props.propValue)
        return item;

    return get(item, props.propValue, "N/A");
};

/**
 * Setzt die Eigenschaft "activeListItemIndex" auf den Index des ausgewählten Listenelements.
 *
 * @param index - Der Index des aktiven Listenobjekts
 */
const setActive = (index:number) =>
    activeListItemIndex.value = index;

watch(
    () => props.activeIndex,
    () => activeListItemIndex.value = props.activeIndex,
    { flush: "post" }
);
</script>

<template lang="pug">
ul
    li(
        v-for="(listItem, listItemIndex) in listItems"

        @click="emitClickToParent(listItem, listItemIndex); setActive(listItemIndex)"

        :key="'listItem' + listItemIndex"
        :class="{active: activeListItemIndex === listItemIndex}"
    )
        span(
            v-html="listItemValue(listItem)"
        )
</template>

<style lang="scss">
.list-control {
    input {
        cursor: default;
    }
}
</style>

<style lang="scss" scoped>
.navigation {
    position: relative;
    float: left;
    bottom: auto;

    background-color: var(--color-background);

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    width: auto;
    min-width: 100%;
    height: 36px;

    margin: 0px;
    padding: 0px;

    list-style-type: none;

    z-index: 1;

    overflow: hidden;
    white-space: nowrap;
    text-align: left;

    li {
        display: flex;
        align-items: center;
        justify-content: center;

        flex: 0 1 auto;

        margin: 0px;
        padding: 0px;

        width: 74px;
        line-height: 42px;
        height: 100%;

        vertical-align: middle;

        cursor: pointer;

        &:last-child {
            border-bottom: none;
        }

        span {
            width: 100%;
            height: 100%;
            color: var(--color-dialog-text-unit);
            background-color: var(--color-background);
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            font-size: 0.68em;
            font-weight: 500;
            display: inline-flex;

            text-overflow: ellipsis;
            padding: 0px 12px;

            text-align: center;
            transition: color 125ms ease-out;
            align-items: center;
            justify-content: center;
        }

        &:hover,
        &.active {
            span {
                color: var(--color-font-3);
                font-weight: 600;
                transition: color 125ms ease-out;
            }
        }

        &.active {
            span {
                background-color: $white;
            }
        }
    }
}
</style>
