import ToolkitTool from "../ToolkitTool";

/**
 * Ein Typ zur Beschreibung der möglichen Event-Namen
 */
type EventName =
  // Maus-Events
  | "click"
  | "dblclick"
  | "mousedown"
  | "mouseup"
  | "mousemove"
  | "mouseover"
  | "mouseout"
  | "mouseenter"
  | "mouseleave"
  | "contextmenu"

  // Tastatur-Events
  | "keydown"
  | "keyup"
  | "keypress"

  // Fokus-Events
  | "focus"
  | "blur"

  // Formular-Events
  | "change"
  | "input"
  | "submit"
  | "reset"

  // Fenster- und Dokument-Events
  | "resize"   // Wird ausgelöst, wenn die Fenstergröße geändert wird
  | "scroll"   // Wird ausgelöst, wenn ein Element gescrollt wird
  | "load"     // Wird ausgelöst, wenn die gesamte Seite geladen ist
  | "unload"   // Wird ausgelöst, wenn das Dokument entladen wird
  | "beforeunload" // Wird vor dem Entladen der Seite ausgelöst
  | "DOMContentLoaded" // Wird ausgelöst, wenn das HTML-Dokument vollständig geladen ist

  // Touch-Events
  | "touchstart"
  | "touchmove"
  | "touchend"
  | "touchcancel"

  // Drag-and-Drop-Events
  | "drag"
  | "dragstart"
  | "dragend"
  | "dragenter"
  | "dragleave"
  | "dragover"
  | "drop"

  // Sonstige Events
  | "wheel";   // Wird ausgelöst, wenn ein Mausrad bewegt wird

type EventCount = {
    amount: number
    listener: EventListenerOrEventListenerObject[]
}

type EventCounts = {
    [Key in EventName]: EventCount
}

/**
 * Ein Toolkit-Tool, das die Anzahl der registrierten Event-Listener zählt
 */
class CaptureEventsTool extends ToolkitTool {
    /**
     * Die Anzahl der Events nach Event-Typ
     */
    private _eventCounts: Partial<EventCounts> = new Proxy({} as Partial<EventCounts>, {
        set: (target, p: EventName, value: EventCount) => {
            target[p] = value;

            console.clear();
            console.table(target);
            console.log("Total: ", Object.values(this.eventCounts).reduce((acc, curr) => acc + curr.amount, 0));

            return true;
        }
    });


    /**
     * Ein Timer zur ausgabe aller registrierten Events in der Konsole
     */
    private watchTimer?: NodeJS.Timeout;

    /**
     * Ein Setter für die Event-Counts
     */
    set eventCounts(value: Partial<EventCounts>) {
        this._eventCounts = value;
    }

    /**
     * Ein Getter für die Event-Counts
     */
    get eventCounts(): Partial<EventCounts> {
        return this._eventCounts;
    }


    /**
     * Das ursprüngliche `addEventListener` von `document`
     */
    private originalAddEventListener?: typeof document.addEventListener;

    /**
     * Das ursprüngliche `removeEventListener` von `document`
     */
    private originalRemoveEventListener?: typeof document.removeEventListener;

    /**
     * Speichert die Anzahl der Registrierungen eines Event-Listeners
     */
    public capture() {
        if (this.originalAddEventListener || this.originalRemoveEventListener)
            return;

        this.originalAddEventListener = document.addEventListener.bind(document);
        this.originalRemoveEventListener = document.removeEventListener.bind(document);

        const _this: CaptureEventsTool = this;

        document.addEventListener = function(type: EventName, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions ): void {
            if (!_this.eventCounts[type])
                _this.eventCounts[type] = {
                    amount: 1,
                    listener: [listener]
                }
            else
                _this._eventCounts[type] = {
                    amount: _this.eventCounts[type]!.amount + 1,
                    listener: [..._this.eventCounts[type]!.listener, listener]
                };

            _this.originalAddEventListener?.(type, listener, options);
        };

        document.removeEventListener = function(type: EventName, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void {
            if (!_this.eventCounts[type])
                return _this.originalRemoveEventListener?.(type, listener, options);

            if (!_this.eventCounts[type]!.listener.includes(listener))
                return _this.originalRemoveEventListener?.(type, listener, options);

            _this.eventCounts[type] = {
                amount: _this.eventCounts[type]!.amount - 1,
                listener: _this.eventCounts[type]!.listener.filter(l => l !== listener)
            };

            _this.originalRemoveEventListener?.(type, listener, options);
        };
    }

    public watch() {
        if (this.watchTimer)
            return;

        this.watchTimer = setInterval(() => {
            console.clear();
            console.table(this.eventCounts);
            console.log("Total: ", Object.values(this.eventCounts).reduce((acc, curr) => acc + curr.amount, 0));
        }, 1000);
    }

    public unwatch() {
        if (!this.watchTimer)
            return;

        clearInterval(this.watchTimer);

        this.watchTimer = undefined;
    }

    /**
     * Stoppt das Erfassen der Events
     */
    public stop() {
        if (this.watchTimer) {
            clearInterval(this.watchTimer);
            this.watchTimer = undefined;
        }

        if (this.originalAddEventListener)
            document.addEventListener = this.originalAddEventListener;

        if (this.originalRemoveEventListener)
            document.removeEventListener = this.originalRemoveEventListener;

        this.originalAddEventListener = undefined;
        this.originalRemoveEventListener = undefined;
    }
}

export default CaptureEventsTool;
