/**
 * Konstante zur Vorbelegung der Optionen der Formatierungsmethoden.
 *
 * @const defaultOptions
 */
const defaultOptions = {
    /**
     * Optionen für Währungswert mit Währungssymbol.
     */
    asCurrency: {
        // Der einzusetzende Formatierungsstil
        style: "currency",

        // Tausender-Trenner aktivieren
        useGrouping: true,

        // Die minimale Anzahl von Ganzzahl Ziffern
        minimumIntegerDigits: 1,

        // Die bei der Währungsformatierung einzusetzende Währung.
        currency: "EUR",

        // Die minimale Anzahl von Nachkommastellen
        minimumFractionDigits: 0,

        // Die Maximale Anzahl von Nachkommastellen
        maximumFractionDigits: 2
    },

    /**
     * Optionen für Währungswert ohne Währungssymbol.
     */
    asCurrencyNoSymbol: {
        // Der einzusetzende Formatierungsstil
        style: "decimal",

        // Tausender-Trenner aktivieren
        useGrouping: true,

        // Die minimale Anzahl von Ganzzahl Ziffern
        minimumIntegerDigits: 1,

        // Die minimale Anzahl von Nachkommastellen
        minimumFractionDigits: 0,

        // Die Maximale Anzahl von Nachkommastellen
        maximumFractionDigits: 2
    },

    /**
     * Optionen für Prozentwerte.
     */
    asPercent: {
        // Der einzusetzende Formatierungsstil
        style: "percent",

        // Tausender-Trenner deaktivieren
        useGrouping: false,

        // Die minimale Anzahl von Ganzzahl Ziffern
        minimumIntegerDigits: 1,

        // Die minimale Anzahl von Nachkommastellen
        minimumFractionDigits: 0,

        // Die Maximale Anzahl von Nachkommastellen
        maximumFractionDigits: 2
    },

    /**
     * Optionen für Double Wert mit "qm" am Ende.
     */
    asSquareMeter: {
        // Der einzusetzende Formatierungsstil
        style: "decimal",

        // Tausender-Trenner deaktivieren
        useGrouping: true,

        // Die minimale Anzahl von Ganzzahl Ziffern
        minimumIntegerDigits: 1,

        // Die minimale Anzahl von Nachkommastellen
        minimumFractionDigits: 0,

        // Die Maximale Anzahl von Nachkommastellen
        maximumFractionDigits: 2
    },

    /**
     * Optionen für Double Wert.
     */
    asDouble: {
        // Der einzusetzende Formatierungsstil
        style: "decimal",

        // Tausender-Trenner deaktivieren
        useGrouping: true,

        // Die minimale Anzahl von Ganzzahl Ziffern
        minimumIntegerDigits: 1,

        // Die minimale Anzahl von Nachkommastellen
        minimumFractionDigits: 0,

        // Die Maximale Anzahl von Nachkommastellen
        maximumFractionDigits: 2
    }
};

export default defaultOptions;
