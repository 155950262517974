import { DataPoint } from "@/components/UI/TrendChart/components/data-point";
import SecurityInfo from "@/components/UI/TrendChart/components/security-info";

/**
 * Eine Klasse zur Generierung der TrendChart-Daten.
 */
 export default class TrendChart {
    points: DataPoint[];

    /**
     * C'tor
     *
     * @param points
     */
    constructor(points: DataPoint[]) {
        this.points = points;
    }

    /**
     * Wandelt eine Liste des vom Typ {@linkcode DataPoint} in eine {@linkcode SecurityInfo} Klasseninstanz um
     *
     * @returns {SecurityInfo}
     */
    toSecurityInfo(): SecurityInfo {
        const prices = this.points
            .map(point => {
                return {
                    d: point.date,
                    v: point.value
                };
            });

        return new SecurityInfo(prices);
    }
 }
