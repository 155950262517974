/**
 * Stellt die Dateipfade der Dateiicons zur Verfügung.
 *
 * @const {{grid: Object, list: Object}}
 */
const imgPaths = {
    grid: {
        archive: new URL("/assets/files/grid/archive.png", import.meta.url),
        audio: new URL("/assets/files/grid/audio.png", import.meta.url),
        document: new URL("/assets/files/grid/document.png", import.meta.url),
        folder: new URL("/assets/files/grid/folder.png", import.meta.url),
        "contacts": new URL("/assets/files/grid/contacts.png", import.meta.url),
        "contacts-empty": new URL("/assets/files/grid/contacts-empty.png", import.meta.url),
        "folder-empty": new URL("/assets/files/grid/folder-empty.png", import.meta.url),
        "folder-uploads": new URL("/assets/files/grid/folder-uploads.png", import.meta.url),
        "folder-uploads-empty": new URL("/assets/files/grid/folder-uploads-empty.png", import.meta.url),
        "general-docs": new URL("/assets/files/grid/general-docs.png", import.meta.url),
        "general-docs-empty": new URL("/assets/files/grid/general-docs-empty.png", import.meta.url),
        "finance-data": new URL("/assets/files/grid/finance-data.png", import.meta.url),
        "finance-data-empty": new URL("/assets/files/grid/finance-data-empty.png", import.meta.url),
        "reports": new URL("/assets/files/grid/reports.png", import.meta.url),
        "reports-empty": new URL("/assets/files/grid/reports-empty.png", import.meta.url),
        generic: new URL("/assets/files/grid/generic.png", import.meta.url),
        image: new URL("/assets/files/grid/image.png", import.meta.url),
        opl: new URL("/assets/files/grid/opl.png", import.meta.url),
        pdf: new URL("/assets/files/grid/pdf.png", import.meta.url),
        presentation: new URL("/assets/files/grid/presentation.png", import.meta.url),
        "recycle-bin": new URL("/assets/files/grid/recycle-bin.png", import.meta.url),
        "recycle-bin-empty": new URL("/assets/files/grid/recycle-bin-empty.png", import.meta.url),
        spreadsheet: new URL("/assets/files/grid/spreadsheet.png", import.meta.url),
        text: new URL("/assets/files/grid/text.png", import.meta.url),
        video: new URL("/assets/files/grid/video.png", import.meta.url)
    },

    list: {
        archive: new URL("/assets/files/list/archive.png", import.meta.url),
        audio: new URL("/assets/files/list/audio.png", import.meta.url),
        document: new URL("/assets/files/list/document.png", import.meta.url),
        folder: new URL("/assets/files/list/folder.png", import.meta.url),
        "folder-empty": new URL("/assets/files/list/folder-empty.png", import.meta.url),
        "folder-uploads": new URL("/assets/files/list/folder-uploads.png", import.meta.url),
        "folder-uploads-empty": new URL("/assets/files/list/folder-uploads-empty.png", import.meta.url),
        generic: new URL("/assets/files/list/generic.png", import.meta.url),
        image: new URL("/assets/files/list/image.png", import.meta.url),
        opl: new URL("/assets/files/list/opl.png", import.meta.url),
        pdf: new URL("/assets/files/list/pdf.png", import.meta.url),
        presentation: new URL("/assets/files/list/presentation.png", import.meta.url),
        "recycle-bin": new URL("/assets/files/list/recycle-bin.png", import.meta.url),
        spreadsheet: new URL("/assets/files/list/spreadsheet.png", import.meta.url),
        text: new URL("/assets/files/list/text.png", import.meta.url),
        video: new URL("/assets/files/list/text.png", import.meta.url)
    }
};

export default imgPaths;
