<script lang="ts" setup>
import type { PropType } from "vue";
import { computed, defineAsyncComponent, ref } from "vue";
import DataView from "@/components/UI/DataView/DataView.vue";
import intersection from "lodash/intersection";
import ReportView from "@/components/UI/ReportView/ReportView.vue";

const Accordion = defineAsyncComponent(() => import("@/components/UI/Accordion/Accordion.vue"));
const ContactView = defineAsyncComponent(() => import("@/components/UI/ContactView.vue"));
const DigitalAssistant = defineAsyncComponent(() => import("@/components/UI/DigitalAssistant/DigitalAssistant.vue"));
const Drive = defineAsyncComponent(() => import("@/components/Drive/Drive.vue"));
const FamilyTree = defineAsyncComponent(() => import("@/components/AncestryChart/FamilyTree.vue"));
const PresentationEditor = defineAsyncComponent(() => import("@/components/PresentationEditor/PresentationEditor.vue"));
const ReleaseNotes = defineAsyncComponent(() => import("@/components/UI/ReleaseNotes/ReleaseNotes.vue"));
const RichEditor = defineAsyncComponent(() => import("@/components/RichEditor/RichEditor.vue"));
const Stack = defineAsyncComponent(() => import("@/components/UI/Stack.vue"));
const Tabs = defineAsyncComponent(() => import("@/components/UI/Tabs/Tabs.vue"));
const TableOfContents = defineAsyncComponent(() => import("@/components/UI/ToC/toc.vue"));
const Toolbar = defineAsyncComponent(() => import("@/components/UI/Toolbar.vue"));

/**
 * Das Wurzel-{@linkcode HTMLElement} der Komponente
 */
const root = ref<HTMLElement>();

/**
 * Mögliche mountbare Komponenten.
 */
const mountables = [
    "accordion",
    "contactView",
    "dataView",
    "digitalAssistantView",
    "documentView",
    "familyView",
    "panel",
    "presentationEditorView",
    "releaseNotes",
    "reportView",
    "richEditorView",
    "stack",
    "tableOfContentsView",
    "tabs",
    "toolbarView"
];

const props = defineProps({
    /**
     * Die Panel-Strukturinformationen
     */
    panel: {
        type: Object as PropType<TPanel>,
        default: () => { return {}; }
    }
});

/**
 * Liefert die Ansicht der darzustellenen Komponente
 */
const type = computed<TValidView>(() => props.panel.view?.type ?? intersection(Object.keys(props.panel), mountables).at(0) as TValidView);

/**
 * Stylevorgaben (wird derzeitig nur von dem Stack-Steuerelement gesetzt).
 */
const style = ref({});

/**
 * Liefert die Layoutinformationen für die einzuhängende Unterkomponente.
 */
const layout = computed(() => {
    if (props.panel.view)
        return props.panel.view;

    if (type.value && props.panel.hasOwnProperty(type.value))
        return props.panel[type.value!];
});

/**
 * Freigegebene Eigenschaften für die Elternkomponente
 */
defineExpose({
    $el: root,
    panel: props.panel,
    style,
});
</script>

<template lang="pug">
//- Ein Panel zur Darstellung weiterer Komponenten.
    Das Panel ist selbstrekursiv und durchläuft die Struktur, welche durch die jeweils aktive Sektion eines Moduls
    geliefert wird.
section.panel(
    ref="root"

    :style="style"
    :class="type"
)
    Accordion(
        v-if="type === 'accordion'"

        :layout="layout"
    )

    ContactView(
        v-if="type === 'contactView'"

        :layout="layout"
    )

    DataView(
        v-if="type === 'dataView'"

        :layout="layout"
    )

    DigitalAssistant(
        v-if="type === 'digitalAssistantView'"

        :layout="layout"
    )

    Drive(
        v-if="type === 'documentView'"

        :layout="layout"
    )

    FamilyTree(
        v-if="type === 'familyView'"

        :layout="layout"
    )

    PresentationEditor(
        v-if="type === 'presentationEditorView'"

        :source="layout.source"
    )

    ReleaseNotes(
        v-if="type === 'releaseNotes'"
    )

    ReportView(
        v-if="type === 'reportView'"

        :layout="layout"
        :displayMode="layout.displayMode || 'inline'"
    )

    RichEditor(
        v-if="type === 'richEditorView'"

        :id="layout.id"
        :source="layout.source"
    )

    Stack(
        v-if="type === 'stack'"

        :layout="layout"
    )

    Tabs(
        v-if="type === 'tabs'"

        :tabs="layout"
    )

    Toolbar(
        v-if="type === 'toolbarView'"

        :controls="layout && layout.elements"
        :rightControls="layout && layout.rightElements"
        :passEvents="layout && layout.passEvents"
        :displayAs="layout && layout.displayAs"
    )

    TableOfContents(
        v-if="type === 'tableOfContentsView'"

        :linkedWithByComponentId="layout.linkedWith"
    )

</template>

<style lang="scss">
/** BEM - Block: Panel Komponente */
.panel {
    display: flex;
    flex-direction: column;
    height: 100%;

    /** BEM - Element: Panel Trenner zum öffnen/schließen sowie Größenveränderung. */
    &__collapser {
        position: relative;
        float: left;

        z-index: 3;
    }

    /** BEM - Modifizerer: Horizontale Größenveränderung von Panels */
    &__collapser--horizontal {
        width: 32px;
        height: 100%;
        flex: 0 0 32px;

        .icon__collapse {
            width: 100%;
            position: absolute;
            top: 50%;
            bottom: 0;

            height: 100%;
            width: 100%;

            transform: translateY(-50%);

            cursor: e-resize;

            .icon__html {
                height: 32px;
                transform: rotateZ(90deg);

                svg {
                    circle {
                        fill: var(--color-divider) !important;
                    }
                }
            }
        }
    }

    /** BEM - Modifizerer: Vertikale Größenveränderung von Panels */
    &__collapser--vertical {
        width: 100%;
        height: 32px;
        flex: 0 0 32px;

        .icon__collapse {
            position: absolute;
            left: 0;
            right: 0;

            height: 100%;
            width: 100%;

            cursor: n-resize;

            .icon__html {
                svg {
                    circle {
                        fill: var(--color-divider) !important;
                    }
                }
            }
        }
    }
}
</style>
