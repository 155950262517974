import type { Ref } from "vue";
/**
 * Liefert einen gecachten Wert.
 *
 * @param {ICache} $cache Die Cache-Bibliothek.
 * @param {string} id Die Id des Caches.
 * @param {string} key Der Zugriffsschlüssel des Caches.
 *
 * @returns {any} Der Wert des Caches.
 */
const get = ($cache: ICache, id: string, key?: string): any =>
    $cache.get(id, key, undefined);

/**
 * Setzt einen zu cachenden Wert.
 *
 * @param {Object} $cache Die Cache-Bibliothek.
 * @param {String} id Die Id des Caches.
 * @param {String} key Der Zugriffsschlüssel des Caches.
 * @param {Any} value Der zu setzende Wert des Caches.
 */
const set = ($cache: ICache, id: string, key: string, value: any) =>
    $cache.set(id, key, value);

const remove = ($cache: ICache, id: string, key: string) =>
    $cache.removeKey(id, key);

/**
 * Liefert Hilfsfunktionen für den Zugriff des Caches der übergebenen Komponenten-Id.
 *
 * @param params - Die Parameter
 * @param params.componentId - Die Komponenten-Id als {@link Ref<string>}
 * @param params.cache - Der Cache
 * @param params.reactiveCache - Der reaktive Cache
 *
 * @returns Die Cache-Methoden
 */
export default ({ componentId, cache, reactiveCache }: { componentId: { value: string }, cache: ICache, reactiveCache?: ICache }): ICacheApi => {
    const utils = {
        /**
         * Liefert alle Einträge des Caches
         *
         * @returns Die Einträge des Caches
         */
        getAll: () => cache.getAll(),

        /**
         * Liefert den gecachten Wert eines Cache-Schlüssels der Komponente.
         *
         * @param key Der Cache-Schlüssel
         *
         * @returns Der Wert im Cache
         */
        get: (key?: string): any => key
            ? get(cache, componentId.value, key)
            : get(cache, componentId.value),

        /**
         * Setzt den Wert des Caches eines Cache-Schlüssels der Komponente.
         *
         * @param key Der Cache-Schlüssel
         * @param value Der zu setzende Wert
         */
        set: (key: string, value: any) => {
            if (!componentId.value)
                return;

            set(cache, componentId.value, key, value);
        },

        /**
         * Entfernt den angegebenen Schlüssel des Cache der Komponente.
         *
         * @param key Der Cache-Schlüssel
         */
        remove: (key: string) => {
            if (!componentId.value)
                return;

            remove(cache, componentId.value, key);
        },

        /**
         * Liefert die Cache-Methoden einer individuellen Id.
         *
         * @param id Die Cache-Id
         */
        id: (id: string) => {
            return {
                /**
                 * Liefert den gecachten Wert eines Cache-Schlüssels der Komponente.
                 *
                 * @param key Der Cache-Schlüssel
                 *
                 * @returns Der Wert
                 */
                get: (key?: string): any => key
                    ? get(cache, id, key)
                    : get(cache, id),

                /**
                 * Setzt den Wert des Caches eines Cache-Schlüssels der Komponente.
                 *
                 * @param key Der Cache-Schlüssel
                 * @param value Der Wert
                 */
                set: (key: string, value: any) =>
                    set(cache, id, key, value),

                /**
                 * Entfernt den angegebenen Schlüssel des Cache der Komponente.
                 *
                 * @param key Der Cache-Schlüssel
                 */
                remove: (key: string) =>
                    remove(cache, id, key)
            };
        },

        reactive: {
            /**
             * Liefert alle Einträge des reaktiven Caches
             *
             * @returns Die Einträge des reaktiven Caches
             */
            getAll: () => reactiveCache?.getAll(),

            /**
             * Liefert den gecachten Wert eines Cache-Schlüssels der Komponente.
             *
             * @param key Der Cache-Schlüssel
             *
             * @returns Der Wert
             */
            get: (key?: string): any => {
                if (!reactiveCache)
                    return;

                return key
                    ? get(reactiveCache, componentId.value, key)
                    : get(reactiveCache, componentId.value);
            },

            /**
             * Setzt den Wert des Caches eines Cache-Schlüssels der Komponente.
             *
             * @param key Der Cache-Schlüssel
             * @param value Der Wert
             */
            set: (key: string, value: any) => {
                if (!componentId.value)
                    return;

                if (!reactiveCache)
                    return;

                set(reactiveCache, componentId.value, key, value);
            },

            /**
             * Entfernt den angegebenen Schlüssel des Cache der Komponente.
             *
             * @param key Der Cache-Schlüssel
             */
            remove: (key: string) => {
                if (!componentId.value)
                    return;

                if (!reactiveCache)
                    return;

                remove(reactiveCache, componentId.value, key);
            },

            /**
             * Übernimmt die Cacheänderungen.
             */
            commit: () =>
                reactiveCache?.commit(),

            /**
             * Liefert die Cache-Methoden einer individuellen Id.
             *
             * @param id Die Cache-Id
             */
            id: (id: string) => {
                return {
                    /**
                     * Liefert den gecachten Wert eines Cache-Schlüssels der Komponente.
                     *
                     * @param key Der Cache-Key
                     *
                     * @returns Der Wert
                     */
                    get: (key?: string): any => {
                        if (!reactiveCache)
                            return;

                        return key
                            ? get(reactiveCache, id, key)
                            : get(reactiveCache, id);
                    },

                    /**
                     * Setzt den Wert des Caches eines Cache-Schlüssels der Komponente.
                     *
                     * @param key Der Cache-Key
                     * @param value Der Wert
                     */
                    set: (key: string, value: any) => {
                        if (!reactiveCache)
                            return;

                        set(reactiveCache, id, key, value);
                    },

                    /**
                     * Entfernt den angegebenen Schlüssel des Cache der Komponente.
                     *
                     * @param key Der Cache-Schlüssel
                     */
                    remove: (key: string) => {
                        if (!reactiveCache)
                            return;

                        remove(reactiveCache, id, key);
                    }
                };
            }
        }
    };

    return utils;
};
