import murmurhash from "murmurhash";
import Logger from "@/utils/Logger";

let store:{[key:string]: string | null} = {};

/**
 * Holt das Icon entweder vom Cache oder vom CDN bzw. der Remote-Adresse ab.
 *
 * @param $config Die Konfigurations-API.
 * @param icon Die Bezeichnung des Icons.
 *
 * @returns Das SVG-Icon als String.
 */
const fetchIcon = async ({$config, icon}:{$config: IConfigApi, icon: string}) => {
    let url = `${$config.get("cdn.icons")}${icon.toLowerCase()}.svg`;
    let iconHash = murmurhash.v3(url);

    if (store[iconHash])
        return store[iconHash];

    try {
        let response = await fetch(url);

        if (response.ok === false)
            throw new Error();

        store[iconHash] = await response.text() || null;

        return store[iconHash];
    } catch (e) {
        Logger.error(`Das Icon mit der Bezeichnung - ${icon} - konnte nicht aufgelöst werden:`, e);
    }

    return null;
};

export default fetchIcon;
