import { isInteractiveFormElement, isInteractiveFormElementClosest } from "@/utils/typeGuards";
import { clearSelection, clearPreSelection, deselectRow, isSelected, selectByClick, selectByShiftClick, stopSelectionByCtrl } from "../lib/selection";

/**
 * (De-)Markiert eine Zeile.
 *
 * @param {Event} e Das Ereignis-Objekt.
 */
export default function (e) {
    if (isInteractiveFormElement(e.target) || isInteractiveFormElementClosest(e.target))
        return;

    clearPreSelection.call(this);

    if (this.state.table.focus) {
        if (!this.multiselect || (!this.state.ctrl.active && !this.state.shift.active && (!isSelected.call(this, e) || this.state.selected.length > 0))) {
            clearSelection.call(this);
            selectByClick.call(this, e, true);
            stopSelectionByCtrl.call(this);
        } else if (this.state.ctrl.active) {
            if (!isSelected.call(this, e))
                selectByClick.call(this, e, !this.state.ctrl.active);
            else {
                deselectRow.call(this, e);

                this.callback();
            }
        } else if (this.state.shift.active) {
            selectByShiftClick.call(this, e);
        }
    }
}
