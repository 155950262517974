import Bowser from "bowser";

/**
 * Der Bowser-Parser
 *
 * @constant {Parser} parser
 */
const parser = Bowser.getParser(navigator.userAgent);

/**
 * Gibt an, ob es sich um ein iOS-Bertriebsystem handelt.
 *
 * @constant {Boolean} isIOS
 */
const isIOS = parser.getOSName().toLowerCase() === "macos";

/**
 * Gibt an, ob es sich um ein Android-Betriebssytem handelt.
 *
 * @constant {Boolean} isAndroid
 */
const isAndroid = parser.getOSName().toLowerCase() === "android";

/**
 * Gibt an, ob es sich um den Microsoft Edge handelt.
 *
 * @constant {Boolean} isIE
 */
const isEdge = parser.getBrowserName().toLowerCase() === "microsoft edge" && parseInt(parser.getBrowserVersion()) < 79;

/**
 * Gibt an, ob es sich um den Internet Explorer handelt.
 *
 * @constant {Boolean} isIe
 * @deprecated
 */
const isIe = parser.getBrowserName().toLowerCase() === "internet explorer";

/**
 * Gibt an, ob es sich um ein mobiles Endgerät handelt.
 *
 * @constant {Boolean} isMobile
 */
const isMobile = parser.getPlatformType().toLowerCase() === "mobile";

/**
 * Gibt an, ob es sich um ein Tablet handelt.
 *
 * @constant {Boolean} isTablet
 */
const isTablet = parser.getPlatformType().toLowerCase() === "tablet";

/**
 * Gibt an, ob es sich um ein Touch-Gerät handelt.
 *
 * @constant {Boolean} isTouchDevice
 */
const isTouchDevice = isIOS || isAndroid;

/**
 * Prüft, ob es sich bei einem Ereignis um ein Touch-Ereignis handelt.
 *
 * @param {Event} e
 *
 * @returns {Boolean}
 */
const isTouchEvent = e => (window.TouchEvent && e instanceof TouchEvent) === true;

/**
 * Stellt Methoden zur Beauskunftung der nativen Popup-Möglichkeiten bereit.
 */
const popup = {
    /**
     * Liefert den Wert, ob der Browser Popups für die Seite zulässt.
     */
    available: async () => {
        return new Promise(resolve => {
            /**
             * Autopopup erzwingen, da Popups durch benutzerinteraktion immer zugelassen werden:
             */
            setTimeout(() => {
                const popup = window.open("./popup_check.html", "win", "toolbar=no, location=no, status=no, menubar=no, scrollbars=no, resizable=no, width=100, height=100, top=10,left=10");

                if (popup) resolve(true);
                else {
                    try {
                        popup.close();
                    } catch (e) {
                        /** Bereits selbst geschlossen */
                    }

                    resolve(false);
                }
            }, 2000);
        });
    }
};

const ios = {
    console: {
        /**
         * Bindet eine "Konsole" in das Browserfenster an gegebener Position ein.
         * (iOS - Konsolenausgabe)
         *
         * @param {"left"|"right"|"top"|"bottom"} position
         */
        show(position = "right") {
            if (!document.querySelector("#ios-console")) {
                const $console = document.createElement("div");

                $console.id = "ios-console";
                $console.style.position = "fixed";

                if (position === "left") {
                    $console.style.left = 0;
                    $console.style.top = 0;
                } else if (position === "top") {
                    $console.style.left = 0;
                    $console.style.top = 0;
                } else if (position === "bottom") {
                    $console.style.left = 0;
                    $console.style.bottom = 0;
                } else {
                    $console.style.right = 0;
                    $console.style.top = 0;
                }

                $console.style.display = "block";
                $console.style.overflow = "auto";
                $console.style.background = "white";
                $console.style.zIndex = 9999999;
                $console.style.color = "black";
                $console.style.height = ["left", "right"].includes(position) ? "100%" : "250px";
                $console.style.width = ["left", "right"].indexOf(position) === -1 ? "100%" : "250px";
                $console.style.height = ["left", "right"].includes(position) ? "100%" : "250px";

                $console.style.border = "2px red dashed";

                document.body.appendChild($console);
            }
        },

        /**
         * Hängt eine Nachricht an die aktuelle iOS-"Konsolenausgabe"
         *
         * @param {String} message
         */
        log(message) {
            if (!document.querySelector("#ios-console"))
                ios.console.show();

            document.querySelector("#ios-console").innerHTML += `<br>${message}`;
        },

        /**
         * Löscht die iOS-"Konsolenausgaben"
         */
        clear() {
            if (document.querySelector("#ios-console"))
                document.querySelector("#ios-console").innerHTML = "";
        }
    }
};

export {
    ios,
    isEdge,
    isIe,
    isIOS,
    isMobile,
    isTablet,
    isTouchDevice,
    isTouchEvent,
    popup,
};
