import formatDate from "../utils/formatDate";
import DriveFileRelease from "./DriveFileRelease";

/**
 * Stellt ein Dateisystemobjekt zur Verfügung.
 */
class DriveFileSystemObject implements IDriveFileSystemObject {
    /**
     * Die Freigabearten des Dateisystemobjekts
     */
    static ShareTypes: { None: string; Explicit: string; Inherited: string; } = {
        None: "none",
        Explicit: "explicit",
        Inherited: "inherited"
    };

    /**
     * Stellt eine Beschreibung des Dateisystemobjekts bereit
     */
    readonly description?: string;

    /**
     * Die Zugriffsberechtigung des Dateisystemobjekts.
     */
    access: IAccessType;

    /**
     * Die Basis-Adresse.
     */
    baseUrl: string | null;

    /**
     * Die Kontakt-Guid-Zuordnung des Dateisystemobjekts
     */
    contactId: string;

    /**
     * Das Erzeugungsdatum des Dateisystemobjekts.
     */
    created: Date | string | number;

    /**
     * Das Löschdatum des Dateisystemobjekts
     */
    deleted: Date | string | number;

    /**
     * Ein Wert, der angibt, ob ein Fehler während einer Dateioperation entstanden ist.
     */
    error = false;

    /**
     * Ein optionales Overlay-Symbol für einen Ordner.
     */
    overlay?: string;

    /**
     * Eine eindeutige Id des Dateisystemobjekts.
     */
    id: number | null = null

    /**
     * Ein Wert, der angibt, dass sich das Dateisystemobjekt im Client vom Dateisystemobjekt auf dem Server unterscheided (bspw. Namensänderung).
     */
    isDirty = false;

    /**
     * Ein Wert, der angibt, ob es sich um ein neues Dateisystemobjekt handelt.
     */
    isNew = false;

    /**
     * Ein Wert, der angibt, ob sich das Dateisytemobjekt gerade in der Vorauswahl befindet.
     */
    isPreselected = false;

    /**
     * Ein Wert, der angibt, ob sich das Dateisytemobjekt gerade in der Auswahl befindet.
     */
    isSelected = false;

    /**
     * Der Name des Dateisystemobjekts.
     */
    name: string | null = null;

    /**
     * Der vollständige Pfad des übergeordneten Verzeichnisses.
     */
    parent: string;

    /**
     * Der vollständige Pfad des Dateisystemobjekts.
     */
    path: string | null;

    /**
     * Ein Wert, der angibt, ob sich das aktuelle Dateisystemobjekt in einer Serveranfrage befindet.
     */
    pending = false;

    /**
      * Ein Wert, der angibt, ob sich die Datei im Papierkorb befindet.
      */
    recycled = false;

    /**
     * Die Punktebewertung im Rahmen einer Suchanfrage.
     */
    score?: number;

    /**
     * Ein Wert, der angibt, ob die Auswahl per Tocuh-Device erfolgt ist.
     */
    selectedByTap = false;

    /**
     * Ein Wert, der angibt, ob dieses Dateisystemobjekt das letzte ausgewählte Dateisystemobjekt im Auswahlmodus ist.
     */
    selectionEnd = false;

    /**
     * Ein Wert, der angibt, ob dieses Dateisystemobjekt das erste ausgewählte Dateisystemobjekt im Auswahlmodus ist.
     */
    selectionStart = false;

    /**
     * Ein Wert, der angibt, ob die Dateifreigaben gesetzt werden können
     */
    shareable = false;

    /**
     * Der Freigabestatus der Datei.
     */
    shareType: IShareType = <IShareType>DriveFileSystemObject.ShareTypes.None;

    /**
     * Die Suchtreffersnippets im Rahmen einer Suchanfrage
     */
    snippets: string[] = [];

    /**
     * Der Typ des Dateisystemobjekts (Datei oder Ornder)
     */
    type: IFsoType;

    /**
     * Die Angabe, ob es sich um ein virtuelles Dateisystemobjekt handelt.
     * Virtuelle Dateisystemobjekte können werder kopiert, noch gelöscht sowie verschoben werden.
     */
    virtual?: boolean;

    /**
     * C'tor
     *
     * @param {IDriveFileSystemObjectParams} options - Die Parameter
     * @param {string} baseUrl - Die Basis-Adresse
     */
    constructor(
        {
            access = <IAccessType>DriveFileRelease.AccessLevel.ReadWriteDelete,
            contactId,
            overlay,
            created = Date.now(),
            deleted = Date.now(),
            description,
            id = null,
            isDirty = false,
            isNew = false,
            shareable = false,
            shareType = "none",
            name = null,
            path = null,
            pending = false,
            type,
            virtual = false
        }: IDriveFileSystemObjectParams,
        baseUrl: string | null
    ) {
        let pathArray = path ? path.split("/") : [];

        if (pathArray.length > 0)
            pathArray.splice(pathArray.length - 1, 1);

        this.contactId = contactId;
        this.overlay = overlay;
        this.access = access || <IAccessType>DriveFileRelease.AccessLevel.ReadWriteDelete;
        this.deleted = deleted;
        this.description = description;
        this.type = <IFsoType>(type || "file").toLocaleLowerCase();
        this.name = name;
        this.path = path;
        this.id = id;
        this.pending = pending;
        this.baseUrl = baseUrl;
        this.parent = pathArray.join("/") || "/";
        this.created = created;
        this.shareable = shareable;
        this.shareType = shareType;
        this.virtual = virtual;

        this.isNew = isNew;
        this.isDirty = isDirty;
    }

    /**
     * Liefert ein menschenlesbares Datum aus dem Erzeugungsdatum des Dateisystemobjekts
     *
     * @returns Das Erzeugungsdatum.
     */
    getCreated(): IFormattedDate {
        return formatDate(this.created);
    }

    /**
     * Liefert ein menschenlesbares Datum aus dem Löschdatum des Dateisystemobjekts
     *
     * @returns Das Löschdatum
     */
    getDeleted(): IFormattedDate {
        return formatDate(this.deleted)
    }

    /**
     * Setzt das Erzeugungsdatum des Dateisystemobjekts.
     *
     * @param dateString - Das Erzeugungsdatum als String
     */
    setCreated(dateString: string) {
        this.created = dateString;

        return this;
    }

    /**
     * Setzt den Status, ob das Dateisystemobjekt ausgewählt ist.
     *
     * @param selected - Der Status, ob das Dateisystemobjekt ausgewählt ist.
     */
    setSelected(selected?: boolean) {
        this.isSelected = selected === true;

        return this;
    }

    /**
     * Setzt den Status, ob die Datei über eine Freigabe verfügt
     *
     * @param shareable - Der Status, ob die Datei über eine Freigabe verfügt
     */
    setShareable(shareable: boolean) {
        this.shareable = shareable;

        return this;
    }

    /**
     * Setzt den Status, ob das Dateisystemobjekt vorausgewählt ist.
     *
     * @param preselected - Der Status, ob das Dateisystemobjekt vorausgewählt ist.
     */
    setPreselected(preselected?: boolean) {
        this.isPreselected = preselected === true;

        return this;
    }

    /**
     * Setzt den Status, ob dieses Dateisystemobjekt zuerst im Auswahlmodus ausgewählt wurde.
     *
     * @param selectionStart - Der Status, ob dieses Dateisystemobjekt zuerst im Auswahlmodus ausgewählt wurde.
     */
    setSelectionStart(selectionStart?: boolean) {
        this.selectionStart = selectionStart === true;

        return this;
    }

    /**
     * Setzt den Status, ob dieses Dateisystemobjekt zuletzt im Auswahlmodus ausgewählt wurde.
     *
     * @param selectionEnd - Der Status, ob dieses Dateisystemobjekt zuletzt im Auswahlmodus ausgewählt wurde.
     */
    setSelectionEnd(selectionEnd?: boolean) {
        this.selectionEnd = selectionEnd === true;

        return this;
    }

    /**
     * Setzt den Namen des Dateisystemobjekts.
     *
     * @param name -  Der Name.
     */
    setName(name: string) {
        this.name = name;

        return this;
    }

    /**
     * Setzt den Zielpfad des Dateisystemobjekts.
     *
     * @param path - Der Zielpfad.
     */
    setPath(path: string = "") {
        path = path.replace(/\/{2,}/g, "/");

        this.path = path;

        const pathArray = this.path ? this.path.split("/") : [];

        if (pathArray.length > 0)
            pathArray.splice(pathArray.length - 1, 1);

        this.parent = pathArray.join("/") || "/";

        return this;
    }

    /**
     * Setzt den Status, ob das Dateisystemobjekt neu ist.
     *
     * @param isNew - Der Wert.
     */
    setNew(isNew: boolean) {
        this.isNew = isNew;

        return this;
    }

    /**
     * Setzt den Status, ob das Dateisystemobjekt umnenannt wird.
     *
     * @param isDirty - Der Wert.
     */
    setDirty(isDirty: boolean) {
        this.isDirty = isDirty;

        return this;
    }

    /**
     * Setzt den Fehlerstatus zum Highlighting einer fehlgeschlagenen Dateisytemoperation.
     *
     * @param hasError - Der Wert des Fehlerstatus.
     */
    setError(hasError: boolean) {
        this.error = hasError;

        return this;
    }

    /**
     * Erzeugt einen frei verfügbaren Verzeichnisnamen:
     *
     * 1) Wenn Name "Neuer Ordner" noch nicht vergeben, dann nutzen,
     * 2) sonst Name "Neuer Ordner (X)" zurückgeben, wobei (X > 1 und X ist nich nicht vergeben).
     *
     * @param fileSystemObjects - Die vorhandenen Dateisystemobjekte.
     *
     * @returns Der frei verfügbare Name.
     */
    generateDirectoryName(fileSystemObjects: IDriveDirectory[]): string {
        const rx = /Neuer Ordner \(([0-9]+)\)$/;

        let name = "Neuer Ordner";
        let usedNumbers = fileSystemObjects
            .filter(fso => RegExp(rx).test(fso.name!))
            .map(fso => parseInt(fso.name!.match(rx)![1]));

        if (fileSystemObjects.filter(fso => fso.name === "Neuer Ordner").length) {
            let newNumber = 2;

            for (var i = 0; i < usedNumbers.length; i++) {
                if (usedNumbers.indexOf(newNumber) === -1) break;

                newNumber = usedNumbers[i] + 1;
            }

            name += ` (${newNumber})`;
        }

        return name;
    }
}

export default DriveFileSystemObject;
