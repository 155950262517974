import notifyState from "./notifyState";
import Scroller from "../../index";
import updateThumbPositions from "./updateThumbPositions";

/**
 * Verarbeitet das Scrollereignis
 *
 * @this Scroller
 */
const handleScroll = function() {
    const scrollTop = !this.isIFrame ? this.element.scrollTop : this.element.contentDocument.body.scrollTop;
    const scrollLeft = !this.isIFrame ? this.element.scrollLeft : this.element.contentDocument.body.scrollLeft;

    window.requestAnimationFrame(() => updateThumbPositions.call(this, [scrollTop, scrollLeft]));
    notifyState.call(this);
};

export default handleScroll;
