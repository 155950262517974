/**
 * Stellt eine Datei-Klasse zur Verfügung.
 */
class DriveAuth {
    /**
     * Gibt an, ob es sich bei dem Benutzer um einen Gast handelt.
     */
    isGuest: boolean = false;

    /**
     * Gibt an, ob es sich um den restriktiven Modus zur Ausführung von Dateisystemoperationen handelt.
     */
    isRestricted: boolean = false;

    /**
     * Der Access-Token (Bearer)
     */
    get AccessToken() {
        const stored = localStorage.getItem("auth");

        if (stored)
            return JSON.parse(stored).accessToken;

        return "";
    }
}

export default DriveAuth;
