import { nextTick } from "vue";
import each from "lodash/each";
import get from "lodash/get";
import Scroller from "@/utils/scroll";

export default {
    /**
     * Aktualisiert die Tabelle.
     */
    refresh() {
        if (!this.$refs.reportView)
            return;

        if (!this.$refs.reportView.querySelector(".table"))
            return;

        let lastLevel = -1;
        let tableInx = -1;
        let tables = [];

        let rows = this.plan.lines || [];
        let columns = get(this.plan , "axis.0.items", []);

        each(rows, (row) => {
            let rowLevel = parseInt(row.level, 10);

            if (rowLevel !== lastLevel) {
                tableInx++;
                lastLevel = rowLevel;

                tables.push({
                    level: rowLevel,
                    rows: [row]
                });
            } else {
                tables[tableInx].rows.push(row);
            }
        });

        this.columns = columns;
        this.showColumns = ((this.plan.diagrams || []).find(diagram => diagram.type === "pieChart") || {showColumns: true}).showColumns !== false;
        this.rows = rows;
        this.tables = tables;

        nextTick(() => {
            this.scrollInstance && this.scrollInstance.destroy();

            this.scrollInstance = new Scroller({
                element: this.$refs.reportView.querySelector(".report__table.table"),
                height: "100%",

                bars: {
                    top: 29,
                    minSize: 50
                }
            });
        });
    }
};
