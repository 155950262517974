<script lang="ts" setup>
import { ref } from "vue";
// @ts-ignore
import { v4 as uuid } from "uuid";
import Icon from "@/components/UI/Icon.vue";

const emit = defineEmits(["update:checked"]);

/**
 * Die Checkbox-Referenz
 */
const checkbox = ref<HTMLInputElement>();

/**
 * Eine ID (DOM-Element-Id), um das Label mit der Checkbox zu verknüpfen.
 */
const elementId = uuid();

/**
 * Der Hover-Status der Checkbox (inkl. Label).
 */
const hover = ref(false);

const props = defineProps({
    /**
     * Eine Labelbezeichnung für das Input-Element.
     */
    label: {
        type: String,
        default: ""
    },

    /**
     * Die Position des Labels der Checkbox.
     */
    alignLabel: {
        type: String,
        default: "right"
    },

    /**
     * Gibt an, ob die Checkbox markiert ist.
     */
    checked: {
        type: Boolean,
        default: false
    },

    /**
     * Gibt an, ob der Wert der Checkbox änderbar ist.
     * Wenn der Wert nicht änderbar ist, wird statt des Change-Ereignisses ein Click-Ereignis an die Elternkomponente emittiert.
     */
    changeable: {
        type: Boolean,
        default: true
    },

    /**
     * Gibt an, ob die Checkbox deaktiviert ist.
     */
    disabled: {
        type: Boolean,
        default: false
    },

    /**
     * Ein Icon zur Darstellung der Checkbox
     */
    icon: {
        type: String,
        default: "check2"
    }
});

/*
 * Emittiert die Statusänderung der Checkbox an die Elternkomponente
 *
 * @param {MouseEvent} e Das Mausereignis
 */
const emitChange = (e:Event) => {
    if (!e.target)
        return;

    e.stopPropagation();

    if (props.disabled)
        return;

    emit("update:checked", (e.target as HTMLInputElement).checked ?? false);
};

</script>

<template lang="pug">
.checkbox(
    :class="{'checkbox--checked': checked, 'checkbox--hover': hover, 'checkbox--disabled': disabled}"

    @mouseenter="hover = true"
    @mouseleave="hover = false"
)
    label.checkbox__label(
        v-if="alignLabel === 'left' && label.length"
        v-html="label"

        :for="elementId"
    )

    label.checkbox__wrapper(
        :for="elementId"
        :title="label"
    )
        input.checkbox__input(
            v-if="changeable"

            :ref="'checkbox'"
            :checked="checked"

            type="checkbox"
            spellcheck="false"

            :disabled="disabled"
            :id="elementId"

            @change="emitChange"
        )

        input.checkbox__input(
            v-else

            type="checkbox"
            spellcheck="false"

            :checked="checked"
            :disabled="disabled"
            :id="elementId"

            @change.prevent="() => {}"
        )

        Icon(
            :icon="icon"
        )

    label.checkbox__label(
        v-if="alignLabel === 'right' && label.length"

        :for="elementId"

        v-html="label"
    )
</template>

<style lang="scss">
/** BEM - Block: Checkbox */
.checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    line-height: 18px;

    /** BEM - Element: Checkbox Wrapper */
    &__wrapper {
        position: relative;
        float: left;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    /** BEM - Element: Checkbox Input */
    &__input {
        position: absolute;
        display: none;
    }

    /** BEM - Element: Checkbox Label */
    &__label {
        color: var(--color-dialog-label);
        padding-left: 8px;
        line-height: 18px;
        height: 18px;
        text-overflow: ellipsis;
        text-align: left;
        overflow: hidden;
        width: 100%;
        font-size: 0.75em;
        cursor: pointer;
    }

    /** BEM - Block: Icon */
    .icon {
        /** BEM - Element: Der Icon-Wrapper */
        &__wrapper {
            position: relative;
            float: left;
            display: block;
            cursor: pointer;

            width: 18px;
            height: 18px;

            margin: 0px;

            background-color: #FFFFFF;

            border-radius: 4px;
            border: 1px var(--color-control-border) solid;

            transition: border 275ms ease-out;
        }

        /** BEM - Element: Der Html Container für das darzustellende Icon */
        &__html {
            width: 16px;
            height: 16px;

            /** HTML - Element: SVG Element (Der Icon-Inhalt) */
            > svg {
                height: 100%;

                transform: scale3d(0,0,0);
                transition: transform 135ms ease-out;

                path {
                    fill: var(--color-font-3);
                }
            }
        }
    }

    /** BEM - Modifizierer: Checkbox aktiv (checked) */
    &--checked {
        /** BEM - Block: Icon */
        .icon {
            /** BEM - Element: Der Html Container für das darzustellende Icon */
            &__html {
                /** HTML - Element: SVG Element (Der Icon-Inhalt) */
                > svg {
                    transform: scale3d(0.75,0.75,1);
                    transition: transform 135ms ease-out;

                    path {
                        transition: fill 275ms ease-out;
                    }
                }
            }
        }
    }

    /** BEM - Modifizierer: Checkbox deaktiviert */
    &--disabled {
        /** BEM - Block: Icon */
        .icon {
            /** BEM - Element: Der Icon-Wrapper */
            &__wrapper {
                cursor: default;
                background-color: var(--color-background);
            }

            &__html > svg path {
                fill: var(--color-form-input-disabled);
            }
        }

        /** BEM - Modifizierer: Checkbox/Label gehovert */
        &.checkbox--hover {
            /** BEM - Block: Icon */
            .icon {
                /** BEM - Element: Der Icon-Wrapper */
                &__wrapper {
                    border: 1px var(--color-control-border) solid;
                }

                &__html > svg path {
                    fill: var(--color-form-input-disabled);
                }
            }
        }

        .checkbox__label {
            cursor: default;
            color: var(--color-form-input-disabled);
        }
    }

    /** BEM - Modifizierer: Checkbox/Label gehovert */
    &--hover {
        /** BEM - Block: Icon */
        .icon {
            /** BEM - Element: Der Icon-Wrapper */
            &__wrapper {
                border: 1px var(--color-accent-2) solid;

                transition: border 275ms ease-out;
            }

            /** BEM - Element: Der Html Container für das darzustellende Icon */
            &__html {
                > svg {
                    path {
                        fill: var(--color-accent-2);
                        transition: fill 275ms ease-out;
                    }
                }
            }
        }
    }
}
</style>
