/**
 * Eine benutzerdefinierte Fehlerklasse, die verwendet wird, um anzuzeigen, dass eine Aktion fehlt.
 */
class MissingAction extends Error {
    /**
     * @inheritdoc
     */
    constructor(message: string) {
        super(message);

        this.name = "MissingAction";
    }
}

export default MissingAction;
