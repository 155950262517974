/**
 * Ein Typ für die Parameter eines Toolkit-Tools
 */
type Params = Record<string, unknown>;

/**
 * Eine abstrakte Klasse, die die Grundlage für Toolkit-Tools bildet
 */
abstract class ToolkitTool<ToolParams extends Params | undefined = undefined> {
    /**
     * Die Parameter des Tools
     */
    protected params: ToolParams;

    /**
     * C'tor
     *
     * @param params Die Parameter des Tools
     */
    constructor(params: ToolParams) {
        this.params = params;
    }
}

export default ToolkitTool;
