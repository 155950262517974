<script lang="ts" setup>
import { onMounted, onBeforeUnmount, ref } from "vue";
import debounce from "lodash/debounce";

const emit = defineEmits(["blur", "change", "clear", "focus"]);

const props = defineProps({
    /**
     * Die Verzögerung, bis die Debounce-Methode aufgerufen wird.
     */
    delay: {
        type: Number,
        default: 500
    },

    /**
     * Gibt an, ob das Eingabefeld deaktiviert ist.
     */
    disabled: {
        type: Boolean,
        default: false
    },

    /**
     * Der Wert des Elements.
     */
    value: {
        type: String,
        default: ""
    }
});

/**
 * Das Eingabefeld {@link HTMLInputElement}.
 */
const input = ref<HTMLInputElement>();

/**
 * Emittiert die Information, dass das Eingabefeld fokussiert wurde.
 */
const emitFocus = () => emit("focus");

/**
 * Emittiert die Info an die Elternkomponente, dass die Eingabe
 * leergetragen wurde.
 */
const emitImmediate = () => {
    if (!input.value?.value.length)
        emit("clear", "");
};

/**
 * Emittiert den Eingabewert nach verlassen des Eingabefeldes.
 */
const emitBlur = () =>
    emit("blur", input.value?.value ?? "");

/**
 * Eine Methode zur verzögerten Eingabe des Eingabefelds
 */
const debounceInput = debounce(() => {
    if (input.value?.value.length)
        emit("change", input.value.value);
}, props.delay);

onMounted(() => {
    input.value?.addEventListener("input", debounceInput);
    input.value?.addEventListener("input", emitImmediate);
});

onBeforeUnmount(() => {
    input.value?.removeEventListener("input", debounceInput);
    input.value?.removeEventListener("input", emitImmediate);
});
</script>

<template lang="pug">
//- Ein Formelement, dessen Änderungen verzögert kommuniziert werden.
    Siehe auch https://codepen.io/dcorb/full/KVxGqN/
input(
    :value="value"
    :disabled="disabled"

    ref="input"

    spellcheck="false"

    @focus="emitFocus"
    @blur="emitBlur"
    @keydown.stop="() => {}"
)
</template>
