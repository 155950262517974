import $ from "@/utils/dom";
import Scroller from "../../index";

/**
 * Verarbeitet das {@linkcode MouseEvent} mousedown auf dem Skrollbalken der vertikalen Achse
 *
 * @this {Scroller}
 *
 * @param {MouseEvent} e
 */
const verticalMouseDown = function (e) {
    e.preventDefault();
    e.stopPropagation();

    this.scrollerState.vertical.isDragging = true;
    this.scrollerState.vertical.pointerPos = e.clientY;
    this.scrollerState.vertical.lastThumbPosition = this.scrollerState.vertical.thumbPosition;

    if ($(this.elements.scrollContainer).hasClass("scrollcontainer--scrolling"))
        return;

    $(this.elements.scrollContainer).addClass("scrollcontainer--scrolling");
};

export default verticalMouseDown;
