import { DisplayMode, EditMode } from "./Enums";

/**
 * Stellt ein Tagobjekt zur Verfügung.
 */
class DriveTag implements IDriveTag {
    /**
     * Die möglichen Darstellungsarten des Tags.
     */
    static DisplayMode = {
        "Default": 0,
        "Configure": 1,
        "New": 2
    };

    /**
     * Die möglichen Bearbeitungsmodi des Tags.
     */
    static EditMode = {
        "Color": 0,
        "New": 1,
        "None": 2
    };

    /**
     * Darstellungsmodus des Tags
     */
    _displayMode:DisplayMode = DisplayMode.Default;

    /**
     * Status Duplikat.
     */
    _duplicate = false

    /**
     * Status Editiermodus.
     */
    _editMode:EditMode = EditMode.None;

    /**
     * Ändert den Darstellungsmodus.
     *
     * @param mode - Der Darstellungmodus.
     */
    setDisplayMode(mode:DisplayMode) {
        this._displayMode = mode;
    }

    /**
     * Gibt den Darastellungsmodus zurück.
     *
     * @returns Der Darstellungmodus.
     */
    getDisplayMode() {
        return this._displayMode;
    }

    /**
     * Versetzt das Tag in einen Bearbeitungsmodus.
     *
     * @param mode - Der Bearbeitungsmodus.
     */
    setEditMode(mode:EditMode) {
        this._editMode = mode;
    }

    /**
     * Liefert den Wert, ob das Tag im Editiermodus ist.
     *
     * @returns Der Wert, ob das Tag im Editiermodus ist.
     */
    getEditMode() {
        return this._editMode;
    }

    /**
     * Versetzt das Tag in den "Bereits vorhanden"-Status.
     *
     * @param duplicate - Der Wert, ob das Tag bereits vorhanden ist.
     */
    setDuplicate(duplicate:boolean) { this._duplicate = duplicate; }

    /**
     * Liefert den "Bereits vorhanden"-Status des Tags.
     *
     * @returns Der Status, ob das Tag bereits vorhanden ist.
     */
    getDuplicate() { return this._duplicate; }

    /**
     * Die Bezeichnung des Tags.
     */
    label = "Tag";

    /**
     * Die Hintergrundfarbe des Tags.
     */
    background = "#000000";

    /**
     * Erzeugt eine Instanz der Klasse
     *
     * @param options - Die Optionen des Tags.
     * @param options.label - Die Bezeichnung des Tags.
     * @param options.color - Die Hintergrundfarbe des Tags.
     */
    constructor({label = "Tag", color = "#000000"} = {}) {
        this.label = label;
        this.background = color;
    }
}

export default DriveTag;
