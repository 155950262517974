<script lang="ts" setup>
import { computed } from "vue";
import { injectUtility } from "@/utils/utility.helper";
import Constant from "@/utils/Constant";
import Pending from "./UI/Pending/Pending.vue";
import SvgLogo from "@/assets/logo-gs.svg";

const $auth = injectUtility("Authentication");
const $modal = injectUtility("Modal");
const $model = injectUtility("Model");

/**
 * Das Model des SplashScreens
 */
const model = computed(() => $model.get("SplashScreen"));

/**
 * Die aktuelle Statusmeldung
 */
const requestMessage = computed(() => model.value.get("requestMessage", "Die Daten werden geladen ..."));

/**
 * Der Status des Ladenvorgangs
 */
const isReady = computed(() => model.value.get("isReady", false));

/**
 * Der Fehlerstatus des Ladenvorgangs
 */
const errorOnLoad = computed(() => model.value.get("errorOnLoad", false));

/**
 * Der Statuscode der Server-Abfrage
 */
const statusCode = computed(() => model.value.get("statusCode", 200));

/**
 * Der Neu-Laden Button
 */
const reloadButton = computed(() => {
    if (statusCode.value !== 403 && statusCode.value !== 401)
        return {
            title: Constant.Button.RETRY.Caption,
            event: () => document.location.reload()
        };

    return {
        title: "Anmelden als",
        event: async () => {
            if (await $auth.logout())
                return;

            $modal.show("dialog", {
                title: Constant.Modal.LOGOUT.Title,
                message: Constant.Modal.LOGOUT.Description,

                buttons: {
                    cancel: {
                        caption: Constant.Button.OK.Caption,
                        accesskey: Constant.Button.OK.Accesskey,
                    }
                }
            });
        }
    };
});
</script>

<template lang="pug">
//- Die Sartseiten-Komponente vor Authentifizierung.
transition(name="fade")
    div#overlay(
        v-show="isReady !== true || errorOnLoad"
    )
        .table-cell(
            v-if="isReady !== true || errorOnLoad"
        )
            SvgLogo#logo-right(
                class="logo-gs no-mobile"
            )

            Pending(
                :pending="!isReady"
                :error="errorOnLoad"
                :message="requestMessage"
                :button="reloadButton"
            )
</template>

<style lang="scss">
:root {
    --pending-overlay: url("//cdn.gschwind-software.de/images/lighthouse/seaside/landscape.svg") no-repeat center bottom;
}

#overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    display: table;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background: var(--pending-overlay);
    background-size: cover;
    z-index: 9999;

    .table-cell {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        height: 100%;
        width: 100%;

        .loading-animation {
            .pending {
                gap: 1em;
            }

            .messages span,
            .error > span {
                color: $white;
                font-size: 1.5em;
            }
        }
    }

    #logo-right {
        position: absolute;
        right: 50px;
        top: 50px;
        height: 42px;
    }
}
</style>
