import $ from "@/utils/dom";
import Scroller from "../../index";
import updateThumbPosition from "./updateThumbPosition";

/**
 * Setzt den Dragstatus der horizontalen und vertikalen Achse zurück
 */
const unsetDragState = function(this: Scroller) {
    ["horizontal", "vertical"]
        .forEach(axis => this.scrollerState[axis as "horizontal" | "vertical"].isDragging = false);

    $(this.elements.scrollContainer)
        .removeClass("scrollcontainer--scrolling");

    if (this.scroll === "normal")
        return;

    updateThumbPosition.call(this, {
        axis: "vertical",
        scrollTo: this.element.scrollTop
    });
};

export default unsetDragState;
